import {useMemo, useState} from "react";
import {getRowStyle} from "../../forecast_simulator_scenario/helpers/ag_grid_helpers";

// FAKE DATA for the table
const fakeColDefs = [
  {
    headerName: '',
    field: 'rowObject'
  },
  {
    headerName: 'Facts',
    field: 'Facts'
  },
  {
    headerName: 'Y1 M1',
    field: 'Y1_M1'
  },
  {
    headerName: 'Y1 M2',
    field: 'Y1_M2'
  },
  {
    headerName: 'Y1 M3',
    field: 'Y1_M3'
  },
  {
    headerName: 'Y1 M4',
    field: 'Y1_M4'
  },
  {
    headerName: 'Y1 M5',
    field: 'Y1_M5'
  },
  {
    headerName: 'Y1 M6',
    field: 'Y1_M6'
  },
  {
    headerName: 'Y1 M7',
    field: 'Y1_M7'
  },
  {
    headerName: 'Y1 M8',
    field: 'Y1_M8'
  },
  {
    headerName: 'Y1 M9',
    field: 'Y1_M9'
  },
  {
    headerName: 'Y1 M10',
    field: 'Y1_M10'
  },
  {
    headerName: 'Y1 M11',
    field: 'Y1_M11'
  },
  {
    headerName: 'Y1 M12',
    field: 'Y1_M12'
  }
];

const fakeRowData = [
  {
    rowObject: 'Scenario',
    Facts: 'Value Sales, USD',
    Y1_M1: '$X,XXX,XXX',
    Y1_M2: '$X,XXX,XXX',
    Y1_M3: '$X,XXX,XXX',
    Y1_M4: '$X,XXX,XXX',
    Y1_M5: '$X,XXX,XXX',
    Y1_M6: '$X,XXX,XXX',
    Y1_M7: '$X,XXX,XXX',
    Y1_M8: '$X,XXX,XXX',
    Y1_M9: '$X,XXX,XXX',
    Y1_M10: '$X,XXX,XXX',
    Y1_M11: '$X,XXX,XXX',
    Y1_M12: '$X,XXX,XXX'
  },
  {
    rowObject: 'Scenario',
    Facts: 'Unit Sales',
    Y1_M1: 'X,XXX,XXX',
    Y1_M2: 'X,XXX,XXX',
    Y1_M3: 'X,XXX,XXX',
    Y1_M4: 'X,XXX,XXX',
    Y1_M5: 'X,XXX,XXX',
    Y1_M6: 'X,XXX,XXX',
    Y1_M7: 'X,XXX,XXX',
    Y1_M8: 'X,XXX,XXX',
    Y1_M9: 'X,XXX,XXX',
    Y1_M10: 'X,XXX,XXX',
    Y1_M11: 'X,XXX,XXX',
    Y1_M12: 'X,XXX,XXX'
  },
  {
    rowObject: 'Benchmark',
    Facts: 'Value Sales, USD',
    Y1_M1: '$X,XXX,XXX',
    Y1_M2: '$X,XXX,XXX',
    Y1_M3: '$X,XXX,XXX',
    Y1_M4: '$X,XXX,XXX',
    Y1_M5: '$X,XXX,XXX',
    Y1_M6: '$X,XXX,XXX',
    Y1_M7: '$X,XXX,XXX',
    Y1_M8: '$X,XXX,XXX',
    Y1_M9: '$X,XXX,XXX',
    Y1_M10: '$X,XXX,XXX',
    Y1_M11: '$X,XXX,XXX',
    Y1_M12: '$X,XXX,XXX'
  },
  {
    rowObject: 'Benchmark',
    Facts: 'Unit Sales',
    Y1_M1: 'X,XXX,XXX',
    Y1_M2: 'X,XXX,XXX',
    Y1_M3: 'X,XXX,XXX',
    Y1_M4: 'X,XXX,XXX',
    Y1_M5: 'X,XXX,XXX',
    Y1_M6: 'X,XXX,XXX',
    Y1_M7: 'X,XXX,XXX',
    Y1_M8: 'X,XXX,XXX',
    Y1_M9: 'X,XXX,XXX',
    Y1_M10: 'X,XXX,XXX',
    Y1_M11: 'X,XXX,XXX',
    Y1_M12: 'X,XXX,XXX'
  },
  {
    rowObject: 'Execution',
    Facts: 'Distribution, %',
    Y1_M1: 'XX,XX%',
    Y1_M2: 'XX,XX%',
    Y1_M3: 'XX,XX%',
    Y1_M4: 'XX,XX%',
    Y1_M5: 'XX,XX%',
    Y1_M6: 'XX,XX%',
    Y1_M7: 'XX,XX%',
    Y1_M8: 'XX,XX%',
    Y1_M9: 'XX,XX%',
    Y1_M10: 'XX,XX%',
    Y1_M11: 'XX,XX%',
    Y1_M12: 'XX,XX%',
  },
  {
    rowObject: 'Execution',
    Facts: 'Average price',
    Y1_M1: 'XX,XX%',
    Y1_M2: 'XX,XX%',
    Y1_M3: 'XX,XX%',
    Y1_M4: 'XX,XX%',
    Y1_M5: 'XX,XX%',
    Y1_M6: 'XX,XX%',
    Y1_M7: 'XX,XX%',
    Y1_M8: 'XX,XX%',
    Y1_M9: 'XX,XX%',
    Y1_M10: 'XX,XX%',
    Y1_M11: 'XX,XX%',
    Y1_M12: 'XX,XX%',
  },
  {
    rowObject: 'Execution',
    Facts: 'Average unit size',
    Y1_M1: 'XX,XX%',
    Y1_M2: 'XX,XX%',
    Y1_M3: 'XX,XX%',
    Y1_M4: 'XX,XX%',
    Y1_M5: 'XX,XX%',
    Y1_M6: 'XX,XX%',
    Y1_M7: 'XX,XX%',
    Y1_M8: 'XX,XX%',
    Y1_M9: 'XX,XX%',
    Y1_M10: 'XX,XX%',
    Y1_M11: 'XX,XX%',
    Y1_M12: 'XX,XX%',
  }
]

export const agGridInit = ({
                             executionTableRef
}) => {
  const [gridReady, setGridReady] = useState(false);
  const [colDefs, setColDefs] = useState(fakeColDefs);
  const [rowData, setRowData] = useState(fakeRowData);

  const onGridReady = () => {
    try {
      setGridReady(true);
    } catch (error) {
      console.error('An error occurred during preparing rows for table:', error);
    }
  };

  const defaultColDef = useMemo(() => ({
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  }), []);

  return {
    columnDefs: colDefs,
    rowStyle: { background: '#FBFCFE' },
    defaultColDef,
    getRowStyle: (params) => getRowStyle(params, {}),
    rowData: rowData,
    onGridReady
  }
}
