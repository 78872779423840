import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { isCollaborator, isDecider } from "../../../helpers/user_helpers";
import Button from 'react-bootstrap/Button';
import { useSearchParams } from 'react-router-dom'
import { isBlank } from "../../../helpers/common";
import Decision from "../../../models/decision";
import { openModal } from "../../../store/modals/actions";
import { openRateBuyInModal } from "../../modals/RateBuyInModal";

export const RateBuyInSection = ({ user, decision, openModal }) => {
  if (!isDecider(user) && !isCollaborator(user)) return null;

  const [searchParams, setSearchParams] = useSearchParams();
  const decisionObj = new Decision(decision)

  useEffect(() => {
    if (searchParams.get('modal_open') === 'buy-in') {
      if(decision.rate_support) openRateBuyInModal({ decision, openModal })
      searchParams.delete('modal_open')
      setSearchParams(searchParams);
    }
  }, []);

  return <div className={`px-3 ${!decisionObj.isDisplayBuyIn ? 'mb-2' : ''}`}>
    <Button onClick={() => openRateBuyInModal({ decision, openModal })}
            className="btn btn-primary w-100 mb-2"
            hidden={isBlank(decision.rate_support)}>
      Rate your level of support
    </Button>
  </div>
};
const mapStateToProps = ({ current_user, decision}) => ({
  decision, user: current_user
});
export default connect(mapStateToProps, { openModal })(RateBuyInSection);
