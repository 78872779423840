import React from "react";
import { AgGridReact } from "ag-grid-react";
import { agGridInit } from "../helpers/execution_table_helpers";

const ExecutionTable = ({ executionTableRef }) => {
  const agGridOpts = agGridInit({ executionTableRef });

  return <div className="bg-white rounded mb-3">
    <div className="row p-3">
      <div className="col ag-theme-quartz ag-quartz-styles">
        <AgGridReact {...{...agGridOpts}} ref={executionTableRef}/>
      </div>
    </div>
  </div>
}
export default ExecutionTable;