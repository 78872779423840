import React from 'react';
import { connect } from "react-redux";
import { copyChoices, setDecisionInputSidebarOpen } from "../../../store/sidebar/actions";
import { isBlank, isPresent } from "../../../helpers/common";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import Button from 'react-bootstrap/Button';
import { decisionObjects, isNonUser } from "./helpers";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import {
  isDecisionInEditModeByOthers,
  decisionEditingByUser
} from "../../../helpers/channel_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../../../channels/tree_channel";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";

export const DisabledDecisionButton = ({ editingByUser, buttonText, classes = '' }) =>
  <TooltipWrapper bubbleIcon="decision-input"
                         placement={'bottom'}
                         tooltipText={`${editingByUser} is editing this decision.`}>
    <Button disabled={true}
            className={`btn btn-secondary w-100 ${classes} `}>
      {buttonText}
    </Button>
  </TooltipWrapper>


const RenderDecisionInputSidebar = ({ channels,
                                      decision, current_user, sidebar,
                                      openDecisionInputSidebar, copyChoices,
                                      addClass = '', isHamburgerBtn = false
                                    }) => {
  const {
    recommendation, recommendationObject, decisionObject
  } = decisionObjects(decision)

  if (isNonUser(current_user)) return null;
  if (isBlank(decision)) return null;
  if (decisionObject.isTreeHistorical && decisionObject.withRecommendation && !recommendationObject.isEnteredRecommendation) return null;
  if (decisionObject.isTreeRecommendation && !recommendationObject.isEnteredRecommendation && isPresent(recommendation)) return null;
  if (decisionObject.isDecisionRecorded) return null;

  const onDecisionEntryOpen = () => {
    if(isBlank(decisionObject.decisionChoices) && isPresent(recommendationObject.recommendationChoices)) {
      copyChoices((success) => {
        if (success) openDecisionInputSidebar(!sidebar.decisionInputSidebar)
      })
    } else {
      openDecisionInputSidebar(!sidebar.decisionInputSidebar)
    }
  }

  const buttonText = () =>
    isDSightDecision(decision) && !recommendationObject?.isEnteredRecommendation ?
      "Enter decision" : "Enter what was decided"

  if (isHamburgerBtn) {
    return <BaseHamburgerBtn onSelect={onDecisionEntryOpen} title="Enter what was decided" />
  }

  if(isDecisionInEditModeByOthers(channels, TREE_CHANNEL_ACTIONS.edit_decision_input, decision.slug, current_user)) {
    const editingByUser = decisionEditingByUser(channels, TREE_CHANNEL_ACTIONS.edit_decision_input, decision)
    return <DisabledDecisionButton editingByUser={editingByUser} buttonText={buttonText()} classes={addClass} />
  }

  return <Button onClick={onDecisionEntryOpen} className={`btn btn-secondary w-100 h-48 ${addClass}`}>
    {buttonText()}
  </Button>
}
const mapStateToProps = ({ current_user, decision, sidebar, channels }) => ({
  current_user, decision, sidebar, channels
});
export default connect(mapStateToProps, {
  openDecisionInputSidebar: setDecisionInputSidebarOpen, copyChoices
})(RenderDecisionInputSidebar);
