import {isPresent} from "./common";
import {
  performAiCompleteDriversAction,
  treeChannelIsConnected
} from "./channel_helpers";
export const AI_ERROR_MESSAGE = 'Oops! Something happened. Please try again.';
export const REVIEWING_DRIVERS_TEXT = 'CloverpopAI is reviewing the contributing drivers';
export const DATA_SOURCES_READY_TEXT = 'Data sources ready, now under review';
export const PREPARING_SOURCES_TEXT = 'CloverpopAI is preparing the data sources';
export const REFINE_TEXT = 'CloverpopAI is refining the results based on additional instructions';
export const FETCH_DRIVERS_TEXT = 'CloverpopAI is fetching the contributing drivers';
export const MAX_AI_RETRIES = 2;

export const getDotsLoaderText = (aiUseOnlyDrivers, countAnsweredChildren, reviewingText, index) => {
  if (index > 0) return REFINE_TEXT;
  if (aiUseOnlyDrivers) return REVIEWING_DRIVERS_TEXT;
  return countAnsweredChildren > 1 ? reviewingText : DATA_SOURCES_READY_TEXT;
};

export const getInitContext = (type, decision, driverData) => {
  const contexts = {
    'recommendation': decision.recommendation?.chat_gpt_queries || [],
    'decision': decision.chat_gpt_queries || [],
    'driver': driverData.driver?.chat_gpt_queries || []
  };

  return contexts[type] || [];
};

export const getInitInstruction = (type, decision, driverData) => {
  const instructions = {
    'decision': decision.ai_instructions || '',
    'recommendation': decision.ai_instructions || '',
    'driver': driverData.driver?.ai_instructions || ''
  };

  return instructions[type] || '';
};

export const gptApiSummarizeRequest = (
  chatGptDriverSummarizeRequest, setLoaded, context, refineMessage,
  setContext, type, objectSlug, setRefineMessage, setLoadedVs, only_assigned_drivers,
  decisionSlug, updateDecisionData
) => {
  setLoadedVs(true)
  setLoaded(false)
  updateDecisionData({ errorAi: '', loadingAiData: true });
  const callback = (success) => {
    if (success) {
      setTimeout(() => setLoaded(true), 50)
      setRefineMessage('')
    }
  }

  if (isPresent(refineMessage)) {
    context.push({role: 'user', content: refineMessage})
  }
  const data = {context, generated_for: {type, slug: objectSlug}, only_assigned_drivers}
  chatGptDriverSummarizeRequest(data, callback, setContext, decisionSlug)
}

export const apiAiResponseRequest = (
  aiResponseRequest, setLoaded, context, refineMessage,
  setContext, objectSlug, setRefineMessage, type,
  vectorStoreUploadFilesRequest, setLoadedVs, only_assigned_drivers,
  decisionSlug, updateDecisionData
) => {
  setLoadedVs(false)
  updateDecisionData({ errorAi: '', loadingAiData: true });
  const callback = (success) => {
    setTimeout(() => setLoaded(true), 50);
    if (success) {
      setRefineMessage('')
    }
  }

  if (isPresent(refineMessage)) {
    context.push({role: 'user', content: refineMessage})
  }
  const generated_for = {type, slug: objectSlug}
  vectorStoreUploadFilesRequest({generated_for}, (success) => {
    setLoadedVs(true)
    setLoaded(false)
    if (success) {
      const data = {context, generated_for, only_assigned_drivers}
      aiResponseRequest(data, callback, setContext, decisionSlug)
    }
  },
  decisionSlug)
}

export const parseAssistantContent = (content) => {
  try {
    return JSON.parse(content || '{}');
  } catch (e) {
    console.error('Error parsing JSON:', e);
    return { short_summary: '', response: '', file_analysis: '' };
  }
};

export const completeUnstartedDrivers = (aiCompleteUnstartedDriversRequest, unstartedDrivers,
                                         decision, navigate, setIsError, setDriversCounter, canceled,
                                         loading, setDriversDeep, setLoading, setLoaded,
                                         onCancel, failedCallback, current_user) => {
  setDriversDeep(1);

  const processDrivers = (drivers, level) => {
    if (!drivers[level] || canceled.current || loading) return;

    const unstarted_driver_slugs = drivers[level].map(d => d?.slug);
    setDriversCounter(unstarted_driver_slugs.length);

    if (isPresent(unstarted_driver_slugs)) {
      const use_files_map = Object.fromEntries(drivers[level].map(d => [d.slug, d.use_files]));
      const callback = (success) => {
        if (success) {
          setDriversDeep(prev => prev + 1);
          processDrivers(drivers, level - 1);

          if (level === 0) {
            setLoading(false);
            setLoaded(true);
            onCancel();
            scrollToFirstDriverCard(drivers[level]);
            if(treeChannelIsConnected()) performAiCompleteDriversAction(current_user)
          }
        } else {
          failedCallback();
        }
      };
      aiCompleteUnstartedDriversRequest({ unstarted_driver_slugs, use_files_map }, callback, decision.slug);
    }
  };

  processDrivers(unstartedDrivers, unstartedDrivers.length - 1);
};

export const uploadFiles = (decisionSlug, vectorStoreUploadFilesRequest, setIsError, setLoading, setLoaded, callback, failedCallback) => {
  vectorStoreUploadFilesRequest({unstarted_drivers: true}, (success) => {
    success ? callback() : failedCallback();
  }, decisionSlug);
};

export const checkFinishUploadFiles = (decisionSlug, checkFinishUploadFilesRequest, setIsError, setLoading, setLoaded, callback, failedCallback) => {
  checkFinishUploadFilesRequest(decisionSlug, (success) => {
    success ? callback() : failedCallback();
  });
}

export const expandDriverCards = (slugs) => {
  slugs.forEach(slug => {
    const expandButton = document.getElementById(`expand-button-${slug}`);
    if (expandButton) {
      expandButton.click();
    }
  })
};

const scrollToFirstDriverCard = (rootDrivers) => {
  const scrollDriverSlug = rootDrivers[0]?.slug;
  if (isPresent(scrollDriverSlug)) {
    const scrollDriver = document.getElementById(`expand-button-${scrollDriverSlug}`);
    if (scrollDriver) {
      scrollDriver.classList.add('scroll-margin-tab');
      scrollDriver.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
