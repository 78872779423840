import React, { useCallback } from 'react';
import { connect } from "react-redux";
import Recommendation from "../../../models/recommendation";
import OpenEndedDecisionInput from "./OpenEndedDecisionInput";
import ChoicesDecisionInput from "./ChoicesDecisionInput";
import { isBlank } from "../../../helpers/common";
import { openModal } from "../../../store/modals/actions";

const DecisionInputBody = ({ decision }) => {
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;

  const CachedDecisionInput = useCallback(() =>
      new Recommendation(decision.recommendation).isOpenEnded ?
        <OpenEndedDecisionInput decision={decision} /> :
        <ChoicesDecisionInput decision={decision} />
    , [decision?.recommendation?.choice_entry_widget_type, decision?.description]
  )

  return <>
    <CachedDecisionInput />
  </>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { openModal })(DecisionInputBody);
