import React, {useState} from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import { openRateBuyInModal } from "../../modals/RateBuyInModal";
import { openModal } from "../../../store/modals/actions";

const RenderRateBuyInButton = ({ decision, openModal }) =>
  <Button onClick={() => openRateBuyInModal({ decision, openModal })}
          className="btn btn-primary w-100 d-none d-lg-flex align-items-center">
    Rate your level of support
  </Button>

const mapStateToProps = ({ current_user, decision, sidebar }) => ({
  current_user, decision, sidebar
});
export default connect(mapStateToProps, { openModal })(RenderRateBuyInButton);
