import React from 'react';
import * as moment from "moment/moment";
import { isPresent } from "../../../helpers/common"
import { TYPES } from "../../../models/forecast/Base";

const prefixWord = (scenario) => {
  if (isPresent(scenario.deleted_at)) return 'Deleted';
  // if (isPresent(scenario.finalized_at)) return 'Finalized';
  if (isPresent(scenario.data_updated_at)) return 'Updated';

  return 'Created';
}

const lastActionDate = (scenario) => {
  if (isPresent(scenario.deleted_at)) return scenario.deleted_at;
  // if (isPresent(scenario.finalized_at)) return scenario.finalized_at;
  if (isPresent(scenario.data_updated_at)) return scenario.data_updated_at;

  return scenario.data_created_at;
}

export default ({ scenario, classNames, user = {} }) => {
  const userNamePrefix = (isPresent(user) && <span className="me-1">by {user.full_name}</span>) || <></>;
  const scenarioTypeText = `${scenario.forecast_type === TYPES.new_product ? 'New product' : 'Forecast'} • `;
  return <div className={classNames}>
    {scenarioTypeText}
    <span className="me-1">{prefixWord(scenario)}</span>
    {userNamePrefix}
    <span className="fw-bolder">
      {moment(lastActionDate(scenario)).format('D MMM, yyyy')}
    </span>
  </div>
}
