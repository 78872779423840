import React, {useMemo} from "react";
import {connect} from "react-redux";
import { HowToMakeDecisionPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import AssignmentSection from "./../sections/AssignmentSection";
import {isBlank, isPresent} from "../../../helpers/common";
import Decision from "../../../models/decision";
import {answeredChildrenDrivers, answeredChildrenRatingDrivers} from "../../../helpers/gpt_helpers";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import AiResponseButton, {filterAndSortDataSources, getFilteredDataSources} from "../driver_input/AiResponseButton";
import {MIN_DRIVERS_FOR_AI_ANALYSIS} from "../../../helpers/drivers_helpers";

export const isShownGptButtons = (decision, current_org, decisionObj, drivers, dataSources, type) => {
  if (isDSightDecision(decision) || !current_org.allow_gpt) return false;

  const hasDataSources = dataSources.length > 0;
  const isRated = !decisionObj.isRateAndCompareDriverChoicesHidden
  const children = isRated ?
    answeredChildrenRatingDrivers(drivers, true):
    answeredChildrenDrivers(drivers, true);
  if((type === 'decision' || type === 'recommendation') && children.length < MIN_DRIVERS_FOR_AI_ANALYSIS) return false;

  return children.length >= MIN_DRIVERS_FOR_AI_ANALYSIS || hasDataSources;
};

const AssignedToSection = ({sidebar, playbook_notes, tree, current_org, dataSources}) => {
  if(isBlank(sidebar.decision)) return null;

  const decisionObj = new Decision(sidebar.decision, sidebar.drivers);
  const assignedUser = useMemo(() => decisionObj.recommendationAssignedUser, [sidebar.decision])
  const assignedCollaboratorEmail = useMemo(() => decisionObj.recommendation.assignedCollaboratorUser, [sidebar.decision])
  const shownGptButtons = isShownGptButtons(sidebar.decision, current_org, decisionObj, tree.drivers, dataSources, 'recommendation')

  return <div className={`px-3 ${isPresent(playbook_notes.how_to) || isPresent(assignedUser) || isPresent(assignedCollaboratorEmail) || shownGptButtons ? 'border-bottom mb-3' : ''}`}>
    <AssignmentSection assignedUser={assignedUser} assignedCollaboratorEmail={assignedCollaboratorEmail} modalType='RecommendationAssignModal'/>
    <HowToMakeDecisionPBSection playbook_notes={playbook_notes} showNA={false} isDecision={true}/>
    <div className='mb-2'>
      <AiResponseButton {...{driverData: tree.drivers, type: 'recommendation', shownGptButtons }} />
    </div>
  </div>
}

const mapStateToProps = ({ sidebar, playbook_notes, tree, current_org }) => {
  const allDataSources = (tree.data_sources) || [];
  const allowedDataSources = filterAndSortDataSources(allDataSources);
  const dataSources = getFilteredDataSources(allowedDataSources, 'hide_in_recommendation_panel')

  return {
    sidebar, playbook_notes, tree, current_org, dataSources
  };
};
export default connect(mapStateToProps, {})(AssignedToSection);
