import { isBlank } from "../../../helpers/common";

export class NpfScenarioConcept {
  constructor({ id, attributes }, scenario) {
    this.id = id;
    this.attributes = attributes;
    this.scenario = scenario;
  }

  get name() {
    return this.attributes.name;
  }

  get selectOption() {
    return { label: this.name, value: this.id }
  }

  get attributesData() {
    const viewOptions = this.scenario.conceptViewData(this);
    return {
      ...(this.attributes.attrs || {}),
      ...(viewOptions?.attributes_data || {})
    }
  }

  get packagesData() {
    const viewOptions = this.scenario.conceptViewData(this);
    return viewOptions?.packages || this.attributes.pkg || []
  }

  get presentPackages() {
    return this.packagesData.filter(pkg =>
      !this.scenario.config?.packagesColumns?.some(f => isBlank(pkg[f.id]))
    )
  }

  get pendingPackages() {
    return this.packagesData.filter(pkg =>
      this.scenario.config?.packagesColumns?.some(f => isBlank(pkg[f.id]))
    )
  }

  valueFor(_column, _timePeriod) {
    // TODO: Implement this method
    return null;
  }
}
