import React from "react";
import PlusIcon from "../shared/PlusIcon";
import {isDeciderReadOnly} from "../../../helpers/user_helpers";
export default ({ user, description, decision, onClick = () => {}, hideEdit = false, editingByOthers = false }) =>
  <div onClick={onClick} className={`${editingByOthers || hideEdit || isDeciderReadOnly(user) || decision?.read_only ? 'cursor-default' : 'pointer'} d-inline-flex w-100 my-auto`}>
    <div className="note_paragraph fw-bolder text-ellipsis">
      {description}
      {!hideEdit && !isDeciderReadOnly(user) && !decision?.read_only && !editingByOthers && <PlusIcon />}
    </div>
  </div>
