import { isBlank, isPresent } from "../../helpers/common";
import {CMUS_GROUP_KEY_SEPARATOR} from "./ag_grid_vars";
import { getTableOrderFromCookies, setScenarioFiltersToCookies } from "./ag_grid_cookies";
import {selectedCmuCombinations} from "./scopes_helpers";

export const DEFAULT_TOTAL_PAGES = 1;
const CMU_GROUPS_PER_PAGE = 9;

const calcPaginationPageSize = (groupedRowsKeys) => {
  return Math.ceil(groupedRowsKeys.length / CMU_GROUPS_PER_PAGE);
};

const applyRootCmuFilters = (tableScopes, forecastScenario) => {
  const cmusCombinations = selectedCmuCombinations(tableScopes);
  return Object.fromEntries(
    Object.entries(forecastScenario.cmusTable).reduce((acc, [index, cmusList]) => {
      const filteredCmusList = cmusList.filter((cmusString) => {
        const cmusArray = cmusString.split('_').map(Number);
        // Remove Fact ID from the list(we need to compare only CMUs)
        cmusArray.pop();
        return cmusCombinations.some((cmusCombination) => cmusCombination.every((cmuId) => cmusArray.includes(Number(cmuId))));
      });
      if (isPresent(filteredCmusList)) acc.push([index, filteredCmusList]);
      return acc;
    }, [])
  );
};

export const applyDescSorting = (filteredCmusTable) => {
  return Object.fromEntries(Object.entries(filteredCmusTable).reverse().map(([index, cmusList], order) => [`${index}-${order}`, cmusList]));
};

const groupedCmusTable = (cmusTableValues, cmusSize) =>
  cmusTableValues.reduce((acc, arr) => {
    const key = arr.slice(0, cmusSize).join(CMUS_GROUP_KEY_SEPARATOR);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(arr);
    return acc;
  }, {});

export const setupShownCmuGroups = (forecastScenario, page, config) => {
  const tableScopes = forecastScenario.tableSettings.scopes || {};
  const order = getTableOrderFromCookies(forecastScenario) || 'asc';
  const filteredCmusTable = applyRootCmuFilters(tableScopes, forecastScenario);
  const sortedFilteredCmusTable = order === 'asc' ? filteredCmusTable : applyDescSorting(filteredCmusTable);
  const arraySortedFilteredCmusTable = Object.values(sortedFilteredCmusTable).flat().map(groupKey => groupKey.split(CMUS_GROUP_KEY_SEPARATOR).map(i => parseInt(i)));
  const groupedCmus = groupedCmusTable(arraySortedFilteredCmusTable, config.cmuColumns.length);
  const groupedKeys = Object.keys(groupedCmus);
  const paginationPageSize = calcPaginationPageSize(groupedKeys);
  const cmusGroupKeys = groupedKeys.slice(page * CMU_GROUPS_PER_PAGE, (page + 1) * CMU_GROUPS_PER_PAGE);
  if (isBlank(cmusGroupKeys)) return { newCurrentCmuGroups: [], newTotalPages: DEFAULT_TOTAL_PAGES };

  const cmuGroups = cmusGroupKeys.map(groupKey => groupKey.split(CMUS_GROUP_KEY_SEPARATOR).map(i => parseInt(i)));
  return { newCurrentCmuGroups: cmuGroups, newTotalPages: paginationPageSize };
};
