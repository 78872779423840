import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { setRecommendationInputSidebarOpen } from "../../../store/sidebar/actions";
import { isBlank } from "../../../helpers/common";
import Button from 'react-bootstrap/Button';
import { decisionObjects, isNonUser } from "./helpers";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import {
  decisionEditingByUser,
  isDecisionInEditModeByOthers
} from "../../../helpers/channel_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../../../channels/tree_channel";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";

export const DisabledRecommendationButton = ({ editingByUser, buttonText = 'Enter recommendation' }) =>
  <TooltipWrapper bubbleIcon="recommendation-input"
                         placement={'bottom'}
                         tooltipText={`${editingByUser} is editing this recommendation.`}>
    <Button disabled={true}
            className="btn btn-secondary w-100">
      {buttonText}
    </Button>
  </TooltipWrapper>

const RenderRecommendationInputSidebar = ({
                                            current_user, decision, sidebar, channels,
                                            openRecommendationInputSidebar
                                          }) => {
  const {
    recommendation, recommendationObject, decisionObject
  } = decisionObjects(decision)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  },[]);

  const onClick = () => {
    openRecommendationInputSidebar(!sidebar.recommendationInputSidebar);
  }

  if (loading || isNonUser(current_user) || !decisionObject.withRecommendation) return null;
  if (decisionObject.isTreeHistorical && decisionObject.withRecommendation && recommendationObject.isEnteredRecommendation) return null;
  if (recommendationObject.isEnteredRecommendation || decisionObject.isDecisionRecorded) return null;
  if (decisionObject.withRecommendation && !decisionObject.isRecommendationFlow && !decisionObject.isTreeHistorical) return null;
  if (isBlank(decision) || isBlank(recommendationObject) || isDSightDecision(decision)) return null;

  if (isDecisionInEditModeByOthers(channels, TREE_CHANNEL_ACTIONS.edit_recommendation_input, decision.slug, current_user)) {
    const editingByUser = decisionEditingByUser(channels, TREE_CHANNEL_ACTIONS.edit_recommendation_input, decision)
    return <DisabledRecommendationButton editingByUser={editingByUser} />
  }

  return <Button onClick={onClick}
                 className="btn btn-secondary w-100">
    Enter recommendation
  </Button>
}

const mapStateToProps = ({ current_org, current_user, decision, sidebar, channels }) => ({
  current_org, current_user, decision, sidebar, channels
});

const mapDispatchToProps = (dispatch) => ({
  openRecommendationInputSidebar: (flag) => dispatch(setRecommendationInputSidebarOpen(flag))
});
export default connect(mapStateToProps, mapDispatchToProps)(RenderRecommendationInputSidebar);
