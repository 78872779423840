import React from 'react';
import Button from 'react-bootstrap/Button';
import { TooltipWrapper } from "../bubble_tooltip/BubbleTooltip";

export const LockedEditRoundButton = ({ classNames, decision, editingByUser, objectText }) => {
  return <TooltipWrapper bubbleIcon={`decision-status-${decision.slug}`}
                         tooltipText={`${editingByUser} is editing this ${objectText}.`} isDriver={true}>
    <Button disabled={true} className={`btn btn-secondary btn-sm btn-sm-round ${classNames}`} >
      <i className="fas fa-pencil-alt fa-lg w-100" />
    </Button>
  </TooltipWrapper>
}
