import React from "react";
import AssignedUser from "../shared/AssignedUser";
import ConfidenceBubble from "../../driver_entry/ConfidenceBubble";
import {CONFIDENCE_LEVELS} from "../../driver_entry/ConfidenceLevel";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";
import {isBlank} from "../../../helpers/common";
import {CloverpopAiIcon} from "../../../icons/custom_icons";

const ConfidenceBubbleElement = ({driver, driverSlug}) => {
  if(isBlank(driver.confidence)) return null;

  const confidenceHash = CONFIDENCE_LEVELS.find(hash => hash.value === driver.confidence.toString())

  return <TooltipWrapper bubbleIcon={`driver-confidence-bubble-${driverSlug}`}
                         tooltipText={`Confidence level: <br> ${confidenceHash.description}`}>
    <div className="d-flex radio-container me-1">
      <ConfidenceBubble
        showAfter={false}
        confidence={driver.confidence.toString()}
        disabled={true}
        {...confidenceHash}  key={`bubble-${confidenceHash.id}`} />
    </div>
  </TooltipWrapper>
}

const CloverpopAiElement = ({driver}) => {
  const {slug, answered_by_ai} = driver;
  if (!answered_by_ai || !slug) return null;

  return <TooltipWrapper bubbleIcon={`driver-ai-bubble-${slug}`}
                         tooltipText={`Provided by CloverpopAI`}>
    <div className="p-1">
      <CloverpopAiIcon />
    </div>
  </TooltipWrapper>
}

export default ({ driverData, driverObject, assignedUser }) => {
  return <>
    {
      driverObject.isCompleted
        ? <>
          <CloverpopAiElement driver={driverData.driver} />
          <ConfidenceBubbleElement driver={driverObject.driver} driverSlug={driverData.driver.slug}/>
        </>
        : <AssignedUser bubbleIcon={`driver-assignment-${driverData.driver.slug}`} assignedUser={assignedUser} />
    }
  </>
}
