import {Link} from "react-router-dom";
import React from "react";
import parse from "html-react-parser";
import {isBlank} from "../../../../helpers/common";

export  const AssistantContentSwitcher = ({assistantContent, index, setIndex}) => {
  if (assistantContent.length < 2) return null;

  return <div className='d-flex mx-3'>
    <span className="text-primary pointer" hidden={index === 0} onClick={() => setIndex(index - 1)}>
      <i className={`fa-solid fa-chevron-left fa-sm fw-bold`} />
    </span>
    <span className="text-muted mx-2">
      {index + 1}/{assistantContent.length}
    </span>
    <span className="text-primary pointer" hidden={index + 1 === assistantContent.length} onClick={() => setIndex(index + 1)}>
      <i className={`fa-solid fa-chevron-right fa-sm`} />
    </span>
  </div>
}

export const DriverList = ({expandedList, setExpandedList, childrenDrivers}) => {
  return <div className='mx-3 my-2'>
    <Link to={'#'} onClick={() => setExpandedList(!expandedList)} hidden={expandedList}>
      List contributing drivers
    </Link>
    <div hidden={!expandedList}>
      <div className='fw-bold'>Summarized drivers:</div>
      <ul hidden={!expandedList}>
        { childrenDrivers.map((child, index) => <li key={index}>{child.driver.question}</li>) }
      </ul>
    </div>
  </div>
}

export const AiUserInstruction = ({instruction}) => {
  if (isBlank(instruction)) return null;

  const match = instruction.match(/.*?(?=(SYSTEM:|SYSTEM Instructions:))/);
  const preparedInstruction = parse(match ? match[0] : instruction);

  return <div className='mx-3 my-2'>
    <div className='mb-0 text-muted fw-bold'>Instructions:</div>
    <div className='text-muted'>{preparedInstruction}</div>
  </div>
}