import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { setDecisionInputSidebarOpen, transferDecisionFromSidebar } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import Header from "./sections/Header";
import Footer from "./decision_input/Footer";
import DecisionInputBody from "./decision_input/DecisionInputBody";
import RateSupportSwitcher from "./decision_input/RateSupportSwitcher";
import RationaleInput from "./decision_input/RationaleInput";
import DecisionDateInput from "./decision_input/DecisionDateInput";
import DataSourcesSection from "./sections/DataSourcesSection";
import { isMobile } from "react-device-detect";
import { saveSideBarData } from "../../store/sidebar/common_actions";
import AssignedDeciderInput from "./decision_input/AssignedDeciderInput";
import MoreActionsFinalSection from "./decision_input/MoreActionsFinalSection";
import NextStepsInput from "./decision_input/NextStepsInput";
import AssignedToSection from "./decision_input/AssignedToSection";
import DuplicateRecommendation from "./decision_input/DuplicateRecommendation";
import { Loader } from "../../common/Loader";
import ExpectedResultsInput from "./decision_input/ExpectedResultsInput";
import ExpectedOpportunityInput from "./decision_input/ExpectedOpportunityInput";
import ExpectedInvestmentInput from "./decision_input/ExpectedInvestmentInput";
import OtherConsideredChoicesInput from "./decision_input/OtherConsideredChoicesInput";
import {showDecisionAssign} from "../modals/DecisionAssignModal";
import EditAssignBtn from "../../common/EditAssignBtn";
import { performEditDecisionInputAction, handleLockAction } from "../../helpers/channel_helpers";
import { partialDeepEqual } from "../../helpers/store_helpers";
import { isPresent } from "../../helpers/common";

const DecisionInputRightPanel = ({ current_user,
                                   sidebar, decision, openSideBar, transferDecisionFromSidebar, saveSideBarData,
                                   hideAssignee = false, isDsight = false, modal
                                 }) => {
  const assignUser = decision.assigned_collaborator_email ? decision.assigned_collaborator_email : '';
  const [initialDecision, setInitialDecision] = useState(null);
  const [delayedAlert, setDelayedAlert] = useState(false);

  const comparisonDecisionData = (decision) => {
    const { chat_gpt_queries, recommendation, decided_at, ...allOtherData } = decision;
    return { ...allOtherData };
  }

  useEffect(() => {
    if(isDsight) return;

    const isPanelOpened = sidebar.decisionInputSidebar;
    const filteredDecisionData = comparisonDecisionData(decision);
    const changed = isPresent(filteredDecisionData) && isPresent(initialDecision) && !partialDeepEqual(filteredDecisionData, initialDecision);
    if(changed && isPanelOpened) {
      setDelayedAlert(true);
    } else if(changed) {
      handleLockAction({
        isDsight,
        touched: true,
        current_user,
        slug: decision.slug,
        action: performEditDecisionInputAction
      });
    }
  }, [decision]);

  return <SlidingPanel
    type="right"
    isOpen={sidebar.decisionInputSidebar}
    panelContainerClassName={`right-sidebar-panel-wrapper decision-input-panel-wrapper decision-panel-width`}
    panelClassName={`right-sidebar-panel-wrapper-content decision-input-panel-content ${isMobile ? '' : 'd-flex flex-column'}`}
    onClose={transferDecisionFromSidebar}
    onClosed={() => {
      if (!sidebar.recommendationInputSidebar && !sidebar.pollPanelSidebar) {
        saveSideBarData({decision: null, isOpened: false})
      } else {
        saveSideBarData({isOpened: false})
      }
      handleLockAction({
        isDsight,
        touched: delayedAlert,
        current_user,
        slug: decision.slug,
        action: performEditDecisionInputAction
      });
      setDelayedAlert(false);
      setTimeout(() => setInitialDecision(null), 1000);
    }}
    onOpened={() => {
      handleLockAction({
        isDsight,
        editMode: true,
        current_user,
        slug: decision.slug,
        action: performEditDecisionInputAction
      });
      saveSideBarData({isOpened: true});
      setInitialDecision(comparisonDecisionData(decision))
    }}
  >
    {sidebar.loading && <Loader/>}
    {
      !sidebar.loading && sidebar.decision && <>
        <Header decision={sidebar.decision} headerText={"Enter what was decided"} openSideBar={() => openSideBar(false)}>
          <EditAssignBtn assignUser={assignUser} openAssignModal={() => showDecisionAssign(modal)} modalType='DecisionAssignModal' isDSight={isDsight}/>
          <MoreActionsFinalSection {...{isDsight}}>
            <DuplicateRecommendation />
          </MoreActionsFinalSection>
        </Header>
        <AssignedToSection />
        {
          isDsight ?
            <DuplicateRecommendation {...{isDsight}}/> :
            <></>
        }
        <DecisionInputBody />
        <RationaleInput />
        <RateSupportSwitcher />
        <DecisionDateInput />
        { hideAssignee ? null : <AssignedDeciderInput /> }
        <OtherConsideredChoicesInput />
        <NextStepsInput />
        <ExpectedResultsInput />
        <ExpectedOpportunityInput />
        <ExpectedInvestmentInput />
        <DataSourcesSection fromDecisionPanel={true} addClass="px-3" />
        <Footer />
      </>
    }
  </SlidingPanel>
}

const mapStateToProps = ({ sidebar, decision, modal, current_user }) => ({ sidebar, decision, modal, current_user });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setDecisionInputSidebarOpen(options)) },
  transferDecisionFromSidebar: () => { dispatch(transferDecisionFromSidebar()) },
  saveSideBarData: (data = {}) => { dispatch(saveSideBarData(data)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionInputRightPanel);
