import React from "react";
import TabComponent from "./TabComponent";
import { TABS } from "../helpers/helpers";
import { useNavigate } from "react-router-dom";

export const NavigationItems = ({ sidebar, setProductForecastSidebarOpen, tab, npfScenario }) => {
  const navigate = useNavigate()
  const isOpened = sidebar.productForecastSidebar;
  const setTab = (tab) => () => {
    const getParams = new URLSearchParams(document.location.search)
    getParams.set('tab', tab)
    navigate(`/npf_simulator/scenarios/${npfScenario.local_id}?${getParams.toString()}`)
  }
  return <div className="sidebar-top">
    <button onClick={() => setProductForecastSidebarOpen(!isOpened)}
            className={`btn-rotate bg-light btn-xsm-round d-flex justify-center align-center border-light-gray mt-2
             ${isOpened ? 'rotate-svg-180' : 'rotate-svg-0'}`}>
      <i className="fas fa-chevron-right fa-lg text-primary"/>
    </button>
    <ul className={`list-unstyled m-0 with-transition ${isOpened ? 'p-3' : 'px-2 py-3'}`}>
      <li className={tab === TABS.details ? 'active' : ''} >
        <TabComponent id={1} title={'Details'} icon={'fa-circle-info'} onClick={setTab(TABS.details)} {...{isOpened}}/>
      </li>
      <li className={tab === TABS.concepts ? 'active' : ''}>
        <TabComponent id={2} title={'Product concept'} icon={'fa-box'} onClick={setTab(TABS.concepts)} {...{isOpened}}/>
      </li>
      <li className={tab === TABS.compare_concepts ? 'active' : ''}>
        <TabComponent id={3} title={'Compare concepts'} icon={'fa-down-left-and-up-right-to-center'} onClick={setTab(TABS.compare_concepts)} {...{isOpened}}/>
      </li>
      <li className={tab === TABS.execution ? 'active' : ''}>
        <TabComponent id={4} title={'Execution'} icon={'fa-rocket'} onClick={setTab(TABS.execution)} {...{isOpened}}/>
      </li>
    </ul>
  </div>
}
