import React from "react";
import {isForecastedPeriod, isFuturePeriod} from "../../models/forecast/ForecastScenario";
import { isBlank, isPresent } from "../../helpers/common";
import FactsCellRenderer from "../table_components/FactsCellRenderer";
import {
  parseFormatData,
  valueFormatter,
  filterLeafChildren,
  getPeriodAgGridSuffixes, simAggValue, aggregatedFact, extractDriverName,
} from "./common";
import { isEditableCell } from "./ag_grid_cell_style";
import {
  FACTS_HEADER,
  HIDDEN_COLUMNS,
  GROUP_COL_ID_SUFFIX,
  FACTS_GR_COL_ID,
  SUB_FACT_HEADER,
  HISTORICAL_CAGR_COL_ID,
  FORECASTED_CAGR_COL_ID,
  VALUE_SALES_FACT,
  N_A_AG_GRID_VALUE,
  YTD_COL_ID, YTG_COL_ID
} from "./ag_grid_vars";
import CustomPeriodCellRenderer from "../table_components/CustomPeriodCellRenderer";
import { timePeriodNameParse } from "../../models/forecast/ForecastTImeScale";

const BASE_HEADER_OPTIONS = {
  sortable: true,
  filter: true,
  resizable: true
}
const CHOOSE_COLUMN_ITEM = 'columnChooser';
const ROW_GROUP_MENU_ITEM = 'rowGroup';
const ROW_UNGROUP_MENU_ITEM = 'rowUnGroup';
const EXPAND_ALL_MENU_ITEM = 'expandAll';
const CONTRACT_ALL_MENU_ITEM = 'contractAll';
const EXCLUDE_MENU_ITEMS = [
  CHOOSE_COLUMN_ITEM, ROW_GROUP_MENU_ITEM, ROW_UNGROUP_MENU_ITEM
];

const totalAggOutput = (value, decimal) => {
  return valueFormatter(value, { decimal })
};

const getPeriod = (forecastScenario, timeScale, colId) => {
  return forecastScenario.allTimeScalePeriods(timeScale).find(period => period.id === colId);
};

const handleLeafGroup = (params, forecastScenario, config) => {
  if (params.values[0] === undefined) return undefined;
  return totalAggOutput(params.values[0], parseFormatData(params, forecastScenario, config).decimal);
};

const consolidatedAggFunc = (params, forecastScenario, colId) => {
  const filteredAllLeafChildren = filterLeafChildren(params.rowNode.allLeafChildren);
  if (isBlank(filteredAllLeafChildren[0]?.data)) return undefined;

  const aggFact = aggregatedFact(forecastScenario.config, forecastScenario) || forecastScenario.findFactByDriverName(VALUE_SALES_FACT);
  const driverName = params.rowNode.leafGroup ? extractDriverName(filteredAllLeafChildren[0].data[FACTS_HEADER]) : aggFact.displayName;
  const driver = forecastScenario.findFactByDriverName(driverName);
  if (driver.isPercentage) return N_A_AG_GRID_VALUE;

  if (params.rowNode.leafGroup) {
    return params.values[0];
  }
  if (isPresent(params.rowNode.aggData)) {
    const aggDataValue = isPresent(params.rowNode.aggData[colId]) ?
      params.rowNode.aggData[colId] :
      simAggValue({ node: params.rowNode }, colId, getPeriodAgGridSuffixes({ node: params.rowNode }));
    if (isBlank(aggDataValue)) return undefined;
    return aggDataValue;
  }
  return undefined;
};

export const CAGRHistoricalAggFunc = (params, forecastScenario) => consolidatedAggFunc(params, forecastScenario, HISTORICAL_CAGR_COL_ID);
export const CAGRForecastedAggFunc = (params, forecastScenario) => consolidatedAggFunc(params, forecastScenario, FORECASTED_CAGR_COL_ID);
export const YTDAggFunc = (params, forecastScenario) => consolidatedAggFunc(params, forecastScenario, YTD_COL_ID);
export const YTGAggFunc = (params, forecastScenario) => consolidatedAggFunc(params, forecastScenario, YTG_COL_ID);

export const totalAggFunc = (params, forecastScenario) => {
  const filteredAllLeafChildren = filterLeafChildren(params.rowNode.allLeafChildren);
  const period = getPeriod(forecastScenario, params.colDef.context.timeScale, params.colDef.colId);

  if (isBlank(filteredAllLeafChildren[0]?.data) || isBlank(period)) return undefined;

  const config = forecastScenario.config;

  if (params.rowNode.leafGroup) {
    return handleLeafGroup(params, forecastScenario, config);
  }
  if (isPresent(params.rowNode.aggData)) {
    const aggDataValue = isPresent(params.rowNode.aggData[period.id]) ?
      params.rowNode.aggData[period.id] :
      simAggValue({ node: params.rowNode }, period.id, getPeriodAgGridSuffixes({ node: params.rowNode }));
    if (isBlank(aggDataValue)) return undefined;
    return totalAggOutput(aggDataValue, parseFormatData(params, forecastScenario, config).decimal);
  }
  return undefined
};

const factsMenuItems = (params) => {
  const menuItems = [];
  const excludeItems = [...EXCLUDE_MENU_ITEMS, EXPAND_ALL_MENU_ITEM, CONTRACT_ALL_MENU_ITEM];
  params.defaultItems.forEach((item) => {
    if (excludeItems.indexOf(item) < 0) {
      menuItems.push(item);
    }
  });
  return menuItems;
}

const customMenuItems = (params) => {
  return params.defaultItems.filter(item => !EXCLUDE_MENU_ITEMS.includes(item)).map(item => {
    if ([EXPAND_ALL_MENU_ITEM, CONTRACT_ALL_MENU_ITEM].includes(item)) {
      const isExpanded = item === EXPAND_ALL_MENU_ITEM;
      const name = isExpanded ? 'Expand All Rows Groups' : 'Collapse All Rows Groups';
      return {
        name,
        action: () => {
          params.api.forEachNode(node => {
            if (node.field === params.column.colDef.headerName) {
              params.api.setRowNodeExpanded(node, isExpanded);
            }
          });
        }
      };
    }
    return item;
  });
}

const groupedColDefs = ({ forecastScenario, config, forecastBenchmarkScenario }) => {
  const colDefs = forecastScenario.groupFields.map(groupField =>
    ({
      headerName: groupField,
      showRowGroup: groupField,
      colId: `${groupField}-${GROUP_COL_ID_SUFFIX}`,
      cellRenderer: 'agGroupCellRenderer',
      filter: false,
      cellRendererParams: { suppressCount: true },
      width: 170,
      sort: 'asc',
      mainMenuItems: customMenuItems
    })
  )
  colDefs.push(
    {
      headerName: 'Facts',
      colId: FACTS_GR_COL_ID,
      minWidth: 250,
      showRowGroup: 'Facts',
      filter: false,
      cellRenderer: (params) => <FactsCellRenderer {...{ params, config, forecastScenario, forecastBenchmarkScenario } } />,
      cellRendererParams: { suppressCount: true },
      type: 'styledFactsColumn',
      aggFunc: 'first',
      valueGetter: (params) => {
        if (params.data) {
          return params.data[SUB_FACT_HEADER];
        }
      },
      mainMenuItems: factsMenuItems
    }
  )
  return colDefs;
}

const CAGR_YTD_YTG_col_def = (definitions, aggFuncName, colId) => {
  definitions.aggFunc = aggFuncName;
  definitions.type = ['styledCAGRColumn'];
  definitions.filter = false
  definitions.colId = colId;
}

export const preparedColDefs = ({ forecastScenario, timeScale, config, ...opts }) => {
  const periods = forecastScenario.periods();
  const isEditableTimeScale = forecastScenario.isEditableTimeScale;
  return groupedColDefs({ forecastScenario, config, ...opts }).concat(forecastScenario.viewHeaders.map(field => {
    let definitions = { field, ...BASE_HEADER_OPTIONS };
    definitions.context = { timeScale };
    if (HIDDEN_COLUMNS.includes(field)) {
      definitions.hide = true;
      }
    if (forecastScenario.groupFields.includes(field)) {
      definitions.hide = true;
      definitions.rowGroup = true;
    }
    if(FACTS_HEADER === field) {
      definitions.rowGroup = true;
    }
    if(forecastScenario.periodHeadersNames.includes(field)) {
      const period = periods.find(p => p.name === field);
      definitions.headerName = timePeriodNameParse(field);
      definitions.colId = period.id;
      definitions.cellRenderer = (params) =>
        <CustomPeriodCellRenderer params={params} forecastScenario={forecastScenario} />;
      definitions.aggFunc = 'totalAggFunc';
      definitions.filter = 'agNumberColumnFilter';
      definitions.width = 120;
      definitions.headerClass = params => {
        return isForecastedPeriod(forecastScenario, period) ? 'bg-forecast-column' : '';
      }
      if(isEditableTimeScale && isFuturePeriod(forecastScenario, period.id)) {
        definitions.editable = (params) => isEditableCell(forecastScenario, params, config, timeScale, period);
        definitions.type = ['editableColumn', 'styledPeriodColumn'];
      } else if(!isEditableTimeScale) {
        definitions.type = ['styledPeriodColumn'];
      }
    }
    if(forecastScenario.CAGRHistoricalHeader === field) {
      CAGR_YTD_YTG_col_def(definitions, 'CAGRHistoricalAggFunc', HISTORICAL_CAGR_COL_ID);
    }
    if(forecastScenario.CAGRForecastedHeader === field) {
      CAGR_YTD_YTG_col_def(definitions, 'CAGRForecastedAggFunc', FORECASTED_CAGR_COL_ID);
    }
    if(forecastScenario.YTDHeader === field) {
      CAGR_YTD_YTG_col_def(definitions, 'YTDAggFunc', YTD_COL_ID);
    }
    if(forecastScenario.YTGHeader === field) {
      CAGR_YTD_YTG_col_def(definitions, 'YTGAggFunc', YTG_COL_ID);
    }
    return definitions;
  }
  )).flat()
}
