import React from 'react';
import { connect } from "react-redux";
import {
  openModal
} from "../../../store/modals/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import {
  updateRecommendationChoicesDraft,
  setRecommendationInputSidebarOpen, updateRecommendationFinalChoices
} from "../../../store/sidebar/actions";
import Recommendation from "../../../models/recommendation";
import Decision from "../../../models/decision";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import { IconBtnDropdown } from "../../../common/dropdowns";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";

const recommendationEntryModalType = (decisionObj) =>
  decisionObj.isRecordedOrRecommended || decisionObj.isTreeHistorical ? 'PanelRecommendationEntryModal' : 'PanelMultiEntryModal';

export const MoreActionsFinalSection = ({ decision, tree, openModal, setRecommendationInputSidebarOpen,
                                          updateRecommendationFinalChoices, updateRecommendationChoicesDraft}) => {

  if (isBlank(decision)) return null
  const decisionObj = new Decision(decision);
  const recommendationObj = new Recommendation(decision?.recommendation);
  const openEndedChoice = recommendationObj.openEndedChoice[0];
  const needChoiceSubmission = () => isPresent(openEndedChoice) && !recommendationObj.isEnteredRecommendation && recommendationObj.isOpenEnded;
  const onEditDecision = (e) => {
    e.preventDefault();
    openModal({
      decision,
      drivers: tree.drivers,
      data_sources: [],
      slug: decision.slug,
      type: recommendationEntryModalType(decisionObj)
    })
  }
  const submitChoices = () => {
    updateRecommendationFinalChoices(openEndedChoice.slug, {
      draft: true
    })
  }

  const updateRecommendationDraftState = () => {
    if (needChoiceSubmission()) {
      submitChoices();
    } else {
      updateRecommendationChoicesDraft({ draft: true });
    }
    setRecommendationInputSidebarOpen(false)
  }


  return <IconBtnDropdown id={'recommendation-MoreActionsFinalSection'}>
    <BaseDropdownBtn hidden={isDSightDecision(decision)} onSelect={onEditDecision}>
      Edit decision
    </BaseDropdownBtn>
    <BaseDropdownBtn onSelect={updateRecommendationDraftState}>
      Save draft
    </BaseDropdownBtn>
  </IconBtnDropdown>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision, tree: state.tree, modal: state.modal });

export default connect(mapStateToProps, {
  openModal, setRecommendationInputSidebarOpen, updateRecommendationFinalChoices, updateRecommendationChoicesDraft
})(MoreActionsFinalSection);
