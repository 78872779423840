import { useMemo, useEffect, useState } from 'react';
import { useWorker } from 'react-hooks-worker';
import { isPresent, isBlank } from '../../helpers/common';
import {hideOverlay, MESSAGES, showOverlayWithMessage} from './custom_loading_overlay';
import { handleRunModelEffect } from './ag_grid_run_model';
import {
  disableHighlights,
  enableHighlights,
  scrollToTop,
  isComparisonRow,
  parsedRowCmus, refreshAggregations
} from "./common";
import { ROW_DRIVER_ID_KEY, VALUE_SALES_FACT } from "./ag_grid_vars";
import { getRowNodes, onHorizontalScrollBody } from "./ag_grid_scroll";

const scenarioRowsWorker = () => new Worker(
  new URL('../workers/forecastScenarioDataProcessor.worker.js', import.meta.url),
  {type: 'module', name: 'forecastScenarioDataProcessor'}
);

const aggregateByDataWorker = () => new Worker(
  new URL('../workers/aggregateByDataProcessor.worker.js', import.meta.url),
  {type: 'module', name: 'aggregateByDataProcessor'}
);

const updateAggDataFromState = (gridRef, handledAggNodes) => {
  handledAggNodes.forEach(node => {
    const rowNode = gridRef.current.api.getRowNode(node.nodeId);
    if(rowNode) {
      rowNode.setAggData({ ...rowNode.aggData, ...node.aggData });
    }
  })
}

const mergeAndUpdateAggData = (gridRef, handledAggNodes, aggregatedData, forecastScenario) =>
  handledAggNodes.map(node => {
    if(node.timeScale !== forecastScenario.timeScale) return node;

    const rowNode = gridRef.current.api.getRowNode(node.nodeId);
    if(rowNode) {
      const newAddedNode = aggregatedData.find(data => data.nodeId === node.nodeId);
      if (isPresent(newAddedNode)) {
        const mergedAggregations = Object.assign({}, ...newAddedNode.aggregations);
        rowNode.setAggData({ ...rowNode.aggData, ...mergedAggregations });
        node.aggData = { ...rowNode.aggData, ...mergedAggregations };
      }
    }
    return node;
  });

const getRowDriverId = (rowNode) =>
  isPresent(rowNode.data) ? rowNode.data[ROW_DRIVER_ID_KEY] : rowNode.allLeafChildren[0]?.data[ROW_DRIVER_ID_KEY]

const filterVisibleNodes = (visibleNodes, handledAggNodes, forecastScenario) =>
  visibleNodes.filter(node =>
    !isComparisonRow(node) && !handledAggNodes.some(n => isPresent(n.aggData) && n.nodeId === node.id && forecastScenario.timeScale === n.timeScale)
  );

const prepareNodesForAggWorker = ({ visibleNodes, forecastScenario, handledAggNodes }) => {
  const nodesToAgg = [];
  const filteredVisibleNodes = filterVisibleNodes(visibleNodes, handledAggNodes, forecastScenario);
  filteredVisibleNodes.forEach((rowNode) => {
    const rowDriverId = getRowDriverId(rowNode);
    const cmus = parsedRowCmus(rowDriverId);
    const aggFact = forecastScenario.aggregatedFact || forecastScenario.findFactByDriverName(VALUE_SALES_FACT);
    if(!rowNode.leafGroup || rowNode.key.includes(aggFact.displayName))
      nodesToAgg.push({
        nodeId: rowNode.id,
        cmusLevel: rowNode.level,
        cmus: cmus,
        driver: aggFact,
        timeScale: forecastScenario.timeScale
      })
  })
  return nodesToAgg;
}

const handleResetEffect = ({ gridRef, forecast_simulator_scenario, preparedRows, updateCachedRows, updateScenarioData }) => {
  updateCachedRows(preparedRows);
  gridRef.current.api.applyTransaction({ update: preparedRows, remove: forecast_simulator_scenario.reset_all_delete_rows });
  gridRef.current.api.forEachNode((rowNode) => {
    if(rowNode.leafGroup) rowNode.setExpanded(false);
  });
  updateScenarioData({ reset_all_to_default: false, reset_all_new_rows: [], reset_all_new_rows_cmus: [], reset_all_delete_rows: [] });
}

export const useRowsWorkerOpts = (dependencies) => {
  const {
    allowWorkerRun,
    gridReady,
    scenario_id,
    config,
    scenario,
    scenarioRows,
    currentCmuGroups,
    timeScale,
    cachedAgGridRows
  } = dependencies;

  return useMemo(() => ({
    allowWorkerRun,
    gridReady,
    scenario,
    rows: scenarioRows,
    config,
    currentCmuGroups,
    timeScale,
    byCmuGroups: true,
    cachedData: cachedAgGridRows[scenario_id]
  }), [gridReady, allowWorkerRun, scenarioRows, currentCmuGroups, timeScale, cachedAgGridRows]);
};

export const handleRowsWorkers = ({
                                    allowWorkerRun,
                                    gridReady,
                                    forecast_simulator_scenario,
                                    forecastScenario,
                                    cachedAgGridRows,
                                    currentCmuGroups,
                                    setPreparedRows
                                  }) => {
  const rowsWorkerOpts = useRowsWorkerOpts({
    allowWorkerRun,
    gridReady,
    config: forecast_simulator_scenario.config,
    scenario: forecast_simulator_scenario.scenario,
    scenario_id: forecast_simulator_scenario.scenario_id,
    scenarioRows: forecastScenario.scenario_rows,
    timeScale: forecastScenario.timeScale,
    currentCmuGroups,
    cachedAgGridRows
  });

  const { result: preparedRowsData } = useWorker(scenarioRowsWorker, rowsWorkerOpts);

  useEffect(() => {
    if(isPresent(preparedRowsData)) setPreparedRows(preparedRowsData);
  }, [preparedRowsData]);
};

const checkNodes = (aggregatedData, forecastScenario, newHandlesNodes, triggerAggWorkerRun) => {
  for (const data of aggregatedData) {
    const node = newHandlesNodes.find(n => n.nodeId === data.nodeId && n.timeScale === forecastScenario.timeScale);
    if (isPresent(node) && isBlank(node.aggData)) {
      triggerAggWorkerRun();
      return;
    }
  }
}

export const handleAggregationsWorkers = ({
                                            gridRef,
                                            gridReady,
                                            forecast_simulator_scenario,
                                            forecastScenario,
                                            handledAggNodes,
                                            updateHandledAggNodes,
                                            updateScenarioData,
                                            forecastBenchmarkScenario,
                                            expandedGroupIds,
                                            expandedGroups,
                                            editedCells,
                                            runModelCells,
                                            updateOpenedGroups,
                                            updateTableCells,
                                            allowAggWorkerRun,
                                            triggerAggWorkerRun
                                          }) => {
  const [nodes, setNodes] = useState([]);
  const opts = useMemo(() => ({
    gridReady,
    config: forecast_simulator_scenario.config,
    scenario: forecast_simulator_scenario.scenario,
    timeScale: forecastScenario.timeScale,
    nodes,
  }), [gridReady, nodes, forecastScenario.timeScale]);

  const { result: aggregatedData } = useWorker(aggregateByDataWorker, opts);

  useEffect(() => {
    if(gridReady && allowAggWorkerRun) {
      const visibleNodes = getRowNodes(gridRef);
      const nodesToAgg = prepareNodesForAggWorker({ visibleNodes, forecastScenario, handledAggNodes });
      if(isPresent(nodesToAgg)) {
        updateHandledAggNodes([...handledAggNodes, ...nodesToAgg]);
        setNodes(nodesToAgg);
        showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.updating_scenario);
      }
      const nodesFromCache = handledAggNodes.filter(node =>
        node.timeScale === forecastScenario.timeScale && isPresent(node.aggData) &&
        visibleNodes.some(n => n.id === node.nodeId && n.aggData && Object.values(n.aggData).some(value => value === undefined)) &&
        !nodesToAgg.some(n => n.nodeId === node.nodeId)
      );
      if(isPresent(nodesFromCache)) {
        updateAggDataFromState(gridRef, nodesFromCache);
        if(isBlank(nodesToAgg)) refreshAggregations(gridRef.current.api);
      }
    }
  }, [gridReady, allowAggWorkerRun]);

  useEffect(() => {
    if (isPresent(aggregatedData)) {
      setNodes([]);
      disableHighlights();
      const newHandlesNodes = mergeAndUpdateAggData(gridRef, handledAggNodes, aggregatedData, forecastScenario);
      updateHandledAggNodes(newHandlesNodes);
      refreshAggregations(gridRef.current.api);
      setTimeout(() => {
        onHorizontalScrollBody({
          gridRef,
          forecastScenario,
          forecastBenchmarkScenario,
          expandedGroupIds,
          expandedGroups,
          editedCells,
          runModelCells,
          updateOpenedGroups,
          updateTableCells
        });
        enableHighlights();
        checkNodes(aggregatedData, forecastScenario, newHandlesNodes, triggerAggWorkerRun)
      }, 100);
      hideOverlay(gridRef.current.api);
    }
  }, [aggregatedData]);
}

const refreshGroupData = ({ gridRef }) => {
  gridRef.current.api.refreshClientSideRowModel('group');
}

const triggerAggWorker = ({ triggerAggWorkerRun }) => {
  setTimeout(() => {
    triggerAggWorkerRun();
  }, 1000)
}

export const useOnWorkerDoneEffect = (dependencies) => {
  const {
    gridRef,
    gridReady,
    changeCurrentCmuGroups,
    setChangeCurrentCmuGroups,
    preparedRows,
    forecastScenario,
    forecastBenchmarkScenario,
    editedCells,
    runModelCells,
    updateOpenedGroups,
    updateCachedRows,
    updateTableCells,
    rowsDataWithEditedCells,
    rowData,
    forecast_simulator_scenario,
    setRunModelActive,
    updateScenarioData,
    runModelRowsIds,
    editedCellsRowIds,
    setRowData,
    setInitialWorkerRun,
    prepareRowsOnChange,
    initialWorkerRun,
    afterRenderActionsWrapper,
    triggerAggWorkerRun,
    changeTimeScale,
    setChangeTimeScale,
  } = dependencies;

  useEffect(() => {
    if (gridReady && isPresent(preparedRows)) {
      if (forecast_simulator_scenario.run_model) {
        handleRunModelEffect({
          gridRef,
          preparedRows,
          editedCells,
          runModelCells,
          forecastScenario,
          forecastBenchmarkScenario,
          updateCachedRows,
          setRunModelActive,
          updateScenarioData,
          runModelRowsIds,
          editedCellsRowIds,
          updateOpenedGroups,
          updateTableCells,
          rowsDataWithEditedCells
        });
        triggerAggWorkerRun();
      } else if (forecast_simulator_scenario.reset_all_to_default) {
        handleResetEffect({ gridRef, forecast_simulator_scenario, preparedRows, updateCachedRows, updateScenarioData });
        triggerAggWorkerRun();
      } else if (isBlank(rowData) || !initialWorkerRun || changeCurrentCmuGroups) {
        updateCachedRows(preparedRows);
        const newRowData = prepareRowsOnChange({ preparedRows, gridRef, forecastScenario });
        scrollToTop(gridRef);
        if (changeCurrentCmuGroups) {
          // setting [] is needed for new data to be displayed in proper order
          setRowData([]);
          setTimeout(() => {
            setRowData(newRowData);
            refreshGroupData({ gridRef, triggerAggWorkerRun });
            triggerAggWorker({ triggerAggWorkerRun });
            setChangeCurrentCmuGroups(false);
            afterRenderActionsWrapper(isBlank(rowData));
          }, 0);
        } else {
          setRowData(newRowData);
          setTimeout(() => {
            if (changeTimeScale) {
              triggerAggWorker({ triggerAggWorkerRun });
              setChangeTimeScale(false);
            }
            if (!initialWorkerRun) {
              triggerAggWorker({ triggerAggWorkerRun });
              setInitialWorkerRun(true);
            }
            afterRenderActionsWrapper(isBlank(rowData));
          }, 0);
        }
      }
      hideOverlay(gridRef.current.api);
    }
  }, [preparedRows]);
};
