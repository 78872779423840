import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import { connect } from "react-redux";
import {groupUnstartedDriversByDeep} from "../helpers/drivers_helpers";
import {
  aiCompleteUnstartedDriversRequest,
  checkFinishUploadFilesRequest,
  vectorStoreUploadFilesRequest
} from "../store/decision/actions";
import {isUnstartedDriversWithFiles} from "../helpers/drivers_helpers";
import {RequestAccessWrapper} from "../homepage/RequestAccess";
import {Error} from "../tree_wizard/steps_wizard/steps/DecisionUploaderStep";
import {Loader} from "../common/Loader";
import {useNavigate} from 'react-router-dom';
import {
  AI_ERROR_MESSAGE,
  checkFinishUploadFiles,
  completeUnstartedDrivers, DATA_SOURCES_READY_TEXT, FETCH_DRIVERS_TEXT, MAX_AI_RETRIES,
  uploadFiles
} from "../helpers/ai_helpers";
import AlertsSection from "../alerts";
import Header from "./header";
import {isBlank, pluralize} from "../helpers/common";
import {loadDecisionAiFailure} from "../store/decision/common_actions";
import { PREPARING_SOURCES_TEXT } from "../helpers/ai_helpers";
import {updateTreeData} from "../store/tree/common_actions";
import {loadDecisionTree} from "../store/tree/actions";
import {connectDecisionChannelEffect} from "../tree_view";
import {updateTreeChannelData} from "../store/channels/actions";
import {closeDriverInputSidebarBySlug} from "../store/sidebar/actions";
import {closeAndResetDriverDetailsModal} from "../store/modals/actions";
import {updateAssignedDriversData} from "../store/assigned_drivers/actions";

const AiCompleteDriversPage = ({
                                 decision, tree, current_user, aiCompleteUnstartedDriversRequest,
                                 vectorStoreUploadFilesRequest, updateTreeData,
                                 checkFinishUploadFilesRequest, loadDecisionTree, updateTreeChannelData,
                                 closeDriverInputSidebarBySlug, closeAndResetDriverDetailsModal,
                                 updateAssignedDriversData
                              }) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [retries, setRetries] = useState(0);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [preparingSources, setPreparingSources] = useState(false);
  const canceled = useRef(false);
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [unstartedDrivers, setUnstartedDrivers] = useState(groupUnstartedDriversByDeep(tree)) || [];
  const [unstartedDriversWithFiles, setUnstartedDriversWithFiles] = useState(isUnstartedDriversWithFiles(tree));
  const driverSlugs = useMemo(() => tree.loaded && unstartedDrivers.flat().map(d => d.slug), []);
  const [driversCounter, setDriversCounter] = useState(unstartedDrivers.length);
  const [driversDeep, setDriversDeep] = useState(1);

  connectDecisionChannelEffect({
    decision,
    current_user,
    updateTreeChannelData,
    closeDriverInputSidebarBySlug,
    closeAndResetDriverDetailsModal,
    updateTreeData,
    updateAssignedDriversData
  })

  const failedCallback = () => {
    setIsError(true);
    setLoading(false);
    setLoaded(true);
  }

  const onCancel = () => {
    canceled.current = true;
    updateTreeData({ loaded: false });
    navigate(`/decisions/${decision.slug}/tree_viewer?expand_drivers=${driverSlugs.join(',')}`);
  };

  const completeDrivers = (unstartedDrivers) => completeUnstartedDrivers(
    aiCompleteUnstartedDriversRequest, unstartedDrivers, decision,
    navigate, setIsError, setDriversCounter, canceled, loading, setDriversDeep,
    setLoading, setLoaded, onCancel, failedCallback, current_user
  );

  const uploadFilesToStore = (callback) => uploadFiles(
    decision.slug, vectorStoreUploadFilesRequest, setIsError, setLoading, setLoaded, callback, failedCallback
  );

  const checkFinishingUploadFiles = (callback) => checkFinishUploadFiles(
    decision.slug, checkFinishUploadFilesRequest, setIsError, setLoading, setLoaded, callback, failedCallback
  );

  const onRetry = () => {
    setIsError(false);
    setLoaded(false);
    setRetries(1);
  }

  const onUploadAndCompleteDrivers = (unstartedDrivers) => {
    uploadFilesToStore(() => {
      checkFinishingUploadFiles(() => {
        setPreparingSources(false);
        completeDrivers(unstartedDrivers);
      })
      setUploadingFiles(false);
    });
  }

  const onCompleteDrivers = (unstartedDrivers) => {
    setLoading(true);
    setIsError(false);
    setRetries(retries + 1);
    setDriversDeep(1);

    if (unstartedDriversWithFiles) {
      setPreparingSources(true);
      setUploadingFiles(true);
      onUploadAndCompleteDrivers(unstartedDrivers);
    } else {
      completeDrivers(unstartedDrivers);
    }
  }

  useEffect(() => {
    if ((loading || loaded) && !(isError && retries < MAX_AI_RETRIES)) return;
    if (retries === 0) return onCompleteDrivers(unstartedDrivers);

    // actualize tree data between retries
    loadDecisionTree((success, _, data) => {
      if (success) {
        const unstartedDrivers = groupUnstartedDriversByDeep(data);
        setUnstartedDrivers(unstartedDrivers);
        setUnstartedDriversWithFiles(isUnstartedDriversWithFiles(data));
        setDriversCounter(unstartedDrivers.length)
        if (isBlank(unstartedDrivers)) return onCancel();

        onCompleteDrivers(unstartedDrivers)
      } else {
        failedCallback()
      }
    });
  }, [loaded, isError]);

  return <Fragment>
    <AlertsSection/>
    <Header onCancel={onCancel} />
    {
      isError && retries >= MAX_AI_RETRIES
        ? <Error error={AI_ERROR_MESSAGE} onRetry={onRetry} />
        : <RequestAccessWrapper key={loading}>
          <h1 className='text-primary text-center'>Loading...</h1>
          { !tree.loaded
            ? <p className='text-center px-4'>{FETCH_DRIVERS_TEXT}</p>
              : uploadingFiles
                ? <p className='text-center px-4'>{PREPARING_SOURCES_TEXT}</p>
                : preparingSources
                  ? <p className='text-center px-4'>{DATA_SOURCES_READY_TEXT}</p>
                  : <p className='text-center px-4'>CloverpopAI is drafting the response for {pluralize(driversCounter, 'driver')} at level {driversDeep}</p>
          }
          <div className='py-3'>
            <Loader marginTop='mt-0'/>
          </div>
          </RequestAccessWrapper>
    }
  </Fragment>
};

const mapStateToProps = ({decision, tree, current_user}) => ({decision, tree, current_user});
const mapDispatchToProps = (dispatch) => ({
  aiCompleteUnstartedDriversRequest: (data, callback, decisionSlug) => dispatch(aiCompleteUnstartedDriversRequest(data, callback, decisionSlug)),
  vectorStoreUploadFilesRequest: (data, callback, decisionSlug) => dispatch(vectorStoreUploadFilesRequest(data, callback, decisionSlug, loadDecisionAiFailure)),
  updateTreeData: (data) => dispatch(updateTreeData(data)),
  checkFinishUploadFilesRequest: (decisionSlug, callback) => dispatch(checkFinishUploadFilesRequest(decisionSlug, callback)),
  loadDecisionTree: (callback) => { dispatch(loadDecisionTree(callback)) },
  updateTreeChannelData: (data) => dispatch(updateTreeChannelData(data)),
  closeDriverInputSidebarBySlug: (driverSlug) => dispatch(closeDriverInputSidebarBySlug(driverSlug)),
  closeAndResetDriverDetailsModal: (driverSlug) => dispatch(closeAndResetDriverDetailsModal(driverSlug)),
  updateAssignedDriversData: (data) => {dispatch(updateAssignedDriversData(data))}
});

export default connect(mapStateToProps, mapDispatchToProps)(AiCompleteDriversPage);
