import TreeChannel, { TREE_CHANNEL_ACTIONS } from "../../../channels/tree_channel";
import EntryPoint from "../EntryPoint";
import { isBlank, isPresent } from "./common";

// TreeChannel helpers
export const treeChannelIsConnected = () => isPresent(EntryPoint.instance.TreeChannelSubscription)

export const isDriverInEditModeByUser = (channels, action, user) => {
  return channels.tree[action]?.some((data) => data.editing_by_email === user.email)
}

export const decisionEditingByUser = (channels, action, decision) =>
  channels.tree[action].find((data) =>
    data.decision_slug === decision.slug
  )?.editing_by

export const driverEditingByUser = (channels, action, slug) =>
  channels.tree[action]?.find((driverData) => driverData.driver_slug === slug)?.editing_by

const isInEditModeByOthers = (channels, action, slug, user, key) => {
  if (isBlank(channels.tree[action])) return false;

  const data = channels.tree[action].find((item) => item[key] === slug && item.edit_mode);
  return isPresent(data) && data.editing_by_email !== user.email;
}

export const isDriverInEditModeByOthers = (channels, action, slug, user) =>
  isInEditModeByOthers(channels, action, slug, user, 'driver_slug');

export const isDecisionInEditModeByOthers = (channels, action, slug, user) =>
  isInEditModeByOthers(channels, action, slug, user, 'decision_slug');

export const performEditDriverDetailsAction = (user = {}, slug = '', editMode = false) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.edit_driver_details,
    editing_by: user.full_name || '',
    editing_by_email: user.email || '',
    driver_slug: slug,
    edit_mode: editMode
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performEditDecisionInputAction = (user = {}, slug = '', editMode = false, touched = false) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.edit_decision_input,
    editing_by: user.full_name || '',
    editing_by_email: user.email || '',
    decision_slug: slug,
    edit_mode: editMode,
    touched
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performEditRecommendationInputAction = (user = {}, slug = '', editMode = false, touched = false) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.edit_recommendation_input,
    editing_by: user.full_name || '',
    editing_by_email: user.email || '',
    decision_slug: slug,
    edit_mode: editMode,
    touched
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performEditDriverAnswerAction = (user = {}, slug = '', editMode = false, touched = false) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.edit_driver_answer,
    editing_by: user.full_name || '',
    editing_by_email: user.email || '',
    driver_slug: slug,
    edit_mode: editMode,
    touched
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performGptDriversAddedAction = (user) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.gpt_drivers_added,
    editing_by: user.full_name || '',
    editing_by_email: user.email || ''
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performReorderDriversAction = (user) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.reorder_drivers,
    editing_by: user.full_name || '',
    editing_by_email: user.email || ''
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performCopyDriversAction = (user) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.copy_drivers,
    editing_by: user.full_name || '',
    editing_by_email: user.email || ''
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performAiCompleteDriversAction = (user) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.ai_complete_drivers,
    editing_by: user.full_name || '',
    editing_by_email: user.email || ''
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performToggleSidebarAction = (user = {}, slug = '', editMode = false) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.edit_driver_answer,
    editing_by: user.full_name || '',
    editing_by_email: user.email || '',
    driver_slug: slug,
    edit_mode: editMode
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const initTreeChannelSubscription = ({
                                              decision,
                                              current_user,
                                              updateTreeChannelData,
                                              closeDriverInputSidebarBySlug,
                                              closeAndResetDriverDetailsModal,
                                              updateTreeData,
                                              updateAssignedDriversData,
                                              showAlert = () => {}
                                            }) => {
  if(isBlank(EntryPoint.instance.TreeChannelSubscription)) {
    const email = current_user.email || '';
    EntryPoint.instance.TreeChannelSubscription = TreeChannel.initInstance(decision.slug).initSubscription()
    EntryPoint.instance.TreeChannelSubscription.received = (data) => {
      if(needCloseDriverInput(data, email)) {
        closeDriverInputSidebarBySlug(data['last_action_data']['driver_slug']);
      }
      if(needCloseDriverDetailsModal(data, email)) {
        closeAndResetDriverDetailsModal(data['last_action_data']['driver_slug'])
      }
      if(needRefreshTree(data, email)) {
        updateTreeData({ loaded: false, real_time_update: true });
        updateAssignedDriversData({ loaded: false, real_time_update: true });
      }
      if(needUpdateDecisionData(data, email) || needUpdateRecommendationData(data, email)) {
        const objectText = data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.edit_decision_input ? 'decision' : 'recommendation';
        const alertClass = data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.edit_decision_input ? 'decision-refresh-page-alert' : 'recommendation-refresh-page-alert';
        showAlert({
          text: `The ${objectText} has been updated. Please <a id="refresh-page-link" href="${window.location.origin}${window.location.pathname}">click here to refresh the page</a>.`,
          type: 'secondary',
          addClass: `${alertClass} text-center`,
          dismissible: true,
          duration: 0
        });
      }
      updateTreeChannelData(data);
    }
  }
}

const needRefreshTree = (data, email) => {
  return isCopyDriversAction(data, email) || isDriverReorderAction(data, email) ||
    gptDriversAdded(data, email) || needUpdateDriverData(data, email) || isAiCompleteDrivers(data, email)
}

const needCloseDriverInput = (data, email) => {
  return isPresent(data['last_action_data']) && email === data['last_action_data']['editing_by_email']
    && !data['last_action_data']['edit_mode']
    && data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.edit_driver_answer
}

const needCloseDriverDetailsModal = (data, email) => {
  return isPresent(data['last_action_data']) && email === data['last_action_data']['editing_by_email']
    && !data['last_action_data']['edit_mode']
    && data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.edit_driver_details
}

const needUpdateDriverData = (data, email) => {
  return isPresent(data['last_action_data']) && email !== data['last_action_data']['editing_by_email']
    && data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.edit_driver_answer
    && !data['last_action_data']['edit_mode'] && data['last_action_data']['touched']
}

const gptDriversAdded = (data, email) => {
  return isPresent(data['last_action_data']) && email !== data['last_action_data']['editing_by_email'] &&
    data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.gpt_drivers_added
}

const isCopyDriversAction = (data, email) => {
  return isPresent(data['last_action_data']) && email !== data['last_action_data']['editing_by_email'] &&
    data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.copy_drivers
}

const isAiCompleteDrivers = (data, email) => {
  return isPresent(data['last_action_data']) && email !== data['last_action_data']['editing_by_email'] &&
    data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.ai_complete_drivers
}

const isDriverReorderAction = (data, email) => {
  return isPresent(data['last_action_data']) && email !== data['last_action_data']['editing_by_email'] &&
    data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.reorder_drivers
}

const needUpdateDecisionData = (data, email) => {
  return isPresent(data['last_action_data']) && email !== data['last_action_data']['editing_by_email']
    && !data['last_action_data']['edit_mode'] &&
    data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.edit_decision_input && data['last_action_data']['touched']
}

const needUpdateRecommendationData = (data, email) => {
  return isPresent(data['last_action_data']) && email !== data['last_action_data']['editing_by_email']
    && !data['last_action_data']['edit_mode'] &&
    data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.edit_recommendation_input && data['last_action_data']['touched']
}

export const isLastActionMadeByUser = (channels, user) => {
  if(isBlank(channels.tree.last_action_data)) return false;

  return channels.tree.last_action_data.editing_by_email === user.email
}

export const handleLockAction = ({ isDsight = false, current_user, slug, editMode = false, touched = false, action = () => {} }) => {
  if(!isDsight) {
    if(treeChannelIsConnected()) action(current_user, slug, editMode, touched);
  }
}

export const unsubscribeTreeChannel = () => {
  if(treeChannelIsConnected()) {
    EntryPoint.instance.TreeChannelSubscription.unsubscribe();
    EntryPoint.instance.TreeChannelSubscription = null
  }
}
