import React from 'react';
import { htmlParser } from "../../../helpers/html_parser";
import Button from 'react-bootstrap/Button';
import { isCollaborator, isDecider } from "../../../helpers/user_helpers";
import { RichTextSection } from "../../../common/RichTextEditor";
import {decisionEditingByUser, isDecisionInEditModeByOthers} from "../../../helpers/channel_helpers";
import {TREE_CHANNEL_ACTIONS} from "../../../../../channels/tree_channel";
import Recommendation from "../../../models/recommendation";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";

const LockedEditButton = ({ user, decision, channels, action, isLegacy }) => {
  if (isLegacy || (!isDecider(user) && !isCollaborator(user))) return null;

  const editingByUser = decisionEditingByUser(channels, action, decision)
  const objectText = action === TREE_CHANNEL_ACTIONS.edit_recommendation_input ? 'recommendation' : 'decision';

  return <TooltipWrapper bubbleIcon={`decision-status-${decision.slug}`}
                         tooltipText={`${editingByUser} is editing this ${objectText}.`} isDriver={true}>
      <span className="icon pe-3 ms-auto mt-2-negative">
      <Button disabled={true} bsPrefix="btn btn-sm btn-secondary btn-sm-round py-1">
        <i className="fas fa-pencil-alt fa-lg w-100"/>
      </Button>
    </span>
  </TooltipWrapper>
}

const EditButton = ({ user, onEdit, isLegacy }) => {
  if (isLegacy || (!isDecider(user) && !isCollaborator(user))) return null;

  return <span className="icon pe-3 ms-auto mt-2-negative">
    <Button onClick={onEdit} bsPrefix="btn btn-sm btn-secondary btn-sm-round py-1">
      <i className="fas fa-pencil-alt fa-lg w-100"/>
    </Button>
  </span>
}

const RankedChoices = ({ object }) =>
  <ol className="ps-3 mb-0">
    {object.finalDecisions.map(c => <li key={`choice-${c.slug}`}>{htmlParser(c.description)}</li>)}
  </ol>

const FinalDecision = ({ object, isLegacy = false }) => {
  if (object.finalDecisions.length === 1)
    return <RichTextSection text={object.finalDecisions[0].description}/>

  if ((isLegacy && object.isFinalDecisionRanked) || (!isLegacy && object.isRankedList))
    return <RankedChoices {...{ object }} />

  return <ul className="ps-3 mb-0">
    {object.finalDecisions.map(c => <li key={`choice-${c.slug}`}><RichTextSection text={c.description}/></li>)}
  </ul>
}

export default ({ user, decision, channels, object, onEdit = () => {}, headerText, isLegacy = false }) => {
  if (object.finalDecisions.length < 1) return null;
  const action = object instanceof Recommendation ? TREE_CHANNEL_ACTIONS.edit_recommendation_input : TREE_CHANNEL_ACTIONS.edit_decision_input
  const isEditingByOthers = isDecisionInEditModeByOthers(channels, TREE_CHANNEL_ACTIONS[action], decision.slug, user)

  return <div>
    <div className="d-flex">
      <h3 className="px-3">{headerText}</h3>
      {
        isEditingByOthers ?
          <LockedEditButton {...{ user, decision, channels, action, isLegacy }} /> :
          <EditButton {...{ user, onEdit, isLegacy }} />
      }
    </div>
    <div className="px-3 mb-3">
      <div hidden={object.finalDecisions.length < 1}>
        <FinalDecision object={object} isLegacy={isLegacy} />
      </div>
    </div>
  </div>;
}
