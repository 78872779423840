import { isPresent, successActions } from "../../helpers/common";
import { deleteWarning } from "../../helpers/alert_helpers";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";

export const duplicateTemplateCallback = (
  createNewTemplate, template, actions, navigate, searchParams, setSearchParams, additionalData = {}
) => () => {
  searchParams.set('search', '')
  setSearchParams(searchParams)
  const additionalDataHash = {
    ...additionalData,
    ...{ description: `${template.description} (copy)`, complete: true }
  };
  createNewTemplate({ template_id: template.slug, ...additionalDataHash }, (slug, completed = false) => {
    if (isPresent(slug)) {
      successActions(true, actions);
      setTimeout(() => {
        navigate(
          `/templates/${slug}${
            template.template_set_slug && completed
              ? '?template_set'
              : completed
                ? ''
                : '/wizard'
          }`
        );
      }, 100);
    }
  });
}
export const deleteTemplateCallback = (deleteTemplate, actions, navigate, location, templateSetSlug = null) => () => {
  const isFromTemplateSet = location.search.includes('template_set');

  deleteWarning(confirmed => {
    if (!confirmed) return;

    deleteTemplate((success) => {
      if (success) {
        successActions(success, actions, true);
        if (templateSetSlug && isFromTemplateSet) {
          navigate(`/template_sets/${templateSetSlug}`);
        } else {
          redirectToHomeCallback(success, navigate, 'templates');
        }
      }
    });
  }, { isTemplate: true });
}
export const removeFromTemplateSetCallback = (slug, removeFromTemplateSet, navigate, { template_set_slug }, actions = []) => () => {
  removeFromTemplateSet(slug , (success) => {
    if (isPresent(success)) {
      successActions(success, actions);
      setTimeout(() => navigate(`/template_sets/${template_set_slug}`), 100);
    }
  });
}
