import React, {useEffect, useState} from 'react';
import {isBlank, isPresent} from "../../../helpers/common";
import {checkLoadingEffect} from "../../../helpers/callbacks_helpers";
import {fetchContacts} from "../../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import {connect} from "react-redux";
import {loadContacts} from "../../../store/contacts/actions";
import AttributeResponseRow from "./AttributeResponseRow";
import {updateDriver} from "../../../store/sidebar/actions";
import {saveSideBarData} from "../../../store/sidebar/common_actions";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import {
  collaboratorCanEditCollaborators,
  isDecider,
  isExistingCollaborator,
  isVisitor,
  userAlreadyAddedAsResponder
} from "../../../helpers/user_helpers";
import {collectCollaborationInvites} from "../../../helpers/decision_helpers";
import {addUserAsCollaboratorWarning} from "../../../helpers/alert_helpers";
import {getAssigneeName} from "../../../tree_editor/modals/DriverAssignModal";
import {driversToArray} from "../../../helpers/drivers_helpers";

const AttributeResponseSection = ({
                                 driverData, contactsData, collaborators = null,
                                 allowPending = true, allowGuest = true, loadContacts,
                                 className = '', updateDriver, decision, assignDriverEntry = false,
                                 current_user, driversData
                               }) => {
  const { driver } = driverData;
  const {responder_email, answered_by_ai} = driver;
  if (answered_by_ai || isDSightDecision(decision) || (assignDriverEntry && isVisitor(current_user))) return null;

  checkLoadingEffect(contactsData, () => loadContacts(() => {}, { invite_yourself: true }));
  collaborators = collaborators || fetchContacts({}, contactsData, allowGuest, allowPending)

  const [responder, setResponder] = useState(responder_email)
  const { slug } = driver;
  const invites = isPresent(decision.invites) ? collectCollaborationInvites(decision) : []
  const userCanEditCollaborators = isDecider(current_user) || collaboratorCanEditCollaborators(current_user, decision)

  const handleResponderChange = (value) => {
    setResponder(value);
    const userName = getAssigneeName(value, collaborators);
    const isExistedCollaborator = isExistingCollaborator(value, invites, decision.user);
    const isExistedResponder = userAlreadyAddedAsResponder(value, driversData.filter(d => d.driver.slug !== slug));

    if (isExistedCollaborator || isExistedResponder || isBlank(value) || !userCanEditCollaborators) {
      updateDriver({ slug, responder_data: { email: value, as_collaborator: false } })
    } else {
      setTimeout(() => {
        addUserAsCollaboratorWarning(confirmed => {
          updateDriver({ slug, responder_data: { email: value, as_collaborator: confirmed } })
        }, userName);
      }, 50);
    }
  };

  const onChangeResponder = (option) => {
    const value = isPresent(option) && option.value ? option.value : '';
    handleResponderChange(value);
  };

  const onCreateResponder = (email) => {
    handleResponderChange(email);
  };

  useEffect(() => {
    setResponder(driver.responder_email)
  }, [driver.responder_email])

  return <AttributeResponseRow {...{collaborators, responder, onChangeResponder, onCreateResponder, className } } />
}

const mapStateToProps = ({ contacts, decision, tree, current_user }) => ({
  decision, tree, current_user,
  contactsData: contacts,
  driversData: isPresent(tree.drivers) ? driversToArray(tree.drivers).filter(d => d.driver.question) : []
});
export default connect(mapStateToProps, {
  loadContacts, updateDriver, saveSideBarData
})(AttributeResponseSection);