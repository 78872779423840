import React from "react";
import {useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";

export default () => {
  const navigate = useNavigate();
  const processBackClick = () => navigate(-1);

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3 justify-content-between">
      <div className="d-flex align-items-center active">
        <span className="btn btn-secondary btn-lg-round d-none d-lg-flex justify-content-around me-2" onClick={processBackClick}>
          <i className="fas fa-lg fa-arrow-left"/>
        </span>
        <span>
          Edit launch parameters
        </span>
      </div>
      <Button onClick={() => {}}
              className="me-2"
              disabled={true}>Update table</Button>
    </nav>
  </header>;
}
