import React from "react";
import parse from "html-react-parser";
import { currencyFormat, isBlank, isPresent } from "../../helpers/common";

const Pending = <span className="text-muted">Pending</span>

const isLastFunc = (index, array) => index === array.length - 1;

const DataField = ({ label, value, isLast = false }) => (
  <div className={`d-flex flex-column ${!isLast ? 'mb-3' : ''}`}>
    <h3 className="mb-2">{label}</h3>
    <span>{value}</span>
  </div>
);

const OutputData = ({ output, value, isLast = false }) =>
  <div className={`d-flex flex-column ${!isLast ? 'mb-2' : ''}`}>
    <strong>{ output.displayName}</strong>
    {
      isBlank(value) ? Pending : <span> {currencyFormat(value)} </span>
    }
  </div>


const SalesData = ({ timePeriod, config, concept, isLast = false }) => (
  <div className={`d-flex flex-column ${!isLast ? 'mb-3' : ''}`}>
    <h3 className="mb-2">{timePeriod.name}</h3>
    {
      config?.scenarioConceptOutputs?.filter(c => c.visibleFor(timePeriod.timeScale))?.map((output, index, array) =>
        <OutputData key={`summary-${timePeriod.id}-${output.id}`} output={output} value={concept?.valueFor(output, timePeriod)} isLast={isLastFunc(index, array)} />
      )
    }
  </div>
);

const ProductMetadata = ({ npfScenario, config }) =>
  <div className="bg-white rounded mt-3 mt-lg-0 ms-0 ms-lg-3 p-3 sidepanel-minwidth">
    {
      config?.cmuColumns.filter(c => c.isVisible).map((column, index, array) =>
        <DataField key={column.id} isLast={isLastFunc(index, array)}
                   label={column.displayName}
                   value={column.values[npfScenario?.cmuColumnValueBy(column)] || Pending}
        />
      )
    }
  </div>


const NotesSection = ({ notes }) => (
  <div className="w-100 bg-white rounded p-3 mb-3">
    <h3>Notes</h3>
    <div>{parse(notes)}</div>
  </div>
);

const FinancialData = ({ npfScenario, config, concept }) => (
  <div className="w-100 bg-white rounded p-3">
    {
      config?.scenarioInputs.filter(c => c.isVisible && c.isCustomField && !c.isDate).map((column) =>
        <DataField key={column.id}
                   label={column.displayName}
                   value={npfScenario?.cmuColumnValueBy(column) || Pending}
        />
      )
    }
    {
      config?.timeScales[0]?.timePeriods?.map((timePeriod, index, array) =>
        <SalesData key={`details-summary-${timePeriod.id}`} {...{ timePeriod, config, concept }} isLast={isLastFunc(index, array)} />
      )
    }
  </div>
);

export default ({ npfScenario, ...opts }) => {
  return (
    <div className="container-xl px-3 mt-2 product-forecast-container">
      <div className="mb-3">
        <h1 className="mt-2">Details</h1>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            {isPresent(npfScenario?.notes) && <NotesSection notes={npfScenario.notes}/>}
            <FinancialData npfScenario={npfScenario} {...opts} />
          </div>
          <ProductMetadata npfScenario={npfScenario} {...opts}/>
        </div>
      </div>
    </div>
  );
};
