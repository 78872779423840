import React, { useMemo } from 'react';
import { connect } from "react-redux";
import {
  copyChoices,
  setDecisionInputSidebarOpen,
  setRecommendationInputSidebarOpen
} from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { isBlank, isPresent } from "../../helpers/common";
import Decision from "../../models/decision";
import Recommendation from "../../models/recommendation";
import { isCollaborator, isDecider } from "../../helpers/user_helpers";
import {
  isDecisionInEditModeByOthers
} from "../../helpers/channel_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../../channels/tree_channel";

const EnterDecisionButton = ({
                               tree, decision, channels, copyChoices, openDecisionInputSidebar,
                               current_user, openRecommendationInputSidebar
                            }) => {

  const decisionObject = useMemo(() => new Decision(decision, tree.drivers), [decision, tree])
  const recommendation = decision.recommendation;
  const recommendationObject = new Recommendation(recommendation);
  const disabled = (action) => isDecisionInEditModeByOthers(channels, action, decision.slug, current_user);

  const onRecommendationOpen = () => {
    openRecommendationInputSidebar(true);
  }
  const onDecisionEntryOpen = () => {
    if(isBlank(decisionObject.decisionChoices) && isPresent(recommendationObject.recommendationChoices)) {
      copyChoices((success) => {
        if (success) openDecisionInputSidebar(true)
      })
    } else {
      openDecisionInputSidebar(true)
    }
  }

  if (isBlank(decision)) return null;
  if (!isDecider(current_user) && !isCollaborator(current_user)) return null;
  if (decisionObject.isDecisionRecorded) return null;

  const isNoRecommendation = isBlank(recommendation) || isBlank(recommendationObject);

  if (!decisionObject.isRecommendationFlow || isNoRecommendation || recommendationObject.isEnteredRecommendation) {
    return <BaseHamburgerBtn disabled={disabled(TREE_CHANNEL_ACTIONS.edit_decision_input)} onSelect={onDecisionEntryOpen} title={'Enter what was decided'} />;
  } else {
    return <BaseHamburgerBtn disabled={disabled(TREE_CHANNEL_ACTIONS.edit_recommendation_input)} onSelect={onRecommendationOpen} title={'Enter recommendation'} />;
  }
}

const mapStateToProps = ({ tree, decision, current_user, channels }) => ({
  tree, decision, current_user, channels
});
const mapDispatchToProps = (dispatch) => ({
  openRecommendationInputSidebar: () => {
    dispatch(setRecommendationInputSidebarOpen(true));
  },
  copyChoices: (callback) => {
    dispatch(copyChoices(callback))
  },
  openDecisionInputSidebar: () => {
    dispatch(setDecisionInputSidebarOpen(true));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EnterDecisionButton);
