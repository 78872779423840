import React, {Fragment, useState} from 'react';
import { connect } from "react-redux";
import AlertsSection from "../alerts";
import Header from "./header";
import {SuccessSectionWrap} from "../quick_poll_entry_page/ChoicesSection";
import {loadDecisionTree} from "../store/tree/actions";
import {checkLoadingEffect} from "../helpers/callbacks_helpers";
import EnterApprovalModal from "../tree_view/modals/EnterApprovalModal";

const ApprovalSuccessPage = ({
                               tree, loadDecisionTree
                            }) => {
  const [showEnterApprovalModal, setShowEnterApprovalModal] = useState(false)
  checkLoadingEffect(tree, () => loadDecisionTree())

  return <Fragment>
    <AlertsSection/>
    <Header/>
    {
      tree.loaded && <div className="container p-3">
        <SuccessSectionWrap>
          <div className='mt-2'>
            <a className='pointer' onClick={() => {setShowEnterApprovalModal(true)}}>
              Click here to edit your response or add notes
            </a>
          </div>
        </SuccessSectionWrap>
      </div>
    }
    <div className="modals">
      <EnterApprovalModal shown={showEnterApprovalModal} onClose={() => setShowEnterApprovalModal(false)}/>
    </div>
  </Fragment>
};

const mapStateToProps = ({tree}) => ({tree});

export default connect(mapStateToProps, {loadDecisionTree})(ApprovalSuccessPage);