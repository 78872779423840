import { isBlank, isPresent, qSortArray } from "../../helpers/common";

export const TABS = {
  details: 'details',
  concepts: 'concepts',
  compare_concepts: 'compare_concepts',
  execution: 'execution'
}

// export const successAlert = (showAlert) => {
//   showAlert({
//     text: 'Success! Your update has been applied.',
//     type: 'success',
//     addClass: 'text-center',
//     dismissible: false,
//     duration: DURATION
//   });
// }
// export const useWhiteBackgroundEffect = () => {
//   useEffect(() => {
//     document.body.classList.add('bg-white');
//     document.documentElement.classList.add('bg-white');
//     return () => {
//       document.body.classList.remove('bg-white');
//       document.documentElement.classList.remove('bg-white');
//     };
//   }, []);
// }

// export const createDecisionTabWithScenario = (forecast_simulator_scenario=null) => {
//   if (forecast_simulator_scenario)
//     window.open(`/homepage/${TABS_URLS.create_decision}?forecast_scenario_id=${forecast_simulator_scenario.scenario_id}`,
//       '_blank').focus()
// }

// export const getScenarioIdFromParam = (document) => {
//   const getParams = new URLSearchParams(document.location.search);
//   return getParams.get('forecast_scenario_id');
// }

// export const generateExcelFile = (gridRef, updateScenarioData) => {
//   generateExcelSuccess('Your download will begin shortly.');
//   setTimeout(() => {
//     updateScenarioData({ export_table: true });
//   }, 1000);
// }

export const filterDropdownScenarios = (fnpf_simulator_scenario) => fnpf_simulator_scenario.config_scenarios

export const filteredOptions = (allOptions, search) =>
  allOptions.filter(hash => {
      if (isBlank(search)) {
        hash.filtered_actualized = null;
        return true;
      }
      if (hash.display_name?.toLowerCase()?.includes(search)) {
        hash.filtered_actualized = null;
        return true;
      }

      hash.filtered_actualized = hash.actualized?.filter(h =>
        h.display_name?.toLowerCase()?.includes(search)
      );
      return isPresent(hash.filtered_actualized);
    }
  )

export const prepareAllOptionsArray = (allOptions) =>
  allOptions.flatMap(h =>
    [
      h,
      ...(h.actualized || []).map(hh =>
        ({ ...hh, display_name: `${h.display_name} ${hh.display_name}` })
      )
    ]
  )

export const scenariosOptions = (npf_simulator_scenario) => {
  return [
    ...qSortArray(
      filterDropdownScenarios(npf_simulator_scenario), true, (hash) => hash.display_name
    )
  ]
}
