import { NpfScenarioConcept } from "./ScenarioConcept";

export class NpfScenario {
  constructor(scenario, config) {
    this.id = scenario.data.id;
    this.local_id = scenario.id;
    this.attributes = scenario.data.attributes;
    this.config = config;
    this.viewOptions = scenario.view_options || {};
    this.displayName = scenario.display_name;
    this._cmuValues = null;
    if (scenario.included) {
      this._initIncluded(scenario.included);
    }
  }

  cmuColumnValueBy(column) {
    if (column.isPartOfCmu) {
      return this.cmuValues.find(value => column.values[value])
    } else {
      return this.categories[column.id];
    }
  }

  get cmuValues() {
    if (this._cmuValues == null) {
      this._cmuValues = this.config?.cmus?.find(row => row.id === this?.cmu)?.cmus || []
    }
    return this._cmuValues;
  }

  get cmu() {
    return this.attributes.config_cmu_id;
  }

  get notes() {
    return this.attributes.notes;
  }


  get categories() {
    return this.attributes.more_cat || {};
  }

  get launchDate() {
    return this.attributes.launch_date;
  }

  get selectedConceptId() {
    return this.viewOptions.concept_id;
  }

  conceptViewData(concept) {
    try {
      return this.viewOptions.concepts[concept.id]
    } catch(e) {
      return {}
    }
  }

  _initIncluded(included) {
    this.concepts = included.filter(({ type }) => type === 'concept');
  }
}
