import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CloseIcon from '../../common/CloseIcon';
import moment from 'moment';
import Select from 'react-select';
import { ChoiceEntryStyle } from '../../tree_wizard/styles/choice_entry_styles';
import { isBlank, isPresent } from "../../helpers/common";
import { loadNpsConfigData } from "../../store/homepage/actions";
import { NpfConfig } from "../../models/forecast/npf/Config";
import NpfScenarioForm from "../../product_forecast/components/ScenarioForm";

const DEFAULT_FIELDS = {
  display_name: '', cmu: null, notes: '', categories: {}
}

const NpfScenarioModal = ({
                            home,
                            loadNpsConfigData,
                            shown,
                            onClose = () => {},
                            onSubmit = () => {}
                          }) => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({ ...DEFAULT_FIELDS, launch_date: moment() });
  const { npf_configs } = home.forecast_simulator.data;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [config_id, setConfig] = useState(npf_configs[0]?.data?.id);
  const [cmus, setCmus] = useState({});
  const [isValid, setIsValid] = useState(false)

  const [selectedConfig, setSelectedConfig] = useState(null)

  useEffect(() => {
    if (shown) {
      setFormData({ ...DEFAULT_FIELDS, launch_date: moment() })
      setCmus({})
    }
  }, [shown]);
  useEffect(() => {
    const newSelectedConfigData = npf_configs.find(config => config.data.id === config_id)
    if (newSelectedConfigData) {
      const newSelectedConfig = new NpfConfig(newSelectedConfigData)
      // if (loaded === config_id && isBlank(newSelectedConfig.cmuColumns)) setLoaded(false);
      if (isPresent(newSelectedConfig.cmuColumns)) setSelectedConfig(newSelectedConfig)
    }
  }, [config_id]);
  useEffect(() => {
    if (loading) return;

    if (loaded !== config_id && isPresent(selectedConfig?.cmuColumns)) {
      return setLoaded(selectedConfig.id);
    }

    if(shown && loaded !== config_id) {
      setLoading(true)
      setLoaded(false)
      loadNpsConfigData(config_id, { tiny: true }, (config, errors) => {
        if (config) {
          setSelectedConfig(new NpfConfig(config))
          setLoaded(config_id)
        } else if (errors) {
          console.error(errors)
          setErrors(errs => ({...errs, display_name: errors}))
        }
        setLoading(false)
      })
    }
  }, [shown, config_id])
  useEffect(() => {
    if (npf_configs.length > 0 && isBlank(config_id)) {
      setConfig(npf_configs[0]?.data?.id)
    }
  }, [config_id, npf_configs.length])
  useEffect(() => {
    if (formRef.current) {
      setIsValid(isBlank(formRef.current?.isInvalid()))
    }
  }, [formData]);

  const close = () => onClose();

  const configsOptions = npf_configs?.map(hash => ({ label: hash.data.attributes.name, value: hash.data.id })) || []

  const onClick = () => {
    if (!formRef.current.validate()) return false;

    setSubmit(true)
    onSubmit(config_id, formData, () => {
      setSubmit(false)
      close()
    })
  }

  return (
    <Modal size="lg" backdrop="static" show={shown} onHide={close}>
      <Modal.Body>
        <CloseIcon onClose={close} />
        <h2>Create new product scenario</h2>
        {
          npf_configs.length < 2 ? <></> :
            <>
              <div className="w-100 mb-3">
                <h3>New product forecast model</h3>
                <Select onChange={(option) => setConfig(option.value)}
                        defaultValue={configsOptions[0]}
                        value={configsOptions.find(hash => hash.value === config_id)}
                        isDisabled={loading || submit}
                        components={{ IndicatorSeparator:() => null }}
                        options={configsOptions}
                        placeholder={'Make a selection'}
                        styles={ChoiceEntryStyle} />
              </div>
            </>
        }

        <NpfScenarioForm
          selectedConfig={selectedConfig}
          formData={formData} setFormData={setFormData}
          cmus={cmus} setCmus={setCmus}
          loading={loading} submit={submit} shown={shown}
          errors={errors} setErrors={setErrors}
          ref={formRef}
        />

        <Button className="w-100 mt-3" onClick={onClick} disabled={submit || !isValid}>
          Done
        </Button>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps, {
  loadNpsConfigData
})(NpfScenarioModal);

