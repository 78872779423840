import React, { useMemo } from 'react';
import { connect } from "react-redux";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";
import EditGreyPencil from "../shared/EditGreyPencil";
import { DRIVER_STATES_DATA } from "../../../models/driver";
import { TREE_CHANNEL_ACTIONS } from "../../../../../channels/tree_channel";
import LockWithTooltip from "../../../common/LockWithTooltip";
import {driverEditingByUser} from "../../../helpers/channel_helpers";

const Icon = ({ driverObject }) => <>
  <div className={`d-flex ${DRIVER_STATES_DATA.completed.color} ${driverObject.isCompleted ? '' : 'd-none'}`}>
    <i className={DRIVER_STATES_DATA.completed.icon_classes} />
  </div>
  <div className={`d-flex ${DRIVER_STATES_DATA.unstarted.color} ${driverObject.isUnstarted ? '' : 'd-none'}`}>
    <i className={DRIVER_STATES_DATA.unstarted.icon_classes} />
  </div>
  <div className={`d-flex ${DRIVER_STATES_DATA.in_flight.color} ${driverObject.isInFlight ? '' : 'd-none'}`}>
    <i className={DRIVER_STATES_DATA.in_flight.icon_classes} />
  </div>
</>

const DriverStatus = ({ driverObject, contactsData, sidebar, editingByOthers, channels }) => {
  const editMode = useMemo(() =>
    sidebar.driverInputSidebar && sidebar.driverSlug === driverObject.driver.slug,
    [sidebar.driverInputSidebar, sidebar.driverSlug])
  if(editingByOthers) {
    const editingByUser = driverEditingByUser(channels, TREE_CHANNEL_ACTIONS.edit_driver_answer, driverObject.driver.slug)
    return <LockWithTooltip editingByUser={editingByUser} bubbleIcon={`driver-status-${driverObject.driver.slug}`} />
  }
  if(editMode) return <EditGreyPencil />

  const answeredByUserEmail = driverObject.driver.answered_by_user
  return <TooltipWrapper bubbleIcon={`driver-status-${driverObject.driver.slug}`}
                         tooltipText={driverObject.statusTooltipText(contactsData, answeredByUserEmail)} isDriver={true}>
    <div className="mt-1 me-2">
      <Icon driverObject={driverObject} />
    </div>
  </TooltipWrapper>
}
const mapStateToProps = ({ sidebar, channels }) => ({ sidebar, channels });
export default connect(mapStateToProps, {})(DriverStatus);