import React, { useCallback, useMemo } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { collectChatMessages } from "../../helpers/discussion_helpers";
import { resetSidebars, setDiscussionPanelOpen, setFinalDecisionSidebarOpen } from "../../store/sidebar/actions";
import { isBlank, successActions } from "../../helpers/common";
import {isDecider, isDeciderReadOnly, isVisitor} from "../../helpers/user_helpers";
import Decision from "../../models/decision";
import { resetTree } from "../../store/tree/common_actions";
import { forceReloadHomepageDecisions } from "../../store/homepage/actions";
import { redirectToHomeCallback, redirectToSignInSuccessCallback } from "../../helpers/decision_helpers";
import { reloadContacts } from "../../store/contacts/actions";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";
import DecisionTreeHamburgerButton from "../../template_view/header/DecisionTreeHamburgerButton";
import DetailsHamburgerButton from "../../template_view/header/DetailsHamburgerButton";
import PlaybookHamburgerButton from "../../template_view/header/PlaybookHamburgerButton";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import { isPlaybookNotesPresent } from "../../helpers/playbook_helpers";
import EnterDecisionButton from "../../template_view/header/EnterDecisionButton";
import HelpHamburgerButton from "../../template_view/header/HelpHamburgerButton";
import DecisionSetPanelHamburgerButton from "../../template_view/header/DecisionSetPanelHamburgerButton";
import { resetChannelsData } from "../../store/channels/actions";
import EntryPoint from "../../EntryPoint";
import { resetDecisionState } from "../../store/decision/common_actions";
import RateCompareHamburgerButton from "./buttons/RateCompareHamburgerButton";
import {hideShowAsCompleted} from "./ShareDropdown";
import {onShowAsCompletedCallback} from "./helpers";
import {openModal, updateDecision} from "../../store/modals/actions";
import RenderDecisionInputSidebar from "./buttons/RenderDecisionInputSidebar";
import Dropdown from "react-bootstrap/Dropdown";
import MoreActionsHamburgerButton from "../../template_view/header/MoreActionsHamburgerButton";
import CollaboratorsHamburgerButton from "../../template_view/header/CollaboratorsHamburgerButton";
import DataSourcesHamburgerButton from "../../template_view/header/DataSourcesHamburgerButton";
import RenderAssignedDriversButton from "./buttons/RenderAssignedDriversButton";
import {openRateBuyInModal} from "../modals/RateBuyInModal";

export const resetDispatch = (dispatch) => ({
  resetSidebars: () => dispatch(resetSidebars()),
  resetTree: () => dispatch(resetTree()),
  forceReloadHomepageDecisions: () => dispatch(forceReloadHomepageDecisions()),
  reloadContacts: () => dispatch(reloadContacts()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  resetDecisionState: () => dispatch(resetDecisionState()),
  resetChannelsData: () => dispatch(resetChannelsData()),
  openModal: (data) => dispatch(openModal(data))
})
export const redirectToHomepageCallback = ({
                                             current_user, navigate,
                                             resetSidebars, resetTree, resetPlaybookNotes, forceReloadHomepageDecisions,
                                             reloadContacts, resetDecisionState, resetChannelsData
                                          }) => () => {
  if (isBlank(current_user.email)) { return redirectToSignInSuccessCallback(true); }

  EntryPoint.instance.recommendationSlug = null;
  successActions(true, [
    resetSidebars, resetTree, resetPlaybookNotes, forceReloadHomepageDecisions,
    reloadContacts, resetDecisionState, resetChannelsData
  ], true)
  redirectToHomeCallback(true, navigate);
}

const DiscussionItem = ({ decision, current_user, openDiscussionSideBar }) => {
  const messages = useMemo(() => collectChatMessages(decision), [decision]);
  if (isBlank(messages) && (isDeciderReadOnly(current_user) || isVisitor(current_user))) return null;

  return <BaseHamburgerBtn onSelect={openDiscussionSideBar} title="Сomments" />
}

const ShowAsCompletedItem = ({ decision, current_user, updateDecision }) => {
  if(hideShowAsCompleted(decision, current_user)) return null;

  return <BaseHamburgerBtn onSelect={() => onShowAsCompletedCallback(updateDecision)} title="Show decision as completed" />
}

export const HamburgerDropdown = ({
                                    decision, current_user, showDecisionSet, openFinalDecisionSideBar,
                                    openDiscussionSideBar, isDsight = false, updateDecision,
                                    showFollowupResults = false, showRateBuyIn = false, openModal,
                                    ...opts
                                  }) => {
  const navigate = useNavigate();
  const decisionObject = useMemo(() => new Decision(decision), [decision]);
  const isDeciderUser = useMemo(() => isDecider(current_user), [current_user])

  const processGoToHomepage = useCallback(redirectToHomepageCallback({
    current_user, navigate, ...opts
  }), [current_user])

  return <HamburgerMenuIconBtn>
    <RateCompareHamburgerButton hidden={decisionObject.isRateAndCompareDriverChoicesHidden} />
    <DetailsHamburgerButton />
    <CollaboratorsHamburgerButton />
    <DataSourcesHamburgerButton />
    { isDsight ? null : <DecisionTreeHamburgerButton title={"Decision tree"} hidden={isDeciderReadOnly(current_user) || isVisitor(current_user)} /> }
    { isPlaybookNotesPresent(decision) ? <PlaybookHamburgerButton /> : null }
    <DiscussionItem {...{ decision, current_user, openDiscussionSideBar }} />
    { showDecisionSet ? <DecisionSetPanelHamburgerButton /> : null }
    <MoreActionsHamburgerButton />
    <HelpHamburgerButton />
    <Dropdown.Divider />
    {showFollowupResults && <BaseHamburgerBtn onSelect={() => openModal({ type: 'FollowupResultsModal'})}
                                              title={'Enter results'} />}
    {showRateBuyIn && <BaseHamburgerBtn onSelect={() => openRateBuyInModal({ decision, openModal })}
                                        title={'Rate support'} /> }
    <EnterDecisionButton />
    <RenderAssignedDriversButton isHamburgerBtn={true} />
    <BaseHamburgerBtn onSelect={processGoToHomepage} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({ decision, share_data, current_user }) => ({
  decision, share_data, current_user
});
const mapDispatchToProps = (dispatch) => ({
  ...resetDispatch(dispatch),
  updateDecision: (data) => dispatch(updateDecision(data)),
  openFinalDecisionSideBar: () => {
    dispatch(setFinalDecisionSidebarOpen(true));
  },
  openDiscussionSideBar: () => {
    dispatch(setDiscussionPanelOpen(true));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);
