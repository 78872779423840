import React from 'react';
import { CheckForm } from "../../../tree_wizard/helpers/DecisionFlowRow";
import { BENCHMARK_KEY, BM_VS_YA_KEY, SIM_VS_BENCHMARK_KEY, SIM_VS_YA_KEY } from "../../helpers/ag_grid_vars";

const LABELS = {
  [BENCHMARK_KEY]: 'Benchmark',
  [SIM_VS_BENCHMARK_KEY]: 'Simulation vs Benchmark (Sim vs BM)',
  [SIM_VS_YA_KEY]: 'Simulation vs a Year Ago (Sim vs YA)',
  [BM_VS_YA_KEY]: 'Benchmark vs Year Ago (BM vs YA)'
}

const ComparisonValues = ({ selectedCompValues, setSelectedCompValues, options, submit }) => {
  return <div className="px-3 mt-3 mb-0">
    <h3>Comparison values</h3>
    {options.map((option, index) => {
      const name = LABELS[option.id];
      const id = option.id

      return <CheckForm
        key={index}
        label={name}
        disabled={submit}
        type={'checkbox'}
        className='mb-0 mt-2'
        checked={selectedCompValues.find(column => column.id === id)?.selected}
        onClick={() => {
          const newSelectedCompValues = selectedCompValues.map(column => {
            if (column.id === id) {
              return { ...column, selected: !column.selected };
            }
            return column;
          });
          setSelectedCompValues(newSelectedCompValues);
        }}
      />
    })}
  </div>
};
export default ComparisonValues;
