import React from 'react';
import BaseHamburgerBtn, { BaseHamburgerItem, DeleteItemElement } from "../../common/BaseHamburgerBtn";
import { IconDropdown} from "../../common/dropdowns";
import { openModal } from "../../store/modals/actions";
import { connect } from "react-redux";
import {createDecisionTabWithScenario} from "../../forecast_simulator_scenario/helpers/helpers";
import {iconDropdownEvents} from "../../forecast_simulator_scenario/header/ActionsDropdown";

const ConceptActionsDropdown = ({ btnId, openModal, deleteCallback, disableDelete = false }) => {
  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.editHandler:
        return openModal({ shown: true, type: 'EditScenarioConceptModal' });
      case iconDropdownEvents.deleteHandler:
        if (disableDelete) return;
        return deleteCallback()
      case iconDropdownEvents.saveAsNewHandler:
        return openModal({ shown: true, type: 'CopyScenarioConceptModal' });
      case iconDropdownEvents.importConceptsHandler:
        return openModal({ shown: true, type: 'ImportConceptsModal' });
    }
  }

  return <IconDropdown id={btnId} bsPrefix="h5 m-0 btn-link pt-2"
                       className="btn btn-secondary btn-lg-round d-flex p-0"
                       onSelect={iconDropdownSelect} >
    <BaseHamburgerBtn title="Save as a new concept"  eventKey={iconDropdownEvents.saveAsNewHandler} />
    <BaseHamburgerBtn title="Edit concept name"  eventKey={iconDropdownEvents.editHandler} />
    <BaseHamburgerBtn title="Import concepts"  eventKey={iconDropdownEvents.importConceptsHandler} />
    <BaseHamburgerItem eventKey={iconDropdownEvents.deleteHandler} disabled={disableDelete}>
      <DeleteItemElement name="concept" />
    </BaseHamburgerItem>
  </IconDropdown>
};

export default connect(null, { openModal })(ConceptActionsDropdown);
