import React from "react";
import ExpandDriver from "./ExpandDriver";

export default ({ editingByOthers = false, description, driverData, expanded, onClick = () => {} }) =>
  <div onClick={onClick} className={`${editingByOthers ? 'cursor-default' : 'pointer'} d-inline-flex w-100 my-auto`} style={{maxWidth: `${expanded ? 'calc(100% - 36px)' : ''}`}}>
    <div className={`note_paragraph ${expanded ? 'h3' : ''} fw-bolder text-ellipsis`}>
      {description}
      <ExpandDriver {...{ expanded, driverData } } />
    </div>
  </div>
