import { parseRowCellId } from "./common";
import { isEditableCell } from "./ag_grid_cell_style";
import { ROW_DRIVER_ID_KEY } from "./ag_grid_vars";
import { isBlank } from "../../helpers/common";

const THIS_POINT_ONLY_ITEM = 'This point only';
const RESET_ITEM = 'Reset';
const DUPLICATE_TO_ITEM = 'Duplicate to all in this row';
const ALL_VISIBLE_TIME_PERIODS_ITEM = 'All visible time periods in this row';
const TIME_PERIODS_ITEM = 'All time periods in this row';

const buildParamsToReset = (rowNode, colDefs) =>
  (colDefs.map((colDef) => ({ node: { id: rowNode.id, prevValue: rowNode.data[colDef.field] }, colDef })))

const buildColDefs = (allFuturePeriodsByTimeScale) =>
  (allFuturePeriodsByTimeScale.map(period => ({ field: period.name, colId: period.id })))

const resetItem = (params, colDefs, onResetCells, allFuturePeriodsByTimeScale) => {
  const rowNode = params.node;

  const action = (visible = false) => {
    const consideredColDefs = visible ?
      colDefs.filter(colDef => colDef.editable) :
      buildColDefs(allFuturePeriodsByTimeScale)
    onResetCells(rowNode, buildParamsToReset(rowNode, consideredColDefs))
  }

  return {
    name: RESET_ITEM,
    subMenu: [
      {
        name: THIS_POINT_ONLY_ITEM,
        action: () => onResetCells(rowNode, buildParamsToReset(rowNode,[params.column.colDef]))
      },
      {
        name: TIME_PERIODS_ITEM,
        action: () => action(true),
      },
    ],
  };
}

const duplicateToItem = (gridRef, params, colDefs, onCellValueChanged, allFuturePeriodsByTimeScale) => {
  const rowNode = params.node;

  const action = (visible = false) => {
    if(isBlank(params.value)) return;

    const valuesToUpdate = [];
    const consideredColDefs = visible ?
      colDefs.filter(colDef => colDef.editable) :
      buildColDefs(allFuturePeriodsByTimeScale)

    consideredColDefs.forEach(colDef => {
      valuesToUpdate.push({
        node: { id: rowNode.id, prevValue: rowNode.data[colDef.field] || 'n/a', value: params.value },
        colDef
      });
    });
    const updatedData = consideredColDefs.reduce((acc, current) => {
      acc[current.field] = params.value;
      return acc;
    }, {});
    onCellValueChanged(valuesToUpdate, () => {
      gridRef.current.api.applyTransaction({ update: [{ ...rowNode.data, ...updatedData }] });
    });
  }

  return {
    name: DUPLICATE_TO_ITEM,
    action: () => action(true)
  };
}

const applyLargeScaleInputItem = (params, openLargeScalePanel) => {
  const rowDriverId = params.node.data[ROW_DRIVER_ID_KEY];
  const { driverId } = parseRowCellId(rowDriverId);
  return { name: 'Apply large-scale input...', action: () => openLargeScalePanel(driverId) }
}

export const getContextMenuItems = (gridRef, params, editable = false, colDefs = [], onCellValueChanged = () => {},
                                    onResetCells = () => {}, allFuturePeriodsByTimeScale, openLargeScalePanel) => {
  if (editable) {
    return [
      resetItem(params, colDefs, onResetCells, allFuturePeriodsByTimeScale),
      duplicateToItem(gridRef, params, colDefs, onCellValueChanged, allFuturePeriodsByTimeScale),
      applyLargeScaleInputItem(params, openLargeScalePanel),
      'separator',
      'copy'
    ];
  } else {
    return [
      duplicateToItem(gridRef, params, colDefs, onCellValueChanged, allFuturePeriodsByTimeScale),
      applyLargeScaleInputItem(params, openLargeScalePanel),
      'separator',
      'copy'
    ]
  }
}

export const showContextMenu = (params, config, timeScale, forecastScenario, periodName, period) => {
  return isEditableCell(forecastScenario, params, config, timeScale, period) &&
    forecastScenario.periodHeadersNames.includes(periodName) &&
    isBlank(params.node.groupData)
}
