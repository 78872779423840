import React from 'react';
import { connect } from "react-redux";
import { hideCustomDashboardTab } from "../../helpers/home_helpers";

const CustomDashboardItem = ({current_org}) => {
  if (hideCustomDashboardTab(current_org)) return null;

  return <li>
    <a href={current_org.custom_dashboard_link} target="_blank">
      <span className="icon d-inline-block text-center">
        <i className="fas fa-chart-line fa-lg" />
      </span>
      <span className="d-none d-lg-inline tab-title">{current_org.custom_dashboard_title || 'Custom dashboard'}</span>
    </a>
  </li>
}

const mapStateToProps = ({ current_org }) => ({ current_org });
export default connect(mapStateToProps, {})(CustomDashboardItem);
