import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import {isMobile} from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import Footer from "./common/Footer";
import { setLargeScalePanelOpen } from "../../store/sidebar/actions";
import FactToUpdate from "./large_scale_input/FactToUpdate";
import BasisChange, { BASIS_CHANGE_OPTIONS_KEYS } from './large_scale_input/BasisChange';
import { CHANGE_TYPE_TABS} from "../helpers/ag_grid_vars";
import ChangeType from './large_scale_input/ChangeType';
import ApplyTo from './large_scale_input/ApplyTo';
import PeriodSelectors from "../modals/settings/PeriodSelectors";
import { updateScenarioData } from "../../store/forecast_simulator_scenario/actions";
import { isBlank } from "../../helpers/common";
import { showAlert } from "../../store/alerts/actions";
import {cmuScopes, DEFAULT_SCOPES} from "../helpers/scopes_helpers";
import { successAlert } from "../helpers/helpers";

const LargeScaleInput = ({ sidebar, forecast_simulator_scenario, config, forecastScenario, openSideBar, updateScenarioData, showAlert }) => {
  if(!forecast_simulator_scenario.scenario_loaded) return null;

  const periods = useMemo(() => forecastScenario.allForecastedEditablePeriods, [forecastScenario]);
  const periodOptions = useMemo(() => periods.map((periodData) =>
    ({
      value: periodData.attributes.start_date,
      start_date: periodData.attributes.start_date,
      end_date: periodData.attributes.end_date,
      label: periodData.name
    })), [periods]);

  const [factToUpdate, setFactToUpdate] = useState({});
  const [changeType, setChangeType] = useState(CHANGE_TYPE_TABS.replaceCurrentValue);
  const [basisChange, setBasisChange] = useState(BASIS_CHANGE_OPTIONS_KEYS.current);
  const [amount, setAmount] = useState('');
  const [from, setFrom] = useState(periodOptions[0]?.start_date);
  const [to, setTo] = useState(periodOptions[periodOptions.length-1]?.end_date);
  const [submit, setSubmit] = useState(false);
  const { scopes, updateScope } = cmuScopes(config, forecastScenario, {}, DEFAULT_SCOPES.all, false);

  const updateAction = () => {
    if(isBlank(amount)) return;

    setSubmit(true);
    updateScenarioData({
      large_scale_input: {
        factToUpdate, changeType, basisChange, amount, from, to, scopes
      }
    })
  }

  useEffect(() => {
    if(submit && isBlank(forecast_simulator_scenario.large_scale_input)) {
      successAlert(showAlert);
      setSubmit(false);
    }
  }, [forecast_simulator_scenario.large_scale_input])

  return <SlidingPanel
    type={'left'}
    isOpen={sidebar.largeScaleInputSidebar}
    panelContainerClassName={`sidebar-panel-wrapper large-scale-panel-wrapper left-side-panel-position`}
    panelClassName={`${isMobile ? '' : 'd-flex flex-column'} sidebar-panel-wrapper-content large-scale-input-panel-content h-100`}
    size={50}>
    <PanelHeader headerName={'Large-scale input'} openSideBar={openSideBar} preview={true}/>
    <div className={'px-3'}>
      <FactToUpdate {...{ config, forecastScenario, setFactToUpdate } } />
      <BasisChange {...{ forecastScenario, basisChange, setBasisChange } } />
      <ChangeType {...{
        factToUpdate,
        basisChange,
        selectedItem: changeType,
        handleToggle: (value) => setChangeType(value),
        amount, setAmount } }
      />
    </div>
    <ApplyTo config={config} scopes={scopes} updateScope={updateScope} scenarioCmus={forecast_simulator_scenario.cmus} />
    <div className={'px-3'}>
      <PeriodSelectors {...{ from, to, setTo, setFrom, options: periodOptions,
        fromHeader: 'Starting period', toHeader: 'Ending period'
      } } />
    </div>
    <Footer btnTitle={'Update fact'} updateAction={updateAction} disabled={submit || forecastScenario.isLocked} />
  </SlidingPanel>
}
const mapStateToProps = ({ sidebar, forecast_simulator_scenario }) =>
  ({ sidebar, forecast_simulator_scenario });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setLargeScalePanelOpen(options));
  },
  updateScenarioData: (data) =>
    dispatch(updateScenarioData(data)),
  showAlert: (data) => dispatch(showAlert(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(LargeScaleInput);
