import React, { useCallback } from 'react';
import { connect } from "react-redux";
import {
  hideCreateDecisionTab,
  hideDSightTab,
  hideGroupsTab,
  hideLearningTab,
  hideSettingsTab,
  hideReportInsightsTab,
  hideForecastSimulatorTab,
  isCategoriesCenter,
  isCreateDecisionCenter,
  isDecisionsCenter,
  isDSightTab,
  isGroupsCenter,
  isGuest,
  isLearningTab, isReportInsightsTab,
  isSearchDecisions,
  isTemplatesCenter,
  isUsersCenter,
  isForecastSimulatorTab,
  TABS,
  TABS_URLS, hideCustomDashboardTab,
} from "../../helpers/home_helpers";
import { signOut, updateHomepageCurrentTab } from "../../store/homepage/actions";
import { redirectToSignInSuccessCallback } from "../../helpers/decision_helpers";
import { Routes } from "../../routes";
import BaseHamburgerBtn, { BaseHamburgerLink } from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import { setHelpPanelOpen } from "../../store/sidebar/actions";
import {isBlank} from "../../helpers/common";

const BaseTabItem = ({ tab, isActive, selectTab, title }) =>
  <BaseHamburgerLink to={`/homepage/${TABS_URLS[tab]}`}>
    <span className={`nav-link ${isActive ? 'active' : ''}` } onClick={() => selectTab(TABS[tab])}>
      {title}
    </span>
  </BaseHamburgerLink>

const CreateDecisionItem = ({ home, current_user, selectTab }) =>
  hideCreateDecisionTab(current_user) ? null :
    <BaseTabItem tab="create_decision" isActive={isCreateDecisionCenter(home, current_user)} selectTab={selectTab} title="Create decisions" />

const CustomDashboardItem = ({ current_org }) =>
  hideCustomDashboardTab(current_org) ? null :
    <BaseHamburgerLink to={current_org.custom_dashboard_link} target={'_blank'}>
      <span className="nav-link">
        {current_org.custom_dashboard_title || 'Custom dashboard'}
      </span>
    </BaseHamburgerLink>

const ReportInsightsItem = ({ home, current_user, current_org, selectTab }) =>
  hideReportInsightsTab(current_user, current_org) ? null :
    <BaseTabItem tab="report_insights" isActive={isReportInsightsTab(home, current_user, current_org)} selectTab={selectTab} title="Report insights" />

const DSightItem = ({ home, current_user, current_org, selectTab }) =>
  hideDSightTab(current_user, current_org) ? null :
    <BaseTabItem tab="dsight" isActive={isDSightTab(home, current_user, current_org)} selectTab={selectTab} title="D-Sight" />

const LearnItem = ({ home, current_user, selectTab }) =>
  hideLearningTab(current_user) ? null :
    <BaseTabItem tab="learn" isActive={isLearningTab(home, current_user)} selectTab={selectTab} title="Decision learning" />

const ForecastSimulatorItem = ({ home, current_user, current_org, selectTab }) => {
  if (hideForecastSimulatorTab(current_org, current_user) || isBlank(home.forecast_simulator.data.configs))
    return null

  return <BaseTabItem tab="forecast_simulator"
                      isActive={isForecastSimulatorTab(home, current_user, current_org)}
                      selectTab={selectTab}
                      title="Forecasting"/>
}

const GroupsItem = ({ home, current_user, selectTab }) =>
  hideGroupsTab(current_user) ? null :
    <BaseTabItem tab="groups" isActive={isGroupsCenter(home, current_user)} selectTab={selectTab} title="Groups" />

const UsersItem = ({ home, current_user, selectTab }) =>
  hideSettingsTab(current_user) ? null :
    <BaseTabItem tab="users" isActive={isUsersCenter(home, current_user)} selectTab={selectTab} title="Users" />

const TemplatesItem = ({ home, current_user, selectTab }) =>
  hideSettingsTab(current_user) ? null :
    <BaseTabItem tab="templates" isActive={isTemplatesCenter(home, current_user)} selectTab={selectTab} title="Templates" />

const CategoriesItem = ({ home, current_user, current_org, selectTab }) =>
  hideSettingsTab(current_user) ? null :
    <BaseTabItem tab="categories" isActive={isCategoriesCenter(home, current_user)} selectTab={selectTab} title="Categories" />

const DecisionsCenterItem = ({ home, current_user, selectTab }) =>
  isGuest(current_user) ? null :
    <BaseTabItem tab="decisions_center" isActive={isDecisionsCenter(home)} selectTab={selectTab} title="Decision center" />

const SearchDecisionsItem = ({ home, selectTab }) =>
  <BaseTabItem tab="search_decisions" isActive={isSearchDecisions(home)} selectTab={selectTab} title="Search decision bank" />

const ProfileItem = () =>
  <BaseHamburgerLink to={Routes.profile} className={''}>
    <span className={`nav-link`}>
      Manage profile
    </span>
  </BaseHamburgerLink>

const HelpItem = ({ openHelpPanel, home, current_user }) => {
  if (isLearningTab(home, current_user)) return null;

  return <BaseHamburgerBtn onSelect={openHelpPanel} className={''}>
    <span className={`nav-link user_name`}>Help & resources</span>
  </BaseHamburgerBtn>
}

const LogoutItem = ({ signOutHandler }) =>
  <BaseHamburgerBtn onSelect={signOutHandler} className={''}>
    <span className={`nav-link user_name`}>Log out</span>
  </BaseHamburgerBtn>

export const NavbarMenu = ({
                             current_user, current_org, home, openHelpPanel,
                             updateHomepageCurrentTab, signOut
                          }) => {
  const selectTab = (current_tab) => { updateHomepageCurrentTab(current_tab) }
  const signOutHandler = useCallback(() => { signOut(redirectToSignInSuccessCallback) }, [])

  return <div>
      <HamburgerMenuIconBtn>
        <DecisionsCenterItem home={home} current_user={current_user} selectTab={selectTab} />
        <DSightItem home={home} current_user={current_user} current_org={current_org} selectTab={selectTab} />
        <ForecastSimulatorItem home={home} current_user={current_user} selectTab={selectTab} current_org={current_org} />
        <LearnItem home={home} current_user={current_user} selectTab={selectTab} />
        <CustomDashboardItem current_org={current_org} />
        <ReportInsightsItem home={home} current_user={current_user} current_org={current_org} selectTab={selectTab} />
        <CreateDecisionItem home={home} current_user={current_user} selectTab={selectTab} />
        <SearchDecisionsItem home={home} selectTab={selectTab} />
        <GroupsItem home={home} current_user={current_user} selectTab={selectTab} />
        <UsersItem home={home} current_user={current_user} selectTab={selectTab} />
        <TemplatesItem home={home} current_user={current_user} selectTab={selectTab} />
        <CategoriesItem home={home} current_user={current_user} selectTab={selectTab} current_org={current_org} />
        <ProfileItem />
        <HelpItem openHelpPanel={openHelpPanel} home={home} current_user={current_user} />
        <LogoutItem signOutHandler={signOutHandler} />
      </HamburgerMenuIconBtn>
    </div>
}

const mapStateToProps = ({ home, current_user, current_org }) => ({ home, current_user, current_org });
const mapDispatchToProps = (dispatch) => ({
  openHelpPanel: () => {
    dispatch(setHelpPanelOpen(true))
  },
  updateHomepageCurrentTab: (data) => {
    dispatch(updateHomepageCurrentTab(data))
  },
  signOut: (callback) => {
    dispatch(signOut(callback))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(NavbarMenu);
