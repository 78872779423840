import React from "react";
import OrgLogo from "../tree_view/header/OrgLogo";
import {useNavigate} from "react-router-dom";
import {successActions} from "../helpers/common";
import {redirectToHomeCallback} from "../helpers/decision_helpers";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {HamburgerMenuIconBtn} from "../common/dropdowns";
import BaseHamburgerBtn from "../common/BaseHamburgerBtn";

const Header = ({current_org, onCancel}) => {
  const navigate = useNavigate();
  const processBackClick = () => {
    successActions(true, []);
    redirectToHomeCallback(true, navigate);
  }

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick} />
        </div>
        <div className="w-100">
          <h5>Complete drivers with CloverpopAI</h5>
        </div>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerMenuIconBtn>
            <BaseHamburgerBtn onSelect={onCancel} title="Cancel" />
            <BaseHamburgerBtn onSelect={processBackClick} title="Go to homepage" />
          </HamburgerMenuIconBtn>
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            <Button onClick={onCancel} className="btn btn-light btn btn-light-primary">
              Cancel
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  </header>
}

const mapStateToProps = ({current_org}) => ({current_org});
export default connect(mapStateToProps, {})(Header);