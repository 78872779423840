import React, { useMemo } from 'react';
import { CHART_TYPES } from "./ChartType";
import LineChart from "../charts/LineChart";
import ColumnChart from "../charts/ColumnChart";
import { SIMULATED_TYPES } from "./SimulatedType";
import { arrayLastElement, isBlank, isPresent } from "../../../helpers/common";
import { CHART_VALUES_TYPES } from "./ChartValuesType";
import NoChartsDataPlaceholder from "./NoChartsDataPlaceholder";
import { genYearAgoPeriod, timePeriodNameParse } from "../../../models/forecast/ForecastTImeScale";

export const calculateYearAgoChanges = ({
                                          currentValue, scenario,
                                          period, cmuId, driverColumn,
                                          rows = false
                                        }) => {
  const prevYearPeriod = genYearAgoPeriod({ period, allowBlank: true });
  if (isBlank(prevYearPeriod)) return { value: 0.0 };
  const prevYearValue = scenario.aggregateBy({
    cmu: parseInt(cmuId),
    period: prevYearPeriod,
    driver: driverColumn,
    rows
  })
  if (isBlank(prevYearValue)) return { value: 0.0 };

  if (driverColumn.isPercentage) return { value: currentValue - prevYearValue, prevYearValue };

  return { value: ((currentValue - prevYearValue) / prevYearValue * 100), prevYearValue }
}

export const calculateScenariosData = ({
                                         periods, scenario, cmuId, driverColumn,
                                         cmusGroups = [], groupedRows = []
                                       }) =>
  periods?.map(period => {
    const currentValue = scenario.aggregateBy({
      cmu: parseInt(cmuId),
      cmusGroups,
      period: period,
      driver: driverColumn,
      rows: groupedRows[`${cmuId}-${period.id}`]
    })
    if (currentValue === null) return null;

    return {
      value: currentValue,
      percent: calculateYearAgoChanges({
        currentValue, scenario,
        period, cmuId, driverColumn,
        rows: groupedRows[`${cmuId}`]
      }).value
    }
  })

const ScenariosChart = ({
                          simulatedType, chartValuesType, chartType, cmusGroups,
                          simulatedChartDataHashes, benchmarkChartDataHashes, driverColumn, cmuValues, periods,
                          forecastScenario
                        }) => {
  const simulatedChartData = useMemo(() => {
    return Object.keys(cmuValues)?.map(cmuId => {
      return {
        name: [cmuValues[cmuId], simulatedType === SIMULATED_TYPES.both ? '(Simulated)' : ''].filter(isPresent).join(' '),
        data: simulatedChartDataHashes[cmuId] || []
      }
    })?.filter(h => isPresent(h.data)) || [];
  }, [simulatedChartDataHashes, simulatedType])

  if (isBlank(simulatedChartData) && isPresent(cmusGroups)) {
    return <NoChartsDataPlaceholder />
  }

  const benchmarkChartData = useMemo(() => {
    if (simulatedType !== SIMULATED_TYPES.both || isBlank(driverColumn)) return [];

    return Object.keys(cmuValues)?.map(cmuId => ({
      name: `${cmuValues[cmuId]} (Benchmark)`,
      data: benchmarkChartDataHashes[cmuId] || []
    }))?.filter(h => isPresent(h.data)) || [];
  }, [benchmarkChartDataHashes, simulatedType, isBlank(driverColumn)])

  const allForecastedPeriods = forecastScenario.allForecastedEditablePeriods

  const forecastedPeriodsForChart = periods.map((p, index) =>
    allForecastedPeriods.some(forecastedDataPeriod => p.isWithinTimePeriod(forecastedDataPeriod.start_date) ) ? index : null
  ).filter(isPresent)
  const chartsOpts = useMemo(() => {
    return {
      title: driverColumn?.displayName,
      measure: chartValuesType === CHART_VALUES_TYPES.percent ? '%' : driverColumn?.measureForChart,
      decimal: (chartValuesType === CHART_VALUES_TYPES.percent ? 1 : driverColumn?.decimal) || 0,
      categories: periods?.map(period => timePeriodNameParse(period.name)),
      series: [
        ...simulatedChartData.map(h => ({
          name: h.name,
          data: h.data.map(hh =>
            (chartValuesType === CHART_VALUES_TYPES.percent ? hh?.percent : driverColumn.prepareValueForChart(hh?.value)) || null
          )
        })),
        ...benchmarkChartData.map(h => ({
          name: h.name,
          data: h.data.map(hh =>
            (chartValuesType === CHART_VALUES_TYPES.percent ? hh?.percent : driverColumn.prepareValueForChart(hh?.value)) || null
          )
        }))
      ],
      plotBands: isPresent(forecastedPeriodsForChart) ? [{ // Highlight the two last years
        from: forecastedPeriodsForChart[0] - 0.5,
        to: arrayLastElement(forecastedPeriodsForChart) + 0.5,
        className: 'bg-forecast-column'
      }] : []
    }
  }, [simulatedChartData, benchmarkChartData, chartValuesType])

  return <div className="w-100">
    {chartType === CHART_TYPES.line && <LineChart {...chartsOpts} />}
    {chartType === CHART_TYPES.column && <ColumnChart {...chartsOpts} />}
  </div>
}
export default ScenariosChart;
