import React, { useState, useRef } from 'react';
import { CheckForm } from "../../../tree_wizard/helpers/DecisionFlowRow";
import {Template} from "../../../models/template";
import {connect} from "react-redux";

const EnableAiTreeCompletionInput = ({ template, onChangeSettings, current_org }) => {
  if (new Template(template).isRateAndCompareDriverChoices || !current_org.allow_gpt) return null;
  const [submit, setSubmit] = useState(true);
  const { enable_ai_tree_completion } = template;
  const checkboxRef = useRef(null);

  return(
    <CheckForm
      label={'Enable tree completion with CloverpopAI'}
      disabled={!submit}
      type={'checkbox'}
      className='mb-0 mt-2'
      checked={enable_ai_tree_completion === true}
      onClick={() => onChangeSettings({ enable_ai_tree_completion: !enable_ai_tree_completion }, checkboxRef, setSubmit)}
      ref={checkboxRef}
    />
  )
};

const mapStateToProps = ({ current_org }) => ({ current_org });
export default connect(mapStateToProps, {})(EnableAiTreeCompletionInput);
