import React from "react";
import { TooltipWrapper } from "../bubble_tooltip/BubbleTooltip";
import { LockIcon } from "./LockIcon";

const LockWithTooltip = ({ editingByUser, bubbleIcon, objectText = 'driver', classes = 'ms-auto me-2' }) => {
  return <TooltipWrapper bubbleIcon={bubbleIcon}
                         tooltipText={`${editingByUser} is editing this ${objectText}.`} isDriver={true}>
    <div className={classes}>
      <LockIcon />
    </div>
  </TooltipWrapper>
}
export default LockWithTooltip;