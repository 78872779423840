import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import UserAvatarImage from "../../../common/UserAvatarImage";
import CreatedData from "./CreatedData";
import {isBlank, isPresent} from "../../../helpers/common";
import ActualizedTooltip from "../../../forecast_simulator_scenario/components/ActualizedTooltip";
import {CalendarCheckMenuIconBtn} from "../../../common/dropdowns";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import { TYPES } from "../../../models/forecast/Base";
import {TABS as SIDEBAR_TABS} from "../../../product_forecast/helpers/helpers";

const TYPE_TO_URL = {
  [TYPES.category_forecast]: 'forecast_simulator/scenarios',
  [TYPES.new_product]: 'npf_simulator/scenarios'
}

const scenarioUrl = (scenario) => {
  return `/${TYPE_TO_URL[scenario.forecast_type] || 'forecast_simulator/scenarios' }/${scenario.id}`
}

const TileContent = ({ scenario, user }) => {
  const navigate = useNavigate();

  const scenarioClickAction = (e, actualizedScenario) => {
    e.preventDefault();
    navigate(scenarioUrl(actualizedScenario))
  }

  return <div className="homepage-list-col">
    <div className="d-flex align-items-center list-col-content overflow-visible bg-white p-2">
      <div className="text-start overflow-hidden lh-sm">
        <div className={`text-nowrap text-ellipsis fw-bolder ${scenario.deleted_at ? 'text-muted' : ''}`}>
          {scenario.display_name}
        </div>
        <CreatedData scenario={scenario} classNames={'text-muted'}/>
      </div>
      <div className="ps-2 ms-auto">
        <div className={`d-flex justify-content-end text-end`}>
          {
            isPresent(scenario.actualized) &&
              <CalendarCheckMenuIconBtn id={`actualized-scenario-${scenario.id}`}
                                        disabled={isBlank(scenario.actualized)}
                                        className="d-inline me-2">
                {
                  scenario.actualized.map(sa =>
                    <BaseHamburgerBtn onSelect={(e) => scenarioClickAction(e, sa)}
                                      key={`scenario-copy-homepage-${sa.id}`}
                                      title={sa.display_name}/>
                  )
                }
              </CalendarCheckMenuIconBtn>
          }
          <ActualizedTooltip {...scenario} iconClassName="sm-avatar me-2" iconSize={'sm'}/>
          {user && <UserAvatarImage key={`${scenario.id}`} user={user}/>}
        </div>
      </div>
    </div>
  </div>
}



export default ({
                  scenario, user
                }) => {
  const handleLinkClick = (e) => {
    if (e.target.closest('.dropdown-toggle')) {
      e.preventDefault();
    }
  };
  const getParams = new URLSearchParams(document.location.search)
  getParams.set('tab', SIDEBAR_TABS.details)

  return <div className="col-12 mb-2">
    {
      isPresent(scenario.deleted_at)  ?
        <div><TileContent {...{ scenario, user }} /></div> :
        <Link to={`${scenarioUrl(scenario)}?${getParams.toString()}`}
              onClick={handleLinkClick}
              className="text-dark text-center">
          <TileContent {...{ scenario, user }} />
        </Link>
    }
  </div>
}
