import React from 'react';
import { CheckForm } from "../../../tree_wizard/helpers/DecisionFlowRow";


const ColumnsTotals = ({ selectedColumns, setSelectedColumns, rootCmuHeaders, submit }) => {
  return <div className="px-3 mt-3 mb-0">
    <h3>Columns & totals</h3>
    {rootCmuHeaders.map((header, index) => {
      const name = header.displayName;
      const id = header.id

      return <CheckForm
        key={index}
        label={name}
        disabled={submit}
        type={'checkbox'}
        className='mb-0 mt-2'
        checked={selectedColumns.find(column => column.id === id)?.selected}
        onClick={() => {
          const newSelectedColumns = selectedColumns.map(column => {
            if (column.id === id) {
              return { ...column, selected: !column.selected };
            }
            return column;
          });
          setSelectedColumns(newSelectedColumns);
        }}
      />
    })}
  </div>
};
export default ColumnsTotals;
