import {
    EXPAND_TREE,
    LOAD_TREE_DATA,
    LOAD_TREE_FAILED,
    LOAD_TREE_STARTED,
    RESET_TREE_DATA, RESET_TREE_DRIVERS_DATA,
    UPDATE_TREE_DATA
} from "./types";

const initialState = {
    loading: false,
    loaded: false,
    error: '',
    drivers: [],
    data_sources: [],
    uploading_sources: [],
    selected_ztree_node: {},
    copied_ztree_node: {},
    default_user_avatar_url: null,
    changesSaved: false,
    expanded: false,
    scroll_to_driver_comment: '',
    scroll_to_driver: '',
    expand_poll_results: '',
    locationPath: '',
    real_time_update: false
};

export function treeReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case LOAD_TREE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false,
                loaded: true,
                real_time_update: false
            };
        }
        case UPDATE_TREE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false,
                changesSaved: true
            };
        }
        case LOAD_TREE_STARTED: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: true,
                loaded: false,
                changesSaved: false
            }
        }
        case LOAD_TREE_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false,
                changesSaved: false,
                real_time_update: false
            }
        }
        case RESET_TREE_DRIVERS_DATA: {
            return {
                ...state,
                ...initialState,
                data_sources: state.data_sources
            }
        }
        case RESET_TREE_DATA: {
            return {
                ...state,
                ...initialState
            }
        }
        case EXPAND_TREE: {
            return {
                ...state,
                expanded: action.payload
            }
        }
        default:
            return state;
    }
}
