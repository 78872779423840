import React from "react";
const indentationSize = 36;

export default ({ children, indentStep = 0, onClick = () => {}, clickable = false, expanded = false, id = '',
                  isPoll= false, decisionObject = {}, hasSingleChild = false, classNames = 'my-2', showBefore = false}) => {
  const indentPx = indentStep * indentationSize;
  return <div className={`${indentStep === 0 ? '' : 'driver-block '}${hasSingleChild ? 'border-short ' : ''}position-relative bg-white rounded ${clickable ? 'pointer' : 'cursor-default'} ${expanded ? 'p-3' : 'p-2'}
                          ${isPoll ? '' : classNames} ${isPoll && decisionObject.isRecordedOrRecommended ? 'mb-3' : ''} 
                          ${isPoll && showBefore ? 'poll-border-to-arrow' : ''}`}
              onClick={onClick} id={id}
              style={{ marginLeft: `${indentPx}px`, minHeight: 60 }}>
    {children}
  </div>
}
