export const TYPES = {
  category_forecast: 'category_forecast',
  new_product: 'new_product'
}

export class ForecastBaseModel {
  constructor({ id, attributes }) {
    this.id = parseInt(id);
    this.attributes = attributes;
  }
}
