import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { isBlank, isPresent } from "../helpers/common";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from 'react-bootstrap/Tooltip';
import { isMsTeamsFlow, isPlaybookBubbleTab, isSearchDecisions, isSlackFLow } from "../helpers/home_helpers";
import { isDSightDecision } from "../helpers/home_decision_helpers";
import parse from 'html-react-parser';
import { ListInFolder } from "../icons/custom_icons";
import Decision from "../models/decision";
import UserAvatarImage from "../common/UserAvatarImage";
import { userName } from "../helpers/user_helpers";
import { fetchAssignedToCollaborator } from "../helpers/decision_user_helpers";

const bubbleContent = (object_hash, template_set_name, decision_set_name) => ({
  sequence_number: {
    keyId: `${object_hash.slug}-${object_hash.sequence_number}`,
    bubbleText: isPresent(object_hash.sequence_number) ? object_hash.sequence_number.toString() :  '',
    tooltipText: false
  },
  assignee: {
    ...(() => {
      const assignedUserEmail = fetchAssignedToCollaborator(object_hash)
      const findUser = (users) => (users || []).find(u => u.email === assignedUserEmail)
      const assignedUser = findUser(object_hash.set_users) || findUser(object_hash.users)

      return {
        html: <UserAvatarImage {...{ user: assignedUser, pendingUserEmail: assignedUserEmail, hideTitle: true }} />,
        tooltipText: `Assigned to ${userName(assignedUser, assignedUserEmail)}`
      }
    })(),
    keyId: `${object_hash.slug}-assignee`,
    classNames: ''
  },
  dsight: {
    bubbleIcon: 'dsights-icon--light h-20 w-0 d-initial',
    tooltipText: isPresent(object_hash.data_set_name) ? `D-Sight<br/>Product ${object_hash.data_set_name}` :  'D-Sight'
  },
  playbook: {
    bubbleIcon: 'align-middle fa-book-open text-white',
    tooltipText: 'Powered by Playbook'
  },
  template_set: {
    bubbleIcon: 'align-middle fas fa-list-check text-white',
    tooltipText: `Part of the decision flow "${template_set_name}"`
  },
  decision_set: {
    bubbleIcon: 'align-middle fas fa-list-check text-white',
    tooltipText: `Part of the decision flow "${decision_set_name}"`
  },
  slack: {
    bubbleIcon: 'align-middle fas fa-brands fa-slack text-white fa-xl',
    tooltipText: 'Slack decision'
  },
  teams: {
    bubbleIcon: 'align-middle fas fa-brands fa-microsoft text-white fa-xl',
    tooltipText: 'Microsoft Teams decision'
  },
  isFlow: {
    bubbleIcon: 'svgIcon',
    isSvgIcon: true,
    tooltipText: 'Decision flow'
  }
})

const AVAILABLE_BUBBLES = [
  {
    key: 'sequence_number',
    displayByHomeTab: () => true,
    isDisplay: (object_hash) => isPresent(object_hash.sequence_number)
  },
  {
    key: 'assignee',
    displayByHomeTab: () => true,
    isDisplay: (object_hash, { isInDecisionSet, isDecisionSet, isTemplateSetSection }) => {
      const obj = new Decision(object_hash)
      return (isInDecisionSet || isDecisionSet || isTemplateSetSection) && !obj.isRecordedOrRecommended &&
        (isPresent(object_hash.decision_set_slug) || isPresent(object_hash.template_set_slug)) && isPresent(fetchAssignedToCollaborator(object_hash, obj))
    }
  },
  {
    key: 'dsight',
    displayByHomeTab: () => true,
    isDisplay: (object_hash) => object_hash.d_sight_semaphore || isDSightDecision(object_hash)
  },
  {
    key: 'template_set',
    displayByHomeTab: () => true,
    isDisplay: (object_hash, { isTemplateSetSection }) => (!isTemplateSetSection && isPresent(object_hash.template_set_slug))
  },
  {
    key: 'decision_set',
    displayByHomeTab: () => true,
    isDisplay: ({}, { isDecisionSet, decision_set_name }) => (isDecisionSet && isPresent(decision_set_name))
  },
  {
    key: 'playbook',
    displayByHomeTab: isPlaybookBubbleTab,
    isDisplay: (object_hash) => (object_hash.playbook || object_hash.playbook_notes)
  },
  {
    key: 'slack',
    displayByHomeTab: (home) => isSearchDecisions(home),
    isDisplay: (object_hash) => (isSlackFLow(object_hash))
  },
  {
    key: 'teams',
    displayByHomeTab: (home) => isSearchDecisions(home),
    isDisplay: (object_hash) => (isMsTeamsFlow(object_hash))
  },
  {
    key: 'isFlow',
    displayByHomeTab: () => true,
    isDisplay: (object_hash) => object_hash.all_decisions_count != null
  }
]

const BubbleItem = ({bubbleIcon = null, bubbleText = null, keyId = null, isSvgIcon = false, tooltipText, html = null, classNames = 'bg-gray py-1'}) =>
  <TooltipWrapper bubbleIcon={keyId || bubbleIcon || ''} tooltipText={tooltipText} >
    {
      isSvgIcon ? <div><ListInFolder fill={'#8A949F'} /></div> :
        <div className={`d-inline-block rounded-circle text-center ${classNames}`}>
          { bubbleIcon && <i className={`fas ${bubbleIcon} fa-lg`} /> }
          { bubbleText && <span className="text-white">{bubbleText}</span> }
          {html}
        </div>
    }
  </TooltipWrapper>

export const TooltipWrapper = ({bubbleIcon, tooltipText, children, placement = 'top', isDriver = false}) =>
  isBlank(tooltipText) ? <>{children}</> :
    <OverlayTrigger key={bubbleIcon} placement={placement}
                    overlay={
                      <Tooltip className={`lh-normal fs-6 px-1 ${isDriver ? 'tooltip-top' : ''}`}>
                        {parse(tooltipText)}
                      </Tooltip>
                    }>
      {children}
    </OverlayTrigger>

const BubbleTooltip = ({
                         home,
                         current_user, current_org,
                         object_hash,
                         isDecisionSet = false, isInDecisionSet = false, isTemplateSetSection = false, isAddTemplateSection = false,
                         template_set_name = {}, decision_set_name = {}, inListLine = false
                      }) => {
  const location = useLocation();
  const bubblesToDisplay = AVAILABLE_BUBBLES.filter(({ displayByHomeTab }) =>
    isAddTemplateSection || displayByHomeTab(home, current_user, current_org, location)
  ).filter(({ isDisplay }) =>
    isDisplay(object_hash, { isTemplateSetSection, isDecisionSet, decision_set_name, isInDecisionSet })
  );

  if (isBlank(bubblesToDisplay)) return null;

  const contents = bubbleContent(object_hash, template_set_name, decision_set_name)

  return <Fragment>
    <div className={`${inListLine ? '' : 'd-flex justify-content-center'}`}>
      <div className={`${inListLine ? 'd-flex' : ''} bubblesRow`}>
        {
          bubblesToDisplay.map(({ key }) => <BubbleItem key={`bubble-${key}`} {...contents[key]} />)
        }
      </div>
    </div>
  </Fragment>
}

const mapStateToProps = ({ home, current_user, current_org }) =>
  ({ home, current_user, current_org })
;
export default connect(mapStateToProps)(BubbleTooltip);
