import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import {ModalDoneFooter} from "../../common/modals";
import {connect} from "react-redux";
import Select from "react-select";
import {BorderedFilterStyle} from "../../tree_wizard/styles/choice_entry_styles";
import {isBlank} from "../../helpers/common";
import Form from "react-bootstrap/Form";
import {CheckForm} from "../../tree_wizard/helpers/DecisionFlowRow";

//TODO: replace fakeAttributes with real data
const fakeOptions = [
  { label: "GoodBar Q4", value: 1, concepts: [{ label: "Concept 1", id: 1 }, { label: "Concept 2", id: 2 }] },
  { label: "DailyBrew H1", value: 3, concepts: [{ label: "Concept A", id: 3 }] },
  { label: "NutriCrunch Growth", value: 4, concepts: [{ label: "Idea X", id: 4 }, { label: "Idea Y", id: 5 }, { label: "Idea Z", id: 6 }] },
  { label: "PureWash Launch", value: 2, concepts: [{ label: "Concept Alpha", id: 7 }, { label: "Concept Beta", id: 8 }] },
  { label: "Essentials Line FY25", value: 6, concepts: [{ label: "Concept 1", id: 9 }] },
  { label: "ShineCare Pilot 1", value: 7, concepts: [{ label: "Vision 1", id: 10 }, { label: "Vision 2", id: 11 }] },
  { label: "SnackWell Expansion", value: 8, concepts: [{ label: "Concept 1", id: 12 }] },
  { label: "PureBubbles Q2", value: 9, concepts: [{ label: "Vision 1", id: 13 }, { label: "Vision 2", id: 14 }] },
  { label: "Fuel Line Regional", value: 10, concepts: [{ label: "Concept 1", id: 15 }] },
  { label: "ShineCare Pilot FY50", value: 11, concepts: [{ label: "Vision 1", id: 16 }, { label: "Vision 2", id: 17 }] },
  { label: "PureBubbles Q4", value: 12, concepts: [{ label: "Vision 1", id: 18 }, { label: "Vision 2", id: 19 }] },
];

export const showImportConceptsModal = (modal) => modal.type === "ImportConceptsModal" && modal.shown;

const ScenarioConcepts = ({scenario, selectedConceptsList = [], setSelectedConceptsList}) => {
  useEffect(() => {
    setSelectedConceptsList(scenario.concepts.map((concept) => concept.id))
  }, [scenario])

  const selectConcept = (conceptId) => {
    setSelectedConceptsList((prev) =>
      prev.includes(conceptId)
        ? prev.filter((id) => id !== conceptId)
        : [...prev, conceptId]
    );
  };

  return <div className="mt-3">
    <h3>Product concepts</h3>
    {scenario.concepts.map((concept) =>
      <div className={`lh-checkbox mt-2`}>
        <input type="checkbox" id={`concept-${concept.id}`} name={`concept-${concept.id}`}
               className="custom-checkbox"
               checked={selectedConceptsList.includes(concept.id)}
               onChange={() => { selectConcept(concept.id) }}/>
        <label htmlFor={`concept-${concept.id}`} className="mb-0">{concept.label}</label>
      </div>
    )}
  </div>
}

const AVAILABLE_ACTIONS = [
  { label: 'Add as a copy', className: 'mb-2', value: 'copy', name: 'scenario' },
  { label: 'Replace concept', className: 'mb-0', value: 'replace', name: 'scenario' }
]

const CheckboxActions = ({selectedActionValue, setSelectedActionValue}) => <div className="mt-3">
    <h3>Add as a copy or replace name matches?</h3>
    <Form>
      {
        AVAILABLE_ACTIONS.map(({ value, label, ...hash }) =>
          <CheckForm {...hash} key={`check-form-${value}`} id={`${value}-${label}`} label={label}
                     checked={selectedActionValue === value} onClick={() => setSelectedActionValue(value)}/>
        )
      }
    </Form>
  </div>

const ImportConceptsModal = ({ show, submit, onClose = () => {}, setSubmit = () => {}, ...opts }) => {
  const [selectedSource, setSelectedSource] = useState()
  const [selectedActionValue, setSelectedActionValue] = useState(AVAILABLE_ACTIONS[0].value)
  const [selectedConceptsList, setSelectedConceptsList] = useState([])
  const focusElement = React.useRef(null);
  useEffect(() => {
    if (show && focusElement.current) focusElement.current.focus();
  }, [show]);

  const handleScenarioSelect = (selectedOption) => {
    if (isBlank(selectedOption)) return setSelectedSource(null);

    const selectedScenario = fakeOptions.find((option) => option.id === selectedOption.id);
    if (selectedScenario) {
      setSelectedSource(selectedOption);
    }
  };

  const onSubmit = () => {
    setSubmit(true);
    setTimeout(() => {
      console.log('Submitted:', { display_name: 'imported' });
      setSubmit(false);
      onClose();
    }, 500);
  };

  return <Modal size="md" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Import concepts</h2>
      <h3>Source scenario</h3>
      <Select onChange={(id) => handleScenarioSelect(id)}
              components={{ IndicatorSeparator:() => null }}
              options={fakeOptions}
              value={selectedSource}
              styles={BorderedFilterStyle}
              isSearchable isClearable
              placeholder={'Make a selection'}
              ref={focusElement}/>
      {selectedSource &&
        <>
          <ScenarioConcepts scenario={selectedSource} {...{selectedConceptsList, setSelectedConceptsList}}/>
          <CheckboxActions {...{selectedActionValue, setSelectedActionValue}}/>
        </>}
    </Modal.Body>
    <ModalDoneFooter onClose={onSubmit} disabled={isBlank(selectedSource) || submit}>
      <span>Import concepts</span>
    </ModalDoneFooter>
  </Modal>
}
const mapStateToProps = ({ current_user }) => ({ current_user });

export default connect(mapStateToProps, {})(ImportConceptsModal);