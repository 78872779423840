import React, {useRef} from "react";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {
  openModal
} from "../../../../store/modals/actions";
import {attachEventEffect} from "../../../../helpers/html_events_helpers";
import Decision from "../../../../models/decision";

const modalType = (decisionObj) =>
  decisionObj.isRecordedOrRecommended || decisionObj.isTreeHistorical ? 'TreePanelRecommendationEntryModal' : 'TreePanelMultiEntryModal';

const EditRecommendation = ({ modal, decision, tree, treeNode, openModal }) => {
  const ref = useRef();
  const decisionObj = new Decision(decision)

  const onEditDecision = (e) => {
    e.preventDefault();
    openModal({ decision, drivers: tree.drivers, data_sources: [], slug: treeNode.slug, type: modalType(decisionObj) })
  }
  attachEventEffect(ref, onEditDecision)

  return <>
    <Button className="btn btn-secondary btn-sm btn-sm-round" ref={ref}>
      <i className="fas fa-pencil-alt fa-lg w-100" />
    </Button>
  </>
}
const mapStateToProps = ({ modal, decision, tree }) => ({ modal, decision, tree });
export default connect(mapStateToProps, { openModal })(EditRecommendation);
