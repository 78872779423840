import React from "react";
import { connect } from "react-redux";
import { removeTreeNode } from "../../../../store/tree/actions";
import { resetDriverInputSidebar } from "../../../../store/sidebar/common_actions";
import BaseDriverRemove from "../../../../common/BaseDriverRemove";
import {
  handleLockAction, performEditDriverAnswerAction,
} from "../../../../helpers/channel_helpers";
import { isPresent } from "../../../../helpers/common";
import EntryPoint from "../../../../EntryPoint";

const triggerPageRefresh = ({ current_user, slug }) => {
  handleLockAction({
    touched: true,
    current_user,
    slug: slug,
    action: performEditDriverAnswerAction
  });
}

const DriverRemove = ({ current_user, treeNode, removeTreeNode, resetDriverInputSidebar }) => {
  const deleteCallback = confirmed => {
    if (confirmed) {
      resetDriverInputSidebar();
      setTimeout(() => {
        removeTreeNode(treeNode.slug, (status) => {
          if(status && isPresent(EntryPoint.instance.TreeChannelSubscription)) {
            triggerPageRefresh({ current_user, slug: treeNode.slug });
          }
        });
      }, 300)
    }
  }
  return <BaseDriverRemove treeNode={treeNode} deleteCallback={deleteCallback} />
}
const mapStateToProps = ({ current_user }) => ({ current_user });

export default connect(mapStateToProps, { removeTreeNode, resetDriverInputSidebar })(DriverRemove);
