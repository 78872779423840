import React from "react";
import {expireLocalStorage, getLocalStorageSize, isBlank, MAX_LOCAL_STORAGE_QUOTA} from "../../../../helpers/common";

const isLocalStorageOverwhelmed = () => getLocalStorageSize() > MAX_LOCAL_STORAGE_QUOTA

export const driverExpand = (treeNode, decisionSlug, collapsedDriversSlugs, keyValue = null, loadVisibleDecisions = () => {}) => {
  if (isBlank(keyValue)) return false;
  if (isBlank(collapsedDriversSlugs)) collapsedDriversSlugs = JSON.parse(localStorage.getItem(keyValue))

  if (collapsedDriversSlugs[decisionSlug]) {
    collapsedDriversSlugs[decisionSlug] = collapsedDriversSlugs[decisionSlug].filter(
      entry => entry.slug !== treeNode.slug
    );

    if (collapsedDriversSlugs[decisionSlug].length === 0) {
      delete collapsedDriversSlugs[decisionSlug];
    }
    loadVisibleDecisions();
    localStorage.setItem(keyValue, JSON.stringify(collapsedDriversSlugs));
  }
}

export const driverCollapse = (treeNode, decisionSlug, collapsedDriversSlugs, keyValue = null) => {
  if (isBlank(keyValue) || isLocalStorageOverwhelmed()) return false;
  if (isBlank(collapsedDriversSlugs)) collapsedDriversSlugs = JSON.parse(localStorage.getItem(keyValue))

  if (!collapsedDriversSlugs[decisionSlug]) {
    collapsedDriversSlugs[decisionSlug] = [];
  }

  if (!collapsedDriversSlugs[decisionSlug].find(entry => entry.slug === treeNode.slug)) {
    collapsedDriversSlugs[decisionSlug].push({slug: treeNode.slug, expiresAt: expireLocalStorage()});
  }

  localStorage.setItem(keyValue, JSON.stringify(collapsedDriversSlugs));
}
