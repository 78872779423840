import React, {Fragment, useEffect, useState} from 'react';
import { connect } from "react-redux";
import DotsLoader from "../../helpers/DotsLoader";
import {isBlank, isPresent} from "../../../../helpers/common";
import {AiUserInstruction, AssistantContentSwitcher, DriverList} from "./AiInputs";
import {
  AI_ERROR_MESSAGE,
  DATA_SOURCES_READY_TEXT, getDotsLoaderText,
  getInitContext, MAX_AI_RETRIES, PREPARING_SOURCES_TEXT,
  REVIEWING_DRIVERS_TEXT
} from "../../../../helpers/ai_helpers";
import {MIN_DRIVERS_FOR_AI_ANALYSIS} from "../../../../helpers/drivers_helpers";
import {RichTextSection} from "../../../../common/RichTextEditor";
import {ShowMoreLessRow} from "../../../../common/MoreLessText";
import {updateDecisionData} from "../../../../store/decision/common_actions";

const FilesAnalysis = ({expandedFilesAnalysis, setExpandedFilesAnalysis, file_analysis}) => {
  if (isBlank(file_analysis)) return null;

  return <>
    {
      expandedFilesAnalysis && <div className='mt-3'>
        <RichTextSection {...{text: file_analysis || ''}} />
      </div>
    }
    <ShowMoreLessRow {...{expanded: expandedFilesAnalysis, setExpanded: setExpandedFilesAnalysis}} />
  </>
}

const ErrorRow = () => <p className='text-muted mx-3 my-2'>Error: {AI_ERROR_MESSAGE}</p>

const AiResponseSection = ({
                             sidebar, loaded, setLoaded, context, setContext, assistantContent, index, setIndex,
                             apiAiResponseCallback, decision, driverData, type, loadedVs, setLoadedVs, expandedList,
                             setExpandedList, childrenDrivers, instruction, short_summary, response, file_analysis,
                             aiUseOnlyDrivers, countAnsweredChildren, expandedFilesAnalysis, setExpandedFilesAnalysis
                           }) => {
  const {recommendationInputSidebar, decisionInputSidebar, driverInputSidebar} = sidebar
  const [reviewingText, setReviewingText] = useState(DATA_SOURCES_READY_TEXT);
  const [retries, setRetries] = useState(0);
  const {errorAi, loadingAiData} = decision;

  useEffect(() => {
    if (loadingAiData) return;
    if (!driverInputSidebar && !recommendationInputSidebar && !decisionInputSidebar) return;

    if (isPresent(context)) {
      setTimeout(() => setLoaded(true), 50);
      setTimeout(() => setLoadedVs(true), 100);
      return;
    }

    const initialContext = getInitContext(type, decision, driverData);
    if ((isBlank(initialContext) || isPresent(errorAi)) && retries < MAX_AI_RETRIES) {
      const increment = isPresent(context) ? 2 : 1;
      setRetries(prev => prev + increment);
      setTimeout(() => apiAiResponseCallback(), 1000);
    } else {
      setContext(initialContext);
    }
  }, [loadingAiData])

  useEffect(() => {
    assistantContent.length > 0 && setIndex(assistantContent.length - 1)
  }, [assistantContent.length])

  useEffect(() => {
    if(!loadedVs) return;
    setReviewingText(DATA_SOURCES_READY_TEXT);

    setTimeout(() => {
      setReviewingText(REVIEWING_DRIVERS_TEXT);
    }, 10000);
  }, [loadedVs, loaded]);

  if (isPresent(errorAi)) return <ErrorRow />
  if (!loadedVs) return <DotsLoader text={PREPARING_SOURCES_TEXT} addClass={'mx-3 my-2'} />
  if (!loaded) return <DotsLoader addClass={'mx-3 my-2'}
                                  text={getDotsLoaderText(aiUseOnlyDrivers, countAnsweredChildren, reviewingText, assistantContent.length)}/>
  if (isBlank(context)) return null;

  return <Fragment>
    <div className='mx-3'>
      <RichTextSection {...{text: short_summary || ''}} />
      <RichTextSection {...{text: response || ''}} />
      <FilesAnalysis {...{expandedFilesAnalysis, setExpandedFilesAnalysis, file_analysis}} />
    </div>
    {countAnsweredChildren >= MIN_DRIVERS_FOR_AI_ANALYSIS && <DriverList {...{expandedList, setExpandedList, childrenDrivers}} />}
    <AssistantContentSwitcher {...{assistantContent, index, setIndex}} />
    <AiUserInstruction instruction={instruction} />
  </Fragment>
}

const mapStateToProps = ({ sidebar, decision }) => ({ sidebar, decision })

export default connect(mapStateToProps, {updateDecisionData})(AiResponseSection);
