import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {groupUnstartedDriversByDeep} from "../helpers/drivers_helpers";
import {isBlank} from "../helpers/common";
import BaseHamburgerBtn from "./BaseHamburgerBtn";
import {isDSightDecision} from "../helpers/home_decision_helpers";
import Decision from "../models/decision";
import {isDecider} from "../helpers/user_helpers";
import {connect} from "react-redux";

const hideCompleteDrivers = (decision, user, tree, current_org) => {
  if(!decision.enable_ai_tree_completion || !current_org.allow_gpt) return true
  if(!new Decision(decision).isRateAndCompareDriverChoicesHidden) return true
  if(isDSightDecision(decision)) return true
  if(isBlank(tree.drivers)) return true

  const decisionObj = new Decision(decision)
  const isDeciderUser = isDecider(user)
  return !isDeciderUser || decisionObj.isReadOnly
}

const CompleteDriversButton = ({ decision, user, tree, completeDriversWithAi, useHamburgerBtn = false, current_org }) => {
  if (hideCompleteDrivers(decision, user, tree, current_org)) return null;

  const isDisabled = isBlank(groupUnstartedDriversByDeep(tree));
  const tooltip = isDisabled ? <Tooltip>Complete subdrivers or add data sources to use this feature.</Tooltip> : <></>;

  return (
    <OverlayTrigger placement='top' overlay={tooltip}>
      <div>
        {
          useHamburgerBtn
            ? <BaseHamburgerBtn
                disabled={isDisabled}
                onSelect={completeDriversWithAi}
                title="Complete drivers with CloverpopAI"
              />
            : <Button onClick={completeDriversWithAi} className="btn btn-secondary w-100 mb-2"
                      disabled={isDisabled}>
                Complete drivers with CloverpopAI
              </Button>
        }
      </div>
    </OverlayTrigger>
  );
};

const mapStateToProps = ({ current_org }) => ({ current_org });
export default connect(mapStateToProps, {})(CompleteDriversButton);