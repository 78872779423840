import React from 'react';
import { connect } from "react-redux";
import AllowCollaboratorsEditTreeInput
  from "../../../tree_view/side_panel/decision_input/AllowCollaboratorsEditTreeInput";
import AllowManageCollaboratorsInput from "../../../tree_view/side_panel/decision_input/AllowManageCollaboratorsInput";
import {updateTemplate} from "../../../store/modals/actions";
import EnableAiTreeCompletionInput from "../../../tree_view/side_panel/decision_input/EnableAiTreeCompletionInput";

const SettingsSection = ({ template, updateTemplate }) => {
  const onChangeSettings = (data, checkboxRef, setSubmit) => {
    setSubmit(false);
    const callback = (success) => {
      if (success) {
        checkboxRef.current && checkboxRef.current.scrollIntoView({ behavior: 'smooth' });
        setSubmit(true);
      }
    };
    updateTemplate({ template: data }, callback)
  };

  return <div className={'my-2'}>
    <h3>Settings</h3>
    <AllowCollaboratorsEditTreeInput {...{decision: template, onChangeSettings}} />
    <AllowManageCollaboratorsInput {...{decision: template, onChangeSettings}} />
    <EnableAiTreeCompletionInput {...{template, onChangeSettings}} />
  </div>
};

const mapStateToProps = ({ template }) => ({ template });
export default connect(mapStateToProps, {updateTemplate})(SettingsSection);
