import React, { useState } from 'react';
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { safeDescription } from "../../../../helpers/decision_helpers";
import { isDSightTemplate } from "../../../../helpers/template_helpers";
import { isPresent, previewQueryParams } from "../../../../helpers/common";
import BubbleTooltip from '../../../../bubble_tooltip/BubbleTooltip'
import { openModal } from "../../../../store/modals/actions";
import { openStartDecisionModal } from "../../../modals/StartDecisionModal";
import { setSetDecisionsSidebarOpen } from "../../../../store/sidebar/actions";
import { startNewDecision } from "../../../../store/homepage/actions";
import { resetDecisionSetData } from "../../../../store/decision_set/common_actions";
import { getSectionSlugFromParam } from "../../../../helpers/set_helpers";
import DSightTemplateTile from "./DSightTemplateTile";
import {addSetInvites, loadAddedDecision} from "../../../../store/decision_set/actions";
import { getScenarioIdFromParam } from "../../../../forecast_simulator_scenario/helpers/helpers";

export const TemplateTile = ({ template,
                               openModal,
                               resetDecisionSetData,
                               startNewDecision,
                               isDecisionSet = false,
                               addSetInvites,
                               loadAddedDecision,
                               backPath = null }) => {
  const [submit, setSubmit] = useState(false)
  if (isDSightTemplate(template)) { return <DSightTemplateTile template={template} /> }

  const navigate = useNavigate();
  const { id } = useParams();

  const handleOnClick = () => {
    if(isDecisionSet) {
      if (submit) return false;

      setSubmit(true)
      const section_slug = getSectionSlugFromParam(document);
      startNewDecision({
        ...isPresent(section_slug) && { section_slug: section_slug },
        template_slug: template.slug,
        description: template.description,
        dsight: false,
        decision_set_slug: id,
        flow_enum: 'unstarted_tree',
        playbook: template.playbook,
        assigned_collaborator_email: template.assigned_collaborator_email,
        poll_notes: template.poll_notes,
        allow_collaborators_edit_tree: template.allow_collaborators_edit_tree,
        allow_collaborators_edit_collaborators: template.allow_collaborators_edit_collaborators,
        ai_instructions: template.ai_instructions,
        enable_ai_tree_completion: template.enable_ai_tree_completion
      }, (slug) => {
        isPresent(template.assigned_collaborator_email) && addSetInvites([template.assigned_collaborator_email])
        loadAddedDecision(slug);
        setSubmit(false)
        navigate(`/decision_sets/${id}?left_side_bar_open=set_decisions`)
      })
    } else {
      openStartDecisionModal({ openModal, template })
    }
  };

  const section_slug = getSectionSlugFromParam(document);
  const forecast_scenario_id = getScenarioIdFromParam(document);
  const queryParams = {
    ...(isPresent(backPath) && backPath),
    ...(isPresent(section_slug) && { section_slug }),
    ...(isPresent(forecast_scenario_id) && { forecast_scenario_id }),
  }

  return <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip object_hash={template} template_set_name={template.template_set_name} isDecisionSet={isDecisionSet}/>
    <div className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">{safeDescription(template)}</h4>
        <Link to={`/templates/${template.slug}/preview${previewQueryParams(queryParams)}`}>
          <div className="btn btn-secondary align-center h-36 py-1 w-100 mb-2">
            <span>Preview decision</span>
          </div>
        </Link>
        <div className="btn btn-primary align-center h-36 py-1 w-100 pointer" onClick={handleOnClick}>
          <i className="fas fa-plus fa-lg me-2" />
          <span>{isDecisionSet ? 'Add to decision flow' : 'Create decision'}</span>
        </div>
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ decision_set }) => ({ decision_set });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  openSideBar: () => { dispatch(setSetDecisionsSidebarOpen(true)) },
  startNewDecision: (data = {}, callback) => dispatch(startNewDecision(data, callback)),
  resetDecisionSetData: () => dispatch(resetDecisionSetData()),
  addSetInvites: (invites, callback) => { dispatch(addSetInvites(invites, callback)) },
  loadAddedDecision: (slug) => dispatch(loadAddedDecision(slug))
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateTile);
