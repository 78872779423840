import {useEffect, useMemo, useState} from "react";
import {isFuturePeriod} from "../../models/forecast/ForecastScenario";
import {isBlank, isPresent} from "../../helpers/common";
import {getContextMenuItems, showContextMenu} from "./ag_grid_context_menu";
import CustomLoadingOverlay, {hideOverlay, MESSAGES, showOverlayWithMessage} from "./custom_loading_overlay";
import {
  CAGRForecastedAggFunc,
  CAGRHistoricalAggFunc,
  preparedColDefs,
  totalAggFunc,
  YTDAggFunc,
  YTGAggFunc
} from "./ag_grid_col_defs";
import { styledCAGRColumn, styledFactsColumn, styledPeriodColumn } from "./ag_grid_cell_style";
import { useApplyLargeScaleInputEffect } from "./ag_grid_large_scale_input_helpers";
import { prepareDataForRunModel, useRunModelEffect } from "./ag_grid_run_model";
import { useImportValuesEffect } from "./ag_grid_import_values";
import { extractDriverName, onCellValueChanged, refreshAggregations, rowCellIdKey } from "./common";
import { GROUP_COL_ID_SUFFIX, ROW_DRIVER_ID_KEY } from "./ag_grid_vars";
import {
  getTableOrderFromCookies,
  restoreForecastCookies,
  saveCollapsedGroups,
  saveColumnState,
  saveFilters,
  saveTableOrderToCookies
} from "./ag_grid_cookies";
import { onBodyScroll, onBodyScrollEnd, onHorizontalScrollBody } from "./ag_grid_scroll";
import { useOnWorkerDoneEffect, handleRowsWorkers, handleAggregationsWorkers } from './ag_grid_web_worker_effect';
import {applyColumnsSettings, COMPARISON_INITIAL_OPTIONS} from "../side_panel/ChangeTableSettingsPanel";

export const getRowStyle = (params, forecastScenario) => {
  if(isValueSalesRow(params, forecastScenario)) {
    return { border: 'none' }
  }
  if (params.node.rowIndex % 2 === 0) {
    return { background: '#FFFFFF' };
  }
}

const isValueSalesRow = (params, forecastScenario) => {
  return params.node.key === forecastScenario.valueSalesDriverName;
}

const isComparisonRowToHide = (params, forecastScenario) => {
  const settingsValues = forecastScenario.comparisonValues || COMPARISON_INITIAL_OPTIONS;
  return settingsValues.some(value => params.node.id.includes(value.id) && !value.selected);
}

const isTotalRowToHide = (params, forecastScenario) => {
  if(!params.node.group) return false;

  return forecastScenario.tableColumnsSettings.some(col =>
    params.node.groupData.hasOwnProperty(`${col.displayName}-${GROUP_COL_ID_SUFFIX}`) && !col.selected
  );
}

const getRowClass = (params, forecastScenario) => {
  if(isValueSalesRow(params, forecastScenario) || isComparisonRowToHide(params, forecastScenario) || isTotalRowToHide(params, forecastScenario)) {
    return 'hidden';
  } else {
    return null;
  }
}

const getRowHeight = (params, forecastScenario) => {
  if(isValueSalesRow(params, forecastScenario) || isComparisonRowToHide(params, forecastScenario) || isTotalRowToHide(params, forecastScenario)) {
    return 0;
  } else {
    return null;
  }
}

export const applyEditedCells = (rows, editedCells, newTimeScale) => {
  return rows.map((row) => {
    const editedCellsData = editedCells.filter(cellData => cellData.nodeId === row[ROW_DRIVER_ID_KEY] && newTimeScale === cellData.timeScale);
    if(isPresent(editedCellsData)) {
      editedCellsData.forEach(editedCellData => {
        row[editedCellData.field] = editedCellData.value;
      })
    }
    return row;
  })
}

export const isGroupOpenByDefault = (params, forecastScenario) => {
  return params.field !== 'Facts' || forecastScenario.openedGroupsIds.includes(params.rowNode.id)
}

const calcUpdatedCells = (runModelCells) => {
  return runModelCells.filter(cellData =>
    isBlank(cellData.request_run_model_at) && isPresent(cellData.run_model_at) //&& cellData.value !== cellData.default_value
  );
}

const updateScheduledCells = (timeScale, editedCells, onCellValueChangedWrapper) => {
  const cellsToUpdate = editedCells.filter(cellData => timeScale === cellData.timeScale && cellData.need_recalculation).map(cellData => {
    cellData.need_recalculation = false
    return cellData;
  });
  if(isBlank(cellsToUpdate)) return;

  onCellValueChangedWrapper([], () => {}, cellsToUpdate);
}

const restoreColumnsSettings = ({ gridRef, forecastScenario }) => {
  applyColumnsSettings(gridRef, forecastScenario.tableColumnsSettings);
}

const anyRunModelCells = (runModelCells) => runModelCells.some(cellData => isPresent(cellData.request_run_model_at));

const onSortChanged = (event, forecastScenario, setNeedUpdate, triggerAggWorkerRun) => {
  const prevOrder = getTableOrderFromCookies(forecastScenario);
  const colId = `${forecastScenario.config.cmuColumns[0].name}-${GROUP_COL_ID_SUFFIX}`;
  const newOrder = event.columns.find(column => column.colId === colId)?.sort || 'asc';
  if(prevOrder !== newOrder) {
    saveTableOrderToCookies(newOrder, forecastScenario);
    setNeedUpdate(true);
  } else {
    triggerAggWorkerRun();
  }
};

const afterRenderActions = ({
                              gridRef,
                              forecastScenario,
                              forecast_simulator_scenario,
                              updateScenarioData,
                              onCellValueChangedWrapper,
                              editedCells,
                              additionalFunctions = []
                           }) => {
  setTimeout(() => {
    restoreColumnsSettings({ gridRef, forecastScenario })
    restoreForecastCookies(gridRef.current, forecastScenario, forecast_simulator_scenario, updateScenarioData);
    updateScheduledCells(forecastScenario.timeScale, editedCells, onCellValueChangedWrapper);
    additionalFunctions.forEach(func => func());
    }, 100);
};

export const getAggFuncs = (forecastScenario) => ({
  'totalAggFunc': (params) => totalAggFunc(params, forecastScenario),
  'CAGRHistoricalAggFunc': (params) => CAGRHistoricalAggFunc(params, forecastScenario),
  'CAGRForecastedAggFunc': (params) => CAGRForecastedAggFunc(params, forecastScenario),
  'YTGAggFunc': (params) => YTGAggFunc(params, forecastScenario),
  'YTDAggFunc': (params) => YTDAggFunc(params, forecastScenario),
});


const calcVisiblePeriods = (gridRef, forecastScenario) =>
  forecastScenario.allTimeScalePeriods()

const setRowsDataFromCache = ({ cachedAgGridRows, forecastScenario, editedCells, timeScale, setRowData }) => {
  const rows = cachedAgGridRows[forecastScenario.local_id];
  const allRows = [...rows, ...forecastScenario.addedComparisonRows(rows)];
  setRowData(applyEditedCells(allRows, editedCells, timeScale));
}

const isDataCached = (cachedAgGridRows, forecastScenario, periods) =>
  isPresent(cachedAgGridRows[forecastScenario.local_id]) &&
  periods.every(period => isPresent(cachedAgGridRows[forecastScenario.local_id][0][period.attributes.name]));

export const agGridInit = ({
                             forecast_simulator_scenario, setRunModelActive, gridRef,
                             currentCmuGroups,
                             config, forecastScenario, forecastBenchmarkScenario, timeScale,
                             setLargeScalePanelOpen, updateScenarioData, runModel,
                             setNeedUpdate, cachedAgGridRows, setCachedAgGridRows, updateOpenedGroups, updateTableCells
                           }) => {
  const [gridReady, setGridReady] = useState(false);
  const [editedCells, setEditedCells] = useState(forecastScenario.actualEditedCells || []);
  const [runModelCells, setRunModelCells] = useState(forecastScenario.actualRunModelCells);
  const colDefsOpts = { forecastBenchmarkScenario, forecastScenario, config, timeScale };
  const [colDefs, setColDefs] = useState(preparedColDefs({...colDefsOpts}));
  const [rowData, setRowData] = useState([]);
  const runModelCellsToHighlight = useMemo(() => runModelCells.filter(cellData => isPresent(cellData.request_run_model_at)), [runModelCells]);
  const editedCellsIds = useMemo(() => editedCells.map(cellData => cellData.edited && cellData.id), [editedCells]);
  const runModelRowsIds = useMemo(() => runModelCells.map(cellData => cellData.edited && cellData.nodeId), [runModelCells]);
  const editedCellsRowIds = useMemo(() => editedCells.map(cellData => cellData.edited && cellData.nodeId), [editedCells]);
  const updatedCells = useMemo(() => calcUpdatedCells(runModelCells), [runModelCells]);
  const openedGroups = useMemo(() => forecastScenario.openedGroups, [forecastScenario.openedGroups]);
  const expandedGroups = useMemo(() => openedGroups.filter(g => g.expanded), [openedGroups]);
  const expandedGroupIds = useMemo(() => expandedGroups.map(g => g.id), [expandedGroups]);
  const [allowWorkerRun, setAllowWorkerRun] = useState(false);
  const [changeCurrentCmuGroups, setChangeCurrentCmuGroups] = useState(false);
  const [initialWorkerRun, setInitialWorkerRun] = useState(false);
  const [preparedRows, setPreparedRows] = useState([]);
  const [handledAggNodes, setHandledAggNodes] = useState(forecast_simulator_scenario.handled_agg_nodes);
  const [allowAggWorkerRun, setAllowAggWorkerRun] = useState(false);
  const [horizontalScrollTarget, setHorizontalScrollTarget] = useState(forecast_simulator_scenario.horizontal_scroll_target_column);
  const [changeTimeScale, setChangeTimeScale] = useState(false);

  const triggerWorkerRun = () => {
    setPreparedRows([]);
    setAllowWorkerRun(true);
    setTimeout(() => setAllowWorkerRun(false), 100);
  };

  const triggerAggWorkerRun = () => {
    setAllowAggWorkerRun(true);
    setTimeout(() => setAllowAggWorkerRun(false), 100);
  }

  const updateHandledAggNodes = (nodes) => {
    setHandledAggNodes(nodes);
    updateScenarioData({ handled_agg_nodes: nodes });
  }

  handleRowsWorkers({
    allowWorkerRun,
    gridReady,
    forecast_simulator_scenario,
    forecastScenario,
    cachedAgGridRows,
    setPreparedRows,
    currentCmuGroups
  });

  handleAggregationsWorkers({
    gridRef,
    gridReady,
    forecast_simulator_scenario,
    forecastScenario,
    handledAggNodes,
    updateHandledAggNodes,
    updateScenarioData,
    forecastBenchmarkScenario,
    expandedGroupIds,
    expandedGroups,
    editedCells,
    runModelCells,
    updateOpenedGroups,
    updateTableCells,
    allowAggWorkerRun,
    triggerAggWorkerRun
  })

  const onCellValueChangedWrapper = (list, callback = () => {}, newEditedCells = []) => {
    showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
    setTimeout(() => {
      onCellValueChanged({ forecastScenario, newEditedCells, list, gridRef, editedCells, updateTableCells, timeScale: forecastScenario.timeScale, runModelCells, callback });
    }, 0)
  };

  const afterRenderActionsWrapper = (refreshCells = false) => {
    afterRenderActions({
      gridRef,
      forecastScenario,
      forecast_simulator_scenario,
      updateScenarioData,
      onCellValueChangedWrapper,
      forecastBenchmarkScenario,
      expandedGroupIds,
      expandedGroups,
      editedCells,
      runModelCells,
      updateOpenedGroups,
      updateTableCells,
      additionalFunctions: [() => refreshCells ? refreshAggregations(gridRef.current.api) : () => {}]
    });
  };

  const updateCachedRows = (rowsData) => {
    if (isBlank(cachedAgGridRows[forecast_simulator_scenario.scenario_id])) {
      setCachedAgGridRows({ ...cachedAgGridRows, [forecast_simulator_scenario.scenario_id]: rowsData });
      return;
    }
    setCachedAgGridRows(prevState => {
      const existingRowsMap = new Map(prevState[forecast_simulator_scenario.scenario_id].map(row => [row[ROW_DRIVER_ID_KEY], row]));
      const updatedRows = rowsData.map(newRow => {
        if (existingRowsMap.has(newRow[ROW_DRIVER_ID_KEY])) {
          const existingRow = existingRowsMap.get(newRow[ROW_DRIVER_ID_KEY]);
          return { ...existingRow, ...newRow };
        } else {
          return newRow;
        }
      });
      return { ...prevState, [forecast_simulator_scenario.scenario_id]: updatedRows };
    });
  };

  const prepareRowsOnChange = ({ preparedRows, forecastScenario, editedCells = null }) => {
    const localEditedCells = editedCells || forecastScenario.actualEditedCells || [];
    const allRows = [...preparedRows, ...forecastScenario.addedComparisonRows(preparedRows)];
    return applyEditedCells(allRows, localEditedCells, forecastScenario.timeScale)
  };

  const rowsDataWithEditedCells = (rows) => applyEditedCells(rows, editedCells, forecastScenario.timeScale);

  useEffect(() => {
    if(gridReady && isPresent(currentCmuGroups)) {
      showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.loading_rows);
      setChangeCurrentCmuGroups(true)
      triggerWorkerRun();
    }
  }, [currentCmuGroups]);

  useEffect(() => {
    if(gridReady) {
      updateHandledAggNodes([]);
      setNeedUpdate(true);
    }
  }, [forecastScenario.tableSettings.scopes]);

  useOnWorkerDoneEffect({
    gridRef,
    gridReady,
    changeCurrentCmuGroups,
    setChangeCurrentCmuGroups,
    preparedRows,
    forecastScenario,
    forecastBenchmarkScenario,
    editedCells,
    runModelCells,
    updateOpenedGroups,
    currentCmuGroups,
    updateTableCells,
    cachedAgGridRows,
    rowData,
    initialWorkerRun,
    forecast_simulator_scenario,
    setRunModelActive,
    updateScenarioData,
    runModelRowsIds,
    editedCellsRowIds,
    setRowData,
    setInitialWorkerRun,
    changeTimeScale,
    setChangeTimeScale,
    updateCachedRows,
    rowsDataWithEditedCells,
    prepareRowsOnChange,
    afterRenderActionsWrapper,
    triggerAggWorkerRun
  });

  useEffect(() => {
    if(gridReady && forecast_simulator_scenario.reset_all_to_default) {
      showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.updating_scenario);
      updateCachedRows([]);
      forecastScenario.updateScenarioRows(forecast_simulator_scenario.reset_all_new_rows);
      triggerWorkerRun();
    }
  }, [forecast_simulator_scenario.reset_all_to_default])

  useEffect(() => {
    if(gridReady) {
      const locEditedCells = forecastScenario.actualEditedCells || [];
      const locRunModelCells = forecastScenario.actualRunModelCells;
      setEditedCells(locEditedCells);
      setRunModelCells(locRunModelCells);
      setRunModelActive(anyRunModelCells(locRunModelCells));
    }
  }, [forecastScenario.tableCells])

  useEffect(() => {
    if(gridReady && isPresent(forecastScenario.viewOptions.timeScale)) {
      forecastScenario.setTimeScale(forecastScenario.viewOptions.timeScale);
      const newPeriods = calcVisiblePeriods(gridRef, forecastScenario);
      setColDefs(preparedColDefs({ ...colDefsOpts, timeScale: forecastScenario.timeScale }));
      if(isDataCached(cachedAgGridRows, forecastScenario, newPeriods)) {
        setRowsDataFromCache({ cachedAgGridRows, forecastScenario, editedCells, timeScale, setRowData });
        afterRenderActionsWrapper(true);
        triggerAggWorkerRun();
      } else {
        setInitialWorkerRun(false);
        setChangeTimeScale(true);
        triggerWorkerRun();
      }
    }
  }, [forecastScenario.viewOptions.timeScale, forecastScenario.viewOptions.from, forecastScenario.viewOptions.to])

  useRunModelEffect({
    gridRef,
    gridReady,
    forecast_simulator_scenario,
    forecastScenario,
    updateScenarioData,
    triggerWorkerRun
  });
  useApplyLargeScaleInputEffect({
    gridRef,
    forecast_simulator_scenario,
    editedCells,
    forecastScenario,
    forecastBenchmarkScenario,
    runModelCells,
    updateScenarioData,
    updateTableCells
  });
  useImportValuesEffect({
    gridRef,
    forecast_simulator_scenario,
    editedCells,
    forecastScenario,
    runModelCells,
    updateScenarioData,
    updateTableCells
  });

  const onResetCells = (rowNode, list) => {
    const updateData = {};
    const updatedList = list.map(params => {
      const editedCell = editedCells.find(editedCell => rowCellIdKey(params) === editedCell.id)
      if(editedCell && editedCell.hasOwnProperty('default_value') && editedCell.default_value !== editedCell.value) {
        params.newValue = editedCell.default_value;
        params.edited = editedCell.run_model_is_run;
        updateData[params.colDef.field] = editedCell.default_value
        return params;
      } else {
        params.edited = false;
        return params;
      }
    }).filter(isPresent);
    onCellValueChangedWrapper(updatedList,() => {
      gridRef.current.api.applyTransaction({ update: [{ ...rowNode.data, ...updateData }] });
    });
  }

  const onGridReady = () => {
    if(isDataCached(cachedAgGridRows, forecastScenario, forecastScenario.periods())) {
      showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.loading_rows);
      setRowsDataFromCache({ cachedAgGridRows, forecastScenario, editedCells, timeScale, setRowData });
      setGridReady(true);
      afterRenderActionsWrapper();
      setTimeout(() => triggerAggWorkerRun(), 500);
      return;
    }
    showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.loading_rows);
    try {
      setColDefs(preparedColDefs({ ...colDefsOpts, timeScale: forecastScenario.timeScale }));
      setGridReady(true);
      triggerWorkerRun();
    } catch (error) {
      console.error('An error occurred during preparing rows for table:', error);
    }
  };
  const openLargeScalePanel = (driverId) => setLargeScalePanelOpen(true, driverId);
  const onRunModel = () => {
    setRunModelActive(false);
    updateCachedRows([]);
    showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.updating_scenario);
    const { driversData, cmusList } = prepareDataForRunModel(editedCells, forecastScenario);
    runModel(forecast_simulator_scenario.scenario_id, { drivers: driversData, cmus: cmusList });
  }

  // AG Grid settings functions
  const getRowId = useMemo(() => ((params) => params.data[ROW_DRIVER_ID_KEY]), []);
  const aggFuncs = useMemo(() => getAggFuncs(forecastScenario), [forecastScenario]);
  const defaultColDef = useMemo(() => ({
    enableCellChangeFlash: true,
    menuTabs: ["filterMenuTab", "generalMenuTab"],
  }), []);
  // Define column types
  const columnTypes = useMemo(() => ({
    styledCAGRColumn: {
      cellStyle: (params) => styledCAGRColumn()
    },
    styledPeriodColumn: {
      cellStyle: (params) => styledPeriodColumn(forecastScenario, params, editedCells, editedCellsIds, updatedCells, config, timeScale, runModelCellsToHighlight)
    },
    styledFactsColumn: {
      cellStyle: (params) => styledFactsColumn(forecastScenario, params, editedCellsRowIds, config, timeScale)
    },
    editableColumn: {
      onCellValueChanged: (params) => {
        if(isPresent(params.newValue) && isPresent(params.oldValue)) {
          onCellValueChangedWrapper([params])
        } else {
          params.api.undoCellEditing();
        }
      }
    },
  }), [forecastScenario, runModelCells, editedCellsIds, runModelCellsToHighlight, updatedCells, editedCells, timeScale]);

  return {
    onRunModel,
    rowData,
    pagination: true,
    suppressPaginationPanel: true,
    paginationPageSizeSelector: false,
    rowStyle: { background: '#FBFCFE' },
    columnDefs: colDefs,
    rowGroupPanelShow: 'never',
    rowGroupPanelSuppressSort: true,
    groupSuppressBlankHeader: true,
    groupAllowUnbalanced: true,
    reactiveCustomComponents: true,
    loadingOverlayComponent: CustomLoadingOverlay,
    undoRedoCellEditing: true,
    columnMenu: 'legacy',
    undoRedoCellEditingLimit: 1,
    defaultColDef,
    getRowStyle: (params) => getRowStyle(params, forecastScenario),
    getRowId,
    aggFuncs,
    isGroupOpenByDefault: (params) => isGroupOpenByDefault(params, forecastScenario),
    groupDisplayType: 'custom',
    suppressAggFuncInHeader: true,
    onGridReady,
    columnTypes,
    getContextMenuItems: (params) => {
      if(isBlank(params.value)) return [];

      const periodName = params.column.colDef.field;
      const periodId = params.column.colDef.colId;
      const period = forecastScenario.periods().find(period => period.id === periodId);
      if(forecastScenario.isEditableTimeScale && showContextMenu(params, config, timeScale, forecastScenario, periodName, period)) {
        const driverHeader = extractDriverName(params.node.data.Facts);
        const editable = config.isEditableDriver(timeScale, driverHeader) && isFuturePeriod(forecastScenario, periodId);
        return getContextMenuItems(gridRef, params, editable, colDefs, onCellValueChangedWrapper, onResetCells, forecastScenario.allForecastedEditablePeriods, openLargeScalePanel);
      }
      return [];
    },
    onFirstDataRendered: (event) => {
      hideOverlay(gridRef.current.api);
      setRunModelActive(anyRunModelCells(runModelCells));
      if(horizontalScrollTarget) {
        const scrollToColumn = gridRef.current.api.getColumn(horizontalScrollTarget);
        gridRef.current.api.ensureColumnVisible(scrollToColumn, 'start');
      }
    },
    onFilterChanged: (event) => saveFilters(event, forecastScenario, forecast_simulator_scenario, updateScenarioData, setNeedUpdate, triggerAggWorkerRun, updateHandledAggNodes),
    onSortChanged: (event) => onSortChanged(event, forecastScenario, setNeedUpdate, triggerAggWorkerRun),
    onRowGroupOpened: (event) => {
      if(isPresent(event.event)) {
        saveCollapsedGroups(event, forecastScenario);
        triggerAggWorkerRun();
      }
    },
    onColumnPinned: (event) => saveColumnState(event, forecastScenario),
    getRowClass: (params) => getRowClass(params, forecastScenario),
    getRowHeight: (params) => getRowHeight(params, forecastScenario),
    onBodyScroll: (event) => onBodyScroll(),
    onBodyScrollEnd: (event) =>
      onBodyScrollEnd({
        event,
        gridReady,
        gridRef,
        forecastScenario,
        forecast_simulator_scenario,
        updateScenarioData,
        onCellValueChangedWrapper,
        forecastBenchmarkScenario,
        expandedGroupIds,
        expandedGroups,
        editedCells,
        runModelCells,
        updateOpenedGroups,
        updateTableCells,
        triggerAggWorkerRun,
        setHorizontalScrollTarget
      }
    )
  };
};
