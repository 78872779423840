import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import { successActions } from "../helpers/common";
import { loadTemplateSet, removeTemplateSet, updateTemplateSet } from "../store/template_set/actions";
import { resetTemplateSetData, updateTemplateSetData } from "../store/template_set/common_actions";
import AlertsSection from "../alerts";
import SidebarTreeNavigationTemplateSet from "./navigation";
import HelpPanel from "../decision_set_view/side_panel/HelpPanel";
import SetHeader from "./header";
import TemplatesSection from "./TemplatesSection";
import TemplateSetDetailsPanel from "./side_panel/TemplateSetDetailsPanel";
import SetNameModal, { showModal } from "./modals/SetNameModal";
import { closeModal } from "../store/modals/actions";
import EntryPoint, { updateEntryPointData } from "../EntryPoint";
import { TABS_URLS } from "../helpers/home_helpers";
import { Loader } from "../common/Loader";
import { resetTemplate } from "../store/template/common_actions";
import { forceReloadHomepageTemplates } from "../store/homepage/actions";
import {
  setDataSourcesSidebarOpen,
  setDecisionOrderSidebarOpen,
  setDecisionSidebarOpen,
  setMembersSidebarOpen, setMoreActionsSidebarOpen,
  setSetDecisionsSidebarOpen
} from "../store/sidebar/actions";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import { resetTree } from "../store/tree/common_actions";
import DecisionSetDecisionsPanel from "../decision_set_view/side_panel/DecisionSetDecisionsPanel";
import TemplateSetUsersModal, { shownTemplateSetUsersEntry } from "./modals/TemplateSetUsersModal";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import LinkPanel from "../tree_view/side_panel/link_panel/LinkPanel";
import TemplateSet from "../models/template_set";
import MembersPanel from "./side_panel/MembersPanel";
import CollaboratorsSection from "./side_panel/sections/CollaboratorsSection";
import DataSourcesPanel from "../tree_view/side_panel/DataSourcesPanel";
import DataSourcesSection from "../template_set_view/side_panel/sections/DataSourcesSection";
import MoreActionsPanel from "../tree_view/side_panel/MoreActionsPanel";
import SetMoreActionsSection from "./side_panel/sections/SetMoreActionsSection";
import SequenceSection from "./side_panel/sections/SequenceSection";
import { resetPlaybookNotes } from "../store/playbook_notes/actions";

const Sidebars = (opts) => {
  const { search } = useLocation();
  useEffect(() => {
    const { left_side_bar_open } = queryString.parse(search)
    opts.setSidebarOpen(left_side_bar_open === 'set_decisions');
  }, []);

  return (
    <Fragment>
      <div className="sidebars">
        <TemplateSetDetailsPanel addClass="left-side-panel-position tree-page" />
        <MembersPanel>
          <CollaboratorsSection {...opts}/>
        </MembersPanel>
        <DataSourcesPanel>
          <DataSourcesSection addClass="px-3 pb-3" />
        </DataSourcesPanel>
        <DecisionSetDecisionsPanel addClass="left-side-panel-position tree-page" isTemplateSet={true} />
        <MoreActionsPanel>
          <SetMoreActionsSection isTemplateSet={true} setTitle='template' homeTab={TABS_URLS.templates} {...opts} />
          <SequenceSection {...{ ...opts, isTemplateSet: true }} />
        </MoreActionsPanel>
        <div className="help-side-panels">
          <HelpPanel />
        </div>
        <LinkPanel key="link-side-panel" />
      </div>
    </Fragment>
  )
}


export const Wrapper = (
  {
    children,
    modal,
    template_set,
    closeModal,
    updateTemplateSet,
    resetTemplateSet,
    removeTemplateSet,
    forceReloadHomepageTemplates,
    setSetDecisionsSidebarOpen
  }
) => {
  const { pathname, search } = useLocation();
  const isAddPage = pathname.includes('add_template');

  const setOptions = {
    homeTab: TABS_URLS.templates,
    reloadRecords: forceReloadHomepageTemplates,
    removeSet: removeTemplateSet,
    resetSet: resetTemplateSet,
    set: template_set,
    isAddPage: isAddPage,
    setSidebarOpen: setSetDecisionsSidebarOpen
  }
  const templateSetObj = new TemplateSet(template_set)
  const [membersInvitesLength, setMembersInvitesLength] = useState(0);

  useEffect(() => {
    setMembersInvitesLength(templateSetObj.collaborator_admissions.length)
  }, [template_set.loaded, template_set?.admissions]);

  return <Fragment>
    <AlertsSection />
    <SetHeader setTitle="template"
               isTemplateSet={true}
               {...setOptions} />
    <div className="d-flex">
      {!isAddPage && <SidebarTreeNavigationTemplateSet addDecision={true} isTemplateSet={true} {...{membersInvitesLength}} />}
      {children}
    </div>
    {!isAddPage && <Sidebars {...setOptions} />}
    <div className="modals">
      <SetNameModal shown={showModal(modal, template_set)} set={template_set} set_key='template_set' setTitle='template'
                    onClose={closeModal} updateSet={updateTemplateSet} />
      <TemplateSetUsersModal shown={shownTemplateSetUsersEntry(modal)} onClose={closeModal} />
    </div>
  </Fragment>
}

export const checkLoadingTemplateSet = ({
                                          template_set, template, updateTemplateSetData, loadTemplateSet,
                                          resetPlaybookNotes,
                                          resetTemplate, resetTree, closeDecisionOrderSideBar, openDecisionSidebar,
                                          openMembersSidebar, openDataSourcesSidebar, openMoreActionsSidebar
                                        }) => {
  const params = useParams()
  EntryPoint.instance.saveSearchParams()
  useEffect(() => {
    updateEntryPointData(params, 'template_sets', () => {
      if (template_set.loaded && template_set.slug !== params?.id) {
        updateTemplateSetData({ loaded: false })
      }
    })
  }, [params?.id, template_set.loaded])
  useEffect(() => {
    if (template.loaded) {
      successActions(true, [resetTemplate, resetTree, resetPlaybookNotes, closeDecisionOrderSideBar])
    }
  }, [])
  checkLoadingEffect(template_set, () => {
    const getParams = new URLSearchParams(document.location.search)
    switch (getParams.get('side_bar_open')) {
      case 'details':
        openDecisionSidebar()
        break;
      case 'members':
        openMembersSidebar()
        break;
      case 'data_sources':
        openDataSourcesSidebar()
        break;
      case 'more_actions':
        openMoreActionsSidebar();
        break;
    }

    loadTemplateSet()
  })
}

const TemplateSetView = ({ template_set, ...opts }) => {
  checkLoadingTemplateSet({ ...opts, template_set })

  return <Wrapper {...opts} {...{template_set}} >
    {
      isStoreLoading(template_set) ?
        <Loader /> :
        <div className="w-100 wrapper-container width-fit-content">
          <div className="home container mt-3">
            <TemplatesSection />
          </div>
        </div>
    }
  </Wrapper>
}
const mapStateToProps = ({ home, template_set, modal, template }) =>
  ({ home, template_set, modal, template });
const mapDispatchToProps = (dispatch) => ({
  closeDecisionOrderSideBar: () => dispatch(setDecisionOrderSidebarOpen(false)),
  loadTemplateSet: () => dispatch(loadTemplateSet()),
  updateTemplateSet: (value, callback) => { dispatch(updateTemplateSet(value, callback)) },
  updateTemplateSetData: (data) => { dispatch(updateTemplateSetData(data)) },
  resetTemplate: () => dispatch(resetTemplate()),
  resetTree: () => dispatch(resetTree()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  resetTemplateSet: () => dispatch(resetTemplateSetData()),
  removeTemplateSet: (callback) => dispatch(removeTemplateSet(callback)),
  forceReloadHomepageTemplates: () => dispatch(forceReloadHomepageTemplates()),
  setSetDecisionsSidebarOpen: (value) => { dispatch(setSetDecisionsSidebarOpen(value)) },
  openMembersSidebar: () => { dispatch(setMembersSidebarOpen(true)) },
  openDecisionSidebar: () => { dispatch(setDecisionSidebarOpen(true)) },
  openDataSourcesSidebar: () => { dispatch(setDataSourcesSidebarOpen(true)) },
  openMoreActionsSidebar: () => { dispatch(setMoreActionsSidebarOpen(true)) },
  closeModal: () => { dispatch(closeModal()) },
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateSetView);
