import React from "react";
import Toggle from "../../common/Toggle";

export const CHART_TABS = {
  line: 'line',
  column: 'column'
}

export const LAUNCH_INPUT_TABS = {
  national: 'national',
  accounts: 'accounts'
}

export const CHART_TYPES = [
  {
    value: CHART_TABS.line,
    body: 'Line'
  },
  {
    value: CHART_TABS.column,
    body: 'Column'
  },
];

export const LAUNCH_INPUT_TYPES = [
  {
    value: LAUNCH_INPUT_TABS.national,
    body: 'National'
  },
  {
    value: LAUNCH_INPUT_TABS.accounts,
    body: 'Accounts'
  },
];

export default ({ items, viewTab, setViewTab, addClass }) => <Toggle selectedItem={viewTab}
                                                                     onClick={setViewTab}
                                                                     padding={'px-2'}
                                                                     {...{items, addClass}}/>
