import React, {Fragment, useState} from 'react';
import { connect } from "react-redux";
import { setRecommendationInputSidebarOpen } from "../../../store/sidebar/actions";
import RecommendationSections from "../../side_panel/sections/RecommendationSections";
import FinalResultsSection from "../../side_panel/sections/FinalResultsSection";
import Recommendation from "../../../models/recommendation";
import { isBlank } from "../../../helpers/common";
import { DSightAnalysis } from "../../modals/DriverShowModal";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import ApprovalSection from "../../side_panel/sections/ApprovalSection";
import SectionWrapper from "../../side_panel/sections/SectionWrapper";

export const RecommendedDecisionCard = ({ decision, editSideBar }) => {
  if(isBlank(decision)) return null;

  const recommendation = decision.recommendation;
  const recommendationObject = new Recommendation(recommendation);

  if (isBlank(recommendationObject) || !recommendationObject.isEnteredRecommendation) return null;

  return <Fragment>
    <div>
      <h1 className="vertical-tree-elements">{decision.description}</h1>
      <FinalResultsSection />
      <ApprovalSection />
      <RecommendationSections editSideBar={editSideBar} />
      <div hidden={!isDSightDecision(decision)}>
        <SectionWrapper>
          <div className="px-3">
            <DSightAnalysis headerClass="h3"
                            showDecisionDescription={true}
                            decision={decision}
                            keyPreffix={'recommendation-details-panel'} />
          </div>
        </SectionWrapper>
      </div>
    </div>
  </Fragment>
}
const mapStateToProps = ({ decision }) => ({ decision });
const mapDispatchToProps = (dispatch) => ({
  editSideBar: (options) => {
    dispatch(setRecommendationInputSidebarOpen(options));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RecommendedDecisionCard);
