import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { Navigate } from 'react-router-dom'
import { resetWizard } from "../../store/wizard/actions";
import {
  isCompletedWizard,
  isTreeBuilder,
  isTreeDecisionCategorization,
  isTreeFraming
} from "../../helpers/wizard_helpers";
import FramingDecisionStep from "./steps/FramingDecisionStep";
import TreeBuilderStep from "./steps/TreeBuilderStep";
import TreePreviewStep from "./steps/TreePreviewStep";
import TreeDecisionCategorizationStep from "./steps/TreeDecisionCategorizationStep";
import EntryPoint from "../../EntryPoint";
import { isBlank, successActions } from "../../helpers/common";
import { resetTemplate, updateTemplateData } from "../../store/template/common_actions";
import { reloadTemplatesData } from "../../store/homepage/actions";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import { updateTreeData } from "../../store/tree/common_actions";
import { isTemplateSetTemplate } from "../../helpers/template_helpers";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";

export const StepsWizard = ({
                              wizard, template, stepRef, resetTemplateSet, resetTemplate, resetPlaybookNotes,
                              resetWizard, reloadTemplatesData, updateTemplateData, updateTreeData
                            }) => {
  const WizardStep = useCallback(() => {
    if (isCompletedWizard(wizard) || (wizard.slug === template.slug && isCompletedWizard(template))) {
      const { objectSlug, controllerName } = EntryPoint.instance
      successActions(true, [
        resetWizard, reloadTemplatesData,
        () => updateTreeData({ loaded: false }),
        () => updateTemplateData({ loaded: false })
      ])
      if(isTemplateSetTemplate(template)) {
        successActions(true, [resetTemplateSet, resetTemplate, resetPlaybookNotes], true);
        return <Navigate to={`/template_sets/${template.template_set_slug}`} replace/>
      } else {
        return <Navigate to={`/${controllerName}/${objectSlug}`} replace/>
      }
    }
    if (wizard.tree_preview) return <TreePreviewStep ref={stepRef} />
    if (isTreeDecisionCategorization(wizard)) return <TreeDecisionCategorizationStep ref={stepRef} />
    if (isTreeFraming(wizard)) return <FramingDecisionStep ref={stepRef} />
    if (isTreeBuilder(wizard)) return <TreeBuilderStep ref={stepRef} />

    return null;
  }, [wizard.tree_preview, wizard.completed_at, wizard.step_index])

  return <div className="container-fluid p-0">
    <span hidden={isBlank(wizard.error)}>Error: {wizard.error}</span>

    <WizardStep />
  </div>
}
const mapStateToProps = ({ wizard, template }) => ({ wizard, template });
const mapDispatchToProps = (dispatch) => ({
  resetWizard: () => dispatch(resetWizard()),
  updateTemplateData: (data) => dispatch(updateTemplateData(data)),
  reloadTemplatesData: () =>  dispatch(reloadTemplatesData()),
  updateTreeData: (data) => dispatch(updateTreeData(data)),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  resetTemplateSet: () => dispatch(resetTemplateSetData()),
  resetTemplate: () => dispatch(resetTemplate())
});
const wrapper = React.forwardRef((props, ref) => <StepsWizard {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
