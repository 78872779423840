import {
  loadForecastSimulatorData,
  loadForecastSimulatorScenarioData,
  removeForecastSimulatorScenarioData,
  resetForecastSimulatorScenarioData,
  runModelData,
  updateForecastSimulatorScenarioData, updateForecastSimulatorScenarioRowsData,
  loadForecastSimulatorScenarioTableCells,
  loadForecastSimulatorScenarioOpenedGroups,
  updateForecastSimulatorScenarioOpenedGroups,
  updateForecastSimulatorScenarioTableCells
} from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { LOAD_SCENARIO_FAILED, UPDATE_SCENARIO_DATA, RESET_SCENARIO_DATA } from "./types";
import { isBlank, isPresent } from "../../helpers/common";
import { deepEqual } from "../../helpers/store_helpers";
import { reloadOrgForecastScenariosData } from "../current_org/actions";
import { isForecastScenarioReadyByState } from "../../models/forecast/state_hyelpers";

const MAX_LOADING_TIMES = 15;

const checkScenario = (dispatch, getState, callback) => {
  setTimeout(() => {
    callback()(dispatch, getState)
  }, 1000)
}

const getFilteredRows = (getState, cmus) => getState().forecast_simulator_scenario.scenario.rows.filter(row =>
  !cmus.some(cmusGroup => cmusGroup.every(cmu => row.attributes.cmus.includes(cmu))))

export const loadForecastSimulator = (scenario_id) => (dispatch, getState) => {
  if (getState().forecast_simulator_scenario.loaded && getState().forecast_simulator_scenario.scenario_id === scenario_id) return;

  dispatch(updateScenarioData({ loading: true }))
  loadForecastSimulatorData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure });

    const { data } = response;
    const benchmark_id = getState().forecast_simulator_scenario.benchmark_id === scenario_id ? null : getState().forecast_simulator_scenario.benchmark_id
    const { config, config_scenarios } = data;
    dispatch(updateScenarioData({ config, config_scenarios, benchmark_id, scenario_id, loaded: true, loading: false }));
    loadForecastSimulatorScenario(scenario_id)(dispatch, getState)
  })
}

const iterateLoadingRows = (dispatch, getState, scenario_id, {
  cmus, index = 0, rows = [], key, callbackFailure, callback = () => {}
}) => {
  const updateKeys = {}
  updateKeys[`${key}_hint`] = `Loading scenario...`
  dispatch(updateScenarioData(updateKeys));

  // Make all requests in parallel
  Promise.all(cmus.map(cmu => updateForecastSimulatorScenarioRowsData(scenario_id, { cmu })))
    .then(responses => {
      responses.forEach(response => {
        if (isResponseFailed(response)) {
          return failedResponseHandler(dispatch, { ...response, callback: callbackFailure });
        }
        const { data } = response;
        rows = [...rows, ...data.rows];
      });

      updateKeys[`${key}_hint`] = false
      updateKeys[`${key}_loaded`] = true
      updateKeys[`${key}_loading`] = false
      updateKeys[key] = {
        ...getState().forecast_simulator_scenario[key],
        rows
      }
      callback(true, rows)
      return dispatch(updateScenarioData(updateKeys));
    });
}

export const loadForecastSimulatorScenario = (scenario_id, checkLoading = true) => (dispatch, getState) => {
  if (checkLoading) {
    if (getState().forecast_simulator_scenario.scenario_loaded && getState().forecast_simulator_scenario.scenario_id === scenario_id) return;
    if (getState().forecast_simulator_scenario.scenario_loading) return;
  }

  if (getState().forecast_simulator_scenario.scenario_loading) {
    dispatch(updateScenarioData({ scenario_loading_times: getState().forecast_simulator_scenario.scenario_loading_times + 1 }))
  } else {
    dispatch(updateScenarioData({ scenario_loading: true, scenario_loading_times: 0 }))
  }
  loadForecastSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadSimFailure });

    const benchmark_id = getState().forecast_simulator_scenario.benchmark_id === scenario_id ? null : getState().forecast_simulator_scenario.benchmark_id
    const { data } = response;
    const { scenario } = data;
    if (!isForecastScenarioReadyByState(scenario.data.attributes.calculating_state)) {
      if (getState().forecast_simulator_scenario.scenario_loading_times > MAX_LOADING_TIMES) {
        return dispatch(updateScenarioData({ scenario_loading: false, scenario_error: 'Recalculating... Try reload page later.' }))
      }
      checkScenario(dispatch, getState, () => loadForecastSimulatorScenario(scenario_id, false))
      return;
    }
    const cmus = data.cmus || scenario.cmus || []

    if (cmus.length > 0) {
      dispatch(updateScenarioData({ cmus, scenario, scenario_id, benchmark_id }));
      iterateLoadingRows(dispatch, getState, scenario_id, { cmus, key: 'scenario', callbackFailure: loadSimFailure })
    } else {
      dispatch(updateScenarioData({ scenario, scenario_id, benchmark_id, scenario_loading: false, scenario_loaded: true }));
    }
  })
}

export const loadForecastSimulatorDBenchmarkScenario = (benchmark_id, checkLoading = true) => (dispatch, getState) => {
  if (isBlank(benchmark_id)) return null;
  if (checkLoading) {
    if (getState().forecast_simulator_scenario.loading || getState().forecast_simulator_scenario.benchmark_loading) return null;
  }

  if (getState().forecast_simulator_scenario.benchmark_loading) {
    dispatch(updateScenarioData({ benchmark_loading_times: getState().forecast_simulator_scenario.benchmark_loading_times + 1 }))
  } else {
    dispatch(updateScenarioData({ benchmark_loading: true, benchmark_loading_times: 0 }))
  }

  loadForecastSimulatorScenarioData(benchmark_id).then(response => {
    if (isResponseFailed(response)) {
      if (response.status === 403 && isPresent(getState().forecast_simulator_scenario.scenario?.view_options?.benchmark)) {
        dispatch(updateViewOptions({
          ...getState().forecast_simulator_scenario.scenario?.view_options,
          benchmark: null
        }, () => {
          dispatch(updateScenarioData({
            benchmark: {}, benchmark_id: null, benchmark_loading: false, benchmark_loaded: false, benchmark_loading_times: 0
          }));
        }, false))
        return;
      }

      return failedResponseHandler(dispatch, { ...response, callback: loadBenchFailure });
    }

    const { data } = response;
    const { scenario } = data;
    if (!isForecastScenarioReadyByState(scenario.data.attributes.calculating_state)) {
      if (getState().forecast_simulator_scenario.benchmark_loading_times > MAX_LOADING_TIMES) {
        return dispatch(updateScenarioData({ benchmark_loading: false, benchmark_error: 'Recalculating... Try reload page later.' }))
      }
      checkScenario(dispatch, getState, () => loadForecastSimulatorDBenchmarkScenario(benchmark_id, false))
      return;
    }
    const cmus = data.cmus || scenario.cmus || []
    if (cmus) {
      dispatch(updateScenarioData({ benchmark: scenario, benchmark_id }));
      iterateLoadingRows(dispatch, getState, benchmark_id, { cmus, key: 'benchmark', callbackFailure: loadBenchFailure })
    } else {
      dispatch(updateScenarioData({ benchmark: scenario, benchmark_id, benchmark_loading: false, benchmark_loaded: true }));
    }
  })
}

export const updateViewOptions = (newViewOptions, callback = () => {}, updateLoadingState = true) => (dispatch, getState) => {
  if (updateLoadingState) {
    if (getState().forecast_simulator_scenario.view_loading) return;
    dispatch(updateScenarioData({ view_loading: true }))
  }

  updateForecastSimulatorScenarioData(getState().forecast_simulator_scenario.scenario_id, { scenario: { view_options: newViewOptions }, tiny: true }).then(response => {
    if (isResponseFailed(response)) {
      return failedResponseHandler(dispatch, {
        ...response,
        callback: loadFailure
      }, callback);
    }

    const { data } = response;
    const { scenario } = data;
    if (updateLoadingState) {
      dispatch(
        updateScenarioData({
          scenario: {
            ...getState().forecast_simulator_scenario.scenario,
            ...scenario
          },
          view_loading: false
        })
      );
    } else {
      dispatch(
        updateScenarioData({
          scenario: {
            ...getState().forecast_simulator_scenario.scenario,
            ...scenario
          }
        })
      )
    }

    callback(true)
  })
}
export const updateScenario = (scenario_id, updateData, callback, showLoading = true) => (dispatch, getState) => {
  if(showLoading) dispatch(updateScenarioData({ loading: true }))
  updateForecastSimulatorScenarioData(scenario_id, updateData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);
    const { data } = response;
    const { scenario } = data;
    if (!isForecastScenarioReadyByState(scenario.data.attributes.calculating_state)) {
      checkScenario(dispatch, getState, () => loadForecastSimulatorScenario(scenario_id, false))
      return;
    }
    dispatch(updateScenarioData({
      scenario: {
        ...getState().forecast_simulator_scenario.scenario,
        ...scenario
      },
      loading: false
    }));
    dispatch(reloadOrgForecastScenariosData())
    callback(true)
  })
}

export const loadForecastSimulatorScenarioPostRunModel = (scenario_id, cmus) => (dispatch, getState) => {
  loadForecastSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadSimFailure });

    const { data } = response;
    const { scenario } = data;
    if (!isForecastScenarioReadyByState(scenario.data.attributes.calculating_state)) {
      checkScenario(dispatch, getState, () => loadForecastSimulatorScenarioPostRunModel(scenario_id, cmus))
      return;
    }
    const filteredRows = getFilteredRows(getState, cmus);
    iterateLoadingRows(dispatch, getState, scenario_id, {
      cmus,
      rows: filteredRows,
      key: 'scenario',
      callbackFailure: loadSimFailure,
      callback: (status, updatedRows) => {
        if(status) {
          dispatch(updateScenarioData({
            run_model: true,
            run_model_new_rows: updatedRows,
            run_model_new_rows_cmus: cmus
          }))
        }
      }
    })
  })
}

export const runModel = (scenario_id, { drivers, cmus }, callback) => (dispatch, getState) => {
  runModelData(scenario_id, { drivers }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    dispatch(updateScenarioData(
      {
        scenario: {
          ...getState().forecast_simulator_scenario.scenario,
          run_model_happened: true
        },
      }
    ))
    loadForecastSimulatorScenarioPostRunModel(scenario_id, cmus)(dispatch, getState)
    callback(true)
  })
}

export const importValues = (scenario_id, cmus, callback) => (dispatch, getState) => {
  iterateLoadingRows(dispatch, getState, scenario_id, {
    cmus,
    key: 'imported_scenario',
    callbackFailure: loadSimFailure,
    callback: (status, importedRows) => {
      if(status) {
        dispatch(updateScenarioData({
          import_values: true,
          imported_scenario_id: scenario_id,
          import_values_new_rows: importedRows
        }))
      }
    }
  })
}

export const resetScenarioValues = (scenario_id, callback, showLoading = true) => (dispatch, getState) => {
  if(showLoading) dispatch(updateScenarioData({ scenario_loading: true }))

  resetForecastSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    const { data } = response;
    const { default_values, scenario } = data;

    if(default_values) {
      if(showLoading) dispatch(updateScenarioData({ scenario_loading: false }))
      dispatch(updateScenarioData({
        scenario: {
          ...getState().forecast_simulator_scenario.scenario,
          ...scenario,
          table_cells: []
        }
      }));
      callback(true, true)
    } else {
      const rowsToDelete = getState().forecast_simulator_scenario.scenario.opened_groups.flatMap(group => group.added_rows);
      dispatch(updateScenarioData({
        scenario: {
          ...getState().forecast_simulator_scenario.scenario,
          ...scenario,
          table_cells: [],
          opened_groups: []
        }
      }));
      const cmus = getState().forecast_simulator_scenario.cmus;
      const filteredRows = getFilteredRows(getState, cmus);
      iterateLoadingRows(dispatch, getState, scenario_id, {
        cmus,
        rows: filteredRows,
        key: 'scenario',
        callbackFailure: loadSimFailure,
        callback: (status, updatedRows) => {
          callback(status, false)
          if(status) {
            dispatch(updateScenarioData({
              reset_all_to_default: true,
              reset_all_new_rows: updatedRows,
              reset_all_delete_rows: rowsToDelete,
              reset_all_new_rows_cmus: cmus
            }))
          }
        }
      })
    }
  })
}

export const removeScenario = (scenario_id, callback) => (dispatch) => {
  dispatch(updateScenarioData({ loading: true }))
  removeForecastSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    dispatch(reloadOrgForecastScenariosData())
    callback(true)
  })
}

// Load/Update Table Cells/Opened Groups
export const loadTableCells = (scenario_id) => (dispatch, getState) => {
  loadForecastSimulatorScenarioTableCells(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, {...response, callback: loadSimFailure});

    const { data } = response;
    const { table_cells } = data;
    dispatch(updateScenarioData({
      scenario: {
        ...getState().forecast_simulator_scenario.scenario,
        table_cells,
      },
      table_cells_loaded: true
    }));
  });
};

export const loadOpenedGroups = (scenario_id) => (dispatch, getState) => {
  loadForecastSimulatorScenarioOpenedGroups(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, {...response, callback: loadSimFailure});

    const { data } = response;
    const { opened_groups } = data;
    dispatch(updateScenarioData({
      scenario: {
        ...getState().forecast_simulator_scenario.scenario,
        opened_groups,
      },
      opened_groups_loaded: true
    }));
  });
};

const handleUpdateTableDataResponse = (dispatch, getState, response, data, key, callback) => {
  if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadSimFailure }, callback);

  const scenario = { ...getState().forecast_simulator_scenario.scenario };
  const user_email = response.data.user_email;
  if(user_email) scenario.data.attributes.user_email = user_email;
  if (data.delete) {
    const item = data[key.slice(0, -1)];
    const filteredItems = scenario[key].filter(i => i.id !== item.id);
    dispatch(updateScenarioData({
      scenario: {
        ...scenario,
        [key]: filteredItems
      }
    }));
    callback(true);
    return;
  }
  if (data.reset) {
    dispatch(updateScenarioData({
      scenario: {
        ...scenario,
        [key]: []
      }
    }));
    callback(true);
    return;
  }
  if (data.bulk) {
    const items = data[key];
    dispatch(updateScenarioData({
      scenario: {
        ...scenario,
        [key]: items
      }
    }));
    callback(true);
  } else {
    const item = data[key.slice(0, -1)];
    const filteredItems = [...scenario[key].filter(i => i.id !== item.id)];
    const newItems = [...filteredItems, item];
    dispatch(updateScenarioData({
      scenario: {
        ...scenario,
        [key]: newItems
      }
    }));
  }
  callback(true);
};

export const updateOpenedGroups = (scenario_id, data, callback) => (dispatch, getState) => {
  updateForecastSimulatorScenarioOpenedGroups(scenario_id, data).then(response => {
    handleUpdateTableDataResponse(dispatch, getState, response, data, 'opened_groups', callback);
  });
};

export const updateTableCells = (scenario_id, data, callback) => (dispatch, getState) => {
  const prevTableCells = getState().forecast_simulator_scenario.scenario.table_cells;
  const newTableCells = data.table_cells;
  const cellsToUpdate = newTableCells.filter(newCell => {
    const prevCell = prevTableCells.find(c => c.id === newCell.id);
    if(!prevCell) return true;

    return !deepEqual(newCell, prevCell);
  });
  updateForecastSimulatorScenarioTableCells(scenario_id, { ...data, table_cells: cellsToUpdate }).then(response => {
    handleUpdateTableDataResponse(dispatch, getState, response, data, 'table_cells', callback);
  });
};


export const loadFailure = error => ({
  type: LOAD_SCENARIO_FAILED,
  payload: {
    error,
    loading: false,
    view_loading: false
  }
});

export const loadSimFailure = error => ({
  type: LOAD_SCENARIO_FAILED,
  payload: {
    scenario_error: error, scenario_loading: false
  }
});

export const loadBenchFailure = error => ({
  type: LOAD_SCENARIO_FAILED,
  payload: {
    benchmark_error: error,
    benchmark_loading: false
  }
});

export const updateScenarioData = (data) => ({
  type: UPDATE_SCENARIO_DATA,
  payload: {
    ...data
  }
});

export const resetScenarioData = () => ({ type: RESET_SCENARIO_DATA });
