import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import {ModalDoneFooterP0} from "../../common/modals";
import {isBlank, isPresent} from "../../helpers/common";
import Button from "react-bootstrap/Button";
import {EditIcon} from "../../common/EditIcon";
import QuestionRow from "../../tree_editor/modals/helpers/QuestionRow";
import {isEmpty} from "ramda";
import {chatGptApiRequest} from "../../store/decision/actions";
import {createWizardDriversSuggestions} from "../../store/wizard/actions";
import {createTreeDriversSuggestions} from "../../store/tree/actions";
import {getParentsDrivers} from "../../helpers/drivers_helpers";
import DotsLoader from "../side_panel/helpers/DotsLoader";
import {isEnter, isEsc} from "../../helpers/keys_helpers";
import { DESCRIPTION_INPUT_LIMIT } from "../../models/decision";
import {AI_ERROR_MESSAGE} from "../../helpers/ai_helpers";
import {performGptDriversAddedAction, treeChannelIsConnected} from "../../helpers/channel_helpers";

const gptApiRequest = (chatGptApiRequest, selectedDriverSlug, selectedDriverQuestion, setDriverSuggestions, setLoaded, setError, wizard) => {
  const callback = (success) => {
    if (success) {
      setLoaded(true)
    } else {
      setLoaded(true)
      setError(AI_ERROR_MESSAGE)
    }
  }
  chatGptApiRequest(
    {
      selected_driver_slug: selectedDriverSlug,
      is_wizard: wizard.loaded,
      selected_driver_question: selectedDriverQuestion
    },
    callback,
    setDriverSuggestions
  )
}

const DisplaySuggestionItem = ({ suggestion, index, selectedDriverSuggestions, setSelectedDriverSuggestions }) => {
  if(isBlank(suggestion) || isBlank(index)) return null

  const [selected, setSelected] = useState(false);
  const [edit, setEdit] = useState(false);
  const [question, setQuestion] = useState(suggestion);

  const onSelectSuggestion = () => {
    setSelected(!selected)
  }

  const onChangeQuestion = () => {
    setSelected(false)
    setEdit(!edit)
  }

  const onCancelChangeQuestion = () => {
    setQuestion(suggestion)
    onChangeQuestion()
  }

  const onKeyDown = (e) => {
    if (isEnter(e.keyCode)) {
      e.preventDefault()
      onChangeQuestion()
    }
    if(isEsc(e.keyCode)) {
      e.preventDefault();
      onCancelChangeQuestion()
    }
  }

  useEffect(() => {
    if (selected) {
      setSelectedDriverSuggestions([...selectedDriverSuggestions, {[index]: question}])
    } else {
      const filteredSuggestions = selectedDriverSuggestions.filter(obj => !obj.hasOwnProperty(index))
      setSelectedDriverSuggestions([...filteredSuggestions])
    }
  }, [selected])

  useEffect(() => {
    if (isBlank(question.trim())) {
      onCancelChangeQuestion()
    }
  }, [question])

  return <div className="d-flex align-items-center mb-2">
    {
      edit ?
        <QuestionRow question={question} showTitle={false} maxLength={DESCRIPTION_INPUT_LIMIT}
                     onKeyDown={onKeyDown}
                     max100={true}
                     setQuestionValue={setQuestion}
                     onChangeQuestion={onChangeQuestion} />:
        <div className={`btn text-start w-100 h-auto ${selected ? 'btn-primary' : 'btn-secondary'}`} onClick={onSelectSuggestion}>
          <div className={`choice-description`}>
            {question}
          </div>
        </div>
    }
    <EditIcon onClick={() => { setEdit(true) }} classNames='ms-2 mt-1 mb-auto' />
  </div>
}

const GptSuggestionsModal = ({
                              shown, onClose, decision, template, drivers, chatGptApiRequest,
                              createWizardDriversSuggestions, setDrivers, tree, wizard,
                              createTreeDriversSuggestions, current_org, current_user
                            }) => {
  if(!current_org.allow_gpt) return null;

  const [selectedNode, setSelectedNode] = useState({});
  const selectedDriverQuestion = selectedNode.question;
  const selectedDriverSlug = selectedNode.slug;
  const [driverSuggestions, setDriverSuggestions] = useState([]);
  const [selectedDriverSuggestions, setSelectedDriverSuggestions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState('');
  const CurrentDriversList = () => {
    const rootDrivers = isBlank(selectedDriverSlug) ? getParentsDrivers(drivers).map(d => d.driver.question) : ''
    const firstLevelChildren = selectedNode.children?.map(child => child.question)
    const topLevelDrivers = rootDrivers || firstLevelChildren

    return isPresent(topLevelDrivers) && <div>
      <h3 className='fw-bold'>Current drivers:</h3>
      <ul>
        { topLevelDrivers.map((driverQuestion, i) => <li key={i}>{driverQuestion}</li>) }
      </ul>
    </div>
  }

  const gptSuggestionsRequest = () => gptApiRequest(chatGptApiRequest, selectedDriverSlug, selectedDriverQuestion, setDriverSuggestions, setLoaded, setError, wizard)

  const onHandleClose = () => {
    onClose()
    setError('')
    setLoaded(false)
    setDriverSuggestions([])
    setSelectedDriverSuggestions([])
  }

  const onHandleRequestSuggestions = () => {
    setLoaded(false)
    setError('')
    gptSuggestionsRequest()
  }

  const createDrivers = () => {
    const callback = (success, drivers = []) => {
      if (success) {
        isPresent(drivers) && setDrivers(drivers)
        onHandleClose()
        if(treeChannelIsConnected()) performGptDriversAddedAction(current_user)
      }
    }
    if (wizard.loaded) {
      createWizardDriversSuggestions({
        suggestions: selectedDriverSuggestions,
        selected_driver_slug: selectedDriverSlug,
        is_wizard: true
      }, callback)
    } else {
      createTreeDriversSuggestions({
        suggestions: selectedDriverSuggestions,
        selected_driver_slug: selectedDriverSlug,
        is_tree: true
      }, callback)
    }
  }

  useEffect(() => {
    shown && gptSuggestionsRequest()
  }, [shown])

  useEffect(() => {
    const node_slug = tree.selected_ztree_node.slug
    if (node_slug === decision?.slug || node_slug === template?.slug) return setSelectedNode({});
    if (isPresent(node_slug)) {
      return setSelectedNode(tree.selected_ztree_node)
    }
  }, [tree.selected_ztree_node?.slug])

  return <Modal size="lg" backdrop="static" show={shown} onHide={onHandleClose}>
    <Modal.Body>
      <>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2 className="lh-sm m-0">CloverpopAI driver suggestions</h2>
          <CloseIcon onClose={onHandleClose} classParams="ms-2"/>
        </div>
        <div>
          <CurrentDriversList />
          {!isEmpty(driverSuggestions) && <p className="text-muted">Click to add a driver suggestion to the decision tree.</p>}
          {!isEmpty(driverSuggestions) && driverSuggestions.map((suggestion, index) =>
            <DisplaySuggestionItem index={index} suggestion={suggestion}
                                   key={`gpt-suggestion-${index}`}
                                   selectedDriverSuggestions={selectedDriverSuggestions}
                                   setSelectedDriverSuggestions={setSelectedDriverSuggestions} />
          )}
          {!loaded && <DotsLoader /> }
          {error && <p className='text-muted mb-2'>Error: {error}</p> }
        </div>
      </>
    </Modal.Body>
    <div className='px-3 my-2'>
      <Button className={`btn-secondary w-100 ${loaded ? '' : 'disabled'}`} onClick={onHandleRequestSuggestions}>
        Get more suggestions
      </Button>
    </div>
    <ModalDoneFooterP0 disabled={isEmpty(selectedDriverSuggestions)} onClose={createDrivers} />
  </Modal>
}

const mapStateToProps = ({ decision, template, tree, wizard, current_org, current_user }) => ({ decision, template, tree, wizard, current_org, current_user });
export default connect(mapStateToProps, { chatGptApiRequest, createWizardDriversSuggestions, createTreeDriversSuggestions })(GptSuggestionsModal);
