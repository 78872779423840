import React, {Fragment, useMemo} from 'react';
import {connect} from "react-redux";
import {isDecider, isDeciderReadOnly, isDecisionMember, isPublicVisitor} from "../../../helpers/user_helpers";
import Button from 'react-bootstrap/Button';
import ShareDropdown, {
  hideCreatePoll,
  hideDecisionDuplicate,
  hideDecisionPDFAndDoc,
  hideShowAsCompleted,
  hideTemplateDuplicate
} from "../../header/ShareDropdown";
import {deleteAndStartActions, mapStartDeleteDispatchToProps} from "../../header/helpers";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import DownloadPDF from "../../header/DownloadPDF";
import DownloadDOCX from "../../header/DownloadDOCX";
import DownloadCSV from "../../header/DownloadCSV";
import RenderDecisionInputSidebar from "../../header/buttons/RenderDecisionInputSidebar";
import Decision from "../../../models/decision";
import SettingsSection from "./SettingsSection";
import CompleteDriversButton from "../../../common/CompleteDriversButton";

export const MoreActionsSection = ({ user, decision, current_org, isDetailsPanel, modal, decision_set, tree, ...opts }) => {
  const isDeciderUser = useMemo(() => isDecider(user), [user])
  const isDeciderReadOnlyUser = useMemo(() => isDeciderReadOnly(user), [user]);
  if (!isDeciderUser && (!isDecisionMember(decision, user) || isDSightDecision(decision))) return null;
  if (isPublicVisitor(user)) return null;
  if (current_org.slug !== user.org_slug) return null;
  const decisionObj = new Decision(decision)

  const {
    deleteDecisionHandler, startDecision, startTemplate, onEditDecision, editDecisionTree, assignDriversTree, createDecisionPoll, onShowAsCompleted, completeDriversWithAi
  } = deleteAndStartActions({
    decision, decision_set, current_user: user, tree,
    ...opts
  })

  return <Fragment>
    <div className={'px-3 pb-3'}>
      <CompleteDriversButton {...{ decision, user, tree, completeDriversWithAi }} />
      <Button onClick={createDecisionPoll} className="btn btn-secondary w-100 mb-2" hidden={hideCreatePoll(decision, user)}>
        Create a decision poll
      </Button>
      <Button onClick={onShowAsCompleted} className="btn btn-secondary w-100 mb-2" hidden={hideShowAsCompleted(decision, user)}>
        Show decision as completed
      </Button>
      {
        (isDeciderUser || isDeciderReadOnlyUser) && <ShareDropdown dropdownClassName="sharing-button" isDetailsPanel={isDetailsPanel} title={"Share decision"}
                                       btnClassName="btn btn-secondary w-100 mb-2" btnId="share-details-dropdown" />
      }
      {
        isDeciderUser && decisionObj.showAsCompleted && <RenderDecisionInputSidebar addClass={'mb-2'} />
      }
      {
        !hideDecisionPDFAndDoc(decision, user) &&
          <Fragment>
            <DownloadPDF isButton={true} />
            <DownloadDOCX isButton={true} />
            <DownloadCSV isButton={true} />
          </Fragment>
      }
      <Button onClick={startDecision} className="btn btn-secondary w-100 mb-2" hidden={hideDecisionDuplicate(decision, user)}>
        Duplicate as a new decision
      </Button>
      <Button onClick={startTemplate} className="btn btn-secondary w-100 mb-2" hidden={hideTemplateDuplicate(decision, user)}>
        Duplicate as a template
      </Button>
      <Button onClick={deleteDecisionHandler} hidden={!isDeciderUser && !isDeciderReadOnlyUser} className="btn-light text-danger w-100 mb-2">
        Delete decision
      </Button>
      <SettingsSection />
    </div>
  </Fragment>
}
const mapStateToProps = ({ current_user, decision, modal, current_org, decision_set, tree }) => ({ user: current_user, decision, modal, current_org, decision_set, tree });
export default connect(mapStateToProps, mapStartDeleteDispatchToProps)(MoreActionsSection);
