import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateHomepageCurrentTab } from "../../store/homepage/actions";
import { hideForecastSimulatorTab, isForecastSimulatorTab, TABS, TABS_URLS } from "../../helpers/home_helpers";

export const ForecastSimulatorItem = ({
                            displayCurrent,
                            home, current_user, current_org,
                            updateHomepageCurrentTab
                          }) => {
  if (hideForecastSimulatorTab(current_org, current_user) ) return null;

  return <li className={displayCurrent && isForecastSimulatorTab(home, current_user, current_org) ? 'active' : ''}>
    <Link to={`/homepage/${TABS_URLS.forecast_simulator}`}
          onClick={() => updateHomepageCurrentTab(TABS.forecast_simulator)}
          className="text-nowrap">
      <span>
        <span className="icon d-inline-block text-center">
          <i className="far fa-magnifying-glass-chart fa-lg"></i>
        </span>
        <span className="d-none d-lg-inline tab-title">Forecasting</span>
      </span>
    </Link>
  </li>
}

const mapStateToProps = ({ home, current_user, current_org }) => ({ home, current_user, current_org });
export default connect(mapStateToProps, { updateHomepageCurrentTab })(ForecastSimulatorItem);
