import { useMemo } from 'react';
import { isBlank, uniqueBy } from "../../helpers/common";

const formatFromOption = ({ label, start_date, end_date }) => ({ label, value: start_date, start_date, end_date })
const formatToOption = ({ label, start_date, end_date }) => ({ label, value: end_date, start_date, end_date })

export const getMemorizedOptions = ({ from, to, options, nextOptionsLimit = false }) => {
  const validToOptions = useMemo(() => {
    const opts = (isBlank(from) ? options : options.slice(options.map(option => option.value).indexOf(from)))
    return (nextOptionsLimit ? opts.slice(0, nextOptionsLimit) : opts).map(formatToOption)
  }, [options, from, nextOptionsLimit])
  const validFromOptions = useMemo(() =>
    (isBlank(to) ? options : options.slice(0, options.map(option => option.value).indexOf(to))).map(formatFromOption)
    , [options, to])

  return {
    validToOptions, validFromOptions
  }
}

export const visibleAndEditablePeriodsScope = (params, forecastScenario) => {
  const periods = forecastScenario.allTimeScalePeriods();
  const allEditablePeriods = forecastScenario.allForecastedAndPrevEditablePeriods;
  const editablePeriods = periods.filter(period => allEditablePeriods.some(editablePeriod => editablePeriod.id === period.id));
  const visiblePeriods = visiblePeriodsScope(params, forecastScenario);
  return uniqueBy([...editablePeriods, ...visiblePeriods], 'id');
};

export const visiblePeriodsScope = (params, forecastScenario) => {
  const periods = forecastScenario.allTimeScalePeriods();
  const visibleAgGridColumns = params.api.getAllDisplayedVirtualColumns();
  return periods.filter(period => visibleAgGridColumns.some(agColumn => Number(agColumn.colDef.colId) === period.id));
};
