import { arrayLastElement, isPresent, qSortArray } from "../../../helpers/common";
import { ForecastBaseModel } from "../Base";
import { TIME_SCALES_KEYS, timePeriodNameParse } from "../ForecastTImeScale";

const TS_SORT_ORDER = {
  [TIME_SCALES_KEYS.year]: 1,
  [TIME_SCALES_KEYS.quarter]: 2,
  [TIME_SCALES_KEYS.month]: 3,
  [TIME_SCALES_KEYS.period]: 4,
  [TIME_SCALES_KEYS.week]: 5
}

export class NpfTimePeriod extends ForecastBaseModel {

  isWithinTimePeriod(date) {
    // TODO: to be implemented
    return false
  }

  get name() {
    return this.attributes.name;
  }
  get timeScaleId() {
    return parseInt(this.attributes.ts_id);
  }
  get timeScaleKey() {
    return this.timeScale?.key;
  }
  get optionLabel() {
    return timePeriodNameParse(this.name)
  }

  get parsedYear() {
    const nameParts = this.name.split('_')
    if (this.timeScale.isYear) return parseInt(nameParts[0]);

    return parseInt(arrayLastElement(nameParts));
  }

  get parsedPeriod() {
    const nameParts = this.name.split('_')
    if (this.timeScale.isYear) return 0;

    return nameParts[0];
  }
}

export class NpfTimeScale extends ForecastBaseModel {

  fillPeriods(periods) {
    this.timePeriods = qSortArray(
      periods.filter(period => period.timeScaleId === this.id),
      true, (period) => period.startDate
    )
    this.timePeriods.forEach(period => period.timeScale = this);
  }

  get sortOrder() {
    return TS_SORT_ORDER[this.key] || 9999
  }

  get isYear() {
    return this.key === TIME_SCALES_KEYS.year;
  }
  get isQuarter() {
    return this.key === TIME_SCALES_KEYS.quarter;
  }

  get isMonth() {
    return this.key === TIME_SCALES_KEYS.month;
  }

  get fromPeriod() {
    return this.timePeriods[0];
  }

  get toPeriod() {
    return this.timePeriods[this.timePeriods.length - 1];
  }

  get displayName() {
    return this.attributes.display_name;
  }
  get key() {
    return this.attributes.config_key;
  }
  get isVisible() {
    return isPresent(this.attributes.visible);
  }
}
