import React, { useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import OrgLogo from "../../tree_view/header/OrgLogo";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import {isAdmin, isMember} from "../../helpers/home_helpers";
import ActionsDropdown from "../components/ActionsDropdown";
import {deleteWarning} from "../../helpers/alert_helpers";
import ScenarioName from "./ScenarioName";
import {
  removeNpfScenario,
  resetNpfScenarioData,
  updateScenarioData,
  updateViewOptions
} from "../../store/npf_simulator_scenario/actions";
import { successActions } from "../../helpers/common";
import { resetForecastData } from "../../store/homepage/actions";
import { NpfScenarioConcept } from "../../models/forecast/npf/ScenarioConcept";
import ToggleBtn from "../filters/ToggleBtn";

const Header = ({
                  current_org, current_user, npf_simulator_scenario, npfScenario, concept,
                  updateScenarioData, updateViewOptions, removeNpfScenario,
                  resetForecastData, resetNpfScenarioData
                }) => {
  const navigate = useNavigate();
  const conceptRecords = useMemo(() =>
    npf_simulator_scenario.scenario_concepts?.map(concept => new NpfScenarioConcept(concept, npfScenario)) || []
    , [npf_simulator_scenario.scenario_concepts, npfScenario])
  const [conceptsOptions, setConceptsOptions] = useState(conceptRecords.map(concept => concept.selectOption));
  const [selectedConcept, setSelectedConcept] = useState(null);
  useEffect(() => {
    setConceptsOptions(conceptRecords.map(concept => concept.selectOption))
    if (conceptRecords.length > 0 && !npf_simulator_scenario.concept_id) {
      const firstConcept = conceptRecords[0]
      if (firstConcept) {
        setSelectedConcept(firstConcept)
        onSelectConcept(firstConcept.selectOption)
      }
    }
  }, [conceptRecords.length]);
  useEffect(() => {
    setSelectedConcept(conceptRecords.find(row => row.id === concept?.id))
  }, [concept]);
  useEffect(() => {
    if (npf_simulator_scenario.scenario_loaded && npf_simulator_scenario.concept_id) {
      const selectedConcept = conceptRecords.find(row => row.id === npf_simulator_scenario.concept_id)
      if (selectedConcept) {
        setSelectedConcept(selectedConcept)
      } else {
        onSelectConcept(conceptRecords[0]?.selectOption || { value: null })
      }
    }
  }, [npf_simulator_scenario.scenario_loaded, npf_simulator_scenario.concept_id]);

  const isAdminOrMember = useMemo(() => isAdmin(current_user) || isMember(current_user), [current_user]);
  const processBackClick = () => {
    redirectToHomeCallback(true, navigate);
  }

  const deleteCallback = () =>
    deleteWarning(confirmed => {
      if (!confirmed) return;

      removeNpfScenario(npf_simulator_scenario.scenario_id, success => {
        successActions(success, [resetForecastData, resetNpfScenarioData]);
        redirectToHomeCallback(success, navigate)
      })
    }, { custom_description: 'delete this scenario permanently' })

  const onSelectConcept = (conceptOption) => {
    updateScenarioData({ concept_id: conceptOption.value })
    updateViewOptions({ concept_id: conceptOption.value })
  }

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo {...{current_org, processBackClick}} />
        </div>
      </div>
      <div className="d-flex flex-row">
        <ScenarioName npf_simulator_scenario={npf_simulator_scenario} />
        {
          conceptsOptions?.length > 1 &&
            <ToggleBtn
              options={conceptsOptions}
              currentOption={selectedConcept?.selectOption || {}}
              onSelect={onSelectConcept}
            />
        }

        {/*{*/}
        {/*  (conceptsOptions && conceptsOptions?.length > 0) ?*/}
        {/*    (*/}
        {/*      conceptsOptions?.length === 1 && selectedConcept ?*/}
        {/*        <div className="d-inline-flex my-auto text-primary fw-medium text-ellipsis">{selectedConcept.name}</div> :*/}
        {/*        <ToggleBtn*/}
        {/*          options={conceptsOptions}*/}
        {/*          currentOption={selectedConcept}*/}
        {/*          onSelect={onSelectConcept}*/}
        {/*        />*/}
        {/*    ) : null*/}
        {/*}*/}

      </div>
      <div className="navbar-nav ms-auto" hidden={!isAdminOrMember}>
        <ActionsDropdown btnId="header-actions-dropdown" {...{deleteCallback, processBackClick }}/>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ sidebar, current_org, current_user, npf_simulator_scenario }) => ({
  current_org, current_user, sidebar, npf_simulator_scenario
});
export default connect(mapStateToProps, {
  updateScenarioData, updateViewOptions, removeNpfScenario,
  resetForecastData, resetNpfScenarioData
})(Header);
