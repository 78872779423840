import React from 'react';
import BaseHamburgerBtn, { BaseHamburgerItem, DeleteItemElement } from "../../common/BaseHamburgerBtn";
import {HamburgerMenuIconBtn, IconDropdown} from "../../common/dropdowns";
import { openModal } from "../../store/modals/actions";
import { connect } from "react-redux";
import {createDecisionTabWithScenario} from "../../forecast_simulator_scenario/helpers/helpers";
import {iconDropdownEvents} from "../../forecast_simulator_scenario/header/ActionsDropdown";

const ActionsDropdown = ({
                           btnId, openModal, deleteCallback, processBackClick, npf_simulator_scenario, disableDelete = false
                         }) => {
  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.editHandler:
        return openModal({ shown: true, type: 'EditProductCategoryScenarioModal' });
      case iconDropdownEvents.deleteHandler:
        return deleteCallback()
      case iconDropdownEvents.saveAsNewHandler:
        return openModal({ shown: true, type: 'CopyProductCategoryScenarioModal' });
      case iconDropdownEvents.createDecisionHandler:
        return createDecisionTabWithScenario(npf_simulator_scenario)
      case iconDropdownEvents.goToHomepageHandler:
        return processBackClick()
    }
  }

  return <>
    <div  className="d-lg-none">
      <HamburgerMenuIconBtn onSelect={iconDropdownSelect}>
        <BaseHamburgerBtn title="Save as a new scenario" eventKey={iconDropdownEvents.saveAsNewHandler} />
        <BaseHamburgerBtn title="Edit scenario details" eventKey={iconDropdownEvents.editHandler} />
        <BaseHamburgerBtn title="Create a decision" eventKey={iconDropdownEvents.createDecisionHandler} />
        <BaseHamburgerItem eventKey={iconDropdownEvents.deleteHandler}>
          <DeleteItemElement name="scenario" />
        </BaseHamburgerItem>
        <BaseHamburgerBtn title="Go to homepage" eventKey={iconDropdownEvents.goToHomepageHandler}/>
      </HamburgerMenuIconBtn>
    </div>
    <div className="d-none d-lg-flex">
      <IconDropdown id={btnId} bsPrefix="h5 m-0 btn-link pt-2"
                    className="btn btn-secondary btn-lg-round d-flex p-0"
                    onSelect={iconDropdownSelect} >
        <BaseHamburgerBtn title="Save as a new scenario" eventKey={iconDropdownEvents.saveAsNewHandler} />
        <BaseHamburgerBtn title="Edit scenario details" eventKey={iconDropdownEvents.editHandler} />
        <BaseHamburgerBtn title="Create a decision" eventKey={iconDropdownEvents.createDecisionHandler} />
        <BaseHamburgerItem eventKey={iconDropdownEvents.deleteHandler}>
          <DeleteItemElement name="scenario" />
        </BaseHamburgerItem>
      </IconDropdown>
    </div>
  </>
};

const mapStateToProps = ({ npf_simulator_scenario }) => ({ npf_simulator_scenario });
export default connect(mapStateToProps, { openModal })(ActionsDropdown);
