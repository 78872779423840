import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { setRecommendationInputSidebarOpen, transferDecisionFromSidebar } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import Footer from "./recommendation_input/Footer";
import DecisionInputBody from "./recommendation_input/DecisionInputBody";
import RationaleInput from "./recommendation_input/RationaleInput";
import AssignedToSection from "./recommendation_input/AssignedToSection";
import { isMobile } from "react-device-detect";
import Header from "./sections/RecommendationHeader";
import DataSourcesSection from "./sections/DataSourcesSection";
import { saveSideBarData } from "../../store/sidebar/common_actions";
import AssignedRecommenderInput from "./recommendation_input/AssignedRecommenderInput";
import MoreActionsFinalSection from "./recommendation_input/MoreActionsFinalSection";
import NextStepsInput from "./recommendation_input/NextStepsInput";
import ExpectedResultsInput from "./recommendation_input/ExpectedResultsInput";
import ExpectedOpportunityInput from "./recommendation_input/ExpectedOpportunityInput";
import ExpectedInvestmentInput from "./recommendation_input/ExpectedInvestmentInput";
import OtherConsideredChoicesInput from "./recommendation_input/OtherConsideredChoicesInput";
import EditAssignBtn from "../../common/EditAssignBtn";
import {showDecisionAssign} from "../modals/DecisionAssignModal";
import { performEditRecommendationInputAction, handleLockAction } from "../../helpers/channel_helpers";
import { partialDeepEqual } from "../../helpers/store_helpers";
import { isPresent, isBlank } from "../../helpers/common";

const RecommendationInputRightPanel = ({ current_user, decision,
                                         sidebar, recommendationData, modal,
                                         openSideBar, transferDecisionFromSidebar, saveSideBarData,
                                         hideAssignee = false, isDsight = false
                                      }) => {
  const assignUser = recommendationData.assigned_collaborator_email ? recommendationData.assigned_collaborator_email : '';
  const [initialRecommendation, setInitialRecommendation] = useState(null);
  const [initialSidebarRecommendationChocies, setInitialSidebarRecommendationChoices] = useState(null);
  const [initialFlowEnum, setInitialFlowEnum] = useState(null);
  const [delayedAlert, setDelayedAlert] = useState(false);
  const [updatingData, setUpdatingData] = useState(false);

  const comparisonRecommendationData = (recommendation) => {
    const { chat_gpt_queries, recommended_at, ...allOtherData } = recommendation;
    return { ...allOtherData };
  }

  const updateDecisionChannel = ({ touched = false, editMode = false }) =>
    handleLockAction({
    isDsight,
    touched,
    current_user,
    editMode,
    slug: decision.slug,
    action: performEditRecommendationInputAction
  });

  useEffect(() => {
    if(isDsight || isBlank(sidebar.decision?.recommendation?.recommendation_choices)) return;

    const isPanelOpened = sidebar.recommendationInputSidebar;
    const changed = isPresent(initialSidebarRecommendationChocies) && !partialDeepEqual(initialSidebarRecommendationChocies, sidebar.decision.recommendation.recommendation_choices);
    if(changed && isPanelOpened) {
      setDelayedAlert(true);
    } else if(changed) {
      updateDecisionChannel({ touched: true });
    }
  }, [sidebar.decision?.recommendation?.recommendation_choices])

  useEffect(() => {
    if(isDsight || isBlank(decision) || isBlank(decision.recommendation)) return;

    const isPanelOpened = sidebar.recommendationInputSidebar;
    const { recommendation } = decision;
    const filteredRecData = comparisonRecommendationData(recommendation);
    const changed = isPresent(filteredRecData) && isPresent(initialRecommendation) && !partialDeepEqual(filteredRecData, initialRecommendation);
    const changedFlow = isPresent(initialFlowEnum) && decision.flow_enum !== initialFlowEnum;
    if((changedFlow || changed) && isPanelOpened) {
      setDelayedAlert(true);
    } else if(changed || changedFlow) {
      updateDecisionChannel({ touched: true });
    }
  }, [decision]);

  return <SlidingPanel
    type="right"
    isOpen={sidebar.recommendationInputSidebar}
    panelContainerClassName={`right-sidebar-panel-wrapper recommendation-input-panel-wrapper`}
    panelClassName={`right-sidebar-panel-wrapper-content recommendation-input-panel-content ${isMobile ? '' : 'd-flex flex-column'}`}
    onClose={transferDecisionFromSidebar}
    onClosed={() => {
      if (!sidebar.decisionInputSidebar && !sidebar.pollPanelSidebar) {
        saveSideBarData({ decision: null, isOpened: false  })
      } else {
        saveSideBarData({ isOpened: false })
      }
      updateDecisionChannel({ touched: delayedAlert });
      setDelayedAlert(false);
      setTimeout(() => {
        setInitialFlowEnum(null);
        setInitialRecommendation(null);
        setInitialSidebarRecommendationChoices(null);
      }, 1000);
    }}
    onOpened={() => {
      updateDecisionChannel({ editMode: true });
      setTimeout(() => saveSideBarData({ isOpened: true }), 200);
      setInitialFlowEnum(decision.flow_enum);
      setInitialRecommendation(comparisonRecommendationData(decision.recommendation));
      setInitialSidebarRecommendationChoices(sidebar.decision?.recommendation?.recommendation_choices);
    }}
  >
    {
      sidebar.decision && <>
        <Header decision={sidebar.decision}
                headerText={"Enter recommendation"}
                openSideBar={() => openSideBar(false)} >
          <EditAssignBtn isDSight={isDsight} assignUser={assignUser} openAssignModal={() => showDecisionAssign(modal)} modalType='RecommendationAssignModal'/>
          {isDsight ?
            <></> :
            <MoreActionsFinalSection /> }
        </Header>
        <AssignedToSection />
        <DecisionInputBody />
        <RationaleInput setUpdatingData={setUpdatingData} />
        { hideAssignee ? null : <AssignedRecommenderInput /> }
        <OtherConsideredChoicesInput />
        <NextStepsInput setUpdatingData={setUpdatingData} />
        <ExpectedResultsInput setUpdatingData={setUpdatingData} />
        <ExpectedOpportunityInput setUpdatingData={setUpdatingData} />
        <ExpectedInvestmentInput setUpdatingData={setUpdatingData} />
        <DataSourcesSection addClass="px-3" fromRecommendationPanel={true}/>
        <Footer updatingData={updatingData} />
      </>
    }

  </SlidingPanel>
}

const mapStateToProps = ({ sidebar, decision, modal, current_user }) => ({
  sidebar, decision, modal, recommendationData: decision.recommendation, current_user
});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setRecommendationInputSidebarOpen(options)) },
  transferDecisionFromSidebar: () => { dispatch(transferDecisionFromSidebar()) },
  saveSideBarData: (data = {}) => { dispatch(saveSideBarData(data)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(RecommendationInputRightPanel);
