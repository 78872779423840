import $ from "jquery";
import React, { useEffect, useRef } from "react";
import { connect, Provider } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import {
  calcAnswered, calcIsInFlight,
} from "../../tree_view/accordion_tree/shared/helpers";
import EntryPoint from "../../EntryPoint";
import ZTreeDriverNode from "./nodes/ZTreeDriverNode";
import ZTreeHoverDriverNode from "./nodes/ZTreeHoverDriverNode";
import { dragDriver } from "../../store/tree/actions";
import { updateTreeData } from "../../store/tree/common_actions";
import { openModal } from "../../store/modals/actions";
import { TREE_MODES } from "../../helpers/drivers_helpers";
import { MOVE_TYPES } from "../wizard/ZTree";
import Decision from "../../models/decision";
import {updateDecisionData} from "../../store/decision/common_actions";
import { createRoot } from "react-dom/client";
import {cleanExpiredCollapsedDrivers} from "../../helpers/local_storage_helpers";
import {driverCollapse, driverExpand} from "./nodes/actions/DriverExpandCollapse";
import {
  handleLockAction,
  performEditDriverAnswerAction,
  performReorderDriversAction, treeChannelIsConnected
} from "../../helpers/channel_helpers";

const COLLAPSED_DRIVERS_STORAGE_KEY = 'collapsedDrivers';

// unmount React components rendered during setup zTree with jquery
export const onUnmountZTree = (actions = () => {}, zTreeId) => {
  const treeObj = $.fn.zTree.getZTreeObj(zTreeId);
  if(treeObj) {
    const nodes = treeObj.getNodes();
    unmoutReactTreeNodes(nodes);
    actions()
    treeObj.destroy();
  }
}
export const unmoutReactTreeNodes = (treeNodes) => {
  treeNodes.forEach((treeNode) => {
    if(treeNode.root) {
      setTimeout(() => {
        treeNode.root.unmount()
        treeNode.rendered = false;
      }, 100)
    }
    if(isPresent(treeNode.children)) unmoutReactTreeNodes(treeNode.children);
  })
}
// ZTree lib setting params
export const BASE_ZTREE_SETTING = {
  view: { dblClickExpand: false, selectedMulti: false, showLine: true },
  data: { simpleData: { enable: true, idKey: "slug", pIdKey: "pSlug", rootPId: "" }, key: { name: "question" } },
}

export const onDragMove = (event, treeId, treeNodes) => {
  const targetElement = $(`#${treeNodes[0].tId}_a`);
  const targetElementWidth = $(`#${treeNodes[0].tId}_a`).css('width');
  const targetText = targetElement[0].innerHTML

  $('.zTreeDragUL').html(targetText).css('width', targetElementWidth);
}

const addDiyDom = (treeId, treeNode, collaborators, disableQuestion = false) => {
  const $driverRow = $(`#${treeNode.tId}_a`);
  if (!treeNode.rendered) treeNode.root = createRoot($driverRow[0])
  treeNode.root.render(<Provider store={EntryPoint.instance.store}>
    <ZTreeDriverNode key={`ztree-node-${treeNode.slug}`} {...{treeNode, collaborators, disableQuestion}} />
  </Provider>);
  treeNode.rendered = true;
}

const addHoverDom = (treeId, treeNode, isTemplate) => {
  const $driverRow = $(`#${treeNode.tId}_a`);
  if (!treeNode.rendered)  treeNode.root = createRoot($driverRow[0])
  treeNode.root.render(<Provider store={EntryPoint.instance.store}>
    <ZTreeHoverDriverNode key={`ztree-hover-node-${treeNode.slug}`} treeNode={treeNode} isTemplate={isTemplate} />
  </Provider>);
  treeNode.rendered = true;
}

const removeHoverDom = (treeId, treeNode, collaborators) => addDiyDom(treeId, treeNode, collaborators);

export const beforeCollapseCallback = (treeId, treeNode, object) => treeNode.isParent && treeNode.slug !== object.slug;

export const preventDropCallback = (targetNode, moveType) => isBlank(targetNode) || isBlank(moveType) ||
  (targetNode.isDecision && (moveType === MOVE_TYPES.next || moveType === MOVE_TYPES.prev))

const dragDriverRequest = (current_user, dragDriver, treeNodes, targetNode, moveType, updateTreeData, updateDecisionData) => dragDriver({
  slug: treeNodes[0].slug,
  target_slug: targetNode.slug,
  move_type: moveType,
  is_decision: targetNode.isDecision
}, (success, data) => {
  if(success) {
    updateTreeData({ copied_ztree_node: {}, selected_ztree_node: {} })
    updateDecisionData(data)
    if(treeChannelIsConnected()) performReorderDriversAction(current_user)
  }
});

const zTreeEditModeSettings = (current_user, decision, collaborators, isTemplate, updateTreeData, dragDriver, updateDecisionData,
                               collapsedDriversSlugs = {}) => {
  return {
    edit: { enable: true, drag: { isCopy: true, isMove: true }, showRemoveBtn: false, showRenameBtn: false },
    view: {
      addDiyDom: (treeId, treeNode) => addDiyDom(treeId, treeNode, collaborators),
      addHoverDom: (treeId, treeNode) => addHoverDom(treeId, treeNode, isTemplate),
      removeHoverDom: (treeId, treeNode) => removeHoverDom(treeId, treeNode, collaborators),
    },
    callback: {
      onExpand: (event, treeId, treeNode) => driverExpand(treeNode, decision.slug, collapsedDriversSlugs, COLLAPSED_DRIVERS_STORAGE_KEY),
      onCollapse: (event, treeId, treeNode) => driverCollapse(treeNode, decision.slug, collapsedDriversSlugs, COLLAPSED_DRIVERS_STORAGE_KEY),
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, decision),
      beforeClick: (treeId, treeNode) => {
        updateTreeData({ selected_ztree_node: treeNode });
        return true;
      },
      onDragMove: onDragMove,
      beforeDrag: (treeId, treeNodes) => !treeNodes.some((node) => !node.drag),
      beforeDrop: (treeId, treeNodes, targetNode, moveType) => {
        if(preventDropCallback(targetNode, moveType)) return false;

        dragDriverRequest(current_user, dragDriver, treeNodes, targetNode, moveType, updateTreeData, updateDecisionData);
        return true
      }
    }
  }
}
const zTreeViewModeSettings = (decision, collaborators, collapsedDriversSlugs) => {
  return {
    view: {
      addDiyDom: (treeId, treeNode) => addDiyDom(treeId, treeNode, collaborators),
    },
    callback: {
      onExpand: (event, treeId, treeNode) => driverExpand(treeNode, decision.slug, collapsedDriversSlugs, COLLAPSED_DRIVERS_STORAGE_KEY),
      onCollapse: (event, treeId, treeNode) => driverCollapse(treeNode, decision.slug, collapsedDriversSlugs, COLLAPSED_DRIVERS_STORAGE_KEY),
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, decision),
      beforeClick: (treeId, treeNode) => {
        return false;
      },
    }
  }
}

const zTreeAssignModeSetting = (decision, collaborators, drivers, data_sources, openModal) => {
  const decisionObj = new Decision(decision)

  return {
    view: { addDiyDom: (treeId, treeNode) => addDiyDom(treeId, treeNode, collaborators, treeNode.answered) },
    callback: {
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, decision),
      beforeClick: (treeId, treeNode) => {
        if(treeNode.isDecision) {
          if (!decisionObj.isRecordedOrShowAsCompleted) {
            openModal({
              decision, drivers, data_sources,
              slug: treeNode.slug,
              type: (isPresent(decision.recommendation) && decisionObj.isTreeRecommendation) ? 'RecommendationAssignModal' : 'DecisionAssignModal'
            })
          }
        } else if(!treeNode.answered) {
          openModal({decision, drivers, data_sources, slug: treeNode.slug, type: 'DriverAssignModal'})
        }
        return true;
      }
    }
  }
}

const generateDriverObject = ({
                                driver = {}, driver_sources_slugs = [], pSlug = null, default_user_avatar_url = '',
                                children = [], isDecision = false, isTemplate =  false,
                                decision = {}, collapsedDriversSlugs
                              }) => {
  const answered = !isTemplate ? calcAnswered(isDecision, driver, driver_sources_slugs, decision) : false;
  const inFlight =  !isTemplate ? calcIsInFlight(isDecision, driver, driver_sources_slugs, decision) : false
  const commented = !isTemplate && !isDecision && answered && isPresent(driver.comments);
  const comments_size = !isTemplate && !isDecision && isPresent(driver.comments) ? driver.comments.length : 0;
  const has_notes = !isDecision && !answered && isPresent(driver.notes);
  const assigned = !answered && (isPresent(driver.assign_to_user) || isPresent(driver.assignedCollaboratorEmail));

  const filtered_children = (children || []).filter(hash => isPresent(hash['driver']['question']))
  const children_empty = (children || []).find(hash => isBlank(hash['driver']['question']))
  const isDriverCollapsed = isPresent(collapsedDriversSlugs) ?
    collapsedDriversSlugs[decision.slug]?.find(entry => entry.slug === driver.slug) :
    false;
  const result = {
    isDecision, answered, commented, has_notes, assigned, comments_size, inFlight,
    isTemplate, default_user_avatar_url,
    assignedToUser: isDecision ? driver.assignedCollaboratorEmail : driver.assign_to_user,
    notes: driver.notes,
    driverTypeSlug: driver.driver_type_slug,
    pSlug: pSlug,
    slug: driver.slug,
    open: !isDriverCollapsed,
    question: driver.question,
    childrenEmptyDriver: children_empty,
    iconSkin: inFlight ? 'in-flight' : (answered ? 'answered' : 'non-answered'),
    drag: !isDecision,
    drop: !isDecision
  };

  if (filtered_children.length > 0) result['children'] = filtered_children.map(hash =>
    generateDriverObject({ ...hash, pSlug: driver.slug, isTemplate, decision, default_user_avatar_url, collapsedDriversSlugs: collapsedDriversSlugs })
  )
  return result;
}

export const hideAssignees = (decisionObj) => {
  if(decisionObj.isTreeHistorical) return decisionObj.isRecordedOrShowAsCompleted;

  return decisionObj.isRecordedOrRecommended;
}

const ZTree = ({ zTreeId = 'decisionTree', tree, decision, current_user, channels, isTemplate = false,
                 treeMode = TREE_MODES.edit,
                 collaborators = [], updateTreeData, dragDriver,
                 openModal, updateDecisionData, collapsedDriversSlugs }) => {
  const ref = useRef(null)
  useEffect(() => {
    cleanExpiredCollapsedDrivers(COLLAPSED_DRIVERS_STORAGE_KEY);

    let stateSettings = ''
    switch (treeMode) {
      case TREE_MODES.view:
        stateSettings = zTreeViewModeSettings(decision, collaborators, collapsedDriversSlugs)
        break
      case TREE_MODES.edit:
        stateSettings = zTreeEditModeSettings(current_user, decision, collaborators, isTemplate, updateTreeData, dragDriver,
          updateDecisionData, collapsedDriversSlugs)
        break
      case TREE_MODES.assign:
        stateSettings = zTreeAssignModeSetting(decision, collaborators, tree.drivers, tree.data_sources, openModal)
        break
    }
    const setting = { ...BASE_ZTREE_SETTING, ...stateSettings };
    const decisionObj = new Decision(decision);
    const assignedCollaboratorEmail = hideAssignees(decisionObj) ?
      null :
      decisionObj.isTreeHistorical
        ? decisionObj.assignedDecisionCollaboratorUser
        : decisionObj.assignedCollaboratorUser;
    const nodes = generateDriverObject({
      driver: { ...decision, question: decision.description, assignedCollaboratorEmail: assignedCollaboratorEmail },
      children: tree.drivers,
      pSlug: decision.slug,
      default_user_avatar_url: tree.default_user_avatar_url,
      isDecision: true,
      isTemplate,
      decision,
      collapsedDriversSlugs
    });
    $.fn.zTree.init($(ref.current), setting, nodes);
    return () =>
      onUnmountZTree(() => updateTreeData({ selected_ztree_node: {}, copied_ztree_node: {} }), zTreeId);
  }, [current_user, decision, treeMode, tree.drivers, channels.tree, isTemplate])

  useEffect(() => {
    if(isBlank(tree.copied_ztree_node)) {
      $.fn.zTree.getZTreeObj(zTreeId).cancelSelectedNode()
    }
  }, [tree.copied_ztree_node])

  return <ul id={zTreeId} className={`ztree ${treeMode === TREE_MODES.view ? 'view-mode' : ''} p-0`} ref={ref} />
}

const mapStateToProps = ({ tree, decision, template, channels, current_user },  { isTemplate }) => ({
  tree, channels, decision: isTemplate ? template : decision, current_user,
  collapsedDriversSlugs: JSON.parse(localStorage.getItem(COLLAPSED_DRIVERS_STORAGE_KEY))
});
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  updateTreeData: (data) => dispatch(updateTreeData(data)),
  dragDriver: (data, callback) => dispatch(dragDriver(data, callback)),
  updateDecisionData: (data) => dispatch(updateDecisionData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(ZTree);
