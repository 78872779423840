import {
  NPF_LOAD_SCENARIO_FAILED, NPF_LOAD_SCENARIO_STARTED,
  NPF_UPDATE_SCENARIO_DATA, NPF_RESET_SCENARIO_DATA
} from "./types";

const initialState = {
  scenario_id: null,
  concept_id: null,
  loaded: false,
  loading: false,
  view_loading: false,
  scenario_loaded: false,
  scenario_loading: false,
  scenario_error: false,
  concept_loaded: false,
  concept_loading: false,
  concept_error: false,
  scenario_hint: false,
  scenario_loading_times: 0,
  concept_loading_times: 0,
  config: {},
  scenario: {},
  scenario_concepts: [],
  config_scenarios: [],
  error: '',
  run_model: false

};

export function npfSimulatorScenarioReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case NPF_UPDATE_SCENARIO_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case NPF_LOAD_SCENARIO_STARTED: {
      return {
        ...state,
        ...action.payload,
        error: '',
        loading: true
      }
    }
    case NPF_LOAD_SCENARIO_FAILED: {
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    }
    case NPF_RESET_SCENARIO_DATA: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state;
  }
}
