import {arrayToSentence, isBlank, isPresent, qSortArray} from "./common";
import {answeredChildrenDrivers} from "./gpt_helpers";
import {filterAndSortDataSources} from "../tree_view/side_panel/driver_input/AiResponseButton";
import driverObject from "../models/driver";
import { COLLECTIVE_TYPES } from "../models/decision";

export function driversToArray(drivers) {
  const result = [];
  const addDriverToArray = (driver) => {
    result.push({ ...driver, driver: {...driver.driver}  });
    if (driver.children && driver.children.length > 0) {
      driver.children.forEach(d => addDriverToArray(d))
    }
  }
  drivers.forEach(d => addDriverToArray(d))
  result.forEach(h => {
    if (h.driver?.parent_id !== null) {
      h.driver.parent = result.find(d => d.children.some(c => c.driver.slug === h.driver.slug))?.driver
    }
  })
  return result;
}

export function groupUnstartedDriversByDeep({data_sources, drivers}) {
  if (isBlank(drivers)) return [];

  const result = [];
  const allowedDataSources = filterAndSortDataSources(data_sources);
  const prepareDrivers = markAsCanBeAnswered(drivers, allowedDataSources);

  const addDriverToArray = (driver, deep) => {
    if (!result[deep]) result[deep] = [];
    const useFiles = allowedDataSources.some(source => driver.driver_sources_slugs.includes(source.slug));

    if (isDriverAllowed(driver.driver, useFiles)) {
      const {slug} = driver.driver;
      result[deep].push({slug, use_files: useFiles});
    }
    if (driver.children && driver.children.length > 0) {
      driver.children.forEach(d => addDriverToArray(d, deep + 1));
    }
  };
  prepareDrivers.forEach(d => addDriverToArray(d, 0));

  return result.filter(levelArray => levelArray.length > 0);
}

const markAsCanBeAnswered = (drivers, allowedDataSources) => {
  const markDriver = (driver) => {
    driver.driver.can_be_answered = isAnsweredOrDataSources(driver, allowedDataSources);
    if (driver.children && driver.children.length > 0) {
      driver.children.forEach(d => markDriver(d));
    }
  };
  drivers.forEach(d => markDriver(d));
  return drivers;
}

const isAnsweredOrDataSources = (driver, allowedDataSources) => {
  const withDataSourcesOrAnswered = (driver) => {
    if (new driverObject(driver.driver).isCompleted) return true;

    return allowedDataSources.some(source => driver.driver_sources_slugs.includes(source.slug));
  }

  const checkChildrenRecursively = (driver) => {
    if (withDataSourcesOrAnswered(driver)) return true;
    if (driver.children && driver.children.length > 0) {
      return driver.children.some(d => checkChildrenRecursively(d));
    }
    return false;
  }

  return checkChildrenRecursively(driver);
}

export const isUnstartedDriversWithFiles = ({data_sources, drivers}) => {
  const allowedDataSources = filterAndSortDataSources(data_sources);
  const checkDriver = (driver) => {
    const useFiles = allowedDataSources.some(source => isPresent(driver.driver_sources_slugs) && driver.driver_sources_slugs.includes(source.slug));
    if (isDriverAllowed(driver.driver, useFiles) && useFiles) {
      return true;
    }
    if (driver.children && driver.children.length > 0) {
      return driver.children.some(d => checkDriver(d));
    }
    return false;
  };
  return drivers.some(driver => checkDriver(driver));
};

const isDriverAllowed = (driver, useFiles) => {
  const driverObj = new driverObject(driver);
  if (!driverObj.isUnstarted || !driverObj.isOpen) return false;
  if (useFiles || driver?.can_be_answered) return true;

  return (answeredChildrenDrivers(driver).length >= MIN_DRIVERS_FOR_AI_ANALYSIS);
};

export function getParentsDrivers(drivers) {
  return drivers.filter(d => !d.driver?.parent_id && d.driver?.question)
}

export const RATING_SCALES_DATA = window.TreeLayoutConstants.driver.rating_scales_data;
export const DEFAULT_RATING_SCALES_DATA = window.TreeLayoutConstants.driver.default_rating_scales_data;
export const MIN_DRIVERS_FOR_AI_ANALYSIS = window.TreeLayoutConstants.driver.min_drivers_for_ai_analysis;

export function calculateRatingScore (rating, startScale, endScale) {
  return Math.round((rating - startScale) / (endScale - startScale) * 100)
}

export const MIN_DRIVERS_FOR_GPT_REQUEST = 2

const fullName = (user) => {
  const firstName = user?.first_name || '';
  const lastName = user?.last_name || '';

  return `${firstName} ${lastName}`.trim();
};

export const assignedByUsersToString = (drivers) => {
  if (isBlank(drivers)) return '';

  const uniqueUsers = drivers.reduce((uniqueUserMap, d) => {
    const assignedByUser = d.driver.assigned_by_user;
    if (isPresent(assignedByUser)) {
      const fullNameStr = fullName(assignedByUser)
      uniqueUserMap.set(fullNameStr, assignedByUser);
    }
    return uniqueUserMap;
  }, new Map());

  const uniqueUserArray = Array.from(uniqueUsers.values());
  const fullNames = uniqueUserArray.map(user => fullName(user));

  return arrayToSentence(fullNames)
};

export const filterHashEmptyValues = (data) => Object.fromEntries(
  Object.entries(data).filter(([_, value]) => isPresent(value))
);

export const TREE_MODES = { view: 'Decision tree', edit: 'Edit decision tree', assign: 'Assign pending items' }

export const isChoicesDifferent = (array1, array2) => {
  if (array1.length !== array2.length) return true;

  for (let index = 0; index < array1.length; index++) {
    const choice1 = array1[index];
    const choice2 = array2[index];

    // Check if choices are not equal based on slug and description
    if (choice1.slug !== choice2.slug || choice1.description.toLowerCase().trim() !== choice2.description.toLowerCase().trim()) {
      return true; // Differences found, return true
    }
  }

  return false; // No differences found, return false
};

export const isEditedChoices = (array1, array2) => {
  if (array1.length !== array2.length) return true;

  for (let index = 0; index < array1.length; index++) {
    const choice1 = array1[index];
    const choice2 = array2[index];

    if (choice1.description.toLowerCase().trim() !== choice2.description.toLowerCase().trim()) return true;
  }
  return false;
};

export function parseFormattedNumber(formattedNumber) {
  if (isBlank(formattedNumber)) return NaN;

  const numericString = formattedNumber.replace(/[^0-9.-]/g, '');
  const parsedNumber = parseFloat(numericString);

  return isNaN(parsedNumber) ? NaN : parsedNumber;
}

export const updateMarkAnsweredState = (choiceEntryWidgetType, markAnswered, state, rateCompareChoices) => {
  if (choiceEntryWidgetType) {
    if (COLLECTIVE_TYPES.includes(choiceEntryWidgetType)) {
      // If rateCompareChoices is false, treat as open_ended
      if (rateCompareChoices === false) {
        markAnswered['open_ended'] = { state };
      } else {
        // Otherwise, update all collective types together
        markAnswered = {
          picky: { state },
          rank_list: { state },
          top_choice: { state }
        };
      }
    } else {
      // For open_ended
      markAnswered = {
        [choiceEntryWidgetType]: { state }
      };
    }
  }
  return markAnswered;
};

export const getChoiceEntryWidgetType = (decision) => {
  const recommendationType = decision.recommendation?.choice_entry_widget_type;
// If recommendation has a type that is either a collective type or 'open_ended', return it
  if (recommendationType && (COLLECTIVE_TYPES.includes(recommendationType) || recommendationType === "open_ended")) {
    return recommendationType;
  }
// Otherwise, return the main decision choice_entry_widget_type
  return decision.choice_entry_widget_type;
};
