import { isBlank, isPresent, qSortArray } from "../helpers/common";
import { InsightsData } from "./d_sight/InsightsData";
import { AnalysisKPI } from "./d_sight/Analysis";
import { DEFAULT_DRIVER_CHOICE_DATA, DriverChoiceRating, sortChoicesByRating } from "./DriverChoiceRating";
import * as moment from "moment/moment";
import { userName } from "../helpers/user_helpers";
import { sortChoicesByOrderRankAndCreatedAt } from "./decision_choices";

export const DRIVER_STATES_DATA = {
  unstarted: { icon_classes: 'fa-duotone fa-circle square-24', color: 'text-light-gray' },
  in_flight: { icon_classes: 'fa-solid fa-circle-dot square-24', color: 'text-warning' },
  completed: { icon_classes: 'fa-solid fa-circle-check square-24', color: 'text-success' }
};

export const QUESTION_INPUT_LIMIT = 100;

export const DEFAULT_WEIGHT = 100;

export const DEFAULT_DRIVER_TYPE_DATA = {
  name: "General",
  slug: ""
};

export const DEFAULT_DRIVER_TYPE_NAME = 'Driver';
export const RATING_SCALES = window.TreeLayoutConstants.driver.rating_scales;
export const DEFAULT_RATING_SCALE = window.TreeLayoutConstants.driver.rating_scales[0];
export const YES_OR_NO_RATING_SCALE = window.TreeLayoutConstants.driver.yes_or_no_rating_scale;

export const DriverChoiceEntryType = window.TreeLayoutConstants.driver.choice_entry_types_upcase;

export const DriverResponseTypes = [
  { value: DriverChoiceEntryType.OPEN, label: 'Open' },
  { value: DriverChoiceEntryType.NUMBER, label: 'Number' },
  { value: DriverChoiceEntryType.TOP_CHOICE, label: 'Pick a choice' },
  { value: DriverChoiceEntryType.PICKY, label: 'Pick all that apply' }
];

export const DRIVER_CHOICE_ENTRY_TYPE_DATA = {
  open: 'Open',
  number: 'Number',
  top_choice: 'Pick a choice.',
  picky: 'Pick all that apply.'
};

export const NUMBER_FORMAT = window.TreeLayoutConstants.driver.number_formats;

export const NUMBER_FORMAT_PLACEHOLDER = {
  default: 'Enter a number',
  dollar: 'Enter a dollar amount',
  percentage: 'Enter a percentage'
};

export const MIN_DISPLAYING_CHOICES = 3

export default class Driver {
  constructor(driver, driver_sources_slugs = [], org_driver_types = {}, decisionObject = null, disableRatingSorting = false) {
    this.driver = driver;
    this.driver_sources_slugs = driver_sources_slugs;
    this.org_driver_types = org_driver_types;
    this.decisionObject = decisionObject;
    this.driverStateData = driver?.state_data || {};
    this.disableRatingSorting = disableRatingSorting;
  }

  get isOpen() {
    return this.driver.choice_entry_widget_type === DriverChoiceEntryType.OPEN || this.driver.choice_entry_widget_type === null;
  }

  get isTopChoice() {
    return this.driver.choice_entry_widget_type === DriverChoiceEntryType.TOP_CHOICE;
  }

  get isPicky() {
    return this.driver.choice_entry_widget_type === DriverChoiceEntryType.PICKY;
  }

  get isNumbering() {
    return this.driver.choice_entry_widget_type === DriverChoiceEntryType.NUMBER;
  }

  sortedChoices() {
    return this.driver.driver_response_choices.sort(sortChoicesByOrderRankAndCreatedAt);
  }

  get finalDecisions() {
    if (!this.withEnteredAnswer) return [];

    return this.sortedChoices().filter(c => isPresent(c.final_decision));
  }

  get cloneObj() {
    return new Driver({...this.driver}, this.driver_sources_slugs, this.org_driver_types, this.decisionObject);
  }

  get keyDriverSlug() {
    if (this.isKeyDriver) return this.slug;

    return new Driver(this.driver.parent).keyDriverSlug;
  }

  get isDSightDriver() {
    return isPresent(this.driver.d_sight);
  }

  get isKeyDriver() {
    return this.driver?.parent_id == null;
  }

  get isSubDriver() {
    return isPresent(this.driver?.parent_id);
  }

  get weightScore() {
    return isBlank(this.driver.weight) ? DEFAULT_WEIGHT : this.driver.weight;
  }
  set weightScore(value) {
    this.driver.weight = value;
  }
  get slug() { return this.driver?.slug }
  get question() { return this.driver.question }

  get weightPercentage() {
    if (isBlank(this.decisionObject?.keyDrivers)) return 100;
    if(this.decisionObject?.keyDrivers.every((obj) => obj.weightScore === 0)) return 0;
    const sum = this.decisionObject?.keyDrivers.reduce((res, obj) => res + obj.weightScore, 0);
    if (sum === 0) return 100;

    return Math.round(parseFloat(this.weightScore)/parseFloat(sum) * 100);
  }

  get dsightChainId() {
    if (!this.isDSightDriver) return null;

    return this.driver.d_sight.d_sight_chain_id;
  }

  get insightsData() {
    if (!this.isDSightDriver) return null;

    return new InsightsData(this.driver.d_sight);
  }

  get dSightKpis() {
    if (!this.isDSightDriver) return [];
    if (isBlank(this.driver.d_sight.kpis)) return [];

    return this.driver.d_sight.kpis.map(kpi => new AnalysisKPI(kpi) );
  }
  get isDraft() {
    return this.driver.draft;
  }

  get withEnteredAnswer() {
    const { answer, choice_entry_widget_type, driver_response_choices } = this.driver;

    const isOpenType = choice_entry_widget_type === DriverChoiceEntryType.OPEN || choice_entry_widget_type === null || choice_entry_widget_type === DriverChoiceEntryType.NUMBER;
    const hasAnswerInOpenType = isPresent(answer) && isOpenType;
    const hasFinalChoiceType = !isOpenType && driver_response_choices?.some(choice => choice.final_decision);

    return (hasAnswerInOpenType || hasFinalChoiceType);
  }

  get withEnteredExplanation() {
    return this.driver.explanation;
  }

  get isOverdue() {
    if(isBlank(this.driver.due_date)) return false;

    return moment(this.driver.due_date).isSameOrBefore(moment());
  }
  statusTooltipText(contactsData = [], answeredByUserEmail = {}) {
    if(this.isCompleted) {
      const completedByUser = this.completedBy(contactsData.contacts);

      return `Entered by ${userName(completedByUser, answeredByUserEmail)}`;
    }
    if(this.isUnstarted) return 'Unstarted';
    if(this.isInFlight) return 'In-flight';

    return '';
  }

  get withEnteredChoicesRating() {
    return this.driveChoicesRatings?.some(o => o.isAnswered);
  }
  getSortedChoices(choices) {
    const sortedChoices = sortChoicesByRating(choices, this.decisionObject.keyDrivers, {
      key_driver_slug: this.keyDriverSlug,
      postSort: this.decisionObject.choiceDriverSortCallback(true),
      disableRatingSorting: this.disableRatingSorting
    })
    return sortedChoices.map(choice => this._prepareChoiceRatingData(choice));
  }
  get sortedDriverChoicesRatings() {
    const allChoices = this.decisionObject.choicesOrRecommendations(true);
    return this.getSortedChoices(allChoices);
  }
  get allDriverChoicesRatings() {
    return this.decisionObject?.choicesOrRecommendations(true).map(choice => this._prepareChoiceRatingData(choice));
  }
  _prepareChoiceRatingData = (choice) => {
    const h = (this.driver.driver_choices || []).find(({ choice_slug }) => choice_slug === choice.slug) || DEFAULT_DRIVER_CHOICE_DATA
    return new DriverChoiceRating({ ...h, choice_slug: choice.slug, choice, driver: this })
  }
  get driveChoicesRatings() {
    if(isBlank(this.driver.driver_choices)) return [];

    const ratings = this.driver.driver_choices.map(h => {
      const choice = this.decisionObject?.choicesOrRecommendations(true)?.find(c => c.slug === h.choice_slug)
      return new DriverChoiceRating({ ...h, choice, driver: this })
    });
    return qSortArray(ratings, false, (r) => parseInt(r.rating))
  }
  get sortedDriveChoicesRatings() {
    const answeredChoices = this.driver.driver_choices?.map(h =>
      this.decisionObject?.choicesOrRecommendations(true)?.find(c => c.slug === h.choice_slug)
    )?.filter(a => isPresent(a));
    return this.getSortedChoices(answeredChoices);
  }
  get showBorder() {
    return isPresent(this.driver.explanation) || isPresent(this.driver.confidence) || this.hasAttachedDataSources
  }
  get hasAttachedDataSources() {
    return isPresent(this.driver_sources_slugs)
  }
  get driverType() {
    const { driver_type_name, driver_type_slug } =  this.driver;
    const emptyType = isBlank(driver_type_name) || isBlank(driver_type_slug);
    if(emptyType) return this.org_driver_types.available_types?.find(driver_type => driver_type.slug === this.driver.driver_type_slug) || DEFAULT_DRIVER_TYPE_DATA;
    return { name: driver_type_name, slug: driver_type_slug }
  }
  get isDefaultDriverType() {
    return this.driverType.name === DEFAULT_DRIVER_TYPE_DATA.name && this.driverType.slug === DEFAULT_DRIVER_TYPE_DATA.slug
  }
  get isAssigned() {
    return isPresent(this.driver.assign_to_user);
  }
  assignee(contactsData = []) {
    return this.decisionObject.collaborators.find(c => this.driver.assign_to_user === c.email) ||
      contactsData.find(contact => this.driver.assign_to_user === contact.email);
  }
  completedBy(contactsData = []) {
    return this.decisionObject.collaborators.find(c => this.driver.answered_by_user === c.email) ||
      contactsData.find(contact => this.driver.answered_by_user === contact.email) || {}
  }
  get withRatingScale() {
    return isPresent(this.driver.rating_scale)
  }

  // These functions are related to Driver State
  get state() {
    return this.driverStateData.state;
  }

  get isCompleted() {
    return this.driverStateData.completed || this.driverStateData.mark_answered;
  }

  get isMarkAnswered() {
    return this.driverStateData.mark_answered;
  }

  get isInFlight() {
    return this.driverStateData.in_flight;
  }

  get isUnstarted() {
    return this.driverStateData.unstarted;
  }
}
