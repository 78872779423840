import React from "react";
import { currencyFormat, isBlank } from "../../helpers/common";

const Pending = <span className="text-muted d-block">Pending</span>

const PeriodRow = ({
                     timePeriod, config, concept
                   }) =>
  <div>
    <h3>{timePeriod.name}</h3>
    {
      config?.scenarioConceptOutputs?.filter(c => c.visibleFor(timePeriod.timeScale))?.map((output) => {
        const aggregatedValue = concept?.valueFor(output, timePeriod)
        return <p key={`concept-details-summary-${timePeriod.id}-${output.id}`}>
          <span className="fw-bolder d-block">{ output.displayName}</span>
          {
            isBlank(aggregatedValue) ?
              Pending :
              <span className="d-block">{currencyFormat(aggregatedValue)}</span>
          }
        </p>
      })
    }
  </div>

export default ({ config, concept }) => {
  return <div className="bg-white rounded mt-3 mt-lg-0 ms-0 ms-lg-3 p-3 sidepanel-minwidth">
    {
      config?.timeScales[0]?.timePeriods?.map((timePeriod) =>
        <PeriodRow key={`concept-details-summary-${timePeriod.id}`} {...{ timePeriod, config, concept }} />
      )
    }
  </div>
}
