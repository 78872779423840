import React from "react";
import {useNavigate} from "react-router-dom";
import {successActions} from "../helpers/common";
import {redirectToHomeCallback} from "../helpers/decision_helpers";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {HamburgerMenuIconBtn} from "../common/dropdowns";
import BaseHamburgerBtn from "../common/BaseHamburgerBtn";
import Image from "react-bootstrap/Image";
import EntryPoint from "../EntryPoint";

const Header = ({decision}) => {
  const navigate = useNavigate();
  const processBackClick = () => {
    successActions(true, []);
    redirectToHomeCallback(true, navigate);
  }

  function redirectToDecisionPage() {
    navigate(`/decisions/${decision.slug}/tree_viewer`);
  }

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer" onClick={processBackClick}>
          <Image src={EntryPoint.instance.org_default_horizontal_logo} alt={EntryPoint.instance.org_default_alt_text}/>
        </div>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerMenuIconBtn>
            <BaseHamburgerBtn onSelect={redirectToDecisionPage} title="See the full decision" />
            <BaseHamburgerBtn onSelect={processBackClick} title="Go to homepage" />
          </HamburgerMenuIconBtn>
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            <Button onClick={redirectToDecisionPage} className="btn btn-secondary w-100">
              See the full decision
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  </header>
}

const mapStateToProps = ({decision}) => ({decision});
export default connect(mapStateToProps, {})(Header);