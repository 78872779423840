import React, { useState } from "react";
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import {isMobile} from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import Footer from "./common/Footer";
import { setChangeForecastTableSettingsOpen } from "../../store/sidebar/actions";
import ChartsDecompositionScope from "./settings/ChartsDecompositionScope";
import {cmuScopes, DEFAULT_SCOPES} from "../helpers/scopes_helpers";
import ColumnsTotals from "./table_settings/ColumnsTotals";
import {
  updateViewOptions,
  updateScenarioData,
  updateOpenedGroups
} from "../../store/forecast_simulator_scenario/actions";
import ComparisonValues from "./table_settings/ComparisonValues";
import {
  BENCHMARK_KEY,
  BM_VS_YA_KEY,
  SIM_VS_BENCHMARK_KEY,
  SIM_VS_YA_KEY,
  GROUP_COL_ID_SUFFIX
} from "../helpers/ag_grid_vars";
import {hideOverlay, MESSAGES, showOverlayWithMessage} from "../helpers/custom_loading_overlay";
import {
  resetNotVisibleGroupRows,
  updateCompDataCallback
} from "../helpers/ag_grid_run_model";
import { partialDeepEqual } from "../../helpers/store_helpers";

export const COMPARISON_INITIAL_OPTIONS = [
  { id: BENCHMARK_KEY, selected: true },
  { id: SIM_VS_BENCHMARK_KEY, selected: false },
  { id: SIM_VS_YA_KEY, selected: false },
  { id: BM_VS_YA_KEY, selected: false }
];

export const COMPARISON_OPTION_IDS = COMPARISON_INITIAL_OPTIONS.map(option => { return  { id: option.id }});

export const applyColumnsSettings = (gridRef, selectedColumns) => {
  selectedColumns.forEach(column => {
    gridRef.current.api.setColumnsVisible([`${column.displayName}-${GROUP_COL_ID_SUFFIX}`], column.selected)
  });
}

const resetOpenedGroups = ({ forecastScenario }) => {
  return forecastScenario.openedGroups.map(group => {
    group.added_rows = resetNotVisibleGroupRows(group, forecastScenario);
    return group;
  });
}

const applyRows = (gridRef, selectedCompValues, selectedColumns) => {
  let redrawRows = []
  const toggleRowHeight = (node, selected) => {
    selected ? node.setRowHeight(42) : node.setRowHeight(0);
    redrawRows.push(node);
  }
  gridRef.current.api.forEachNode(node => {
    const compRow = selectedCompValues.find(comp => node.id.includes(comp.id));
    const totalRow = node.group && selectedColumns.find(col => node.groupData.hasOwnProperty(`${col.displayName}-${GROUP_COL_ID_SUFFIX}`));
    if(compRow) toggleRowHeight(node, compRow.selected);
    if(totalRow) toggleRowHeight(node, totalRow.selected);
  });
  gridRef.current.api.redrawRows(redrawRows);
}

const ChangeTableSettingsPanel = ({
  gridRef, sidebar, config, forecast_simulator_scenario, forecastScenario,
  openSideBar, updateViewOptions,
  updateScenarioData, updateOpenedGroups
}) => {
  if(!forecast_simulator_scenario.scenario_loaded) return null;
  const [submit, setSubmit] = useState(false);
  const rootCmuHeaders = [...config?.rootCmuHeaders];
  const [selectedColumns, setSelectedColumns] = useState(forecastScenario.tableColumnsSettings);
  const [selectedCompValues, setSelectedCompValues] = useState(forecastScenario.comparisonValues || COMPARISON_INITIAL_OPTIONS);

  const prevScopes = forecastScenario.tableSettings.scopes || {};
  const { scopes, updateScope } = cmuScopes(config, forecastScenario, prevScopes, DEFAULT_SCOPES.all, false);

  const successCallback = () => {
    openSideBar(false)
    setSubmit(false);
    applyColumnsSettings(gridRef, selectedColumns);
    applyRows(gridRef, selectedCompValues, selectedColumns);
  }

  const updateOpenedFacts = () => {
    if(forecastScenario.noOpenedGroups) return;

    const updatedOpenedGroups = resetOpenedGroups({ forecastScenario });
    updateOpenedGroups(forecastScenario.local_id, {
      opened_groups: updatedOpenedGroups,
      bulk: true
    }, (status) => {
      if (status) {
        updateCompDataCallback(gridRef, updatedOpenedGroups);
      }
    })
  }

  const updateAction = () => {
    setSubmit(true);
    showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.updating_scenario);
    updateViewOptions({
      tableSettings: {
        columns: selectedColumns, scopes,
        comparisonValues: selectedCompValues
      }}, (success) => {
        if(success) {
          if(!partialDeepEqual(scopes, prevScopes)) {
            successCallback();
            hideOverlay(gridRef.current?.api)
            updateOpenedFacts();
          } else {
            successCallback();
            hideOverlay(gridRef.current?.api)
          }
        } else {
          setSubmit(false);
          hideOverlay(gridRef.current?.api)
        }
    });
  }
  return <SlidingPanel
    type={'left'}
    isOpen={sidebar.forecastTableSettingsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper  table-settings-panel-wrapper left-side-panel-position`}
    panelClassName={`${isMobile ? '' : 'd-flex flex-column'} sidebar-panel-wrapper-content table-settings-panel-content h-100`}
    size={50}>
    <PanelHeader headerName={'Table settings'} openSideBar={openSideBar} preview={true}/>
    <ChartsDecompositionScope header={'Scope'}
                              scopes={scopes}
                              defaultToBtn={false}
                              updateScope={updateScope}
                              scenarioCmus={forecast_simulator_scenario.cmus}
                              forecastConfig={config}/>
    <ColumnsTotals forecastConfig={config}
                   rootCmuHeaders={rootCmuHeaders}
                   selectedColumns={selectedColumns}
                   setSelectedColumns={setSelectedColumns}
                   submit={submit} />
    <ComparisonValues selectedCompValues={selectedCompValues}
                      setSelectedCompValues={setSelectedCompValues}
                      options={COMPARISON_OPTION_IDS}
                      submit={submit} />
    <Footer btnTitle={'Update table'} updateAction={updateAction} disabled={submit || forecastScenario.isLocked} />
  </SlidingPanel>
}
const mapStateToProps = ({ sidebar, forecast_simulator_scenario }) =>
  ({ sidebar, forecast_simulator_scenario });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setChangeForecastTableSettingsOpen(options));
  },
  updateViewOptions: (params, callback) => dispatch(updateViewOptions(params, callback)),
  updateScenarioData: (params) => dispatch(updateScenarioData(params)),
  updateOpenedGroups: (scenario_id, data, callback) => dispatch(updateOpenedGroups(scenario_id, data, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangeTableSettingsPanel);
