import React from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';

export const BaseDropdown = ({ ...opts }) =>
  <DropdownButton size="" align="end" variant="" title="" {...opts} />

export const IconDropdown = ({ className = '', ...opts }) =>
  <BaseDropdown className={`icon ${className}`} {...opts} />

export const BtnDropdown = ({ bsPrefix = '', ...opts }) =>
  <BaseDropdown bsPrefix={`btn ${bsPrefix}`} {...opts} />

export const BtnDropdownSmallRound = ({ bsPrefix = '', ...opts }) =>
  <BtnDropdown bsPrefix={`btn-sm btn-secondary btn-sm-round ${bsPrefix}`} {...opts} />

export const BtnDropdownToggle = ({ bsPrefix = '', ...opts }) =>
  <BtnDropdown bsPrefix={`dropdown-toggle ${bsPrefix}`} {...opts} />

export const BtnDropdownToggleSmallRound = ({ bsPrefix = '', ...opts }) =>
  <BtnDropdownToggle bsPrefix={`btn-sm btn-secondary btn-sm-round ${bsPrefix}`} {...opts} />

export const BtnDropdownToggleInline = ({ ...opts }) =>
  <BtnDropdownToggle className="d-inline-flex" {...opts} />

export const BtnDropdownToggleInlineShadowText = ({ bsPrefix = '', paddingClasses = 'ps-0 py-0', ...opts }) =>
  <BtnDropdownToggleInline bsPrefix={`text-primary ${paddingClasses} after-primary shadow-none ${bsPrefix}`} {...opts} />

export const IconBtnDropdown = ({ bsPrefix = '', ...opts }) =>
  <IconDropdown bsPrefix={`btn btn-secondary btn-sm btn-sm-round dropdown-toggle ${bsPrefix}`} {...opts} />

const hamburgerTitle = <i className="fas fa-bars text-primary pointer fa-2x"/>
export const HamburgerMenuIconBtn = ({ ...opts }) =>
  <DropdownButton id="hamburger-dropdown" className="transparent-btn" bsPrefix="no-after p-0" title={hamburgerTitle} {...opts} />

const calendarCheckTitle = <i className="far fa-calendar-check text-primary pointer fa-lg"/>
export const CalendarCheckMenuIconBtn = ({ ...opts }) =>
  <BaseDropdown bsPrefix="icon btn btn-secondary btn-sm btn-sm-round dropdown-toggle no-after p-0" title={calendarCheckTitle} {...opts} />
