import React, {useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {ExampleCustomInput} from "../../../common/DecisionDate";
import * as moment from "moment";
import {BorderedFilterStyle} from "../../../tree_wizard/styles/choice_entry_styles";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";

const options = [
  { label: "Top performance", value: 1 },
  { label: "Mid performance", value: 2 },
  { label: "Low performance", value: 3 }
];

export default ({date = moment(), id = 'National', isAccount = false}) => {
  const [initDate, setInitDate] = useState(date)

  return <div className="bg-white rounded mb-3 p-3">
    <h3>{isAccount ? `Account ${id}` : 'National'}</h3>
    <div className="d-flex flex-row gap-2 mb-3 pe-4">
      <div className='w-25'>
        <div className="d-flex flex-column text-center justify-content-center rounded bg-light h-100">
          Launch date
        </div>
      </div>
      <div className='w-25'>
        <DatePicker
          wrapperClassName="date-picker" id={`Launch-date-${id}`}
          selected={initDate.toDate()}
          onChange={(date) => {setInitDate(moment(date))}}
          popperPlacement="bottom-start"
          customInput={<ExampleCustomInput id={id} date={initDate} header={null} />}
        />
      </div>
    </div>
    {
      isAccount && <div className="d-flex flex-row gap-2 mb-2">
        <div className="w-25"></div>
        <div className="w-25 fw-bolder text-primary pointer" onClick={() => {}}>Match for all accounts</div>
        <div className="w-25 fw-bolder text-primary pointer" onClick={() => {}}>Match for all accounts</div>
        <div className="w-25 fw-bolder text-primary pointer" onClick={() => {}}>Match for all accounts</div>
      </div>
    }
    <div className="d-flex flex-row gap-2 mb-2">
      <div className="w-25"></div>
      <div className="w-25 fw-bolder">Proxy for ramp-up curve</div>
      <div className="w-25 fw-bolder">Target by the end of Y1</div>
      <div className="w-25 fw-bolder">Franchise distribution incrementality</div>
    </div>
    <div className="d-flex flex-row gap-2">
      <div className="w-25">
        <div className="d-flex flex-column text-center justify-content-center rounded bg-light h-100">
          Distribution
        </div>
      </div>
      <div className="w-25">
        <Select onChange={() => {}}
                components={{ IndicatorSeparator:() => null }}
                options={options}
                // value={{value: 0, label: 'Any'}}
                styles={BorderedFilterStyle} className="pointer" />
      </div>
      <div className="w-25">
        <input type="number"
               className="form-control rounded"
               placeholder={'Enter #'}
               onChange={() => {}} />
      </div>
      <div className="w-25">
        <CurrencyInput
          className="form-control rounded"
          id={`price.id-0`}
          name={`price.id-0`}
          placeholder={'Enter #'}
          decimalsLimit={2}
          suffix={'%'}
          allowNegativeValue={false}
          maxLength={20}
          onValueChange={() => {}}
        />
      </div>
    </div>
  </div>
}
