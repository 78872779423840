import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { hideAlert } from "../store/alerts/actions";
import parse from 'html-react-parser';
import Alert from 'react-bootstrap/Alert';
import EntryPoint from "../EntryPoint";
import { isPresent } from "../helpers/common";
export const DURATION = 3000;
export const checkFlashMessages = (showAlert, ERROR_MESSAGES = {}, duration = DURATION) => {
  const {instance} = EntryPoint;
  const checkFlashMessageBy = (key, type, addClass = 'center-text') => {
    if (isPresent(instance.flash[key])) {
      showAlert({
        text: ERROR_MESSAGES[instance.flash[key]] || instance.flash[key],
        dismissible: true,
        type, addClass, duration,
      })
      delete instance.flash[key];
    }
  }
  checkFlashMessageBy('notice', 'primary')
  checkFlashMessageBy('warning', 'warning')
  checkFlashMessageBy('error', 'danger')
}

const AlertsSection = ({ alerts, hideAlert }) => {
  useEffect(() => {
    if (alerts.shown && alerts.duration) {
      setTimeout(() => { close() }, alerts.duration)
    }
  }, [alerts.shown, alerts.text])

  useEffect(() => {
    if (alerts.shown) close();
  }, [EntryPoint.instance?.controllerName, EntryPoint.instance?.objectSlug])

  const close = () => hideAlert(alerts.dismissible);

  return <div className={`alerts ${alerts.addClass}`}>
    <Alert variant={alerts.type} show={alerts.shown} onClose={close} dismissible={alerts.dismissible}>
      {parse(alerts.text)}
    </Alert>
  </div>
}
const mapStateToProps = ({ alerts }) => ({ alerts });
export default connect(mapStateToProps, { hideAlert })(AlertsSection);
