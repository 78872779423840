import React, { useRef } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { openModal } from "../../../../store/modals/actions";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";
import Decision from "../../../../models/decision";

const modalType = (isTemplate, decisionObj) =>
  isTemplate ? 'TreePanelTemplateEntryModal' :
    (decisionObj.isRecordedOrRecommended || decisionObj.isTreeHistorical ? 'TreePanelDecisionEntryModal' : 'TreePanelMultiEntryModal');

const EditDecision = ({ decision, tree, isTemplate, treeNode, openModal }) => {
  const decisionObj = new Decision(decision)
  const ref = useRef();
  const onEditDecision = (e) => {
    e.preventDefault();

    openModal({ decision, drivers: tree.drivers, data_sources: [], slug: treeNode.slug, type: modalType(isTemplate, decisionObj) })
  }
  attachEventEffect(ref, onEditDecision)

  return <>
    <Button className="btn btn-secondary btn-sm btn-sm-round" ref={ref}>
      <i className="fas fa-pencil-alt fa-lg w-100" />
    </Button>
  </>
}
const mapStateToProps = ({ modal, decision, template, tree },  { isTemplate }) => ({
  modal, tree, decision: isTemplate ? template : decision
});
export default connect(mapStateToProps, { openModal })(EditDecision);
