import {
  runNpfModelData,
  removeNpfSimulatorScenarioData,
  loadNpfSimulatorData,
  loadNpfSimulatorScenarioData,
  updateNpfSimulatorScenarioData,
  loadNpfSimulatorScenarioConceptData,
  removeNpfSimulatorScenarioConceptData,
  updateNpfSimulatorScenarioConceptData,
  createNpfSimulatorScenarioConcept,
  updateConceptViewData
} from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { NPF_LOAD_SCENARIO_FAILED, NPF_UPDATE_SCENARIO_DATA, NPF_RESET_SCENARIO_DATA } from "./types";
import { reloadOrgForecastScenariosData } from "../current_org/actions";

export const loadNpfSimulator = (scenario_id) => (dispatch, getState) => {
  if (getState().npf_simulator_scenario.loaded && getState().npf_simulator_scenario.scenario_id === scenario_id) return;

  dispatch(updateScenarioData({ loading: true }))
  loadNpfSimulatorData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure });

    const { data } = response;
    const { config, config_scenarios } = data;
    dispatch(updateScenarioData({ config, config_scenarios, scenario_id, loaded: true, loading: false }));
    loadNpfSimulatorScenario(scenario_id)(dispatch, getState)
  })
}

export const reloadConfigScenarios = (scenario_id) => (dispatch) => {
  dispatch(updateScenarioData({ loading: true }))
  loadNpfSimulatorData(scenario_id, { only_config_scenarios: true }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure });

    const { data } = response;
    const { config_scenarios } = data;
    dispatch(updateScenarioData({ config_scenarios, loading: false }));
  })
}

export const loadNpfSimulatorScenario = (scenario_id, checkLoading = true) => (dispatch, getState) => {
  if (checkLoading) {
    if (getState().npf_simulator_scenario.scenario_loaded && getState().npf_simulator_scenario.scenario_id === scenario_id) return;
    if (getState().npf_simulator_scenario.scenario_loading) return;
  }

  if (getState().npf_simulator_scenario.scenario_loading) {
    dispatch(updateScenarioData({ scenario_loading_times: getState().npf_simulator_scenario.scenario_loading_times + 1 }))
  } else {
    dispatch(updateScenarioData({ scenario_loading: true, scenario_loading_times: 0 }))
  }
  loadNpfSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadSimFailure });

    const { data } = response;
    const { scenario } = data;

    dispatch(updateScenarioData({ scenario, scenario_id, scenario_loading: false, scenario_loaded: true, scenario_concepts: [], concept_id: null }));
  })
}

export const updateViewOptions = (newViewOptions, callback = () => {}, updateLoadingState = true) => (dispatch, getState) => {
  if (updateLoadingState) {
    if (getState().npf_simulator_scenario.view_loading) return;
    dispatch(updateScenarioData({ view_loading: true }))
  }

  updateNpfSimulatorScenarioData(getState().npf_simulator_scenario.scenario_id, { scenario: { view_options: newViewOptions }, tiny: true }).then(response => {
    if (isResponseFailed(response)) {
      return failedResponseHandler(dispatch, {
        ...response,
        callback: loadFailure
      }, callback);
    }

    const { data } = response;
    const { scenario } = data;
    const updateState = {
      scenario: {
        ...getState().npf_simulator_scenario.scenario,
        ...scenario
      },
    }
    if (updateLoadingState) updateState['view_loading'] = false
    dispatch(updateScenarioData(updateState))
    callback(true)
  })
}
export const updateNpfScenario = (scenario_id, updateData, callback, showLoading = true) => (dispatch, getState) => {
  if(showLoading) dispatch(updateScenarioData({ loading: true }))
  updateNpfSimulatorScenarioData(scenario_id, updateData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);
    const { data } = response;
    const { scenario } = data;
    dispatch(updateScenarioData({
      scenario: {
        ...getState().npf_simulator_scenario.scenario,
        ...scenario,
        data: {
          ...getState().npf_simulator_scenario.scenario.data,
          ...scenario.data,
          attributes: {
            ...getState().npf_simulator_scenario.scenario.data.attributes,
            ...scenario.data.attributes,
          }
        }
      },
      loading: false
    }));
    dispatch(reloadOrgForecastScenariosData())
    dispatch(reloadConfigScenarios(scenario_id))
    callback(true)
  })
}

export const loadNpfSimulatorScenarioPostRunModel = (scenario_id, concept_id) => (dispatch, getState) => {
  loadNpfSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadSimFailure });

    const { data } = response;
    const { scenario } = data;
    // TODO: implement proper handling of running npf model
  })
}

export const runModel = (scenario_id, concept_id, { values }, callback) => (dispatch, getState) => {
  runNpfModelData(scenario_id, { concept_id, values }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    dispatch(updateScenarioData(
      {
        scenario: {
          ...getState().npf_simulator_scenario.scenario,
          run_model_happened: true
        },
      }
    ))
    // TODO: implement proper handling of running npf model
    // loadNpfSimulatorScenarioPostRunModel(scenario_id, concept_id)(dispatch, getState)
    callback(true)
  })
}

export const removeNpfScenario = (scenario_id, callback) => (dispatch) => {
  dispatch(updateScenarioData({ loading: true }))
  removeNpfSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, {
      ...response,
      callback: loadFailure
    }, callback);

    dispatch(reloadOrgForecastScenariosData())
    callback(true)
  })
}

// Concept Actions
export const loadNpfSimulatorScenarioConcept = (scenario_id, concept_id, checkLoading = true) => (dispatch, getState) => {
  if (checkLoading) {
    if (getState().npf_simulator_scenario.concept_loaded && getState().npf_simulator_scenario.concept_id === concept_id) return;
    if (getState().npf_simulator_scenario.concept_loading) return;
  }

  if (getState().npf_simulator_scenario.concept_loading) {
    dispatch(updateScenarioData({ concept_loading_times: getState().npf_simulator_scenario.concept_loading_times + 1 }))
  } else {
    dispatch(updateScenarioData({ concept_loading: true, concept_loading_times: 0 }))
  }
  loadNpfSimulatorScenarioConceptData(scenario_id, concept_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadSimFailure });

    const { data } = response;
    const { concept } = data;

    const scenario_concepts = getState().npf_simulator_scenario.scenario_concepts.map(row =>
      row.id === concept_id ? { ...row, ...concept } : row
    )

    dispatch(updateScenarioData({
      concept_id,
      scenario_concepts,
      concept_loading: false,
      concept_loaded: true
    }));
  })
}

export const createNpfScenarioConcept = (scenario_id, createData, callback, showLoading = true) => (dispatch, getState) => {
  if(showLoading) dispatch(updateScenarioData({ loading: true }))
  createNpfSimulatorScenarioConcept(scenario_id, createData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);
    const { data } = response;
    const { concept, scenario } = data;

    const scenario_concepts = [
      ...getState().npf_simulator_scenario.scenario_concepts,
      concept
    ]
    dispatch(updateScenarioData({
      scenario_concepts,
      scenario: {
        ...getState().npf_simulator_scenario.scenario,
        ...scenario
      },
      loading: false
    }));
    callback(concept)
  })
}

export const updateNpfScenarioConcept = (scenario_id, concept_id, updateData, callback, showLoading = true) => (dispatch, getState) => {
  if(showLoading) dispatch(updateScenarioData({ loading: true }))
  updateNpfSimulatorScenarioConceptData(scenario_id, concept_id, updateData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);
    const { data } = response;
    const { concept } = data;

    const scenario_concepts = getState().npf_simulator_scenario.scenario_concepts.map(row =>
      row.id === concept_id ? { ...row, ...concept } : row
    )
    dispatch(updateScenarioData({ scenario_concepts, loading: false }));
    callback(true)
  })
}

export const removeScenarioConcept = (scenario_id, concept_id, callback) => (dispatch, getState) => {
  dispatch(updateScenarioData({ loading: true }))
  removeNpfSimulatorScenarioConceptData(scenario_id, concept_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, {
      ...response,
      callback: loadFailure
    }, callback);

    const { data } = response;
    const { scenario } = data;

    const scenario_concepts = getState().npf_simulator_scenario.scenario_concepts.filter(row => row.id !== concept_id)
    const updateStateData =  {
      scenario_concepts,
      scenario: {
        ...getState().npf_simulator_scenario.scenario,
        ...scenario
      },
      loading: false
    }
    if (getState().npf_simulator_scenario.concept_id === concept_id) updateStateData['concept_id'] = null

    dispatch(updateScenarioData(updateStateData));
    callback(true)
  })
}

export const updateConceptViewOptions = (concept_id, newViewOptions, callback = () => {}, updateLoadingState = true) => (dispatch, getState) => {
  if (updateLoadingState) {
    if (getState().npf_simulator_scenario.view_loading) return;
    dispatch(updateScenarioData({ view_loading: true }))
  }

  updateConceptViewData(getState().npf_simulator_scenario.scenario_id, concept_id, { concept: newViewOptions }).then(response => {
    if (isResponseFailed(response)) {
      return failedResponseHandler(dispatch, {
        ...response,
        callback: loadFailure
      }, callback);
    }

    const { data } = response;
    const { scenario } = data;
    const updateState = {
      scenario: {
        ...getState().npf_simulator_scenario.scenario,
        ...scenario
      },
    }
    if (updateLoadingState) updateState.view_loading = false
    dispatch(updateScenarioData(updateState))
    callback(true)
  })
}

export const loadFailure = error => ({
  type: NPF_LOAD_SCENARIO_FAILED,
  payload: {
    error,
    loading: false,
    view_loading: false
  }
});

export const loadSimFailure = error => ({
  type: NPF_LOAD_SCENARIO_FAILED,
  payload: {
    scenario_error: error, scenario_loading: false
  }
});

export const loadBenchFailure = error => ({
  type: NPF_LOAD_SCENARIO_FAILED,
  payload: {
    benchmark_error: error,
    benchmark_loading: false
  }
});

export const updateScenarioData = (data) => ({
  type: NPF_UPDATE_SCENARIO_DATA,
  payload: {
    ...data
  }
});

export const resetNpfScenarioData = () => ({ type: NPF_RESET_SCENARIO_DATA });
