import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CloseIcon from '../../common/CloseIcon';
import moment from 'moment';
import NpfScenarioForm from "../components/ScenarioForm";
import { isBlank, isPresent } from "../../helpers/common";
import { NpfScenarioConcept } from "../../models/forecast/npf/ScenarioConcept";

export const showEditProductCategoryScenarioModal = (modal) =>
  modal.shown && modal.type === 'EditProductCategoryScenarioModal';

export const EditProductScenarioModal = ({
                                           show,
                                           onClose = () => {},
                                           config,
                                           npfScenario, npf_simulator_scenario,
                                           updateNpfScenario, resetForecastData
                                         }) => {
  const defaultValues = useCallback(() => ({
    display_name: npfScenario?.displayName,
    cmu: npfScenario?.cmu,
    notes: npfScenario?.notes,
    categories: npfScenario?.categories,
    launch_date: moment(npfScenario?.launchDate)
  }), [npfScenario])

  const defaultCmuValues = useCallback(() => {
    const result = {};
    config?.cmuColumns?.forEach(column => {
      if (column.isPartOfCmu) {
        result[column.id] = npfScenario?.cmuColumnValueBy(column)?.toString() || ''
      }
    })
    return result;
  }, [config, npfScenario])

  const formRef = useRef(null);
  const [formData, setFormData] = useState(defaultValues());
  const [errors, setErrors] = useState({});
  const [submit, setSubmit] = useState(false);
  const [cmus, setCmus] = useState(defaultCmuValues());
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (show) {
      setFormData(defaultValues())
      setCmus(defaultCmuValues())
      setErrors({})
      setIsValid(true)
    }
  }, [show]);
  useEffect(() => {
    if (formRef.current) {
      setIsValid(isBlank(formRef.current?.isInvalid()))
    }
  }, [formData]);

  const close = () => onClose();

  const onClick = () => {
    if (!formRef.current.validate()) return false;

    setSubmit(true)
    updateNpfScenario(npfScenario.local_id, formData, (success,errors) => {
      setSubmit(false)
      if (!success) { return console.error(errors) }

      resetForecastData()
      close()
    })
  }

  const disabledCmus = () => {
    const concepts = npf_simulator_scenario.scenario_concepts.map(concept => new NpfScenarioConcept(concept, npfScenario))
    const attrsFieldsWithValues = config.featuresFields.filter(f => f.isShownFor(npfScenario.cmuValues)).filter(field =>
      concepts.some(concept => isPresent(concept.attributesData[field.id]))
    )
    return config.cmuColumns.filter(column => column.isPartOfCmu).filter(column =>
      attrsFieldsWithValues.some(field => field.cmus.some(cmu => column.cmuIds.includes(cmu.toString())))
    ).map(column => column.id);
  }

  return (
    <Modal size="lg" backdrop="static" show={show} onHide={close}>
      <Modal.Body>
        <CloseIcon onClose={close} />
        <h2>Edit scenario details</h2>

        {
          show && npfScenario &&
            <NpfScenarioForm
              selectedConfig={config}
              formData={formData} setFormData={setFormData}
              cmus={cmus} setCmus={setCmus}
              submit={submit} shown={show}
              errors={errors} setErrors={setErrors}
              ref={formRef} disabledCmus={disabledCmus()}
            />
        }


        <Button className="w-100 mt-3" onClick={onClick} disabled={submit || !isValid}>
          Done
        </Button>
      </Modal.Body>
    </Modal>
  );
};
