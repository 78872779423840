import React, {Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ShareData from "../../models/share_data";
import {isBlank, isPresent} from "../../helpers/common";
import {addShare, disablePublicLinkShare, publicLinkShare, removeShare} from "../../store/share/actions";
import copyToClipboard from "../../../js/utls/copyToClipboard";
import {getBasePath} from "../../helpers/routes_helpers";
import EntryPoint from "../../EntryPoint";
import {showAlert} from "../../store/alerts/actions";
import {formatShareItemData} from "../../helpers/share_helpers";
import ShareDropdownModal from "../modals/ShareDropdownModal";
import {DURATION} from "../../alerts";
import BaseHamburgerBtn, {BaseDropdownBtn, DeleteItemElement} from "../../common/BaseHamburgerBtn";
import {isAdmin, isGuest} from "../../helpers/home_helpers";
import {isDSightDecision} from "../../helpers/home_decision_helpers";
import {deleteAndStartActions, mapStartDeleteDispatchToProps} from "./helpers";
import {collaboratorCanEditTree, isDecider, isDecisionMember} from "../../helpers/user_helpers";
import Decision from "../../models/decision";
import {isVoted} from "../../helpers/decision_votes_helpers";
import Recommendation from "../../models/recommendation";
import RenderDecisionInputSidebar from "./buttons/RenderDecisionInputSidebar";
import CompleteDriversButton from "../../common/CompleteDriversButton";

export const openSharingModal = () => {
  const getParams = new URLSearchParams(document.location.search)
  const requester = getParams.get('requester')
  const modalOpenParam = getParams.get('modal_open');
  return {
    show: isPresent(modalOpenParam) && modalOpenParam === 'sharing' && isPresent(requester),
    requester,
    getParams
  };
}

export const hideDecisionPDFAndDoc = (decision, user) => !isDecisionMember(decision, user);
export const hideDecisionDuplicate = (decision, user) => isDSightDecision(decision) || isGuest(user)
export const hideTemplateDuplicate = (decision, user) => isDSightDecision(decision) || !isAdmin(user)

export const hideCreatePoll = (decision, user) => {
  if(isDSightDecision(decision)) return true

  const decisionObj = new Decision(decision)
  const isDeciderUser = isDecider(user)
  const isCollaboratorCanEdit = collaboratorCanEditTree(user, decision)
  const isVotedChoices = isVoted(decision)
  const object = decisionObj.isRecommendationFlow ? new Recommendation(decision.recommendation) : decisionObj
  return (!isCollaboratorCanEdit && !isDeciderUser) || isVotedChoices || object.isOpenEnded || decisionObj.isPoll || decisionObj.isReadOnly
}

export const hideShowAsCompleted = (decision, user) => {
  if(isDSightDecision(decision)) return true

  const decisionObj = new Decision(decision)
  const { showAsCompleted, isDecisionRecorded, isDecisionWithResults, isRecommendationFlow, isEnteredRecommendation } = decisionObj;
  if(!isRecommendationFlow || !isDecider(user) || !isEnteredRecommendation) return true

  return showAsCompleted || isDecisionRecorded || isDecisionWithResults
}

export const ShareDropdown = ({
                                dropdownClassName, decision,
                                btnClassName, title,
                                btnId, share_data, isDetailsPanel = false,
                                addShareAdmittable,
                                removeShareAdmittable, current_user,
                                enablePublicLinkShare,
                                disablePublicLinkShare,
                                showAlert, org, decision_set,
                                modal, tree,
                                ...opts
                              }) => {
  const [modalShown, setModalShown] = useState(false);
  const shareObject = new ShareData(share_data)
  const decisionObj = new Decision(decision)

  useEffect(() => {
    if(openSharingModal().show) setModalShown(true);
  }, []);

  const shareTo = (item) => addShareAdmittable([formatShareItemData(item)])
  const enablePublicShare = () => {
    enablePublicLinkShare();
    copyPublicLink();
  }
  const disablePublicShare = () => {
    disablePublicLinkShare();
    showAlert({
      text: 'Public link sharing has been turned off.',
      type: 'success',
      addClass: 'text-center',
      dismissible: false,
      duration: DURATION
    });
  }
  const copyPublicLink = () => {
    const { controllerName, objectSlug } = EntryPoint.instance
    copyToClipboard(`${getBasePath()}/${controllerName}/${objectSlug}/tree_viewer`).then(_ => {});
    showAlert({
      text: 'A public link has been copied to your clipboard.',
      type: 'success',
      addClass: 'text-center',
      dismissible: false,
      duration: DURATION
    });
  }
  const removeShare = (item) => removeShareAdmittable(item)
  const closeModal = () => setModalShown(false)
  const submitModal = (items, invites, data, callback) =>
    addShareAdmittable(items, invites, data, (success) => {
      callback(success);
      if (success) closeModal()
    })
  const {
    deleteDecisionHandler, startDecision, startTemplate, onEditDecision, editDecisionTree, assignDriversTree, createDecisionPoll, onShowAsCompleted, completeDriversWithAi
  } = deleteAndStartActions({
    decision, decision_set, current_user, tree,
    ...opts
  })

  return <Fragment>
    <DropdownButton id={btnId} title={title} className={dropdownClassName} bsPrefix={btnClassName}>
      <BaseHamburgerBtn hidden={!shareObject.couldShareOrg} onSelect={() => shareTo(shareObject.entireOrg)}>
        Share with {shareObject.entireOrgLabel}
      </BaseHamburgerBtn>
      <BaseHamburgerBtn hidden={!shareObject.couldShareSet} onSelect={() => shareTo(shareObject.entireDecisionSet)}>
        Share with decision flow viewers
      </BaseHamburgerBtn>
      <BaseHamburgerBtn onSelect={() => setModalShown(true)} title="Share with people or groups..." />
      <BaseHamburgerBtn hidden={shareObject.isShared} onSelect={enablePublicShare} title="Share via a public link" />
      <BaseHamburgerBtn hidden={!shareObject.isShared} onSelect={copyPublicLink} title="Copy public link" />
      <BaseHamburgerBtn hidden={!shareObject.isShared} onSelect={disablePublicShare} title="Turn off public link sharing" />
      <Dropdown.Divider hidden={isBlank(shareObject.selectedItems)}/>
      <Dropdown.ItemText hidden={isBlank(shareObject.selectedItems)}><small className="text-muted">Shared with</small></Dropdown.ItemText>
      {shareObject.selectedItems.map(item =>
        <BaseHamburgerBtn key={`remove-share-${item.admittable_id}`} onSelect={() => removeShare(item)}>
          <i className="fas fa-times-circle text-danger"/>&nbsp;{item.label}
        </BaseHamburgerBtn>)
      }
      <div hidden={isDetailsPanel}>
        <Dropdown.Divider />
        <CompleteDriversButton {...{ decision, user: current_user, tree, completeDriversWithAi, useHamburgerBtn: true }} />
        <BaseHamburgerBtn hidden={hideCreatePoll(decision, current_user)} onSelect={createDecisionPoll} title="Create a decision poll" />
        <BaseHamburgerBtn hidden={hideShowAsCompleted(decision, current_user)} onSelect={onShowAsCompleted} title="Show decision as completed" />
        {
          decisionObj.showAsCompleted && <RenderDecisionInputSidebar isHamburgerBtn={true} />
        }
        <BaseHamburgerBtn hidden={hideDecisionDuplicate(decision, current_user)} onSelect={startDecision} title="Duplicate as a new decision" />
        <BaseHamburgerBtn hidden={hideTemplateDuplicate(decision, current_user)} onSelect={() => startTemplate()} title="Duplicate as a template" />
        <BaseDropdownBtn onSelect={deleteDecisionHandler}>
          <DeleteItemElement name="decision" />
        </BaseDropdownBtn>
      </div>
    </DropdownButton>
    <ShareDropdownModal shown={modalShown} onClose={closeModal} onSubmit={submitModal} />
  </Fragment>
}
const mapStateToProps = ({ share_data, decision, current_user, modal, tree, org, decision_set }) => ({ share_data, decision, current_user, modal, tree, org, decision_set });
const mapDispatchToProps = (dispatch) => ({
  ...mapStartDeleteDispatchToProps(dispatch),
  addShareAdmittable: (items, invites = null, additional_data = {}, callback = () => {}) => {
    dispatch(addShare(items, invites, additional_data, callback))
  },
  removeShareAdmittable: (item) => dispatch(removeShare(item)),
  enablePublicLinkShare: () => dispatch(publicLinkShare()),
  disablePublicLinkShare: () => dispatch(disablePublicLinkShare()),
  showAlert: (data) => dispatch(showAlert(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(ShareDropdown);
