import React from 'react';
import { Link, Page, View, Text, Image } from '@react-pdf/renderer';
import styles from '../../styles/common'
import DocumentFooter from "./DocumentFooter";
import DocumentHeader from "./DocumentHeader";
import {
  isBlank,
  qSortArray,
  sortSourceByType,
  protocolHost,
  getDriverEnteredData
} from "../../../helpers/common";
import * as moment from "moment";
import DataSource, {PDF_IMAGE_FILE_TYPES} from "../../../models/data_source";
import Driver from "../../../models/driver";
import {baseBlockMargin, DSightInsightsBlock} from "./DecisionBlockSection";
import { Html } from 'react-pdf-html';
import imageStyles from "../../styles/images";
import {protectedImageSrc} from "./UserImage";
import {formatMessageText} from "../../../helpers/discussion_helpers";
import {modifyRichText, quillIndentStyles} from "../../helpers/rich_text";
import { userName } from "../../../helpers/user_helpers";
import {pdfTextParser} from "../../helpers/common";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";

const ProvidedByBlock = ({driver, driverResponder, driverType, decision}) => {
  if((isBlank(driverResponder) && !driver.answered_by_ai) || isDSightDecision(decision)) return null;
  const providedByCloverpopAi = driver.answered_by_ai ? 'CloverpopAI' : null;
  const providedBy = providedByCloverpopAi || userName(driverResponder) || driverResponder

  return <View style={[styles.row, {maxWidth: '95%'}]}>
    <Text style={[styles.text, styles.body, styles.muted, styles.pl0]}>
      {
        isBlank(driver.driver_type_slug) ?
          'Provided ': `${driverType.name} provided `
      }
      by {providedBy}
    </Text>
  </View>
}

const AnsweredByBlock = ({driver, enteredUser, enteredAt, driverResponder}) => {
  const responderEmail = driverResponder?.email || driverResponder || ''
  const enteredEmail = enteredUser?.email || enteredUser || ''
  const name = (enteredEmail !== responderEmail) || driver.answered_by_ai ? `by ${userName(enteredUser) || enteredUser} ` : ''

  return <View style={[styles.row, {maxWidth: '95%'}, ...baseBlockMargin]}>
    <Text style={[styles.text, styles.body, styles.muted, styles.pl0]}>
      Last updated {name}{moment(enteredAt).format('DD MMM, yyyy')}
    </Text>
  </View>
}

const DSightAnsweredByBlock = ({driver, driverType, enteredUser, enteredAt}) => {
  return <View style={[styles.row, {maxWidth: '95%'}, ...baseBlockMargin]}>
    <Text style={[styles.text, styles.body, styles.muted, styles.pl0]}>
      {
        isBlank(driver.driver_type_slug) ?
          'Last updated ': `${driverType.name} last updated `
      }
      by {userName(enteredUser) || enteredUser} {moment(enteredAt).format('DD MMM, yyyy')}
    </Text>
  </View>
}

export const DriverTypeBlock = ({org_driver_types, driver, decision}) => {
  if (isBlank(driver.answered_at) || isBlank(driver.answered_by_user)) return null;

  const driverObj = new Driver(driver, [], org_driver_types);
  const driverType = driverObj.driverType;
  const { enteredUser, enteredAt, driverResponder } = getDriverEnteredData(decision, driver);

  return <>
    <ProvidedByBlock {...{driver, driverResponder, driverType, decision}} />
    {
      isDSightDecision(decision) ?
        <DSightAnsweredByBlock {...{driver, driverType, enteredUser, enteredAt}} />:
        <AnsweredByBlock {...{driver, enteredUser, enteredAt, driverResponder}} />
    }
    <DSightDriverBlock driver={driver} hideBlock={false} />
  </>
}

export const QuestionBlock = ({driver, subStyles = []}) => {
  if (isBlank(driver.question)) return null;

  return <View style={[subStyles]} id={driver.slug}>
    <Text style={[{overflow: 'hidden'}]}>{driver.question}</Text>
  </View>
}

export const NotAnswerBlock = ({driver, isLast = false}) => <View style={isLast ? [] : [...baseBlockMargin]}>
    <Text style={styles.muted}>N/A</Text>
  </View>

export const AnswerBlock = ({driver, isLast}) => {
  const driverObj = new Driver(driver);

  const DriverAnswer = () => {
    if (driverObj.isOpen || driverObj.isNumbering) {
      return <Html stylesheet={quillIndentStyles()} style={styles.text}>{modifyRichText(driver.answer)}</Html>
    } else if (driverObj.isTopChoice) {
      return <Text style={styles.text}>{driverObj.finalDecisions[0].description}</Text>
    } else if (driverObj.isPicky) {
      return driverObj.finalDecisions.length === 1 ?
        <Text style={styles.text}>{driverObj.finalDecisions[0].description}</Text>:
        <View>
          { driverObj.finalDecisions.map(c =>
            <View style={[styles.text]} key={`picky-choice-${c.slug}`}>
              {pdfTextParser(`${c.description}`, true)}
            </View>
          )}
        </View>
    }
  }

  return <View style={[styles.row, styles.text, {maxWidth: '95%'},  isLast ? '' : baseBlockMargin]}>
    <DriverAnswer />
  </View>
}

export const DetailsBlock = ({driver}) => {
  if (isBlank(driver.explanation)) return null;

  return <View style={[{paddingRight: 1}, ...baseBlockMargin]}>
    <Text style={[styles.h2]}>Details</Text>
    <View style={[styles.text]}>
      <Html stylesheet={quillIndentStyles()} style={ styles.text }>{modifyRichText(driver.explanation)}</Html>
    </View>
  </View>
}
export const ConfidenceBlock = ({driver}) => {
  if (isBlank(driver.confidence)) return null;

  return <View style={[{maxWidth: '95%'}, ...baseBlockMargin]}>
    <Text style={[styles.h2]}>Confidence&nbsp;</Text>
    <Text style={[styles.text]}>{driver.confidence} out of 5</Text>
  </View>
}
export const DriverDataSources = ({driver, driver_sources_slugs, data_sources = []}) => {
  const driverSourcesSlugs = driver_sources_slugs || [];
  const driverDataSources = data_sources.filter((source) => driverSourcesSlugs.includes(source.slug));
  if (isBlank(driverDataSources)) return null;

  const sortedDriverDataSources = qSortArray(driverDataSources, true,(o) => moment(o.created_at));
  return <View style={[...baseBlockMargin]}>
    <Text style={[styles.h2]}>Data Sources</Text>
    <DriverDataSourcesBlock data_sources={sortedDriverDataSources} sourceBlockKey={(data_source) => `driver-${driver.slug}-data-source-${data_source.slug}-${data_source.key}`} />
  </View>
}
export const DriverComments = ({driver, decision}) => {
  if (isBlank(driver.comments)) return null;

  return <View style={[...baseBlockMargin]}>
    <Text style={[styles.h2]}>Comments</Text>
    <CommentsBlock comments={driver.comments} decision={decision} commentBlockKey={(comment) => `driver-${driver.slug}-data-source-${comment.slug}-${comment.key}`} />
  </View>
}

export const CommentsBlock = ({ comments, decision, commentBlockKey = (_comment) => {} }) =>
  <View>
    { comments.map((comment, i) => <CommentBlock comment={comment} decision={decision} key={`${commentBlockKey(comment)}-${i}`} />) }
  </View>

export const CommentBlock = ({ comment, decision }) => {
  const commentedUser = decision.users.find(u => u.email === comment.user_email);
  return <Text style={[styles.row, {maxWidth: '95%'}]}>
    <Text style={[styles.textBold]}>
      {userName(commentedUser, comment.user_email)}:&nbsp;&nbsp;
    </Text>
    <Text style={[styles.text]}>
      {formatMessageText(comment, true)}
    </Text>
  </Text>
}

export const DataSourcesBlock = ({ data_sources, sourceBlockKey = (_data_source) => {} }) =>
  <View>
    { data_sources.map((data_source, index) => <DataSourceBlock data_source={data_source} index={index} key={sourceBlockKey(data_source)} />) }
  </View>

const DriverDataSourcesBlock = ({ data_sources, sourceBlockKey = (_data_source) => {} }) => {
  const sortedDataSources = sortSourceByType(data_sources, PDF_IMAGE_FILE_TYPES)
  return <View>
    {sortedDataSources.map((data_source, index) => {
      const sourceObj = new DataSource(data_source);
      return sourceObj.isPdfImage ?
        <DataSourceImage data_source={data_source} key={sourceBlockKey(data_source)} />:
        <DataSourceBlock data_source={data_source} index={index} key={sourceBlockKey(data_source)} />
    })}
  </View>
}

export const DataSourceImage = ({data_source, stylesView = [], isLast = false}) =>
  <View style={[stylesView, {marginTop: 4} , isLast ? styles.mb0 : {marginBottom: 4}]}>
    <Image style={imageStyles.source_image} src={protectedImageSrc(data_source.url)} />
  </View>

export const DataSourceBlock = ({data_source, index = 0}) => {
  if (isBlank(data_source)) return null;
  const sourceObj = new DataSource(data_source);
  const host = protocolHost();
  return <View style={[styles.row, {maxWidth: '95%'}]}>
    {
      sourceObj.isLinkedReport || sourceObj.isLinkedForecastScenario ?
        <Link src={sourceObj.isPdfImage ? `#${data_source.key}` : (host + data_source.link_url)} style={[styles.linkText, {lineHeight: 1.5}]}>
          {data_source.title || data_source.link_url}
        </Link> :
        sourceObj.isLink && !sourceObj.isDSight ?
        <Link src={sourceObj.isPdfImage ? `#${data_source.key}` : data_source.link_url} style={[styles.linkText, {lineHeight: 1.5}]}>
          {data_source.title || data_source.link_url}
        </Link> :
          sourceObj.isDSight ?
              <Link src={sourceObj.isPdfImage ? `#${data_source.key}` : (host + data_source.link_url)} style={[styles.linkText, {lineHeight: 1.5}]}>
                {data_source.title || data_source.link_url}
              </Link> :
              <Link src={sourceObj.isPdfImage ? `#${data_source.key}` : (host + data_source.url)} target='_self' style={[styles.linkText, {lineHeight: 1.5}]}>
                {sourceObj.fileName}
              </Link>
    }
    <Text style={[styles.pl1, styles.muted, styles.uppercase]}>
      {sourceObj.isFile ? `(${sourceObj.fileExtension})` : '' }
    </Text>
  </View>
}

export const DSightDriverBlock = ({ driver, hideBlock }) => {
  const driverObj = new Driver(driver);
  const insightsData = driverObj.insightsData
  if(isBlank(insightsData) || hideBlock) return null;

  return <View style={[...baseBlockMargin]}>
    <Text style={[styles.h2, styles.mb0]}>D-Sight analysis</Text>
    <DSightInsightsBlock insightsData={insightsData} />
  </View>
}

const DriverSubPage = ({decision, decisionObj, driver, driver_sources_slugs, data_sources= [], org_driver_types}) => {
  if (isBlank(driver.question)) return null;

  const driverObj = new Driver(driver, [], {}, decisionObj);
  const hideBlock = !!(driverObj.isCompleted);

  return <View>
    <QuestionBlock driver={driver} subStyles={[styles.h2]} />
    { driverObj.withEnteredAnswer && !driverObj.isDraft ? <AnswerBlock driver={driver} /> : <NotAnswerBlock driver={driver} />}
    <DetailsBlock driver={driver} />
    <ConfidenceBlock driver={driver} />
    <DriverTypeBlock org_driver_types={org_driver_types} driver={driver} decision={decision} />
    <DSightDriverBlock driver={driver} hideBlock={hideBlock} />
    <DriverComments driver={driver} decision={decision} />
    <DriverDataSources driver={driver} driver_sources_slugs={driver_sources_slugs} data_sources={data_sources} />
  </View>
}

const KeyDriverPage = ({ org, decisionObj, decision, driver, driver_sources_slugs, org_driver_types, children = [],
                         data_sources = [], format }) => {
  if (isBlank(driver) || isBlank(driver.question)) return null;

  return <Page size={format} style={styles.container}>
    <DocumentHeader description={driver.question} title={'Drivers'} src_id={'#drivers_page'} />
    <View style={styles.body}>
      <DriverSubPage {...{decision, decisionObj, driver, driver_sources_slugs, children, data_sources, org_driver_types}}
                     depth={1} />
    </View>
    <DocumentFooter org={org} />
  </Page>
}
export default KeyDriverPage;
