import React, { useState } from "react";
import CloseIcon from "../../common/CloseIcon";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SourceScenario from "../../forecast_simulator_scenario/side_panel/import_values/SourceScenario";
import {productScenariosOptions} from "../edit_launch";

export const TYPE = 'ImportExecutionParamsModal';

export const showImportExecutionParamsModal = (modal) => modal.shown && modal.type === TYPE;

const ImportExecutionParamsModal = ({ show, onClose }) => {
  const [selectedScenario, setSelectedScenario] = useState(productScenariosOptions[0]);
  const [submit, setSubmit] = useState(false);

  const onChange = () => {
    onClose();
  };

  return  <Modal size="md" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Change table settings</h2>
      <SourceScenario forecast_simulator_scenario={{ config_scenarios: productScenariosOptions }}
                      selectedScenario={selectedScenario}
                      setSelectedScenario={setSelectedScenario}/>
      <Button className="w-100 mt-3" onClick={onChange}>
        Import and run model
      </Button>
    </Modal.Body>
  </Modal>
}
export default ImportExecutionParamsModal;
