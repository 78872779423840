import React, { useState, useEffect, useMemo } from "react";
import { BaseDropdownBtn } from "../../common/BaseHamburgerBtn";
import { BtnDropdownToggle } from "../../common/dropdowns";
import SearchIcon from "../../homepage/tabs/filters/SearchIcon";

const ToggleBtn = ({ options = [], currentOption = null, onSelect = () => {}, showInput = false, labelClassName = 'fw-normal text-primary' }) => {
  const focusElement = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(currentOption);

  useEffect(() => {
    if (showInput && focusElement.current) focusElement.current.focus()
  }, [isOpen, showInput, focusElement])

  const filteredOptions = useMemo(() => {
    return options.filter((option) => option.label?.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [options, searchTerm]);

  const handleToggle = () => setIsOpen((prev) => !prev);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedOption(currentOption);
  }, [currentOption, options]);

  const title = options.find((option) => option.value === selectedOption.value)?.label;

  if (options.length === 1) {
    return <div className={`d-inline-block my-auto ${labelClassName} fw-medium`}>{selectedOption?.label}</div>;
  }

  return (
    <div className="d-inline-block">
      <BtnDropdownToggle
        id={`product-scenario-${selectedOption.value}`}
        bsPrefix={`px-0 ${labelClassName}`}
        title={title}
        onToggle={handleToggle}
        align={'start'}
      >
        <div style={{maxHeight: '500px'}} className="overflow-auto">
          {showInput && (
            <div className="position-sticky top-0 px-2 pt-1 pb-2 bg-white">
              <SearchIcon style={{ top: '22px', right: '28px' }} />
              <input
                ref={focusElement}
                className="form-control border-rounded driver-block"
                placeholder="Search scenarios"
                onChange={(e) => setSearchTerm(e.target.value)}
                autoFocus={true}
              />
            </div>
          )}
          {filteredOptions.map((option) => (
            <BaseDropdownBtn
              key={option.value}
              title={option.label}
              bsPrefix={`py-2`}
              onClick={() => handleSelect(option)}
              active={selectedOption.value === option.value}
            >
              {option.label}
            </BaseDropdownBtn>
          ))}
        </div>
      </BtnDropdownToggle>
    </div>
  );
};

export default ToggleBtn;
