import React, { useState } from "react";
import CloseIcon from "../../common/CloseIcon";
import Modal from "react-bootstrap/Modal";
import ComparisonValues from "../../forecast_simulator_scenario/side_panel/table_settings/ComparisonValues";
import { COMPARISON_INITIAL_OPTIONS, COMPARISON_OPTION_IDS } from "../../forecast_simulator_scenario/side_panel/ChangeTableSettingsPanel";
import Button from "react-bootstrap/Button";

export const TYPE = 'TableSettingsModal';

export const showTableSettingsModal = (modal) => modal.shown && modal.type === TYPE;

const TableSettingsModal = ({ show, onClose }) => {
  const [selectedCompValues, setSelectedCompValues] = useState(COMPARISON_INITIAL_OPTIONS);
  const [submit, setSubmit] = useState(false);

  const onChange = () => {
    onClose();
  };

  return  <Modal size="md" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Change table settings</h2>
      <ComparisonValues selectedCompValues={selectedCompValues}
                        setSelectedCompValues={setSelectedCompValues}
                        options={COMPARISON_OPTION_IDS}
                        submit={submit}
      />
      <Button className="w-100 mt-3" onClick={onChange}>
        Update table
      </Button>
    </Modal.Body>
  </Modal>
}
export default TableSettingsModal;
