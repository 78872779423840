// Common functions
import {LOAD_DECISION_AI_FAILED, LOAD_DECISIONS_FAILED, RESET_DECISIONS_DATA, UPDATE_DECISIONS_DATA} from "./types";

export const updateDecisionData = (data) => ({
  type: UPDATE_DECISIONS_DATA,
  payload: {
    ...data
  }
});

export const loadDecisionsFailure = error => ({
  type: LOAD_DECISIONS_FAILED,
  payload: { error }
});

export const loadDecisionAiFailure = errorAi => ({
  type: LOAD_DECISION_AI_FAILED,
  payload: { errorAi }
});

export const resetDecisionState = () => ({ type: RESET_DECISIONS_DATA });
