import React, { useEffect, useRef, useState } from "react";
import { isBlank, isPresent } from "../../helpers/common";
import Select from "react-select";
import { BorderedFilterStyle } from "../../tree_wizard/styles/choice_entry_styles";
import CurrencyInput from "../../common/CurrencyInput";
import CharCounter from "../../common/CharCounter";
import { DISPLAY_NAME_LIMIT } from "../../models/forecast/ForecastScenario";
import { RichTextEditor } from "../../common/RichTextEditor";
import DecisionDate from "../../common/DecisionDate";
import moment from "moment/moment";

const NpfScenarioForm = ({
                           selectedConfig,
                           formData, setFormData,
                           cmus, setCmus,
                           loading = false, disabledCmus = [],
                           submit, shown,
                           errors, setErrors
                         }, ref) => {
  const focusElement = useRef(null);
  const [showCharCounter, setShowCharCounter] = useState(false);

  useEffect(() => {
    if (isBlank(selectedConfig)) return;

    const cmuValues = Object.values(cmus).filter(isPresent)
    const availableCmus = selectedConfig.cmus.filter(cmuRecord => cmuRecord.includeCmus(cmuValues))
    if (availableCmus.length === 1) {
      handleInputChange('cmu', availableCmus[0].id)
    } else {
      handleInputChange('cmu', null)
    }
  }, [selectedConfig, cmus])
  useEffect(() => {
    if (shown && focusElement.current) {
      focusElement.current.focus();
    }
  }, [shown]);

  const handleInputChange = (field, value) => { setFormData((prev) => ({ ...prev, [field]: value })); };
  const handleAddCategory = (field, value) => {
    setFormData((prev) => ({ ...prev, categories: { ...prev.categories, [field]: value } }));
  }

  const handleSelectChange = (column, option) => {
    handleAddCategory(column.id, option?.value || '');
    if (option?.value) setErrors(prev => ({ ...prev, [column.name]: null }))
  };

  const handleSelectCmuChange = (column, option) => {
    setCmus(prev => ({ ...prev, [column.id]: option?.value || null }))
    if (option?.value) setErrors(prev => ({ ...prev, [column.name]: null }))
  };

  const renderSelectField = (column) => {
    let options = column.valuesOptions,
      onChange = handleSelectChange,
      value = (formData.categories || {})[column.id]
    if (column.isPartOfCmu) {
      const anotherCmus = Object.values({ ...cmus, [column.id]: null }).filter(isPresent)
      onChange = handleSelectCmuChange
      options = isBlank(anotherCmus) ? options : options.filter(opt =>
        selectedConfig.cmus.some(cmuRecord => cmuRecord.includeCmus([ ...anotherCmus, opt.value ]))
      )
      value = cmus[column.id]
    }

    return <div className="w-100 mb-3" key={column.id}>
      <h3>{column.displayName}</h3>
      <Select
        classNames={{
          control: (_) =>
            errors[column.name] ? 'is-invalid' : ''
        }}
        isClearable
        value={options.find((opt) => opt.value === value) || null}
        onChange={(option) => onChange(column, option)}
        components={{ IndicatorSeparator: () => null }}
        placeholder="Make a selection"
        isDisabled={loading || disabledCmus.includes(column.id)}
        options={options}
        styles={BorderedFilterStyle}
      />
    </div>
  };

  const renderInputField = (label, field, key = '') =>
    <div className="w-100 mb-3" key={key}>
      <h3>{label}</h3>
      <input
        className={`form-control ${errors[field] ? 'is-invalid' : ''}`}
        placeholder={`Enter the ${label}`}
        value={formData[field] || ''}
        onChange={(e) => handleInputChange(field, e.target.value)}
        disabled={submit}
      />
    </div>

  const renderNumberField = (input) =>
    <div className="w-100 mb-3" key={input.id}>
      <h3>{input.displayName}</h3>
      <CurrencyInput
        key={input.id}
        onChange={(value) => { handleAddCategory(input.id, value || '') }}
        className={`form-control ${errors[input.id] ? 'is-invalid' : ''}`}
        placeholder="Enter an amount"
        name={input.name}
        id={input.id}
        value={formData[input.id] || ''}
      />
    </div>

  const renderDateFields = (input) =>
    <div className="w-100 mb-3" key={input.id}>
      <DecisionDate
        id={input.id}
        name={input.name}
        minDate={new Date()}
        header={input.displayName}
        date={formData[input.name.toLowerCase()] || moment()}
        onChangeDate={(date) => { handleInputChange(input.name.toLowerCase(), moment(date)) }}
        className={`form-control ${errors[input.name] ? 'is-invalid' : ''}`}
      />
    </div>

  const isInvalid = () => {
    const newErrors = {}
    if (isBlank(formData.display_name)) newErrors.display_name = 'Required';
    if (isBlank(formData.cmu)) newErrors.cmu = 'Required';
    selectedConfig?.cmuColumns?.filter(col => col.isVisible)?.forEach(field => {
      if (field.isPartOfCmu) {
        if (isBlank(cmus[field.id])) newErrors[field.name] = 'Required';
      } else {
        if (isBlank((formData.categories || {})[field.id])) newErrors[field.name] = 'Required';
      }
    })
    selectedConfig?.scenarioInputs?.filter(i => i.isVisible && i.isCustomField).forEach(field => {
      if (field.isDate) {
        if (isBlank(formData[field.name.toLowerCase()])) newErrors[field.name] = 'Required';
      } else {
        if (isBlank((formData.categories || {})[field.name])) newErrors[field.name] = 'Required';
      }
    })

    return newErrors;
  }

  const validate = () => {
    if (loading) return true;

    const newErrors = isInvalid()
    setErrors(newErrors)
    if (isPresent(newErrors)) {
      console.log({
        newErrors, formData, cmus
      })
    }

    return isBlank(newErrors)
  }

  if (ref) ref.current = { validate, isInvalid }

  return (
    <>
      <div className="w-100 mb-3">
        <h3>Name</h3>
        <CharCounter
          show={showCharCounter}
          field={formData.display_name}
          limit={DISPLAY_NAME_LIMIT}
        />
        <input
          className={`form-control ${errors.display_name ? 'is-invalid' : ''}`}
          placeholder="Enter the name"
          ref={focusElement}
          value={formData.display_name || ''}
          maxLength={DISPLAY_NAME_LIMIT}
          onChange={(e) => handleInputChange('display_name', e.target.value)}
          onBlur={() => setShowCharCounter(false)}
          onFocus={() => setShowCharCounter(true)}
          disabled={submit}
        />
      </div>

      {
        loading ? <div>Loading...</div> :
          <>
            {
              selectedConfig?.cmuColumns?.filter(i => i.isVisible)?.map(renderSelectField)
            }
            {
              selectedConfig?.scenarioInputs?.filter(i => i.isVisible && i.isCustomField)?.map(input => {
                if (input.isNumber) {
                  return renderNumberField(input)
                } else if (input.isDate) {
                  return renderDateFields(input)
                } else {
                  return renderInputField(input.displayName, input.name, input.id)
                }
              })
            }
          </>
      }

      <div className="w-100 mb-3">
        <h3>Notes</h3>
        <RichTextEditor
          quillId="ProductScenarioNotes"
          value={formData.notes}
          setValue={(value) => handleInputChange('notes', value)}
          placeholder="Enter any notes for this scenario"
          disabled={submit}
        />
      </div>
    </>
  );
}

export default React.forwardRef(NpfScenarioForm)
