import React, {useMemo} from "react";
import {connect} from "react-redux";
import { HowToMakeDecisionPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import AssignmentSection from "./../sections/AssignmentSection";
import {isBlank, isPresent} from "../../../helpers/common";
import Decision from "../../../models/decision";
import {isShownGptButtons} from "../recommendation_input/AssignedToSection";
import AiResponseButton, {filterAndSortDataSources, getFilteredDataSources} from "../driver_input/AiResponseButton";

const AssignedToSection = ({sidebar, playbook_notes, tree, current_org, dataSources}) => {
  if(isBlank(sidebar.decision)) return null;

  const decisionObj = new Decision(sidebar.decision, sidebar.drivers);
  const assignedUser = useMemo(() => decisionObj.decisionAssignedUser, [sidebar.decision])
  const assignedCollaboratorEmail = useMemo(() => sidebar.decision?.assigned_collaborator_email, [sidebar.decision])
  const shownGptButtons = isShownGptButtons(sidebar.decision, current_org, decisionObj, tree.drivers, dataSources, 'decision')

  return <div className={`px-3 ${isPresent(playbook_notes.how_to) || isPresent(assignedUser || isPresent(assignedCollaboratorEmail) || shownGptButtons) ? 'border-bottom mb-3' : ''}`}>
    <AssignmentSection assignedUser={assignedUser} assignedCollaboratorEmail={assignedCollaboratorEmail} modalType='DecisionAssignModal' />
    <HowToMakeDecisionPBSection playbook_notes={playbook_notes} showNA={false} isDecision={true}/>
    <div className='mb-2'>
      <AiResponseButton {...{driverData: tree.drivers, type: 'decision', shownGptButtons }} />
    </div>
  </div>
}

const mapStateToProps = ({ sidebar, playbook_notes, tree, current_org }) => {
  const allDataSources = (tree.data_sources) || [];
  const allowedDataSources = filterAndSortDataSources(allDataSources);
  const dataSources = getFilteredDataSources(allowedDataSources, 'hide_in_decision_panel')

  return {
    sidebar, playbook_notes, tree, current_org, dataSources
  };
};
export default connect(mapStateToProps, {})(AssignedToSection);
