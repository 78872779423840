import React, { useState } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import {isCollaborator, isDecider, isVisitor} from "../../../helpers/user_helpers";
import Button from 'react-bootstrap/Button';
import Decision from "../../../models/decision";
import Recommendation from "../../../models/recommendation";
import { copyChoices, setDecisionInputSidebarOpen } from "../../../store/sidebar/actions";
import {openModal} from "../../../store/modals/actions";
import {
  decisionEditingByUser,
  isDecisionInEditModeByOthers
} from "../../../helpers/channel_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../../../channels/tree_channel";
import { DisabledDecisionButton } from "../../header/buttons/RenderDecisionInputSidebar";

const WhatWasDecidedButton = ({ channels, decision, user, onDecisionEntryOpen }) => {
  if(isDecisionInEditModeByOthers(channels, TREE_CHANNEL_ACTIONS.edit_decision_input, decision.slug, user)) {
    const editingByUser = decisionEditingByUser(channels, TREE_CHANNEL_ACTIONS.edit_decision_input, decision)
    return <DisabledDecisionButton editingByUser={editingByUser} buttonText={'Enter what was decided'} classes={'mb-2'} />
  } else {
    return <Button onClick={onDecisionEntryOpen} className="btn btn-secondary w-100 mb-2">Enter what was decided</Button>
  }
}

const RecordRecommendationActionsSection = ({
                                              user, decision, channels,
                                              setDecisionInputSidebarOpen, copyChoices, setShowEnterApprovalModal,
                                              openModal
                                            }) => {
  if (!isDecider(user) && !isCollaborator(user)) return null;
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;

  const decisionObject = new Decision(decision);
  const recommendationObject = new Recommendation(decision.recommendation);

  const editFollowup = decisionObject.isDecisionWithResults

  const onDecisionEntryOpen = () => {
    if(isBlank(decisionObject.decisionChoices) && !recommendationObject.isOpenEnded) {
      copyChoices((success) => {
        if (success) setDecisionInputSidebarOpen(true)
      })
    } else {
      setDecisionInputSidebarOpen(true)
    }
  }

  return <div className="px-3 mb-3">
    <Button onClick={() => openModal({ type: 'RequestApprovalModal'})} className="btn btn-secondary w-100 mb-2">Request approval</Button>
    <div hidden={decisionObject.isWithApprovalInvites || decisionObject.isWithApproval || isVisitor(user)} className={'mb-2'}>
      <Button onClick={() => openModal({ type: 'EnterApprovalModal'})} className="btn btn-secondary w-100">
        Enter approval
      </Button>
    </div>
    <WhatWasDecidedButton channels={channels} decision={decision} user={user} onDecisionEntryOpen={onDecisionEntryOpen} />
    <div hidden={editFollowup}>
      <Button onClick={() => openModal({ type: 'FollowupResultsModal'})} className="btn btn-secondary w-100">Enter the results & actions taken</Button>
    </div>
  </div>
};
const mapStateToProps = ({ current_user, decision, channels }) => ({
  decision, user: current_user, channels
});
export default connect(mapStateToProps, { setDecisionInputSidebarOpen, copyChoices, openModal})(RecordRecommendationActionsSection);
