import { currencyFormat, isBlank, isPresent } from "../../../helpers/common";
import { ForecastBaseModel } from "../Base";

const TYPES = {
  concept: 'ConceptField',
  scenario: 'ScenarioField',
  featureFields: 'VirtualField'
}
export const npfColumnFactory = (data, config) => {
  switch (data.attributes.type) {
    case TYPES.featureFields:
      return new NpfScenarioFeatureColumn(data, config);
    case TYPES.scenario:
      if (data.attributes.v_opt?.type === 'output') return new NpfScenarioConceptOutput(data, config);
      return new NpfScenarioConceptColumn(data, config);
    case TYPES.concept:
      if (data.attributes.section === 'scenario') return new NpfScenarioColumn(data, config);

      const record = new NpfConceptColumn(data, config)
      if (record.isPackagedField) return new NpfConceptPackageColumn(data, config)

      return record;
    default:
      return new NpfColumn(data, config);
  }
}

export class NpfColumn extends ForecastBaseModel {
  constructor(data, config) {
    super(data)
    this.config = config;
  }

  get displayName() {
    return this.attributes.name;
  }

  get name() {
    return this.attributes.name;
  }

  get sortOrder() {
    return parseInt(this.attributes.sort_order);
  }

  get coefficientColumn() {
    return null;
  }

  get driverRules() {
    return this.attributes.dr_rul;
  }

  get selectHash() {
    return { value: this.id , label: this.selectName }
  }

  get metricSelectHash() {
    return { value: this.id , label: this.metricSelectName }
  }

  get selectName() {
    return [this.displayName, this.measure].filter(isPresent).join(', ');
  }

  get metricSelectName() {
    return [this.displayName, this.measure].filter(isPresent).join(', ');
  }

  visibleFor(_timeScale) {
    return false
  }

  get _viewOptions() {
    return this.attributes.v_opt || {};
  }

  get viewType() {
    return this._viewOptions.type;
  }

  get dataType() {
    return this._viewOptions.data_type;
  }

  get isVisible() {
    return this._viewOptions.visible;
  }
}

export class NpfScenarioColumn extends NpfColumn {

  visibleFor(_timeScale) {
    return true;
  }

  get isPartOfCmu() {
    if (this.__isPartOfCmu == null) {
      this.__isPartOfCmu = this._checkIsPartOfCmu();
    }
    return this.__isPartOfCmu;
  }

  fillValues(values) {
    this.values = values.filter(({ attributes }) => parseInt(attributes.field_id) === this.id).reduce((res, hash) => {
      res[hash.id] = hash.attributes.value;
      return res;
    }, {})
  }

  get cmuIds() {
    return Object.keys(this.values)
  }

  get displayName() {
    return this.attributes.display_name;
  }

  get valuesOptions() {
    return this.cmuIds.map((id) => ({ value: id, label: this.values[id] })) || []
  }

  get isCustomField() {
    if (this.viewType === 'identificator') return false;

    return true
  }

  get isNumber() {
   return this.dataType === 'numeric'
  }

  get isDate() {
    return this.dataType === 'date'
  }

  _checkIsPartOfCmu() {
    if (isBlank(this.values)) return false;

    return this.cmuIds.some(id =>
      this.config.cmus.some(cmu => cmu.includeCmu(id))
    );
  }
}

export class NpfConceptColumn extends NpfColumn {

  visibleFor(_timeScale) {
    return true;
  }

  fillValues(cmuValues) {
    this.values = cmuValues.filter(({ attributes }) => parseInt(attributes.field_id) === this.id).reduce((res, hash) => {
      res[hash.id] = hash.attributes.value;
      return res;
    }, {})
  }

  get isPackagedField() {
    return this.name.includes('Packaging_');
  }

  get displayName() {
    return this.attributes.display_name;
  }
}

export class NpfConceptPackageColumn extends NpfConceptColumn {

  formatValue(value) {
    if (this.isCurrency) return currencyFormat(value)
    if (this.isSKU) return `${value} SKU`
    if (this.isSize) return `${value} oz`
    return value;
  }

  formatForList(value) {
    if (this.isCurrency) return ` for ${this.formatValue(value)}`
    if (this.isSKU) return `, ${this.formatValue(value)}`
    if (this.isCount) return ` x ${this.formatValue(value)}`

    return this.formatValue(value);
  }

  get isSKU() {
    return this.name.includes('SKU')
  }

  get isSize() {
    return this.name.includes('Size')
  }

  get isCount() {
    return this.name.includes('Count')
  }

  get isPackagedField() {
    return true;
  }

  get placeholderName() {
    return this.displayName.toLowerCase();
  }

  get defaultValue() {
    return this._viewOptions.default;
  }

  get isPositive() {
    return this._viewOptions.restriction === '> 0';
  }

  get isNumber() {
    return this.dataType === 'numeric'
  }

  get isCurrency() {
    return this.dataType === 'currency' || this.name.includes('Price')
  }

  get placeholder() {
    return this.attributes.placeholder || this._viewOptions.placeholder || `Enter a ${this.placeholderName}`;
  }
}

export class NpfScenarioConceptColumn extends NpfScenarioColumn {
  get metric() {
    return this._viewOptions.metric;
  }

  visibleFor(timeScale) {
    return this._visibility[timeScale.key].toString() === 'true';
  }

  get _visibility() {
    return this.attributes.vis || {};
  }
}

export class NpfScenarioConceptOutput extends NpfScenarioConceptColumn {
  get displayNameWithMetric() {
    return [this.displayName, this.metric].filter(isPresent).join(', ');
  }
}

export class NpfScenarioFeatureColumn extends NpfScenarioColumn {
  isShownFor(cmuValues) {
    return !this.cmus.some(cmu => !cmuValues.includes(cmu))
  }

  get isMultiSelect() {
    return this.viewType === 'multi'
  }

  get isSingleSelect() {
    return this.viewType === 'single'
  }

  get cmus() {
    return this._viewOptions.cmus || []
  }

  get parentColumn() {
    return this.config.conceptFields.find(field => field.id === this._parentId)
  }

  get sortOrder() {
    return [this.parentColumn?.sortOrder,parseInt(this.attributes.sort_order)];
  }

  get defaultOption() {
    // TODO: clarify if some default attributes needed
    // if (this.defaultRule === 'First from Value lst') {
    //   return this.valuesOptions[0]
    // }
    return null
  }

  get _parentId() {
    return this.attributes.parent_field_id;
  }

  get defaultRule() {
    return this._viewOptions.default
  }
}
