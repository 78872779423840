import React, { useCallback, useEffect } from "react";
import {connect} from "react-redux";
import {deleteWarning} from "../../helpers/alert_helpers";
import AccordionWrapper from "../../common/AccordionWrapper";
import ProductConceptAttributes from "../components/ProductConceptAttributes";
import ConceptActionsDropdown from "../components/ConceptActionsDropdown";
import ProductConceptPackaging from "../components/ProductConceptPackaging";
import YearlyTotals from "../components/YearlyTotals";
import Button from "react-bootstrap/Button";
import { isBlank, isPresent, successActions } from "../../helpers/common";
import {
  removeScenarioConcept,
  updateConceptViewOptions,
  updateScenarioData
} from "../../store/npf_simulator_scenario/actions";
import { openModal } from "../../store/modals/actions";
import { deepEqual } from "../../helpers/store_helpers";
import ConceptAttributesVariation from "../components/ConceptAttributesVariation";
import ConceptPackagesVariation from "../components/ConceptPackagesVariation";
import { useDebouncedCallback } from "use-debounce";

const Header = ({
                  concept,
                  deleteCallback, disableDelete = false,
                  onCreateConcept = () => {},
                  onRunModel = () => {}
                }) =>
  <div className="d-flex flex-row justify-content-between align-items-end mb-2">
    <h1 className="m-0">Product concept</h1>
    <div className={`buttons-container d-flex justify-content-end ms-auto`}>
      {
        !concept ?
          <Button onClick={onCreateConcept} className="me-2">New concept</Button> :
          <>
            <div className="d-flex">
              <Button onClick={onRunModel} className="me-2 light-gray-disabled" disabled={true} title='To be implemented'>Run model</Button>
            </div>
            <div className="d-flex">
              <ConceptActionsDropdown  btnId="concept-actions-dropdown" {...{deleteCallback, disableDelete}} />
            </div>
          </>
      }
    </div>
  </div>

const Attributes = ({
                      conceptVariation, config, concept, npfScenario,
                      disabled = false, displayVariants = true,
                      attributes = {},
                      onSetAttribute = () => {}
                    }) => {
  const attributeFields = config.featuresFields.filter(f => f.isShownFor(npfScenario.cmuValues))
  if (isBlank(attributeFields)) return null;

  return <div className="w-100 bg-white rounded p-3">
    <h3>Attributes</h3>
    <ProductConceptAttributes attributes={attributes} attributeFields={attributeFields} concept={concept} onSetAttribute={onSetAttribute} disabled={disabled} />
    {
      displayVariants && <AccordionWrapper accordionObject={conceptVariation} className='w-100' />
    }

  </div>
}

const Packaging = ({
                     conceptVariation, packages, config, concept,
                     onSetPackage, setPackages,
                     disabled = false, displayVariants = true
                   }) => {
  const pkgFields = config.packagesColumns
  if (isBlank(pkgFields)) return null;

  return <div className="w-100 bg-white rounded p-3">
    <h3>Packaging</h3>
    <ProductConceptPackaging {...{ pkgFields, packages, concept, onSetPackage, setPackages, disabled }} />
    {
      displayVariants && <AccordionWrapper accordionObject={conceptVariation} className='w-100' />
    }
  </div>
}


const ProductConceptTab = ({
                             concept, config, npfScenario,
                             npf_simulator_scenario,
                             updateViewOptions, openModal, removeScenarioConcept,
                             updateScenarioData, updateConceptViewOptions,
                             ...opts
                           }) => {
  const [attributes, setAttributes] = React.useState({})
  const [packages, setPackages] = React.useState({})
  const [reLoadPackages, setReLoadPackages] = React.useState(false)

  const defaultEmptyPackage = useCallback(() =>
      config.packagesColumns.reduce((acc, column) => {
        acc[column.id] = ''
        return acc
      }, {})
    ,[config])
  const conceptPackages = useCallback(() => {
    const result = {}
    concept?.packagesData?.forEach((row, index) => {
      result[index] = row
    })
    return result;
  }, [concept])
  const displayVariants = npf_simulator_scenario.scenario_concepts.length > 1

  useEffect(() => {
    if (concept) {
      setAttributes(concept.attributesData)
    }
  }, [concept])
  useEffect(() => {
    if (config.packagesColumns?.length > 0 && isBlank(packages) && !concept) {
      const newPackages = isPresent(concept?.packagesData) ? conceptPackages() : { '0': defaultEmptyPackage() }
      setPackages(newPackages)
    }
  }, [config.packagesColumns?.length > 0, concept && concept.id, packages])
  useEffect(() => {
    if (concept) {
      const newPackages = isPresent(concept?.packagesData) ? conceptPackages() : { '0': defaultEmptyPackage() }
      setPackages(newPackages)
      if (concept?.scenario.conceptViewData(concept) == null && npfScenario.conceptViewData(concept) != null) {
        setTimeout(() => { setReLoadPackages(true) }, 30)
      }
      if (reLoadPackages) setReLoadPackages(false)
    }
  }, [concept && concept.id, reLoadPackages]);

  const _updatePackageViewIOptions = useDebouncedCallback((packages) => {
    const newPackages = [...Object.values(packages)]
    if (!concept || deepEqual(newPackages, concept.packagesData)) return;
    updateConceptViewOptions(concept.id, {
      packages: newPackages
    }, () => {}, false)
  }, 300);

  const deleteCallback = () =>
    deleteWarning(confirmed => {
      if (!confirmed) return;

      removeScenarioConcept(npf_simulator_scenario.scenario_id, concept.id, (success, errors) => {
        successActions(success, []);
      })
    }, { custom_description: 'delete this concept permanently from this scenario' })

  const onCreateConcept = () => {
    openModal({ shown: true, type: 'CreateScenarioConceptModal' })
  }

  const matchAttributes = (e, concept, conceptVariant) => {
    e.preventDefault()
    const newAttributes = { ...attributes }
    config.featuresFields.forEach(f => {
      if (!f.isShownFor(npfScenario.cmuValues)) return;
      if (isBlank(conceptVariant.attributesData[f.id])) return;

      newAttributes[f.id] = conceptVariant.attributesData[f.id]
    })
    updateConceptViewOptions(concept.id, {
      attributes: newAttributes
    }, (success, errors) => {
      if (success) {
        setAttributes(prev => ({
          ...prev, ...newAttributes
        }))
      }
    })
    return false;
  }

  const matchPackages = (e, concept, conceptVariant) => {
    e.preventDefault()

    const newPackages = [
      ...conceptVariant.presentPackages,
      ...concept.pendingPackages
    ]
    updateConceptViewOptions(concept.id, { packages: newPackages }, (success) => {
      if (success) setTimeout(() => { setReLoadPackages(true) }, 50)
    })
    return false;
  }

  const conceptAttrsVariation = {
    header: <h3 className="mb-0">Concept variation</h3>,
    body: <ConceptAttributesVariation {...{ config, concept, npfScenario, npf_simulator_scenario, matchAttributes }} />,
    defaultExpand: false
  }

  const conceptPkgVariation = {
    header: <h3 className="mb-0">Concept variation</h3>,
    body: <ConceptPackagesVariation {...{ config, concept, npfScenario, npf_simulator_scenario, matchPackages }} />,
    defaultExpand: false
  }

  const onSetAttribute = (opts, concept, field) => {
    const values = opts?.length ? opts.map(opt => opt.value) : opts?.value
    updateConceptViewOptions(concept.id, {
      attributes: {
        ...attributes, [field.id]: values
      }
    }, (success, errors) => {
      if (success) {
        setAttributes(prev => ({
          ...prev, [field.id]: values
        }))
      }
    })
  }
  const onSetPackage = (value, concept, field, pkgIndex) => {
    setPackages(prev => {
      const newRows = {
        ...prev,
        [pkgIndex]: {
          ...prev[pkgIndex],
          [field.id]: value
        }
      }
      setTimeout(() => { _updatePackageViewIOptions(newRows) }, 150)
      return newRows
    })
  }

  const onSetPackages = (callback) => {
    setPackages(prev => {
      const newPkgs = callback(prev)
      _updatePackageViewIOptions(newPkgs)
      return newPkgs;
    })
  }

  return <div className="container-xl px-3 mt-2 product-forecast-container">
    <div className="mb-3">
      <Header {...{concept, deleteCallback, onCreateConcept, disableDelete: npf_simulator_scenario.scenario_concepts.length < 2}}/>
      {
        isBlank(concept) ? <div className="alert alert-info">Create a new concept to start</div> :
          <div className="d-flex flex-column flex-lg-row">
            <div  className="flex-grow-1">
              <div className="row g-3">
                <div className="col-12">
                  <Attributes {...{
                    conceptVariation: conceptAttrsVariation,
                    attributes, config, concept, npfScenario, onSetAttribute,
                    disabled: npf_simulator_scenario.view_loading, displayVariants
                  }}/>
                </div>
                <div className="col-12">
                  <Packaging {...{
                    conceptVariation: conceptPkgVariation,
                    packages, config, concept, onSetPackage, setPackages: onSetPackages,
                    disabled: npf_simulator_scenario.view_loading, displayVariants
                  }}/>
                </div>
              </div>
            </div>
            <YearlyTotals {...{ config, concept }} />
          </div>
      }
    </div>
  </div>
}


const mapStateToProps = ({ modal, sidebar, npf_simulator_scenario }) => ({ modal, sidebar, npf_simulator_scenario });
export default connect(mapStateToProps, { updateConceptViewOptions, removeScenarioConcept, openModal, updateScenarioData })(ProductConceptTab)
