import React, {useEffect, useRef, useState} from 'react';
import { connect } from "react-redux";
import { isPresent } from "../../helpers/common";
import Decision, { SUPPORT_VALUES } from "../../models/decision";
import Modal from "react-bootstrap/Modal";
import { saveUserSupport } from "../../store/decision/actions";
import { ModalBodyWithClose, ModalDoneFooterP3 } from "../../common/modals";
import { JoditTextEditor } from '../../common/JoditTextEditor';

export const setRatingScaleCallback = (inputRef, shown, rating, setRating, isStringType = true) => {
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.addEventListener('click', onChangeRating)
      return () => {
        inputRef?.current?.removeEventListener('click', onChangeRating)
      }
    }
  }, [inputRef.current, shown, rating])

  const onChangeRating = () => {
    const currentRating = isStringType ? inputRef.current.value : parseInt(inputRef.current.value)
    if (rating && currentRating === rating) {
      setRating(null)
    } else {
      setRating(currentRating)
    }
  }
}

const SupportValuesBubble = ({
                               supportData, index, array,
                               shown, support, setSupport
                            }) => {
  const isFirst = index === 0
  const isLast = index === array.length - 1
  const inputRef = useRef(null)

  setRatingScaleCallback(inputRef, shown, support, setSupport, false)

  return <div className={`rating-circle ${supportData.className} ${support === supportData.value ? 'active' : (isPresent(support) ? 'non-active' : '')}`} key={`user-support-${index}`}>
    <input type="radio" name="radio-group" ref={inputRef}
           id={`${supportData.className}id`}
           className={`pointer bg-transparent ${isFirst ? 'first' : isLast ? 'last' : ''}`}
           value={supportData.value} />
    <label className="pointer" htmlFor={`${supportData.className}id`}>
      {supportData.value}
    </label>
  </div>
}

export const openRateBuyInModal = ({ decision, openModal }) => openModal({ type: 'RateBuyInModal', decision, slug: decision.slug });

const RateBuyInModal = ({decision, current_user, shown, onClose = () => {}, saveUserSupport}) => {
  const decisionObject = new Decision(decision)
  const userSupport = decisionObject.findSupportForUser(current_user)
  const [support, setSupport] = useState(userSupport?.support)
  const [supportComment, setSupportComment] = useState(userSupport?.support_comment || '')
  const [submit, setSubmit] = useState(false)

  const reset = () => {
    setSupport(userSupport?.support)
    setSupportComment(userSupport?.support_comment || '')
    onClose()
  }

  const submitData = () => {
    setSubmit(true)
    saveUserSupport({ user_support: { support, support_comment: supportComment } }, (success) => {
      setSubmit(false)
      if (success) onClose();
    })
  }
  useEffect(() => {
    setSupport(userSupport?.support);
    setSupportComment(userSupport?.support_comment || '');
  }, [shown]);

  return <Modal enforceFocus={false} size="lg" backdrop="static" show={shown} onHide={reset}>
    <ModalBodyWithClose bsPrefix="rate-support-modal" onClose={reset} header="Rate your level of support">
      <h3>On a scale from 1 to 5, how likely are you to support this decision going forward?</h3>
      <div className="mb-3">
        <div className="radio-pillbox w-100">
          <div className="radio-container d-flex justify-content-between">
            {
              SUPPORT_VALUES.map((supportData, index, array) =>
                <SupportValuesBubble {...{ supportData, index, array, shown, support, setSupport }} key={`user-support-${index}-fragment`} />
              )
            }
          </div>
        </div>
      </div>
      <div>
        <h3>Briefly explain your level of support. <span className="text-muted h5 fw-normal">(Optional)</span></h3>
        <JoditTextEditor className="jodit-container-default"
                         value={supportComment}
                         placeholder="Enter your comments"
                         setValue={setSupportComment}
                         autofocus={false} />
      </div>
    </ModalBodyWithClose>
    <ModalDoneFooterP3 disabled={submit} onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({ decision, current_user }) => ({ decision, current_user });
export default connect(mapStateToProps, { saveUserSupport })(RateBuyInModal);
