import React from "react";
import ToggleSubDrivers from "./ToggleSubDrivers";
import { EditDriverIcon } from "../../modals/DriverShowModal";
import Description from "../decision/Description";
import CompleteDriverDescription from "./CompletedDriverDescription";
import {isVisitor} from "../../../helpers/user_helpers";
import { isNil } from "ramda";
import { LockedEditRoundButton } from "../../../common/LockedEditRoundButton";

export default ({ user, driverData, driverObject, childrenDrivers, setShowChildren, onExpandDriverClick = () => {},
                  onEditClick = () => {}, decision, expanded = false, editingByUser,
                  editingByOthers = false, hideEdit
}) => {
  const EditButton = () => {
    if(editingByOthers) {
      return <div className={`ms-auto mt-2-negative me-2-negative`}>
        <LockedEditRoundButton classNames={'py-1'}
                               editingByUser={editingByUser} objectText={'driver'}
                               decision={decision}/>
      </div>
    } else {
      return <EditDriverIcon editingByOthers={editingByOthers} driver={driverData.driver} onEdit={onEditClick}
                             margin={'mt-2-negative me-2-negative'}
                             hideEdit={isNil(hideEdit) ? isVisitor(user) : hideEdit}/>
    }
  }

  return <>
    <div hidden={!expanded || decision?.read_only} className="float-end">
      <EditButton />
    </div>
    <ToggleSubDrivers {...{driverData, childrenDrivers, setShowChildren}} />
    {
      driverObject.isCompleted ?
        <CompleteDriverDescription editingByOthers={editingByOthers} description={driverData.driver.question}
                                   onClick={onExpandDriverClick} {...{driverData, expanded}} /> :
        <Description editingByOthers={editingByOthers} description={driverData.driver.question} decision={decision}
                     onClick={onEditClick} user={user} hideEdit={isNil(hideEdit) ? isVisitor(user) : hideEdit}/>
    }
  </>
}
