import React from "react";
import SearchRow from "./SearchRow";
import { resetSearchAction } from "../helpers/SearchRow";
import Select from "react-select";
import {BorderedFilterStyle} from "../../../tree_wizard/styles/choice_entry_styles";
import { TYPES } from "../../../models/forecast/Base";

const FILTER_TYPE = [
  { label: 'All types', value: null },
  { label: 'Forecast', value: TYPES.category_forecast },
  { label: 'New product', value: TYPES.new_product }
]

const SearchFilterStyles = {
  ...BorderedFilterStyle,
  control: styles => {
    return {
      ...styles,
      border: 'none',
      boxShadow: 'none',
      borderRadius: 10,
      fontFamily: 'Montserrat, omnes-pro, "Open Sans", sans-serif',
      height: 48
    }
  },
  option: (styles, {isFocused, isSelected }) => {
    return {
      ...styles,
      border: 'none',
      backgroundColor:  isSelected ? '#2487BF' : '#FFFFFF',
      color: isSelected ? '#FFFFFF' : '#1F2F3F',
      "&:hover": {
        backgroundColor: isSelected ? '#2487BF' : '#F0F6FC',
      }
    }
  }
}

export const SearchFilter = ({selectedFilter, setSelectedFilter}) =>
  <div className="d-inline-flex mw-fit-content">
    <Select options={FILTER_TYPE}
            value={selectedFilter || FILTER_TYPE[0]}
            components={{IndicatorSeparator: () => null}}
            onChange={setSelectedFilter}
            className="forecast-search-filter"
            styles={SearchFilterStyles} show={true}/>
  </div>

export default ({ searchQuery, setSearchQuery, resetSearch }) =>
  <SearchRow {...{ searchQuery, setSearchQuery }}
             placeholder={'Search scenarios'}
             resetSearchAction={() => resetSearchAction(setSearchQuery, resetSearch)} />
