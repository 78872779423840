import React from 'react'
import { valueFormatter } from "../../helpers/common";

const TotalsBlock = ({
                       driver, startValue, endValue,
                       changeValue, changeMeasure = null, changeDecimal = null
                     }) => {
  return <div className="w-100 bg-light rounded-12 py-3 px-2">
    <div className="mb-3">
      <div className="fs-6 mb-2 lh-1">Starting value</div>
      <div className="fw-bolder lh-normal">
        {
          valueFormatter(driver.prepareValueForChart(startValue), { metric: driver?.measureForChart, decimal: driver?.decimal, toCurrencyFormat: true }, ' ')
        }
      </div>
    </div>
    <div className="">
      <div className="fs-6 mb-2 lh-1">Ending value</div>
      <div className="fw-bolder lh-normal">
        {
          valueFormatter(driver.prepareValueForChart(endValue), { metric: driver?.measureForChart, decimal: driver?.decimal, toCurrencyFormat: true }, ' ')
        }
      </div>
    </div>
    {
      (changeValue === Infinity || isNaN(changeValue)) ? null :
        <div className="mt-3">
          <div className="fs-6 mb-2 lh-1">Change value</div>
          <div className="fw-bolder lh-normal">
            {
              valueFormatter(changeValue, {
                metric: changeMeasure || driver?.measureForChart,
                decimal: changeDecimal || driver?.decimal,
                toCurrencyFormat: true
              }, ' ')
            }
          </div>
        </div>
    }
  </div>
}
export default TotalsBlock;
