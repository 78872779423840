import React, { useMemo } from 'react';
import { connect } from "react-redux";
import EditGreyPencil from "../shared/EditGreyPencil";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";
import { DECISION_STATES_DATA } from "../../../models/decision_state";
import { TREE_CHANNEL_ACTIONS } from "../../../../../channels/tree_channel";
import { decisionEditingByUser } from "../../../helpers/channel_helpers";
import LockWithTooltip from "../../../common/LockWithTooltip";

const Icon = ({ decisionObject }) => <>
  <div className={`d-flex ${DECISION_STATES_DATA.completed.color} ${decisionObject.state === DECISION_STATES_DATA.completed.state ? '' : 'd-none'}`}>
    <i className={DECISION_STATES_DATA.completed.icon_classes} />
  </div>
  <div className={`d-flex ${DECISION_STATES_DATA.unstarted.color} ${decisionObject.state === DECISION_STATES_DATA.unstarted.state ? '' : 'd-none'}`}>
    <i className={DECISION_STATES_DATA.unstarted.icon_classes} />
  </div>
  <div className={`d-flex ${DECISION_STATES_DATA.in_flight.color} ${decisionObject.state === DECISION_STATES_DATA.in_flight.state ? '' : 'd-none'}`}>
    <i className={DECISION_STATES_DATA.in_flight.icon_classes} />
  </div>
</>

const DecisionStatus = ({ sidebar, channels, decisionObject, isRecommendation  = false, editingByOthers = false }) => {
  const editMode =  useMemo(() =>
      (sidebar.decisionInputSidebar || sidebar.recommendationInputSidebar),
    [sidebar.decisionInputSidebar, sidebar.recommendationInputSidebar])

  if(editingByOthers) {
    const action = isRecommendation ? TREE_CHANNEL_ACTIONS.edit_recommendation_input : TREE_CHANNEL_ACTIONS.edit_decision_input
    const editingByUser = decisionEditingByUser(channels, action, decisionObject.decision)
    return <LockWithTooltip editingByUser={editingByUser}
                            bubbleIcon={`decision-status-${decisionObject.decision.slug}`}
                            objectText={isRecommendation ? 'recommendation' : 'decision'} />
  }

  if(editMode) return <EditGreyPencil />

  return <TooltipWrapper bubbleIcon={`decision-status-${decisionObject.decision.slug}`}
                         tooltipText={decisionObject.statusTooltipText} isDriver={true}>
    <div className="mt-1 me-2">
      <Icon decisionObject={decisionObject} />
    </div>
  </TooltipWrapper>
}
const mapStateToProps = ({ sidebar, channels }) => ({ sidebar, channels });
export default connect(mapStateToProps, {})(DecisionStatus);