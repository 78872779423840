import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default ({id, title, icon, isOpened, onClick}) => {
  return <div className="overflow-hidden">
    <OverlayTrigger trigger={isOpened ? [] : ["hover", "focus"]}
                    placement='right'
                    overlay={<Tooltip bsClass={`${isOpened ? 'd-none' : ''}`} {...{id}}>{title}</Tooltip>}>
      <div className="side-row text-nowrap pointer with-transition"
           onClick={onClick}>
              <span className="icon d-inline-block text-center ">
                <i className={`fas ${icon} fa-lg`} />
              </span>
        <span className={`${isOpened ? 'text-dark' : 'text-none w-0'} tab-title with-transition`}>{title}</span>
      </div>
    </OverlayTrigger>
  </div>
}
