import { ForecastBaseModel } from "../Base";

export class NpfCmu extends ForecastBaseModel {
  get cmus() {
    return this.attributes.cmus;
  }

  includeCmu(cmu) {
    return this.cmus.includes(parseInt(cmu));
  }

  includeCmus(cmus) {
    return !cmus.some(cmu => !this.includeCmu(cmu));
  }
}
