import React, {useEffect, useRef, useState} from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import {RichTextEditor} from "../../common/RichTextEditor";
import Button from "react-bootstrap/Button";
import {isBlank, isPresent} from "../../helpers/common";
import {saveUserApproval, updateUserApproval} from "../../store/decision/actions";
import Decision from "../../models/decision";

const CommentRow = ({ approvalComment, setApprovalComment, refInput }) =>
  <div className="mb-0" id="approval_comments" >
    <h3>Comments</h3>
    <RichTextEditor quillId="approval comments" ref={refInput}
                    value={approvalComment || ''} setValue={setApprovalComment}
                    placeholder="Enter your notes, explanation, or who was involved in the approval"/>
  </div>

const ApproveRow = ({ approvedDecision, setApprovedDecision, commentActive, setCommentActive, refInput, setCanceled }) => {
  const doApprovedDecision = () => {
    if (approvedDecision) {
      setCanceled(true);
      setApprovedDecision(null);
    } else {
      setCanceled(false);
      setApprovedDecision(true);
    }
    setCommentActive(false);
  }

  const doNotApprovedDecision = () => {
    if (approvedDecision === false) {
      setCanceled(true);
      setApprovedDecision(null);
      setCommentActive(false);
    } else {
      setCanceled(null);
      setApprovedDecision(false);
      setCommentActive(true);
    }
  }

  useEffect(() => {
    commentActive && refInput.current.focus();
  }, [commentActive])

  return <>
    <div className="h3">Is this decision approved?</div>
    <div className="row mb-3">
      <div className="col-12 col-lg-12 d-flex">
        <Button className={`w-100 me-1 ${approvedDecision ? 'btn-primary' : 'btn-secondary'}`} onClick={doApprovedDecision}>Yes</Button>
        <Button className={`w-100 ms-1 ${approvedDecision === false ? 'btn-primary' : 'btn-secondary'}`} onClick={doNotApprovedDecision}>No</Button>
      </div>
    </div>
  </>
}

export const showEnterApprovalModal = (modal) => modal.type === "EnterApprovalModal" && modal.shown;

const EnterApprovalModal = ({
                              shown, onClose, saveUserApproval, current_user,
                              decision, updateUserApproval
                            }) => {

  const decisionObject = new Decision(decision)
  const userApproval = decisionObject.findApprovalForUser(current_user)
  const [approvalComment, setApprovalComment] = useState(userApproval?.approved_comment || '')
  const [approvedDecision, setApprovedDecision] = useState(isPresent(userApproval) ? userApproval?.approved : null)
  const [canceled, setCanceled] = useState(userApproval?.canceled)
  const [commentActive, setCommentActive] = useState(false)
  const [submit, setSubmit] = useState(false)
  const edit = isPresent(userApproval)
  const refInput = useRef()

  const submitData = () => {
    setSubmit(true)
    const callback = (success) => {
      setSubmit(false)
      if (success) onClose();
    }
    const updateUserApprovalData = () => {
      if (approvedDecision === userApproval.approved && approvalComment === userApproval.approved_comment) {
        setSubmit(false)
        return onClose()
      }
      updateUserApproval(data, callback)
    }

    const data = {decision_user_approval: { approved: approvedDecision, approved_comment: approvalComment, canceled }}
    edit ? updateUserApprovalData() : saveUserApproval(data, callback)
  }

  const closeModal = () => {
    if (!edit) {
      setApprovalComment('')
      setApprovedDecision(true)
      setCommentActive(false)
    }
    onClose()
  }

  const disableDone = (isBlank(approvalComment) && approvedDecision === false) || submit || (approvedDecision === null && !canceled)

  useEffect(() => {
    setApprovedDecision(isPresent(userApproval) ? userApproval?.approved : null)
    setApprovalComment(userApproval?.approved_comment)
    setCanceled(userApproval?.canceled)
  }, [shown])

  useEffect(() => {
    approvedDecision === null && isBlank(userApproval?.created_at) && setCanceled(null)
  }, [userApproval])

  return <Modal enforceFocus={false} size="lg" backdrop="static" show={shown} onHide={closeModal}>
    <Modal.Body>
      <div>
        <CloseIcon onClose={closeModal} classParams="float-end ms-2"/>
        <h2>Enter approval</h2>
      </div>
      <ApproveRow {...{ approvedDecision, setApprovedDecision, commentActive, setCommentActive, refInput, setCanceled }} />
      <CommentRow {...{ approvalComment, setApprovalComment, refInput }} />
    </Modal.Body>
    <ModalDoneFooter disabled={disableDone} onClose={submitData} />
  </Modal>
}

const mapStateToProps = ({ decision, current_user }) => ({ decision, current_user });
export default connect(mapStateToProps, { saveUserApproval, updateUserApproval })(EnterApprovalModal);
