import { isPresent } from "../../helpers/common";
import { extractDriverName, rowCellIdKey } from "./common";
import {
  EDITABLE_COMPARISON_HEADERS,
  NOT_EDITABLE_COMPARISON_HEADERS, SALES_FACTS
} from "./ag_grid_vars";
import {isForecastedPeriod} from "../../models/forecast/ForecastScenario";

const TEXT_COLOR = '#2477B2';
const EDITED_BACKGROUND_COLOR = '#E5F5FF';
const EDITABLE_BACKGROUND_COLOR = '#f2f6fa';
const UPDATE_TOTAL_CELL_BG = '#D7F6ED';
const EDITABLE_CELL_STYLES = { backgroundColor: EDITABLE_BACKGROUND_COLOR };
const EDITED_CELL_STYLES_BG = { backgroundColor: EDITED_BACKGROUND_COLOR };
const EDITED_CELL_STYLES_COLOR = { fontStyle: 'italic', color: TEXT_COLOR };
const REQUIRED_RUN_MODEL_CELL_STYLES = { backgroundColor: '#FBF5D8' };
const CAGR_CELL_STYLES = { backgroundColor: '#D7FFFD' };
const UPDATED_TOTAL_CELL_STYLES = { backgroundColor: UPDATE_TOTAL_CELL_BG };
const isUpdatedCell = (params, updatedCells) => {
  const periodId = params.colDef.colId;
  return updatedCells.some(updatedCell => {
    return updatedCell.relatedPeriodIds.includes(periodId) && updatedCell.nodeId === params.node.id
    }
  );
}

const isUpdatedFactTotalCell = (forecastScenario, params, config, timeScale, editedCell, period) => {
  return isEditableFactTotalCell(forecastScenario, params, config, timeScale, period) && editedCell
}

const isNotEditableFactHeader = (cellFactHeader) => NOT_EDITABLE_COMPARISON_HEADERS.includes(cellFactHeader);

const isEditableFactHeaders = (cellFactHeader) => EDITABLE_COMPARISON_HEADERS.includes(cellFactHeader);

const isEditableFactCell = (forecastScenario, params, config, timeScale) => {
  if(isNotEditableFactHeader(params.value)) return false;

  const subFactHeader = params.data?.sub_fact || params.node?.data?.sub_fact;
  if(isNotEditableFactHeader(subFactHeader)) return false;

  const factHeader = extractDriverName(params.data?.Facts || params.node?.data?.Facts);
  return config.isEditableDriver(forecastScenario.editableTimeScaleKey,  extractDriverName(params.node.key) || factHeader)
}

const isEditableFactTotalCell = (forecastScenario, params, config, timeScale, period) => {
  if(!isPresent(params.node.allLeafChildren)) return false;
  if(isNotEditableFactHeader(params.value)) return false;

  const subFactHeader = params.data?.sub_fact || params.node?.data?.sub_fact;
  if(isNotEditableFactHeader(subFactHeader)) return false;

  if(forecastScenario.isEditableTimeScale) {
    return config.isEditableDriver(timeScale, extractDriverName(params.node.key))
  } else {
    return isForecastedPeriod(forecastScenario, period) && config.isEditableDriver(forecastScenario.editableTimeScaleKey, extractDriverName(params.node.key))
  }
}

export const isEditableCell = (forecastScenario, params, config, timeScale, period) => {
  if(isNotEditableFactHeader(params.value)) return false;

  const subFactHeader = params.data?.sub_fact || params.node?.data?.sub_fact;
  if(isNotEditableFactHeader(subFactHeader)) return false;

  const factHeader = extractDriverName(params.data?.Facts || params.node?.data?.Facts);
  return (config.isEditableDriver(forecastScenario.editableTimeScaleKey, factHeader) && isForecastedPeriod(forecastScenario, period) ||
    (isEditableFactHeaders(params.value) && config.isEditableDriver(forecastScenario.editableTimeScaleKey,  extractDriverName(params.node?.parent?.key))) ||
    (isEditableFactHeaders(subFactHeader) && config.isEditableDriver(forecastScenario.editableTimeScaleKey, factHeader)))
}

const isRequiredRunModel = (params, runModelCellsToHighlight) => {
  const periodId = params.colDef.colId;

  return runModelCellsToHighlight.some(runModelCell =>
    runModelCell.nodeId === params.node.id && runModelCell.relatedPeriodIds.includes(periodId)
  );
};

const someEditedCells = (editedCells, node, periodId) => {
  return editedCells.some(editedCell =>
    editedCell.edited && editedCell.nodeId === node.id && editedCell.relatedPeriodIds.includes(periodId)
  )
}

const anyChildEdited = (params, editedCells, periodId) =>
  params.node.allLeafChildren?.some(child => someEditedCells(editedCells, child, periodId));

const currentNodeEdited = (params, editedCells, periodId) =>
  someEditedCells(editedCells, params.node, periodId);

const isEditedCell = (params, editedCells, editedCellsIds) => {
  const periodId = params.colDef.colId;
  return editedCellsIds.includes(rowCellIdKey(params)) ||
    currentNodeEdited(params, editedCells, periodId) || anyChildEdited(params, editedCells, periodId)
};

const isEditedFactCell = (params, editedCellsRowIds) => editedCellsRowIds.includes(params.node.id)

const isEditedFactCellTotal = (params, editedCellsRowIds) => params.node.allLeafChildren?.some(child => editedCellsRowIds.includes(child.id))

export const styledPeriodColumn = (forecastScenario, params, editedCells, editedCellsIds, updatedCells, config, timeScale, runModelCellsToHighlight) => {
  const aggregatedFact = forecastScenario.aggregatedFact;
  if(isPresent(aggregatedFact) && !SALES_FACTS.includes(aggregatedFact.displayName)) return {};

  if (isRequiredRunModel(params, runModelCellsToHighlight)) {
    return REQUIRED_RUN_MODEL_CELL_STYLES;
  }
  if (isUpdatedCell(params, updatedCells)) {
    return UPDATED_TOTAL_CELL_STYLES;
  }
  const periodId = params.colDef?.colId;
  const period = forecastScenario.periods().find(period => period.id === periodId);
  const editedCell = isEditedCell(params, editedCells, editedCellsIds);
  if (isUpdatedFactTotalCell(forecastScenario, params, config, timeScale, editedCell, period)) {
    return UPDATED_TOTAL_CELL_STYLES;
  }
  if (isEditableFactTotalCell(forecastScenario, params, config, timeScale, period)) {
    return EDITABLE_CELL_STYLES;
  }
  if (isEditableCell(forecastScenario, params, config, timeScale, period) && editedCell) {
    return {
      ...EDITED_CELL_STYLES_BG,
      ...EDITED_CELL_STYLES_COLOR
    }
  }
  if (isEditableCell(forecastScenario, params, config, timeScale, period)) {
    return EDITED_CELL_STYLES_BG;
  }
  if (!isEditableCell(forecastScenario, params, config, timeScale, period) && editedCell) {
    return EDITED_CELL_STYLES_BG;
  }
}

export const styledFactsColumn = (forecastScenario, params, editedCellsRowIds, config, timeScale) => {
  if (isEditableFactCell(forecastScenario, params, config, timeScale) && isEditedFactCellTotal(params, editedCellsRowIds)) {
    return UPDATED_TOTAL_CELL_STYLES;
  }
  if (isEditableFactCell(forecastScenario, params, config, timeScale) && isEditedFactCell(params, editedCellsRowIds)) {
    return EDITED_CELL_STYLES_BG;
  }
  if (isEditableFactCell(forecastScenario, params, config, timeScale) && !isPresent(params.node.allLeafChildren)) {
    return EDITED_CELL_STYLES_BG;
  }
  if (isEditableFactCell(forecastScenario, params, config, timeScale) && isPresent(params.node.allLeafChildren)) {
    return EDITABLE_CELL_STYLES;
  }
};

export const styledCAGRColumn = () => {
 return CAGR_CELL_STYLES;
};
