import { GROUP_COL_ID_SUFFIX, FACTS_GR_COL_ID } from "./ag_grid_vars";
import { aggregatedFact, refreshAggregations, getScenarioFiltersJson } from "./common";
import { isBlank, isPresent, expireCookie } from "../../helpers/common";
import { cleanCacheStore, fetchFromCacheStore, saveInCacheStore } from "../../helpers/cookie_helpers";

const factsFilterChanged = (event) => event.columns[0]?.colId === FACTS_GR_COL_ID;

const setAggregatedFact = (forecastScenario, forecast_simulator_scenario, filterModel) => {
  const prevAggregatedFact = forecast_simulator_scenario.ag_grid_aggregated_fact;
  const newAggregatedFact = aggregatedFact(forecastScenario.config, forecastScenario, filterModel);
  if(prevAggregatedFact?.id !== newAggregatedFact?.id) {
    return newAggregatedFact;
  } else {
    return prevAggregatedFact;
  }
};

const updateAggregatedFact = (event, forecastScenario, forecast_simulator_scenario, filterModel, updateScenarioData) => {
  const aggregatedFact = setAggregatedFact(forecastScenario, forecast_simulator_scenario, filterModel);
  forecastScenario.aggregatedFact = aggregatedFact;
  updateScenarioData({ ag_grid_aggregated_fact: aggregatedFact });
  refreshAggregations(event.api);
};

export const saveFilters = (event, forecastScenario, forecast_simulator_scenario, updateScenarioData, setNeedUpdate, triggerAggWorkerRun, updateHandledAggNodes) => {
  // We want to apply this function only when user clicked on filter selection
  if(event.source === 'api') return;

  const filterModel = event.api.getFilterModel();
  setScenarioFiltersToCookies(forecastScenario, filterModel);
  updateScenarioData({ table_fitlers_updated_at: Date.now() });
  if(factsFilterChanged(event)) {
    updateHandledAggNodes([]);
    updateAggregatedFact(event, forecastScenario, forecast_simulator_scenario, filterModel, updateScenarioData);
    triggerAggWorkerRun();
  } else {
    setNeedUpdate(true);
  }
};

export const setScenarioFiltersToCookies = (forecastScenario, filterModel) => {
  saveInCacheStore(`forecast-scenario-filters-${forecastScenario.local_id}`, JSON.stringify(filterModel), { expires: expireCookie() });
};

export const restoreFilters = (event, forecastScenario, forecast_simulator_scenario, updateScenarioData) => {
  const filterModelData = getScenarioFiltersJson(forecastScenario)
  if(filterModelData) {
    event.api.setFilterModel(filterModelData);
    updateAggregatedFact(event, forecastScenario, forecast_simulator_scenario, filterModelData, updateScenarioData);
  }
};

export const clearForecastCookies = (forecastScenario) => {
  cleanCacheStore(`forecast-scenario-filters-${forecastScenario?.local_id}`);
  cleanCacheStore(`forecast-scenario-collapsed-groups-${forecastScenario?.local_id}`);
  cleanCacheStore(`forecast-scenario-pinned-data-${forecastScenario?.local_id}`);
};

export const saveCollapsedGroups = (event, forecastScenario) => {
  let collapsedGroups = fetchFromCacheStore(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`);
  collapsedGroups = collapsedGroups ? JSON.parse(collapsedGroups) : [];

  if (!event.node.expanded) {
    collapsedGroups.push(event.node.id);
  } else {
    collapsedGroups = collapsedGroups.filter(id => id !== event.node.id);
  }
  saveInCacheStore(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`, JSON.stringify(collapsedGroups), { expires: expireCookie() });
};

export const restoreCollapsedGroups = (event, forecastScenario) => {
  const collapsedGroups = fetchFromCacheStore(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`);
  if (!collapsedGroups) return;

  JSON.parse(collapsedGroups).forEach(id => {
    const node = event.api.getRowNode(id);
    if (node) node.setExpanded(false);
  });
};

export const saveColumnState = (event, forecastScenario) => {
  if(isBlank(event?.column?.colId)) return;

  let pinnedColumns = fetchFromCacheStore(`forecast-scenario-pinned-data-${forecastScenario.local_id}`);
  pinnedColumns = pinnedColumns ? JSON.parse(pinnedColumns) : {};

  pinnedColumns[event.column.colId] = event.pinned;
  saveInCacheStore(`forecast-scenario-pinned-data-${forecastScenario.local_id}`, JSON.stringify(pinnedColumns), { expires: expireCookie() });
};

export const restoreColumnState = (event, forecastScenario) => {
  const columnState = fetchFromCacheStore(`forecast-scenario-pinned-data-${forecastScenario.local_id}`);
  if(columnState) {
    const parsedColumnState = JSON.parse(columnState);
    Object.keys(parsedColumnState).forEach(colId => {
      event.api.setColumnsPinned([colId], parsedColumnState[colId]);
    });
  } else {
    initPinnedColumns(event);
  }
};

export const anyScenarioCachedData = (forecastScenario) =>
  isPresent(fetchFromCacheStore(`forecast-scenario-filters-${forecastScenario.local_id}`)) ||
  isPresent(fetchFromCacheStore(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`)) ||
  isPresent(fetchFromCacheStore(`forecast-scenario-pinned-data-${forecastScenario.local_id}`)) ||
  isPresent(getTableOrderFromCookies(forecastScenario)) ||
  isPresent(getTablePageFromCookies(forecastScenario));

const lastAncestorId = (forecast_simulator_scenario, forecastScenario) => {
  let ancestorId;
  const scenario = forecast_simulator_scenario.config_scenarios.find(scenario =>
    scenario.id === forecastScenario.local_id
  );
  if (scenario && scenario.actualized && scenario.actualized.length > 0) {
    ancestorId = scenario.actualized[0].id;
  }
  return ancestorId;
}

const ancestorCachedData = (ancestorId) => {
  const ancestorFilters = fetchFromCacheStore(`forecast-scenario-filters-${ancestorId}`);
  const ancestorCollapsedGroups = fetchFromCacheStore(`forecast-scenario-collapsed-groups-${ancestorId}`);
  const ancestorPinnedData = fetchFromCacheStore(`forecast-scenario-pinned-data-${ancestorId}`);
  const ancestorTableOrder = getTableOrderFromCookies({ local_id: ancestorId });
  const ancestorTablePage = getTablePageFromCookies({ local_id: ancestorId });
  return { ancestorFilters, ancestorCollapsedGroups, ancestorPinnedData, ancestorTableOrder, ancestorTablePage };
}



export const saveCachedDataFromAncestor = (forecast_simulator_scenario, forecastScenario) => {
  if(isBlank(forecast_simulator_scenario.config_scenarios)) return;
  if(anyScenarioCachedData(forecastScenario)) return;

  const ancestorId = lastAncestorId(forecast_simulator_scenario, forecastScenario);
  if(isBlank(ancestorId)) return;

  const {
    ancestorFilters,
    ancestorCollapsedGroups,
    ancestorPinnedData,
    ancestorTableOrder,
    ancestorTablePage
  } = ancestorCachedData(ancestorId);
  if(ancestorFilters) saveInCacheStore(`forecast-scenario-filters-${forecastScenario.local_id}`, ancestorFilters);
  if(ancestorCollapsedGroups) saveInCacheStore(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`, ancestorCollapsedGroups);
  if(ancestorPinnedData) saveInCacheStore(`forecast-scenario-pinned-data-${forecastScenario.local_id}`, ancestorPinnedData);
  if(ancestorTableOrder) saveInCacheStore(`forecast-scenario-table-order-${forecastScenario.local_id}`, ancestorTableOrder);
  if(ancestorTablePage) saveInCacheStore(`forecast-scenario-table-page-${forecastScenario.local_id}`, ancestorTablePage);
}

export const restoreForecastCookies = (event, forecastScenario, forecast_simulator_scenario, updateScenarioData) => {
  restoreFilters(event, forecastScenario, forecast_simulator_scenario, updateScenarioData);
  restoreCollapsedGroups(event, forecastScenario);
  restoreColumnState(event, forecastScenario);
};

export const initPinnedColumns = (event) => {
  const columnState = event.api.getColumnState();
  columnState.forEach(column => {
    if(column.rowGroup) {
      event.api.setColumnsPinned([`${column.colId}-${GROUP_COL_ID_SUFFIX}`], 'left')
    }
  });
};

export const saveTableOrderToCookies = (event, forecastScenario) => {
  saveInCacheStore(`forecast-scenario-table-order-${forecastScenario.local_id}`, event, { expires: expireCookie() });
};

export const getTableOrderFromCookies = (forecastScenario) => {
  return fetchFromCacheStore(`forecast-scenario-table-order-${forecastScenario.local_id}`);
};

export const saveTablePageToCookies = (page, forecastScenario) => {
  saveInCacheStore(`forecast-scenario-table-page-${forecastScenario.local_id}`, page, { expires: expireCookie() });
};

export const getTablePageFromCookies = (forecastScenario) => {
  return fetchFromCacheStore(`forecast-scenario-table-page-${forecastScenario.local_id}`);
};
