import {
  npfColumnFactory, NpfConceptColumn,
  NpfScenarioColumn, NpfScenarioConceptColumn, NpfScenarioConceptOutput, NpfScenarioFeatureColumn
} from "./Column";
import {isBlank, isPresent, qSortArray} from "../../../helpers/common";
import { NpfTimePeriod, NpfTimeScale } from "./TImeScale";
import { NpfCmu } from "./Cmu";

export class NpfConfig {
  constructor(config) {
    this.id = config.data?.id;
    this.attributes = config.data?.attributes;
    if (config.included) {
      try {
        this._initCmus(config.included)
        this._initColumns(config.included)
        this._initTimeScales(config.included)
      } catch (e) {
        console.error(e)
      }
    }
  }

  get cmuHeaders() {
    return this.cmuColumns || []
  }

  get rootCmuHeader() {
    return this.cmuColumns.sort((a, b) => a.sortOrder - b.sortOrder)[0];
  }

  // viewDriversBy(timeScale) {
  //   return this.allFactsColumns.filter(column => column.visibleFor(timeScale))
  // }

  // TODO: implement setting editable flag during initialization instead of checking it here
  // isEditableDriver(timeScale, driverName) {
  //   return this.allFactsColumns.some(column => {
  //     return column.displayName === driverName && column.editableFor(timeScale)
  //   })
  // }

  // periodOptions(timeScale) {
  //   return this.getTimeScaleBy(timeScale)?.timePeriods?.map((periodData) =>
  //     ({
  //       value: periodData.start_date,
  //       start_date: periodData.start_date,
  //       end_date: periodData.end_date,
  //       label: periodData.optionLabel
  //     })
  //   ) || []
  // }

  // get firstForecastedPeriod() {
  //   if (isBlank(this._firstForecastedPeriod)) {
  //     this._firstForecastedPeriod = this.timeScales.flatMap(scale => scale.timePeriods).find(period => period.attributes.first_forecasted)
  //   }
  //   return this._firstForecastedPeriod;
  // }

  // getTimeScaleBy(key) {
  //   return this.timeScales?.find(scale => scale.key === key);
  // }
  //
  // allIntersectedPeriodsIdsByPeriod(period) {
  //   return this.timeScales.flatMap(scale => scale.timePeriods).filter(timePeriod => {
  //     return timePeriod.startDate <= period.endDate && timePeriod.endDate >= period.startDate;
  //   }).map(period => period.id);
  // }
  //
  // get dataTimeScale() {
  //   return this.timeScales.find(scale => scale.isInitData)
  // }
  //
  // get timeScalesOptions() {
  //   return this.timeScales.map(timeScale =>
  //     ({ value: timeScale.key, label: timeScale.displayName })
  //   )
  // }

  _initCmus(included) {
    const cmus = included.filter(({ type }) => type === 'cmu');
    this.cmus = cmus.map(hash => new NpfCmu(hash));
  }

  _initColumns(included) {
    const options = included.filter(({ type }) => type === 'cfo');
    const fields = included.filter(({ type }) => type === 'field');
    const allFields = fields.map(hash => npfColumnFactory(hash, this));
    this.scenarioFields = allFields.filter(column => column instanceof NpfScenarioColumn)
    this.conceptFields = allFields.filter(column => column instanceof NpfConceptColumn)
    this.scenarioConceptFields = allFields.filter(column => column instanceof NpfScenarioConceptColumn)
    this.scenarioConceptOutputs = allFields.filter(column => column instanceof NpfScenarioConceptOutput)
    this.featuresFields = qSortArray(
      allFields.filter(column => column instanceof NpfScenarioFeatureColumn).filter(column => {
        column.fillValues(options)
        return isPresent(column.values);
      }),
      true, column => column.sortOrder
    )
    this.cmuColumns = qSortArray(
      this.scenarioFields.filter(column => {
        column.fillValues(options)
        return isPresent(column.values);
      }), true, column => column.sortOrder
    )
    this.scenarioInputs = this.scenarioFields.filter(column => isBlank(column.values))
    this.packagesColumns = this.conceptFields.filter(column => column.isPackagedField)
  }

  _initTimeScales(included) {
    const timePeriodsData = included.filter(({ type }) => type === 'tp').map(hash => new NpfTimePeriod(hash));
    this.timeScales =
      qSortArray(
        included.filter(({ type }) => type === 'ts').map(hash => new NpfTimeScale(hash)),
        true, column => column.sortOrder
      )
    ;
    this.timeScales.forEach(timeScale => timeScale.fillPeriods(timePeriodsData))
  }
}
