import $ from "jquery";
import React, { useEffect, useRef } from "react";
import {beforeCollapseCallback, hideAssignees, onUnmountZTree} from "../../../tree_editor/tree_view/ZTree";
import { connect, Provider } from "react-redux";
import EntryPoint from "../../../EntryPoint";
import { safeDescription } from "../../../helpers/decision_helpers";
import { useNavigate } from "react-router-dom";
import {
  BASE_NODE_SETTINGS,
  BASE_ZTREE_SETTING
} from "../../../template_set_view/side_panel/sections/TreeSection";
import Decision from "../../../models/decision";
import DecisionSet, { calcSequenceNumber } from "../../../models/decision_set";
import { isBlank, isPresent } from "../../../helpers/common";
import { templateSetParams } from "../../../template_preview/header";
import DraftTitle from "../../../common/DraftTitle";
import { TABS_URLS } from "../../../helpers/home_helpers";
import { accessAlert } from "../../../decision_set_view/tiles/UnstartedTemplateTile";
import { generateSetChildren } from "../../../tree_editor/set_decisions/ZTree";
import { DecisionAssignment, NodePlaceholder } from "../../../tree_editor/set_decisions/nodes/ZTreeDecisionNode";
import {SectionName} from "../../../tree_editor/set_decisions/nodes/ZTreeSectionNode";
import SequenceTitle from "../../../common/SequenceTitle";
import { fetchAssignedToCollaborator } from "../../../helpers/decision_user_helpers";
import { isDraftDecision, isUnstartedDecision } from "../../../models/decision_state";
import { createRoot } from "react-dom/client";
import {cleanExpiredCollapsedDrivers} from "../../../helpers/local_storage_helpers";
import {driverCollapse, driverExpand} from "../../../tree_editor/tree_view/nodes/actions/DriverExpandCollapse";
import {checkVisibleDecisionsEffect} from "../../../helpers/callbacks_helpers";
import { loadVisibleDecisions } from "../../../store/decision_set/actions";
export const COLLAPSED_SET_DRIVERS_STORAGE_KEY = 'collapsedSetDrivers';

const ZTreeDecisionNode = ({ treeNode, decisionSetObj, isDecision, preview }) => {
  if(treeNode.loading) return <NodePlaceholder treeNode={treeNode} />

  const hidden = treeNode.isRoot || treeNode.isSectionNode;
  const statusClass = treeNode.answered ? 'answered' : (treeNode.inFlight ? 'in-flight' : 'non-answered')
  const completionProgress = treeNode.totalCompletionPercentage
  const textClassName = (treeNode.draft || treeNode.unstarted) && decisionSetObj.isSharedVisitor ? 'text-muted' : ''
  return <div className={`d-flex align-items-center h-inherit justify-content-between load-decision-slug-${treeNode.slug}`}>
    <span className={`selectable-area decision-order text-truncate w-100 ${treeNode.active ? 'active py-0' : ''}`}>
      <span hidden={hidden} className={`button ${statusClass}`} />
      {
        treeNode.isRoot || treeNode.isSectionNode ?
          <SectionName treeNode={treeNode} pointer={(isDecision || preview) && decisionSetObj.isSetViewer} /> :
          <span id={`${treeNode.tId}_span`} className="node_name">
            { completionProgress && <span className="fw-bolder text-gray p-0">{completionProgress}% </span> }
            <SequenceTitle treeNode={treeNode}  />
            <DraftTitle draft={treeNode.draft && !treeNode.unstarted}/>
            <span className={textClassName}>{safeDescription({ description: treeNode.name })}</span>
          </span>
      }
    </span>
    {treeNode.isDecisionNode ? <DecisionAssignment treeNode={treeNode} set={decisionSetObj} /> : null}
  </div>
}

const addDiyDom = (treeId, treeNode, decisionSetObj, isDecision, preview) => {
  const $decisionRow = $(`#${treeNode.tId}_a`);
  if(treeNode.isRoot && decisionSetObj.isVisitor) $decisionRow.addClass('cursor-default')
  if(treeNode.active && !$decisionRow.hasClass('curSelectedNode')) $decisionRow.addClass('curSelectedNode');
  if (decisionSetObj.isSharedVisitor) {
    if (treeNode.draft || treeNode.unstarted) $decisionRow.addClass('cursor-default')
  }

  treeNode.root = createRoot($decisionRow[0])
  treeNode.root.render(<Provider store={EntryPoint.instance.store}>
    <ZTreeDecisionNode key={`ztree-template-node-${treeNode.slug}`} treeNode={treeNode} decisionSetObj={decisionSetObj} isDecision={isDecision} preview={preview} />
  </Provider>);
}

const clickOnTreeNode = (treeNode, navigate, decisionSetObj) => {
  if (treeNode.isRoot && decisionSetObj.isVisitor) return false;
  if (treeNode.isSectionNode) return false;
  if (treeNode.active) return false;

  if (decisionSetObj.isSharedVisitor) {
    if (treeNode.draft || treeNode.unstarted) return false;
  }
  if (!treeNode.isRoot && !treeNode.hasAccess) {
    if (treeNode.unstarted) {
      if (decisionSetObj.isSharedVisitor) return false;
      accessAlert();
      return false;
    }
    if (treeNode.draft) return false;

    window.open(`/homepage/${TABS_URLS.decisions_center}?request_access_decision_slug=${treeNode.slug}`, '_blank');
    return true;
  }

  const redirectPath = treeNode.isRoot ?
    `/decision_sets/${decisionSetObj.slug}` :
    treeNode.unstarted ?
      `/templates/${treeNode.template_slug}/preview?decision_set=${decisionSetObj.slug}&decision_slug=${treeNode.slug}` :
      treeNode.draft ?
        `/decisions/${treeNode.slug}/tree_wizard` :
        `/decisions/${treeNode.slug}/tree_viewer`

  if (treeNode.openNewTab) {
    window.open(redirectPath, '_blank');
    return true;
  }

  navigate(redirectPath);
  return true;
}

const staticZTreeSettings = (decisionSetObj, navigate, isDecision, preview, collapsedDriversSlugs = {}, loadVisibleDecisions = () => {}) =>
  ({
    view: { addDiyDom: (treeId, treeNode) => addDiyDom(treeId, treeNode, decisionSetObj, isDecision, preview) },
    callback: {
      onExpand: (event, treeId, treeNode) => driverExpand(treeNode, decisionSetObj.slug, collapsedDriversSlugs, COLLAPSED_SET_DRIVERS_STORAGE_KEY, loadVisibleDecisions),
      onCollapse: (event, treeId, treeNode) => driverCollapse(treeNode, decisionSetObj.slug, collapsedDriversSlugs, COLLAPSED_SET_DRIVERS_STORAGE_KEY),
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, decisionSetObj),
      beforeClick: (treeId, treeNode) => clickOnTreeNode(treeNode, navigate, decisionSetObj)
    }
  })

const isDecisionViewableBy = (decision, decisionSetObj) => {
  if (isUnstartedDecision(decision)) {
    if (decisionSetObj.isManager || decisionSetObj.isSetUser || decisionSetObj.isSharedVisitor) return true;
    if (decisionSetObj.isVisitor) return false;
  }
  if (isDraftDecision(decision)) {
    return decisionSetObj.isManager || decisionSetObj.isSetUser || decisionSetObj.isSharedVisitor;
  }
  return true;
}

const viewableDecisionsBy = (decisionSetObj) =>
  decisionSetObj.decision_set.decisions.filter(decision => isDecisionViewableBy(decision, decisionSetObj))

const nodeObject = (node, decisionSetObj, slug, default_user_avatar_url, collapsedDriversSlugs = {}) => {
  const decisionObject = new Decision(node);
  const inFlight = decisionObject.isInFlight;
  const totalCompletionPercentage = inFlight && decisionObject.totalCompletionPercentage;
  const assignedToUser = hideAssignees(decisionObject) ? null : fetchAssignedToCollaborator(node, decisionObject);
  const isDriverCollapsed = isPresent(collapsedDriversSlugs) ?
    collapsedDriversSlugs[decisionSetObj.slug]?.find(entry => entry.slug === node.slug) :
    false;

  return {
    ...BASE_NODE_SETTINGS,
    open: !isDriverCollapsed,
    default_user_avatar_url,
    loading: node.loading,
    decision: node, isRoot: false,
    inFlight, totalCompletionPercentage,
    users: node.isDecisionNode ? node.users : [],
    isDecisionNode: node.isDecisionNode,
    isSectionNode: node.isSectionNode,
    slug: node.slug,
    template_slug: node.isDecisionNode ? node.template_slug : '',
    hasAccess: node.isDecisionNode ? decisionSetObj.decision_set.accessible_decisions.some(slug => slug === node.slug) : true,
    name: node.isDecisionNode ? node.description : node.name,
    answered: !node.isSectionNode ? decisionObject.isRecordedOrShowAsCompleted : false,
    unstarted: !node.isSectionNode ? isUnstartedDecision(node) : false,
    draft: !node.isSectionNode ? isDraftDecision(node) : false,
    active: !node.isSectionNode ? node.slug === slug : false,
    drag: !node.isSectionNode,
    drop: !node.isSectionNode,
    assigned: node.isDecisionNode && isPresent(assignedToUser) && decisionSetObj.isSetMember,
    assignedToUser: assignedToUser,
    sequence_number: node.isDecisionNode ? node.sequence_number : false
  }
}

const generateChildren = (decision_set, decisionSetObj, { slug = '' }, default_user_avatar_url, openNewTab, collapsedDriversSlugs) => {
  const viewableDecisions = viewableDecisionsBy(decisionSetObj);
  const viewableDecisionSlugs = viewableDecisions.map(({ slug }) => slug);
  const combinedNodes = generateSetChildren(decision_set, false)
  const sections = decisionSetObj.sections;
  return combinedNodes.filter(node => node.isSectionNode || viewableDecisionSlugs.includes(node.slug))
    .map((node) => {
      let result = { ...nodeObject(node, decisionSetObj, slug, default_user_avatar_url, collapsedDriversSlugs), openNewTab }
      if(node.isSectionNode) {
        const sectionDecisions = sections.find(s => s.slug === node.slug )?.decisions?.map((decision, sectionIndex) => {
          const decisionNode = viewableDecisions.find(d => d.slug === decision.slug)
          return decisionNode && { ...decisionNode, sectionIndex }
        })?.filter(hash => isPresent(hash))
        // const sectionDecisions = viewableDecisions.filter((decision) => decision.section_slug === node.slug)
        if(isPresent(sectionDecisions)) {
          result['children'] = sectionDecisions.map(decision =>
            nodeObject({
              ...decision, isDecisionNode: true, isSectionNode: false, sequence_number: calcSequenceNumber(decision_set, node.startIndex, decision.sectionIndex)
            }, decisionSetObj, slug, default_user_avatar_url)
          )
        }
      }
      return result
    })
}

const generateNodes = (decision_set, decisionSetObj, decision, default_user_avatar_url, openNewTab,
                       collapsedDriversSlugs) => ({
  ...BASE_NODE_SETTINGS,
  default_user_avatar_url,
  decision: decisionSetObj.decision_set,
  children: generateChildren(decision_set, decisionSetObj, decision, default_user_avatar_url, openNewTab, collapsedDriversSlugs),
  slug: decisionSetObj.slug,
  name: decisionSetObj.name,
  answered: false,
  unstarted: false,
  draft: false,
  drag: false,
  drop: false
})

const TreeSection = ({
                       zTreeId = 'decisionSetTree',
                       default_user_avatar_url, isDecision = false,
                       preview = false, decision_set, current_user, decision,
                       openNewTab = false, collapsedDriversSlugs, loadVisibleDecisions
                    }) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const { isDecisionSet, decisionSlug } = templateSetParams()
  const currentDecision = isDecisionSet ? { slug: decisionSlug } : decision
  checkVisibleDecisionsEffect(containerRef.current?.parentElement, loadVisibleDecisions);

  useEffect(() => {
    if (isBlank(decision_set.decisions)) return;

    cleanExpiredCollapsedDrivers(COLLAPSED_SET_DRIVERS_STORAGE_KEY);
    const decisionSetObj = new DecisionSet(decision_set, current_user)
    const setting = {
      ...BASE_ZTREE_SETTING,
      ...staticZTreeSettings(decisionSetObj, navigate, isDecision, preview, collapsedDriversSlugs, loadVisibleDecisions)
    }
    const nodes = generateNodes(decision_set, decisionSetObj, currentDecision, default_user_avatar_url, openNewTab,
      collapsedDriversSlugs);
    $.fn.zTree.init($(ref.current), setting, nodes);
    return () => onUnmountZTree(() => {}, zTreeId)
  }, [decision_set.loaded, decision_set.sections, decision_set.decisions, decision_set.name, currentDecision, collapsedDriversSlugs])

  return <div className="px-3" ref={containerRef}>
    <div className={"tree-container decision-order"}>
      {
        isBlank(decision_set.sections) && isBlank(decision_set.decisions) ?
          <div className="fw-bolder py-2">{decision_set.name}</div> :
          <ul id={zTreeId} className="ztree p-0" ref={ref} />
      }
    </div>
  </div>
}
const mapStateToProps = ({ current_user, decision_set, decision, tree }) => ({
  current_user, decision_set, decision, default_user_avatar_url: tree.default_user_avatar_url,
  collapsedDriversSlugs: JSON.parse(localStorage.getItem(COLLAPSED_SET_DRIVERS_STORAGE_KEY))
});
export default connect(mapStateToProps, { loadVisibleDecisions })(TreeSection);
