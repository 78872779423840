import React, { useEffect, useState } from 'react';
import { SmallLoader } from '../../common/Loader';
import { validatedResult } from "../helpers/ag_grid_formulas";
import { valueFormatter, parseFormatData } from "../helpers/common";
import { isBlank } from "../../helpers/common";
import { N_A_AG_GRID_VALUE } from "../helpers/ag_grid_vars";
import { formatCurrencyRegEx } from "../../helpers/common";

export const formatValue = (value) => {
  if (isBlank(value)) return '';

  return value.replace(formatCurrencyRegEx, '$1,');
}

const CustomPeriodCellRenderer = ({ params, forecastScenario }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const { value, node } = params;
    if (value === undefined) {
      setContent(<SmallLoader justifyContent={'justify-content-left'} marginTop={'mt-2'} />);
    } else if (isBlank(node.data)) {
      const value = validatedResult(params.value?.toString());
      const formattedValue = value === N_A_AG_GRID_VALUE ? value : formatValue(value);
      setContent(<div>{formattedValue}</div>);
    } else {
      const value = validatedResult(valueFormatter(params.value?.toString(), parseFormatData(params, forecastScenario)));
      const formattedValue = value === N_A_AG_GRID_VALUE ? value : formatValue(value);
      setContent(<div>{formattedValue}</div>);
    }
  }, [params, forecastScenario]);

  return <div>{content}</div>;
};

export default CustomPeriodCellRenderer;