import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { hideForecastSimulatorTab, TABS_URLS } from "../../helpers/home_helpers";
import { useNavigate } from "react-router-dom";
import Switcher, { TABS } from "./forecast_simulator/Switcher";
import { TABS as SIDEBAR_TABS } from "../../product_forecast/helpers/helpers"
import Search, { SearchFilter as TypeFilter} from "./forecast_simulator/Search";
import ScenariosCount from "./forecast_simulator/ScenariosCount";
import SortByCriteria, { SortByTypes } from "./search/SortByActivity";
import ScenarioItem from "./forecast_simulator/ScenarioItem";
import { Loader } from "../../common/Loader";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";
import { loadContacts } from "../../store/contacts/actions";
import { allContacts } from "../../helpers/filter_helpers";
import {
  needToLoadHomepageSection,
  loadForecastSimulatorData,
  isHomepageSectionLoading, createForecastSimulatorRecord, createNpfSimulatorRecord
} from "../../store/homepage/actions";
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import { homeSearchState } from "./SearchSection";
import NullSearchResults from "./helpers/NullSearchResults";
import CategoryForecastScenarioModal from "../modals/CategoryForecastScenarioModal";
import NpfScenarioModal from "../modals/NpfScenarioModal";
import { showAlert } from "../../store/alerts/actions";
import { DURATION } from "../../alerts";
import * as moment from "moment/moment";
import {StartFromScratchBlock} from "./create_decision_tab/StartFromScratch";

const formatedDate = (h) => moment(h.finalized_at).format('DD MMM, yyyy')

const sortScenarios = (scenarios, sortOrder) => {
  if (isBlank(scenarios)) return [];

  if (sortOrder === SortByTypes.MOST_RECENT) {
    return qSortArray(scenarios, false, row => row.finalized_at || row.data_updated_at || row.data_created_at)
  } else if (sortOrder === SortByTypes.NEWEST) {
    return qSortArray(scenarios, false, row => row.data_created_at)
  } else if (sortOrder === SortByTypes.OLDEST) {
    return qSortArray(scenarios, true, row => row.data_created_at)
  }
  return scenarios;
}

const filterScenarios = (scenarios, { selectedFilter, viewTab, user, searchQuery, findUser }) => {
  let result = scenarios
  if (viewTab === TABS.your_scenarios) {
    result = result?.filter(row => row.user_email === user.email)
  }
  if (isPresent(searchQuery)) {
    const normQuery = searchQuery.toLowerCase();
    result = result?.filter(row => {
      const scenarioUser = findUser(row) || { email: '', full_name: '' };
      return [
        row.display_name,
        scenarioUser.email, scenarioUser.full_name,
        ...(row.actualized || []).flatMap(h => [
          h.display_name || '', formatedDate(h)
        ])
      ].some(value => value.toLowerCase().includes(normQuery))
    })
  }
  if (selectedFilter?.value) {
    result = result?.filter(row => row.forecast_type === selectedFilter?.value)
  }

  return result;
}


const ForecastSimulatorTab = ({
                                home, current_user, current_org, contactsData,
                                loadContacts, loadForecastSimulatorData,
                                createForecastSimulatorRecord, createNpfSimulatorRecord,
                                showAlert
                              }) => {
  const navigate = useNavigate()
  const allContactsData = useMemo(() => allContacts(contactsData, current_user), [contactsData.contacts, current_user]);
  const findUser = useCallback((scenario) => {
    const userEmail = scenario.user_email;
    if (isBlank(userEmail)) return null;

    return (allContactsData.find(i => i.email === userEmail) || {
      value: userEmail,
      email: userEmail
    })
  }, [contactsData])

  const {
    searchQuery, resetSearch, ...searchOpts
  } = homeSearchState(home)
  const [sortOrder, setSortOrder] = useState(SortByTypes.MOST_RECENT);
  const [viewTab, setViewTab] = useState(TABS.all_scenarios);
  const [shownNewModal, setShownModal] = useState(false)
  const [showNpfScenarioModal, setShowNpfScenarioModal] = useState(false);
  const { scenarios, configs, npf_configs } = home.forecast_simulator.data;
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [showLoader, setLoader] = useState(false)
  const [viewScenarios, setViewScenarios] = useState(
    sortScenarios(
      filterScenarios(scenarios, { selectedFilter, viewTab, user: current_user, searchQuery: searchQuery.trim(), findUser }),
      sortOrder
    )
  );
  checkLoadingEffect(contactsData, loadContacts);
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'forecast_simulator')) loadForecastSimulatorData({ tiny: true }, current_org)
  }, [home.forecast_simulator.loaded])
  useEffect(() => {
    setViewScenarios(
      sortScenarios(
        filterScenarios(scenarios, { selectedFilter, viewTab, user: current_user, searchQuery: searchQuery.trim(), findUser }),
        sortOrder
      )
    )
  }, [home.forecast_simulator.data.scenarios, viewTab, sortOrder, searchQuery, selectedFilter])
  useEffect(() => {
    if (isBlank(home.forecast_simulator.error) || !home.forecast_simulator.loaded) return;

    setTimeout(() => {
      showAlert({
        text: home.forecast_simulator.error,
        dismissible: true,
        type: 'danger',
        addClass: 'center-text',
        duration: DURATION
      })
    }, 100)
  }, [home.forecast_simulator])

  useEffect(() => {
    if (hideForecastSimulatorTab(current_org, current_user)) {
      navigate(`/homepage/${TABS_URLS.decisions_center}`);
      return;
    }
    if (home.forecast_simulator.loaded) {
      const noConfigs = isBlank(home.forecast_simulator.data.configs) && isBlank(home.forecast_simulator.data.npf_configs)
      if (noConfigs) navigate(`/homepage/${TABS_URLS.decisions_center}`);
    }
  }, [home.forecast_simulator.loaded]);

  const isLoading = isHomepageSectionLoading(home, 'forecast_simulator');
  const showNullResults = isBlank(viewScenarios) && (isPresent(searchQuery.trim()) || viewTab !== TABS.all_scenarios)

  const resetAllFilters = () => {
    resetSearch();
    setViewTab(TABS.all_scenarios);
  }
  const submitScenario = useCallback((config_id, display_name, callback) => {
    createForecastSimulatorRecord(config_id, { scenario: { display_name }, tiny: true }, (scenario, errors) => {
      callback(scenario, errors)
      if (scenario) {
        setTimeout(() => {
          navigate(`/forecast_simulator/scenarios/${scenario.id}`)
        }, 100)
      }
    })
  }, []);
  const submitNpfScenario = useCallback((config_id, scenario_data, callback) => {
    createNpfSimulatorRecord(config_id, { scenario: scenario_data, tiny: true }, (scenario, errors) => {
      callback(scenario, errors)
      if (scenario) {
        const getParams = new URLSearchParams(document.location.search)
        getParams.set('tab', SIDEBAR_TABS.concepts)
        setTimeout(() => {
          navigate(`/npf_simulator/scenarios/${scenario.id}?${getParams.toString()}`)
        }, 100)
      } else {
        console.error(errors)
      }
    })
  }, []);

  const show_forecast_categories = current_org?.category_forecast;
  const show_new_product_forecast = current_org?.new_product_forecast;
  const showTypeFilter = show_forecast_categories && show_new_product_forecast
  const CreateSection = () => {
    if (!(show_forecast_categories || show_new_product_forecast)) return null;

    return <div className="row">
      <h1>What would you like to do?</h1>
      {
        isPresent(configs) && show_forecast_categories &&
          <StartFromScratchBlock title="Create forecast scenario"  addClass="py-3"
                                 onClick={() => setShownModal(true)} />
      }
      {
        isPresent(npf_configs) && show_new_product_forecast &&
          <StartFromScratchBlock title="Create new product scenario" addClass="py-3"
                                 onClick={() => setShowNpfScenarioModal(true)} />
      }
    </div>
  }

  return <>
    <div className="mx-auto dsight-container">
      <CreateSection />
      <div className="row">
        <div className="col-6">
          <h1>Scenarios</h1>
        </div>
      </div>
      <div className="row">
        <div className={"col search-scenario-block"}>
          <div className="d-flex gap-2 mw-fit-content">
            <Switcher {...{ viewTab, setViewTab }} />
            {
              showTypeFilter && <TypeFilter {...{selectedFilter, setSelectedFilter}} />
            }
          </div>
          <Search {...{searchQuery, resetSearch, ...searchOpts}} />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex">
          <ScenariosCount scenarios_count={(viewScenarios || []).length} />
          <SortByCriteria store={{ sort_order: sortOrder }} onChangeSortingCriteria={setSortOrder} />
        </div>
      </div>
      <div className="row">
        {
          showLoader || isLoading ? <Loader/> :
            showNullResults ? <NullSearchResults {...{home, resetAllFilters}} /> :
              viewScenarios.map((scenario) =>
                <ScenarioItem key={`scenario-homepage-${scenario.id}`} scenario={scenario} user={findUser(scenario)} />
              )
        }
      </div>
    </div>
    <div className="modals">
      {
        show_new_product_forecast &&
        npf_configs &&
        <NpfScenarioModal {...{
          setLoader,
          shown: showNpfScenarioModal,
          onClose: () => setShowNpfScenarioModal(false),
          onSubmit: submitNpfScenario
        }} />
      }
      {
        show_forecast_categories &&
        configs &&
        <CategoryForecastScenarioModal {...{
          setLoader,
          shown: shownNewModal,
          onClose: () => setShownModal(false),
          onSubmit: submitScenario
        }} />
      }
    </div>
  </>
}

const mapStateToProps = ({ home, current_user, current_org, contacts }) => ({
  home, current_user, current_org, contactsData: contacts
});
export default connect(mapStateToProps, {
  loadContacts, loadForecastSimulatorData, createForecastSimulatorRecord, createNpfSimulatorRecord, showAlert
})(ForecastSimulatorTab);
