import {isBlank, isPresent} from "./common";
import Driver from "../models/driver";
import {copyFormattedText} from "../../js/utls/copyToClipboard";
import {DURATION} from "../alerts";
import {useEffect} from "react";

export function answeredChildrenDrivers(driverData, isRoot = false) {
  const children = isRoot ? driverData : driverData.children
  if (isBlank(children)) return [];

  return children.filter(child => {
    const driverObj = new Driver(child.driver);
    return (
      isPresent(child.driver.question) &&
      (
        (driverObj.isMarkAnswered && driverObj.withEnteredExplanation) ||
        driverObj.withEnteredAnswer
      ) &&
      !driverObj.isDraft
    );
  });
}

export function answeredChildrenRatingDrivers(driverData, isRoot = false) {
  const children = isRoot ? driverData : driverData.children;
  if (isBlank(children)) return [];

  return children.filter(child => {
    if (isBlank(child.driver.question)) return false;

    const driverObj = new Driver(child.driver);
    const driverChoices = child.driver.driver_choices;
    const hasRelevantChoices =
      Array.isArray(driverChoices) &&
      driverChoices.some(c => isPresent(c['rating']) || isPresent(c['evaluation']));

    return (
      ((driverObj.isMarkAnswered && driverObj.withEnteredExplanation) || hasRelevantChoices) &&
      !driverObj.isDraft
    );
  });
}

export const copyAssistantSuggestion = async (showAlert, disabled, detailsData) => {
  if (disabled) return;
  const {short_summary, response, file_analysis} = detailsData;

  try {
    await copyFormattedText(short_summary + response + file_analysis);
    showAlert({
      text: 'Summary copied to clipboard',
      type: 'success',
      addClass: 'text-center',
      dismissible: false,
      duration: DURATION
    });
  } catch (error) {
    console.error('Failed to copy:', error);
  }
};

export const summarizedDrivers = (childrenDrivers) => {
  if(isBlank(childrenDrivers)) return '';

  return '<strong>Summarized drivers:</strong><ul>'
    + childrenDrivers.map(child => `<li>${child.driver.question}</li>`).join('')
    + '</ul><p><br/></p>';
};

export const handleAddToDetails = ({setDisabledAddButton, detailsData, type, openRecommendationInputSidebar,
                                     openDecisionInputSidebar, openDriverInputSidebar, driverData, addDataToRecommendationReasons,
                                     addDataToDecisionReasons, addDataToDriverExplanation}) => {
  setDisabledAddButton(true);
  setTimeout(() => {
    const callback = (success) => {
      if (success) {
        setDisabledAddButton(false);
      }
    }
    if (type === 'recommendation') {
      openRecommendationInputSidebar();
      addDataToRecommendationReasons({ details: detailsData }, callback);
    } else if (type === 'decision') {
      openDecisionInputSidebar();
      addDataToDecisionReasons({ details: detailsData }, callback);
    } else if (type === 'driver') {
      const { slug } = driverData.driver;
      openDriverInputSidebar(slug);
      addDataToDriverExplanation({ slug, details: detailsData }, callback);
    }
  }, 200);
};

export const aiObjectSlug = (type, driverData, decision) => {
  return type === 'driver' ?
    driverData?.driver?.slug :
    type === 'recommendation' ?
      decision.recommendation.slug : decision.slug
}

export const shouldCloseSummarizeSidebarCallback = (recommendationInputSidebar, decisionInputSidebar, driverInputSidebar,
                                                    type, driverSlug, gptDriverSlug, sidebar, openSideBar, updateDecisionData, isSidebarOpen) => {
  useEffect(() => {
    const shouldCloseSidebar = (
      (recommendationInputSidebar && type !== 'recommendation') ||
      (decisionInputSidebar && type !== 'decision') ||
      (driverInputSidebar && type !== 'driver') ||
      (type === 'driver' && driverSlug !== gptDriverSlug)
    );

    if (isSidebarOpen && shouldCloseSidebar) {
      openSideBar(false);
      updateDecisionData({ errorAi: '', loadingAiData: false });
    }
  }, [recommendationInputSidebar, decisionInputSidebar, driverInputSidebar]);
}
