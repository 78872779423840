import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {updateDriver} from "../../../store/sidebar/actions";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import {
  isVisitor,
} from "../../../helpers/user_helpers";
import {CheckForm} from "../../../tree_wizard/helpers/DecisionFlowRow";
import { isNil } from "ramda";

const AnsweredByAiSection = ({
                              driverData, current_user,
                              updateDriver, decision,
                              current_org
                            }) => {
  if (!current_org.allow_gpt || isDSightDecision(decision) || isVisitor(current_user)) return null;

  const { driver } = driverData;
  const { answered_by_ai } = driver;
  if (isNil(answered_by_ai)) return null;

  const [submit, setSubmit] = useState(true);
  const [answeredByAi, setAnsweredByAi] = useState(answered_by_ai)
  const { slug } = driver;

  const onChangeAnsweredByAi = () => {
    setSubmit(false);
    const callback = (success) => {
      success && setSubmit(true);
    };
    updateDriver({ slug, answered_by_ai: !answeredByAi }, callback);
  };

  useEffect(() => {
    setAnsweredByAi(driver.answered_by_ai)
  }, [driver.answered_by_ai])

  return <div className='px-3 mb-3'>
    <CheckForm
      label={'Attribute response to CloverpopAI'}
      disabled={!submit}
      className={'mb-0'}
      type={'checkbox'}
      checked={answeredByAi === true}
      onClick={onChangeAnsweredByAi}
    />
  </div>
}

const mapStateToProps = ({ decision, current_user, current_org }) => ({
  decision, current_user, current_org
});
export default connect(mapStateToProps, {updateDriver})(AnsweredByAiSection);