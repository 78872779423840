import React, {useState, useEffect, useCallback, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import DropdownWithSearch from "../../common/DropdownWithSearch"
import { SmallLoader } from "../../common/Loader";
import { isPresent } from "../../helpers/common";
import { filterDropdownScenarios, filteredOptions, prepareAllOptionsArray } from "../helpers/helpers";

const ScenarioName = ({ npf_simulator_scenario }) => {
  const navigate = useNavigate();

  const allOptions = useMemo(() =>
    [...filterDropdownScenarios(npf_simulator_scenario)]
    , [npf_simulator_scenario.config_scenarios])
  const allOptionsArray = useMemo(() => prepareAllOptionsArray(allOptions), [allOptions])

  const [selectedOption, setSelectedOption] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredScenarios, setFilteredScenarios] = useState(allOptions)
  useEffect(() => {
      setSelectedOption(
        allOptionsArray.find(h => h.id === npf_simulator_scenario.scenario_id)?.display_name
      )
  }, [npf_simulator_scenario.scenario, allOptions]);
  useEffect(() => {
    setFilteredScenarios(
      filteredOptions(allOptions, searchQuery.trim().toLowerCase())
    )
  }, [searchQuery, npf_simulator_scenario.config_scenarios]);
  const onSelectScenario = useCallback(scenario_id => {
    if (scenario_id === npf_simulator_scenario.scenario_id) return false;

    const getParams = new URLSearchParams(document.location.search)
    navigate(`/npf_simulator/scenarios/${scenario_id}?${getParams.toString()}`)
    return false;
  }, [document.location.search, npf_simulator_scenario.scenario_id])

  const selectedOptionValue = <span className="text-ellipsis text-primary">{selectedOption}  </span>

  return <div className="d-flex flex-row me-2">
    <div className="d-inline-flex my-auto me-1">Scenario:</div>
    {
      isPresent(npf_simulator_scenario.scenario_error) ?
        <span>{npf_simulator_scenario.scenario_error}</span> :
        isPresent(npf_simulator_scenario.scenario_hint) ?
          <span>{npf_simulator_scenario.scenario_hint}</span> :
          !npf_simulator_scenario.loaded ? <SmallLoader /> :
            allOptionsArray.length === 1 ?
              <div className="d-inline-flex my-auto text-primary fw-medium text-ellipsis">{selectedOption}</div> :
              <div className="d-inline-flex" hidden={!npf_simulator_scenario.loaded}>
                <DropdownWithSearch {...{
                  paddingClasses: 'px-0',
                  subKey: 'scenario',
                  selectedOption: selectedOptionValue,
                  searchQuery, setSearchQuery,
                  onClick: onSelectScenario,
                  active: npf_simulator_scenario.scenario_id,
                  options: filteredScenarios
                }} />
              </div>
    }
  </div>
}
export default ScenarioName;
